import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { get } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { getUserTxRole } from '../../util/transaction';
import { useParams } from 'react-router-dom';
import {
  ensureUser,
  getBookingConfig,
} from '../../util/data';
import BookingTimeForm from '../../forms/EditTripForm/BookingTimeForm';

import {
  BookingInfoSection,
  IconSpinner,
  Logo,
  NamedLink,
  Page,
  PartnerInfo,
} from '../../components';
import {
  initiateSpeculativeUpdateBooking,
  loadData,
  tripDatesSet,
  updateBookingClick,
} from './EditTripPage.duck';
import {
  $bookingTx,
  $estimatedTx,
  $estimatedTxError,
  $isEstimatedTxPending,
  $isShowEstimatedBreakdown,
  $isSubmitButtonDisable,
  $isSubmitButtonInProgress,
  $tripBookingDates,
  $tripDates,
  $updateBookingFormError,
} from './EditTripPage.selectors';

import css from './EditTripPage.css';
import { fetchTimeSlots, requestToUpdateBooking } from '../TransactionPage/TransactionPage.duck';
import { estimateBreakdown, resetCode } from '../ListingPage/ListingPage.duck';
import { REQUEST_TO_BOOK_BUTTON_ID } from '../../util/gtm/gtmConstants';
import { createSlug } from '../../util/urlHelpers';
import { convertMoneyToNumber } from '../../util/currency';
import { initializeCardPaymentData } from '../../ducks/stripe.duck';
import TripModificationPeriod from '../../components/TripModificationPeriod/TripModificationPeriod';
import EstimatedBreakdownMaybe from '../../forms/BookingTimeForm/EstimatedBreakdownMaybe';
import { addSeconds, isAfter } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const PROVIDER = 'provider';
const CUSTOMER = 'customer';
const MAX_MOBILE_SCREEN_WIDTH = 768;
const MAX_TABLET_SCREEN_WIDTH = 1024;
const TODAY = new Date();
const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

// TransactionPage handles data loading for Sale and Order views to transaction pages in Inbox.
export const EditTripPageComponent = props => {
  const {
    tripDates,
    isSubmitButtonInProgress,
    isSubmitButtonDisable,
    isShowEstimatedBreakdown,
    updateBookingFormError,
    tripBookingDates,
    scrollingDisabled,
    transaction,
    intl,
    transactionRole,
    viewport,
    currentUser,
    history,
    timeSlots,
    monthlyTimeSlots,
    unitType,
    onSubmit,
    isOwnListing,
    onFetchTimeSlots,
    onResetCode,
    checkedCode,
    checkCodeErorr,
    onCheckingVoucher,
    checkCodeInProgress,
    onEstimateBreakdown,
    estimateBreakdownInProgress,
    estimateError,
    estimatedTx,
    initialDate,
    onRequestToUpdateBooking,
    location,
    onInitiateSpeculativeUpdateBooking,
    onTripDatesSet,
    onUpdateBookingClick,
    onManageDisableScrolling
  } = props;

  const [childTransaction, setChildTransaction] = useState({});

  const [updatedStartDate, setUpdatedStartDate] = useState(null);
  const [updatedStartTime, setUpdatedStartTime] = useState(null);
  const [updatedEndDate, setUpdatedEndDate] = useState(null);
  const [updatedEndTime, setUpdatedEndTime] = useState(null);

  const currentListing = transaction && transaction.listing;
  const listingTitle = currentListing && currentListing.attributes.title;
  const listingId = currentListing && currentListing.id;
  const title = intl.formatMessage({ id: 'TripDetailsPage.title' }, { title: listingTitle });

  const { id, action } = useParams();

  const isTripExtension = action === 'extend-trip'

  const price =
    currentListing && currentListing.attributes ? currentListing.attributes.price : null;
  const transactionTimezone = transaction && transaction.attributes && transaction.attributes.protectedData && transaction.attributes.protectedData.transactionTimezone;
  const localTimeZone = getDefaultTimeZoneOnBrowser(transactionTimezone);
  const timeZone =
    currentListing &&
    currentListing.attributes.availabilityPlan &&
    currentListing.attributes.availabilityPlan.timezone;
  const listingParams = currentListing
    ? {
        slug: createSlug(currentListing && currentListing.attributes.title),
        id: currentListing && currentListing.id.uuid,
      }
    : null;
  const bookingConfig = currentListing ? getBookingConfig(currentListing) : null;
  const isNewCar = currentListing ? get(currentListing, 'attributes.metadata.isNewCar') : false;
  const isLongTerm = currentListing
    ? get(transaction, 'attributes.protectedData.isLongTermRental')
    : false;

  const startTime = tripBookingDates && new Date(tripBookingDates.start).getTime();
  const endTime = tripBookingDates && new Date(tripBookingDates.end).getTime();

  const formatDateTime = (utcDate, timezone) => {
    if (!utcDate || !timezone) return ""; // Prevents blank issue
    return moment.utc(utcDate).tz(timezone).format("DD MMM, h:mm A");
  };

  const currentTripText =
    transactionTimezone &&
    tripBookingDates?.start &&
    tripBookingDates?.end
      ? `Current trip: ${formatDateTime(tripBookingDates.start, transactionTimezone)} - ${formatDateTime(tripBookingDates.end, transactionTimezone)}`
      : null;


  const initialValues = {
    bookingStartDate: { date: tripBookingDates.start },
    bookingEndDate: { date: tripBookingDates.end },
    bookingStartTime: startTime ? startTime.toString() : null,
    bookingEndTime: endTime ? endTime.toString() : null,
  };

  const currentProvider = transaction && ensureUser(transaction.provider);
  const currentCustomer = transaction && ensureUser(transaction.customer);

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const isTabletLayout = viewport.width < MAX_TABLET_SCREEN_WIDTH;

  const currentUserRole =
    currentUser && currentUser.id && transaction && getUserTxRole(currentUser.id, transaction);
  const isProviderRole = currentUserRole === PROVIDER;
  const isCustomerRole = currentUserRole === CUSTOMER;

  const pageProps = {
    title,
    scrollingDisabled,
    className: css.root,
  };

  const listingRegularPrice = get(currentListing, 'attributes.price');
  const listingPeakPrice = get(currentListing, 'attributes.publicData.pricing.peakPrice');
  const hourlyRegularPrice = get(currentListing, 'attributes.publicData.pricing.hourlyRegularPrice');
  const hourlyPeakPrice = get(currentListing, 'attributes.publicData.pricing.hourlyPeakPrice');
  const initialTransactionPrice = get(transaction, 'attributes.protectedData.pricing');

  const isListingPriceIncreased = (
    transaction &&
    currentListing &&
    initialTransactionPrice &&
    (
      (listingPeakPrice && initialTransactionPrice.peakPrice &&
        listingPeakPrice.amount > initialTransactionPrice.peakPrice.amount * 100) ||

      (listingRegularPrice && initialTransactionPrice.regularPrice &&
        listingRegularPrice.amount > initialTransactionPrice.regularPrice.amount * 100) ||

      (hourlyPeakPrice && initialTransactionPrice.hourlyPeakPrice &&
        hourlyPeakPrice.amount > initialTransactionPrice.hourlyPeakPrice.amount * 100) ||

      (hourlyRegularPrice && initialTransactionPrice.hourlyRegularPrice &&
        hourlyRegularPrice.amount > initialTransactionPrice.hourlyRegularPrice.amount * 100)
    )
  );

  const isPriceDecreasedForModification = estimatedTx && (estimatedTx.attributes.payinTotal.amount < estimatedTx.attributes.protectedData.initialPayment);

  const headingText = () => {
    if (!updatedEndDate && !updatedStartDate) {
      return <FormattedMessage id="BookingPanel.selectTripStartDate" />
    }

    if (updatedStartDate && !updatedEndDate) {
      return <FormattedMessage id="BookingPanel.selectTripEndDate" />;
    }

    if (updatedStartDate && updatedEndDate && !updatedStartTime && !updatedEndTime) {
      return <FormattedMessage id="BookingPanel.selectTripStartTime" />;
    }

    if (updatedStartDate && updatedEndDate && updatedStartTime && !updatedEndTime) {
      return <FormattedMessage id="BookingPanel.selectTripEndTime" />;
    }

    if (updatedStartDate && updatedEndDate && updatedStartTime && updatedEndTime) {
      return <FormattedMessage id="BookingPanel.selectTripEndTime" />;
    }
  }

  const subHeadingText = () => {
    if (!updatedEndDate && !updatedStartDate) {
      return <FormattedMessage id="BookingPanel.selectTripStartDateDesc" />
    }

    if (updatedStartDate && !updatedEndDate) {
      return <FormattedMessage id="BookingPanel.selectTripEndDateDesc" />;
    }

    if (updatedStartDate && updatedEndDate && !updatedStartTime && !updatedEndTime) {
      return <FormattedMessage id="BookingPanel.selectTripStartTimeDesc" />;
    }

    if (updatedStartDate && updatedEndDate && updatedStartTime && !updatedEndTime) {
      return <FormattedMessage id="BookingPanel.selectTripEndTimeDesc" />;
    }

    if (updatedStartDate && updatedEndDate && updatedStartTime && updatedEndTime) {
      return <FormattedMessage id="BookingPanel.selectTripEndTimeDesc" />;
    }
  }

  const getChildTransactionData = value => {
    const mapedLineItems = value.lineItems.map(item => {
      return {
        ...item,
        lineTotal: convertMoneyToNumber(item.lineTotal),
        unitPrice: convertMoneyToNumber(item.unitPrice),
      };
    });

    const childTransaction = {
      lineItems: mapedLineItems,
      bookingStart: moment(value.bookingStart).format('YYYY-MM-DD HH:mm:ss'),
      bookingEnd: moment(value.bookingEnd).format('YYYY-MM-DD HH:mm:ss'),
      payinTotal: convertMoneyToNumber(value.payinTotal),
      transactionId: value.transactionId,
      transaction: value.transaction,
    };

    setChildTransaction(childTransaction);
  };

  const transactionId = get(transaction, 'id.uuid')

  const onSubmitUpdateBooking = values => {
    onUpdateBookingClick(props.history, isTripExtension, transactionId, isListingPriceIncreased);
  };

  const ExtendTripBanner = () => {
    let statusTitle = <FormattedMessage id="BookingPanel.extensionAvailable"  values={{availableUptoDate: latestAvailableUptoDate}}/>;
    let statusText = <FormattedMessage id="BookingPanel.extensionAvailableDesc" />;

    return (
      <div className={css.extendTripBannerContainer}>
        <h4 className={css.statusTitleExtendTrip}>
          {statusTitle}
        </h4>
        <p className={css.statusTextExtendTrip}>{statusText}</p>
      </div>
    );
  };

  const CannotExtendTripBanner = () => {
    let statusTitle = <FormattedMessage id="BookingPanel.cannotExtendTrip"/>;
    let statusText = <FormattedMessage id="BookingPanel.cannotExtendTripDesc" />;

    return (
      <div className={css.cannotExtendTripBannerContainer}>
        <h4 className={css.statusTitleExtendTrip}>
          {statusTitle}
        </h4>
        <p className={css.statusTextExtendTrip}>{statusText}</p>
      </div>
    );
  };

  const topbar = (
    <div className={css.topbar}>
      <NamedLink className={css.home} name="LandingPage">
        <Logo
          className={css.logoMobile}
          title={intl.formatMessage({
            id: 'CheckoutPage.goToLandingPage',
          })}
          format="mobile"
        />
        <Logo
          className={css.logoDesktop}
          alt={intl.formatMessage({
            id: 'CheckoutPage.goToLandingPage',
          })}
          format="desktop"
        />
      </NamedLink>
    </div>
  );

  function extractAllTimeSlots(monthlyTimeSlots) {
    let allTimeSlots = [];
    for (let month in monthlyTimeSlots) {
      if (monthlyTimeSlots.hasOwnProperty(month)) {
        allTimeSlots = allTimeSlots.concat(monthlyTimeSlots[month].timeSlots);
      }
    }
    return allTimeSlots;
  }

  const allTimeslots = extractAllTimeSlots(monthlyTimeSlots)

  const validTimeSlots = allTimeslots.filter(timeSlot => timeSlot && timeSlot.attributes);

  function mergeTimeSlots(timeSlots) {
    if (!Array.isArray(timeSlots) || timeSlots.length === 0) return [];
    let mergedSlots = [];
    let prevSlot = timeSlots[0];
    for (let i = 1; i < timeSlots.length; i++) {
      let currentSlot = timeSlots[i];
      if ((prevSlot.attributes.end).getTime() === (currentSlot.attributes.start).getTime()) {
        prevSlot = {
          ...prevSlot,
          attributes: {
            ...prevSlot.attributes,
            end: currentSlot.attributes.end
          }
        };
      } else {
        mergedSlots.push(prevSlot);
        prevSlot = currentSlot;
      }
    }
    mergedSlots.push(prevSlot);
    return mergedSlots;
  }

  const newMergedTimeSlots =  mergeTimeSlots(validTimeSlots)

  const getLatestAvailableSlot = (currentBookingEnd) => {
    const availableSlot = newMergedTimeSlots
      .map(slot => {
        const slotEnd = slot.attributes.end;
        const slotStart = slot.attributes.start;
        return { slotStart, slotEnd };
      })
      .filter(
        slot => isAfter(slot.slotStart, currentBookingEnd),
      )
      .sort((a, b) => a.slotStart.getTime() - b.slotStart.getTime());

    const currentBookingEnd_Padding = addSeconds(
      currentBookingEnd,
      2 * 60 * 60,
    );

    if (
      currentBookingEnd_Padding?.getTime() !==
      availableSlot[0]?.slotStart?.getTime()
    ) {
      return null;
    }

    if (availableSlot.length === 0) return null; // No slots = block all
    return availableSlot[0].slotEnd; // First available slot's end time
  };
  const initialEndDate = get(transaction, 'booking.attributes.displayEnd');
  const availableUptoDate = getLatestAvailableSlot(initialEndDate);

  function adjustBookingEndDate(bookingEndDate) {
    const now = new Date();
    const sixMonthsLater = new Date(now.getFullYear(), now.getMonth() + 7, 0);
    sixMonthsLater.setHours(23, 30, 0, 0); // Moves to the last day of the previous month (last day of six months later)
    const latestAvailableDateTime_format = new Date(availableUptoDate).getTime();
    console.log("sixMonthsLater", sixMonthsLater);
    console.log("latestAvailableDateTime_format", latestAvailableDateTime_format);

    if (latestAvailableDateTime_format > (sixMonthsLater).getTime()) {
      bookingEndDate = sixMonthsLater;
      console.log('Updated Booking End Date:', bookingEndDate);
    } else {
      bookingEndDate = availableUptoDate;
    }
    return bookingEndDate;
  }

  const adjustedUptoDate = adjustBookingEndDate(initialEndDate);

  console.log('adjustedUptoDate', adjustedUptoDate)

  const latestAvailableUptoDate = transaction && transaction.booking ? moment(adjustedUptoDate).tz(transactionTimezone).format('DD MMM, h:mm A') : null;

  const editTripSection = (
    <>
      { !isTripExtension &&
        <div className={css.titleContainer}>
          <h3 className={css.updateBookingTitle}>{headingText()}</h3>
          {currentTripText && (
            <p className={css.updateBookingSubTitle}>
              Current trip:{' '}
              <strong className={css.timeText}>
                {formatDateTime(tripBookingDates.start, transactionTimezone)}
              </strong>{' '}
              -{' '}
              <strong className={css.timeText}>
                {formatDateTime(tripBookingDates.end, transactionTimezone)}
              </strong>
            </p>
          )}
          <p className={css.updateBookingSubTitle}>{subHeadingText()}</p>
        </div>
      }

      { isTripExtension &&
        <div className={css.titleContainer}>
          <h3 className={css.updateBookingTitle}>
            <FormattedMessage id="BookingPanel.selectNewTripEndDate" />
          </h3>
          {currentTripText && (
            <p className={css.updateBookingSubTitle}>
              Current trip end:{' '}
              <strong className={css.timeText}>
                {formatDateTime(tripBookingDates.end, transactionTimezone)}
              </strong>
            </p>
          )}
          <p className={css.updateBookingSubTitle}>
            <FormattedMessage id="BookingPanel.selectTripEndDateDesc" />
          </p>
        </div>
      }
      {transaction && transaction.booking ? (
        <BookingTimeForm
          tripDates={tripDates}
          isSubmitButtonInProgress={isSubmitButtonInProgress}
          isSubmitButtonDisable={isSubmitButtonDisable}
          isShowEstimatedBreakdown={isShowEstimatedBreakdown}
          updateBookingFormError={updateBookingFormError}
          currentUser={currentUser}
          timeSlots={timeSlots}
          monthlyTimeSlots={monthlyTimeSlots}
          className={css.bookingForm}
          formId="BookingPanel"
          submitButtonWrapperClassName={css.submitButtonWrapper}
          unitType={unitType}
          onSubmit={onSubmitUpdateBooking}
          price={price}
          isOwnListing={isOwnListing}
          listingId={listingId}
          onFetchTimeSlots={onFetchTimeSlots}
          startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
          endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
          timeZone={transactionTimezone}
          listing={currentListing}
          onResetCode={onResetCode}
          checkedCode={checkedCode}
          checkCodeErorr={checkCodeErorr}
          checkCodeInProgress={checkCodeInProgress}
          onCheckingVoucher={onCheckingVoucher}
          onEstimateBreakdown={onEstimateBreakdown}
          estimateBreakdownInProgress={estimateBreakdownInProgress}
          estimatedTx={estimatedTx}
          requestButtonId={REQUEST_TO_BOOK_BUTTON_ID}
          listingParams={listingParams}
          bookingConfig={bookingConfig}
          initialDate={initialDate}
          estimateError={estimateError}
          initialValues={initialValues}
          localTimeZone={localTimeZone}
          isNewCar={isNewCar}
          isLongTerm={isLongTerm}
          transaction={transaction}
          updateBooking={true}
          onRequestToUpdateBooking={onRequestToUpdateBooking}
          getChildTransactionData={getChildTransactionData}
          location={location}
          onTripDatesSet={onTripDatesSet}
          onInitiateSpeculativeUpdateBooking={onInitiateSpeculativeUpdateBooking}
          setUpdatedStartDate={setUpdatedStartDate}
          setUpdatedStartTime={setUpdatedStartTime}
          setUpdatedEndDate={setUpdatedEndDate}
          setUpdatedEndTime={setUpdatedEndTime}
          updatedStartDate={updatedStartDate}
          updatedStartTime={updatedStartTime}
          updatedEndDate={updatedEndDate}
          updatedEndTime={updatedEndTime}
          allTimeslots={validTimeSlots}
          isTripExtension={isTripExtension}
          availableUptoDate={adjustedUptoDate}
          boundsMergedTimeSlots={newMergedTimeSlots}
        />
      ) : (
        <div className={css.loadingTimeSlotWrapper}>
          <IconSpinner />
        </div>
      )}
    </>
  );

  return (
    <Page {...pageProps}>
      {topbar}
      <div className={css.contentContainer}>
        <div className={css.contentRow}>
          <div className={css.addonsPageTitles}>
            <div className={css.addonsBreadcrumbs}>
              {transaction && (
                <NamedLink
                  className={css.arrowBack}
                  name={isCustomerRole ? 'OrderDetailsPage' : 'SaleDetailsPage'}
                  params={{ id: transaction.id.uuid }}
                >
                  <span className={css.arrowBackText}>&#8249;</span>

                  {listingTitle}
                </NamedLink>
              )}
            </div>
            <h3 className={css.policiesSubTitle}>
              {isTripExtension ? (
                <FormattedMessage id="BookingPanel.extendTripTitle" />
              ) : (
                <FormattedMessage id="BookingPanel.modifyBookingTitle" />
              )}
            </h3>
          </div>
        </div>
        <div className={css.contentRow}>
          <div className={css.addonsSectionLeftRoot}>
            {isTripExtension && transaction ? (
              adjustedUptoDate ? (
                <ExtendTripBanner />
              ) : (
                <CannotExtendTripBanner />
              )
            ) : isTripExtension && (
              <div className={css.loadingTimeSlotWrapper}>
                <IconSpinner />
              </div>
            )}
            {isTripExtension && !adjustedUptoDate ? (
              <div></div>
            ) : (
              <div className={css.addonsSectionLeft}>{editTripSection}</div>
            )}

            {!isSubmitButtonDisable &&
              ((updatedStartDate && updatedEndDate && updatedStartTime && updatedEndTime && !isTripExtension) || isTripExtension) && (
                <div>
                  <TripModificationPeriod transaction={estimatedTx} />

                  <EstimatedBreakdownMaybe
                    unitType={unitType}
                    estimating={estimateBreakdownInProgress}
                    estimatedTx={estimatedTx}
                    isEditTripPage
                    onManageDisableScrolling={onManageDisableScrolling}
                    isTripExtension={isTripExtension}
                    isListingPriceIncreased={isListingPriceIncreased}
                    isPriceDecreasedForModification={isPriceDecreasedForModification}
                  />
                </div>
              )}
          </div>

          {isMobileLayout ? null : (
            <div className={css.addonsSectionRight}>
              <div>
                {transaction && currentListing ? (
                  <BookingInfoSection
                    transaction={transaction}
                    isCustomer={isCustomerRole}
                    showAvatar={false}
                    listing={currentListing}
                    currentProvider={currentProvider}
                    intl={intl}
                  />
                ) : (
                  <div className={css.loadingTimeSlotWrapper}>
                    <IconSpinner />
                  </div>
                )}
                {transaction ? (
                  <PartnerInfo
                    currentUserRole={transactionRole}
                    transaction={transaction}
                    otherUser={isProviderRole ? currentCustomer : currentProvider}
                    show={true}
                    isMobileLayout={isMobileLayout}
                    userTypeText={intl.formatMessage({
                      id: isCustomerRole
                        ? 'TripDetailsPage.userTypeText'
                        : 'TripDetailsPage.userTypeTextHost',
                    })}
                  />
                ) : (
                  <div className={css.loadingTimeSlotWrapper}>
                    <IconSpinner />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};

EditTripPageComponent.defaultProps = {
  currentUser: null,
  transaction: null,
};

const { bool, func, oneOf, shape, string, arrayOf, number } = PropTypes;

EditTripPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  transaction: propTypes.transaction,
  transactionRole: oneOf([PROVIDER, CUSTOMER]).isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    nextLongTermTransaction,
    childLongTermTransactions,
    currentChildLongTermTransaction,
  } = state.TripDetailsPage;
  const { currentUser } = state.user;
  const { monthlyTimeSlots } = state.TransactionPage;
  const { timeSlots } = state.ListingPage;

  const transaction = $bookingTx(state);
  if (nextLongTermTransaction && transaction) {
    transaction.nextTransaction = nextLongTermTransaction;
    transaction.childTransaction = childLongTermTransactions;
    transaction.currentChildTransaction = currentChildLongTermTransaction;
  }

  return {
    currentUser,
    transaction,
    monthlyTimeSlots,
    estimatedTx: $estimatedTx(state),
    estimateBreakdownInProgress: $isEstimatedTxPending(state),
    timeSlots,
    estimateError: $estimatedTxError(state),
    tripBookingDates: $tripBookingDates(state),
    updateBookingFormError: $updateBookingFormError(state),
    isShowEstimatedBreakdown: $isShowEstimatedBreakdown(state),
    isSubmitButtonDisable: $isSubmitButtonDisable(state),
    isSubmitButtonInProgress: $isSubmitButtonInProgress(state),
    tripDates: $tripDates(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    onFetchTimeSlots: (listingId, start, end, timeZone) =>
      dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
    onResetCode: () => dispatch(resetCode()),
    onEstimateBreakdown: params => dispatch(estimateBreakdown(params)),
    onRequestToUpdateBooking: params => dispatch(requestToUpdateBooking(params)),
    callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
    onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
    onTripDatesSet: () => dispatch(tripDatesSet()),
    onInitiateSpeculativeUpdateBooking: dates => dispatch(initiateSpeculativeUpdateBooking(dates)),
    onUpdateBookingClick: (history, isTripExtension, transactionId, isListingPriceIncreased) =>
      dispatch(updateBookingClick(history, isTripExtension, transactionId, isListingPriceIncreased)),
  };
};

const EditTripPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(EditTripPageComponent);

EditTripPage.loadData = loadData;

export default EditTripPage;
