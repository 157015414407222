/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { memo, useEffect, useState } from 'react';
import classNames from 'classnames';
import BLandingCss from './LandingPageNew.css';
import isEqual from 'lodash/isEqual';
import insuranceLogo from '../../assets/insurance-logo.svg';
import Typewriter from 'typewriter-effect';
import { FormattedMessage } from 'react-intl';
import { NamedLink, PrimaryButton } from '../../components';
import LandingPageSignupSection from './LandingPageSignupSection';
import { useHistory, useLocation } from 'react-router';
import { parse } from '../../util/urlHelpers';
import { checkSignUpCode } from '../../util/referralProgram';
import imageSource from '../../util/imageSource';

const ImageComponent = ({ src1x, src2x, children }) => {
  return (
    <div className={BLandingCss.bannerSlideItem}>
      <img
        alt="Normal Image"
        srcSet={`${src1x} 1x, ${src2x} 2x`}
        className={BLandingCss.imageComponentImageBLanding}
      />
      <div className={BLandingCss.imageComponentContentBLanding}>{children}</div>
    </div>
  );
};

const CheckMark = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 16 16" {...props}>
      <path
        d="M16 8a8 8 0 11-8-8 8 8 0 018 8zm-8.925 4.236L13.01 6.3a.516.516 0 000-.73l-.73-.73a.516.516 0 00-.73 0L6.71 9.681l-2.26-2.26a.516.516 0 00-.73 0l-.73.73a.516.516 0 000 .73l3.355 3.355a.516.516 0 00.73 0z"
        fill="#ffcd05"
      />
    </svg>
  );
};

const LandingSectionBannerNew = memo(
  ({ isMobileLayout, currentUser, openModal, isAuthenticated }) => {
    const location = useLocation();
    const history = useHistory();
    const isScoot = location.pathname === '/scoot-drivelah-signup';
    const [signUpCodeAmount, setSignUpCodeAmount] = useState(0);
    const [loading, setLoading] = useState(false);
    // const [isModalOpen, setIsModalOpen] = useState(false);
    useEffect(() => {
      const { code } = parse(location.search);
      if (code) {
        setLoading(true);
        checkSignUpCode(code).then(res => {
          if (res.valid) {
            setSignUpCodeAmount(res.credits);
          }
          setLoading(false);
        });
      }
    }, []);

    const newText = (
      <div className={BLandingCss.typeEffect}>
        <Typewriter
          options={{
            strings: ['<u>by the hour</u>', '<u>by the day</u>'],
            autoStart: true,
            loop: true,
          }}
        />
      </div>
    );

    // const openModal = (e) => {
    //   console.log("hhhfvbhvbfh")
    //   setIsModalOpen(true)
    // };

    // const changeModalVisiblity = (value) => {
    //     setIsModalOpen(value)
    // };

    return (
      <>
        <div className={BLandingCss.sectionImagesBLanding}>
          <ImageComponent
            src1x={isMobileLayout ? imageSource.bannerImage.bannerImageMobile : imageSource.bannerImage.bannerImage}
            src2x={isMobileLayout ? imageSource.bannerImage.bannerImageMobile2x : imageSource.bannerImage.bannerImage2x}
          >
            <div className={BLandingCss.bannerContentBlock}>
              <div
                className={classNames(
                  BLandingCss.sectionImageTitleBLanding,
                  BLandingCss.hourlyTitleBLanding
                )}
              >
                <span>
                  <i>Rent your neighbour’s</i>
                  <br />
                  <i>car&nbsp;</i>
                  <i>{newText}</i>
                </span>
              </div>
              {!isMobileLayout && (
                <div
                  className={classNames(
                    BLandingCss.bannerContentSection,
                    isAuthenticated && BLandingCss.isSectionLoggedIn
                  )}
                >
                  <div className={BLandingCss.sectionRow}>
                    <div className={BLandingCss.sectionColumn7}>
                      <div
                        className={classNames(BLandingCss.searchBar, BLandingCss.searchBarMobile)}
                      >
                        <div className={BLandingCss.searchBarContent}>
                          <div className={classNames(BLandingCss.heroContent)}>
                            <div className={BLandingCss.row}>
                              <div>
                                <CheckMark />
                              </div>
                              <div className={BLandingCss.heroContentBrandText}>
                                Rent by the hour, day or month
                              </div>
                            </div>
                            <div className={BLandingCss.row}>
                              <div>
                                <CheckMark />
                              </div>
                              <div className={BLandingCss.heroContentBrandText}>
                                Mate’s rates starting at $4/hr or $40/day
                              </div>
                            </div>
                            <div className={BLandingCss.row}>
                              <div>
                                <CheckMark />
                              </div>
                              <div className={BLandingCss.heroContentBrandText}>
                                Contactless access technology
                              </div>
                            </div>
                            <div className={BLandingCss.row}>
                              <div>
                                <CheckMark />
                              </div>
                              <div className={BLandingCss.heroContentBrandText}>
                                Hundreds of vehicles nearby
                              </div>
                            </div>
                            <div className={BLandingCss.row}>
                              <div>
                                <CheckMark />
                              </div>
                              <div className={BLandingCss.heroContentBrandText}>
                                Unmatched 24x7 support
                              </div>
                            </div>
                            <p className={BLandingCss.sectionPara}>
                              And much more, coming soon to your neighbourhood!
                            </p>
                          </div>
                        </div>
                        {/* {currentUser && currentUser.id ? (
                      <NamedLink name="NewListingPage" className={BLandingCss.signupButton}>
                        <PrimaryButton type="button">
                          <FormattedMessage id="LandingSectionBanner.ListYourCarButtonMessage" />
                        </PrimaryButton>
                      </NamedLink>
                    ) : (
                      <NamedLink name="SignupPage" className={BLandingCss.signupButton}>
                        <PrimaryButton type="button">
                          <FormattedMessage id="LandingSectionBanner.SignupNowButtonMessage" />
                        </PrimaryButton>
                      </NamedLink>
                    )} */}
                        <div className={BLandingCss.insuranceListingWrapper}>
                          {/* <div className={BLandingCss.trustPilotLogo}>
                            <TrustBox />
                          </div> */}
                          <div
                            className={BLandingCss.comprehensiveInsuranceBlock}
                            onClick={() => openModal(true)}
                          >
                            <div className={BLandingCss.comprehensiveInsuranceBlockWrapper}>
                              <div className={BLandingCss.cILogo}>
                                <img src={insuranceLogo} alt="" />
                              </div>
                              <div className={BLandingCss.cIContent}>
                                <span>
                                  Comprehensive insurance up to <strong>$35m</strong> cover
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!isAuthenticated && (
                      <div className={BLandingCss.sectionColumn5}>
                        <LandingPageSignupSection
                          isScoot={isScoot}
                          signUpCodeAmount={signUpCodeAmount}
                          loading={loading}
                        />
                      </div>
                    )}
                    {currentUser &&
                      !currentUser.attributes.profile.publicData
                        .guestIdentityVerificationStatus && (
                        <div
                          className={classNames(
                            BLandingCss.sectionColumn5,
                            BLandingCss.verificationContent
                          )}
                        >
                          <h3 className={BLandingCss.verificationHeading}>
                            Complete driver verification
                          </h3>

                          <p>
                            You are already signed up, go ahead and verify your profile so you can
                            start renting once we are live in your neighbourhood
                          </p>
                          <NamedLink
                            name="AccountSettingDriverVerificationPage"
                            className={BLandingCss.signupButton}
                          >
                            <PrimaryButton type="button">
                              <FormattedMessage id="LandingSectionBanner.completeNow" />
                            </PrimaryButton>
                          </NamedLink>
                        </div>
                      )}
                    {currentUser &&
                      currentUser.attributes.profile.publicData.guestIdentityVerificationStatus && (
                        <div
                          className={classNames(
                            BLandingCss.sectionColumn5,
                            BLandingCss.verificationContent
                          )}
                        >
                          <h3 className={BLandingCss.verificationHeading}>You are good to go</h3>

                          <p>
                            This offer is only for new members, your profile is already verified and
                            ready to start renting.
                          </p>
                        </div>
                      )}
                  </div>
                </div>
              )}
            </div>
          </ImageComponent>
          {isMobileLayout ? (
            <div className={BLandingCss.searchBar}>
              {!isAuthenticated && (
                <LandingPageSignupSection
                  isScoot={isScoot}
                  signUpCodeAmount={signUpCodeAmount}
                  loading={loading}
                />
              )}
              {currentUser &&
                !currentUser.attributes.profile.publicData.guestIdentityVerificationStatus && (
                  <div
                    className={classNames(
                      BLandingCss.sectionColumn5,
                      BLandingCss.verificationContent
                    )}
                  >
                    <h3 className={BLandingCss.verificationHeading}>
                      Complete driver verification
                    </h3>
                    <p>
                      You are already signed up, go ahead and verify your profile so you can start
                      renting once we are live in your neighbourhood
                    </p>
                    <NamedLink
                      name="AccountSettingDriverVerificationPage"
                      className={BLandingCss.signupButton}
                    >
                      <PrimaryButton type="button">
                        <FormattedMessage id="LandingSectionBanner.completeNow" />
                      </PrimaryButton>
                    </NamedLink>
                  </div>
                )}
              {currentUser &&
                currentUser.attributes.profile.publicData.guestIdentityVerificationStatus && (
                  <div
                    className={classNames(
                      BLandingCss.sectionColumn5,
                      BLandingCss.verificationContent
                    )}
                  >
                    <h3 className={BLandingCss.verificationHeading}>You are good to go</h3>
                    <p>
                      This offer is only for new members, your profile is already verified and ready
                      to start renting.
                    </p>
                  </div>
                )}
              <div className={BLandingCss.insuranceListingWrapper}>
                {/* <div
                  className={BLandingCss.trustPilotLogo}
                  style={{ flex: '1 1 40%', maxWidth: '40%' }}
                >
                  <TrustBox />
                </div> */}
                <div
                  className={BLandingCss.comprehensiveInsuranceBlock}
                  onClick={() => openModal(true)}
                >
                  <div className={BLandingCss.comprehensiveInsuranceBlockWrapper}>
                    <div className={BLandingCss.cILogo}>
                      <img src={insuranceLogo} alt="" />
                    </div>
                    <div className={BLandingCss.cIContent}>
                      <span>
                        Comprehensive insurance up to <strong>$35m</strong> cover
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={BLandingCss.searchBarContent}>
                <div className={classNames(BLandingCss.heroContent)}>
                  <div className={BLandingCss.row}>
                    <div>
                      <CheckMark />
                    </div>
                    <div className={BLandingCss.heroContentBrandText}>
                      Rent by the hour, day or month
                    </div>
                  </div>
                  <div className={BLandingCss.row}>
                    <div>
                      <CheckMark />
                    </div>
                    <div className={BLandingCss.heroContentBrandText}>
                      Mate’s rates starting at $4/hr or $40/day
                    </div>
                  </div>
                  <div className={BLandingCss.row}>
                    <div>
                      <CheckMark />
                    </div>
                    <div className={BLandingCss.heroContentBrandText}>
                      Contactless access technology
                    </div>
                  </div>
                  <div className={BLandingCss.row}>
                    <div>
                      <CheckMark />
                    </div>
                    <div className={BLandingCss.heroContentBrandText}>
                      Hundreds of vehicles nearby
                    </div>
                  </div>
                  <div className={BLandingCss.row}>
                    <div>
                      <CheckMark />
                    </div>
                    <div className={BLandingCss.heroContentBrandText}>Unmatched 24x7 support</div>
                  </div>
                  <p className={BLandingCss.sectionPara}>
                    And much more, coming soon to your neighbourhood!
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        {/* {isModalOpen && (
          <InsuranceSummaryModal
            isModalOpen={isModalOpen}
            changeModalVisiblity={changeModalVisiblity}
          />
        )} */}
      </>
    );
  },
  (prev, next) => isEqual(prev, next)
);

export default LandingSectionBannerNew;
