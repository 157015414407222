import classNames from 'classnames';
import moment from 'moment-timezone';
import { bool, func, object, string } from 'prop-types';
import React, { Fragment, PureComponent, useState } from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { compose } from 'redux';
import { get } from 'lodash';
import {
  FieldSelect,
  Form,
  PrimaryButton,
} from '../../components';
import config from '../../config';
import {
  currentUserIdentityStatus,
  listingIsInstantBooking,
} from '../../util/data';
import {
  getMonthStartInTimeZone,
  timeOfDayFromLocalToTimeZone,
} from '../../util/dates';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { sendCheckoutSlackNotification } from '../../util/slackNotify';
import { propTypes } from '../../util/types';
import css from '../BookingTimeForm/BookingTimeForm.css';
import BookingRangeSelector from './BookingRangeSelector';
import TripExtensionDateSelector from './TripExtensionDateSelector';
import { addMinutes, endOfDay, isAfter, isBefore, isSameDay, startOfDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const IconInfo = ({ fill = "#DA6C6C" }) => {
  return (
    <svg
      className={css.infoIcon}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00014 0.25C11.3209 0.25 13.5467 1.17194 15.1878 2.813C16.8288 4.45406 17.7508 6.67981 17.7508 9.00062C17.7508 11.3214 16.8288 13.5472 15.1878 15.1883C13.5467 16.8293 11.3209 17.7513 9.00014 17.7513C6.67933 17.7513 4.45357 16.8293 2.81251 15.1883C1.17145 13.5472 0.249512 11.3214 0.249512 9.00062C0.249512 6.67981 1.17145 4.45406 2.81251 2.813C4.45357 1.17194 6.67933 0.25 9.00014 0.25ZM10.3126 5.6225C10.9626 5.6225 11.4901 5.17125 11.4901 4.5025C11.4901 3.83375 10.9614 3.3825 10.3126 3.3825C9.66264 3.3825 9.13764 3.83375 9.13764 4.5025C9.13764 5.17125 9.66264 5.6225 10.3126 5.6225ZM10.5414 12.6562C10.5414 12.5225 10.5876 12.175 10.5614 11.9775L9.53389 13.16C9.32139 13.3837 9.05514 13.5387 8.93014 13.4975C8.87343 13.4766 8.82603 13.4362 8.79647 13.3835C8.76691 13.3308 8.75713 13.2693 8.76889 13.21L10.4814 7.8C10.6214 7.11375 10.2364 6.4875 9.42014 6.4075C8.55889 6.4075 7.29139 7.28125 6.52014 8.39C6.52014 8.5225 6.49514 8.8525 6.52139 9.05L7.54764 7.86625C7.76014 7.645 8.00764 7.48875 8.13264 7.53125C8.19422 7.55335 8.24469 7.59872 8.2732 7.65762C8.30171 7.71651 8.306 7.78423 8.28514 7.84625L6.58764 13.23C6.39139 13.86 6.76264 14.4775 7.66264 14.6175C8.98764 14.6175 9.77014 13.765 10.5426 12.6562H10.5414Z"
        fill={fill}
      />
    </svg>
  );
};
const TODAY = new Date();
export class BookingTimeFormComponent extends PureComponent {
  state = {
    loading: false,
    timeError: {},
    currentMonth: getMonthStartInTimeZone(TODAY, this.props.localTimeZone),
    availableStartTimes: [],
    availableEndTimes: [],
    sameDayEndTimes: []
  };

  render() {
    const {
      rootClassName,
      className,
      price: unitPrice,
      timeSlots,
      currentUser,
      initialDate,
      initialValues: rawInitialValues,
      ...rest
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        rawInitialValues={rawInitialValues}
        currentUser={currentUser}
        unitPrice={unitPrice}
        onSubmit={values => {
          const { discountChoice, ...rest } = values;
          if (!discountChoice || discountChoice !== 'credits') {
            rest.signupCredits = 0;
          }
          rest.voucherCode = this.props.checkedCode;
          if (this.props.listing && this.props.listing.id.uuid && currentUser.id.uuid) {
            sendCheckoutSlackNotification({
              listingId: this.props.listing.id.uuid,
              userId: currentUser.id.uuid,
            });
          }
          this.props.onSubmit(rest);
        }}
        render={fieldRenderProps => {
          const {
            tripDates,
            isSubmitButtonInProgress,
            isSubmitButtonDisable,
            isShowEstimatedBreakdown,
            updateBookingFormError,
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            submitButtonWrapperClassName,
            unitPrice,
            unitType = 'line-item/units',
            values,
            onFetchTimeSlots,
            timeZone,
            currentUser,
            listing,
            checkCodeInProgress,
            checkedCode,
            onCheckingVoucher,
            checkCodeErorr,
            onResetCode,
            onEstimateBreakdown,
            estimateBreakdownInProgress,
            estimatedTx,
            requestButtonId,
            listingParams,
            onManageDisableScrolling,
            onReadInsurance,
            onOpenRentalAgreement,
            bookingConfig,
            estimateError,
            rawInitialValues,
            localTimeZone,
            isNewCar,
            isLongTerm,
            isLongTermBooking,
            monthlyTimeSlots,
            transaction,
            updateBooking,
            onRequestToUpdateBooking,
            getChildTransactionData,
            location,
            onInitiateSpeculativeUpdateBooking,
            onTripDatesSet,
            setUpdatedStartDate,
            setUpdatedEndDate,
            setUpdatedStartTime,
            setUpdatedEndTime,
            updatedStartDate,
            updatedStartTime,
            updatedEndDate,
            updatedEndTime,
            allTimeslots,
            isTripExtension,
            availableUptoDate,
            boundsMergedTimeSlots,
          } = fieldRenderProps;

          if (!this.mounted) {
            form.batch(() => {
              onTripDatesSet();
              Object.entries(rawInitialValues).forEach(entry => form.change(entry[0], entry[1]));
            });
            this.mounted = true;
          }


          const initialRangeDates = {'dates' : { 'startDate' : null, 'endDate' :  null }};

          console.log('rawInitialValues.bookingStartDate', rawInitialValues.bookingStartDate)

          const noticeHrs = get(listing, 'attributes.publicData.paddingHours', 0);
          console.log('notice hours', noticeHrs);

          const [selectedTripEndDate, setSelectedTripEndDate] = useState({
            startDate: null,
            endDate: null
          });

          const isInstantBooking = listingIsInstantBooking(listing);

          const {
            guestVerified: verifiedGuest,
            guestUnderVerify: underVerifiedGuest,
          } = currentUserIdentityStatus(currentUser);

          const { discountChoice, signupCredits = 0 } = values;



          const disableUpdateTripButton =
            rawInitialValues &&
            values &&
            rawInitialValues.bookingEndTime === values.bookingEndTime &&
            rawInitialValues.bookingStartTime === values.bookingStartTime;

          const isPriceDecrease = estimatedTx && (estimatedTx.attributes.payinTotal.amount < estimatedTx.attributes.protectedData.initialPayment);
          const startTimeLabel = intl.formatMessage({ id: 'FieldDateTimeInput.pickUpTime' });
          const endTimeLabel = intl.formatMessage({ id: 'FieldDateTimeInput.dropoffTime' });

          function getAvailableTimeSlots(originalTimeSlots, selectedDate) {
            const selectedDateStart = new Date(moment(selectedDate).tz(timeZone).startOf('day').toISOString());
            const selectedDateEnd = new Date(moment(selectedDate).tz(timeZone).endOf('day').toISOString());
            let availableSlots = [];
            originalTimeSlots.forEach(slot => {
              let start = slot.attributes.start;
              let end = slot.attributes.end;
              // Ensure slot overlaps with the selected date
              if (isBefore(start, selectedDateEnd) && isAfter(end, selectedDateStart)) {
                let slotStart = isBefore(start, selectedDateStart)
                  ? selectedDateStart
                  : start;
                let slotEnd = isAfter(end, selectedDateEnd) ? selectedDateEnd : end;
                let current = slotStart;
                while (!isAfter(current, slotEnd)) {
                  availableSlots.push(current); // Formatting to 12-hour format
                  current = addMinutes(current, 30);
                }
              }
            });
            return availableSlots;
          }

          function adjustNoticeHoursTimeslots(timeslots, noticeHours) {
            const currentTime = moment();
            const roundedTime = currentTime
              .clone()
              .minute(Math.ceil(currentTime.minute() / 30) * 30)
              .second(0);
            let noticeWindowEnd = roundedTime.clone().add(noticeHours, 'hours');

            return timeslots
              .map(timeslot => {
                const slotStart = moment(timeslot.attributes.start);
                const slotEnd = moment(timeslot.attributes.end);

                // If the slot starts before the notice window ends, adjust its start time
                if (slotStart.isBefore(noticeWindowEnd)) {
                  // If the entire slot is within the notice window, remove it
                  if (slotEnd.isBefore(noticeWindowEnd)) {
                    return null; // Remove this slot
                  }
                  // Otherwise, adjust the start time to the end of the notice window
                  timeslot.attributes.start = new Date(noticeWindowEnd);
                }
                return timeslot;
              })
              .filter(timeslot => timeslot !== null); // Remove null entries (slots that were entirely within the notice window)
          }

          const initialStartDate = get(transaction, 'booking.attributes.displayStart');
          const initialEndDate = get(transaction, 'booking.attributes.displayEnd');

          const paddedStartDate = moment(initialStartDate).subtract(2, 'hours');

          const paddedEndDate = moment(initialEndDate).add(2, 'hours');

          const validTimeSlots = boundsMergedTimeSlots.filter(
            timeSlot => timeSlot && timeSlot.attributes
          );

          function mergeTimeSlots(timeSlots, initialStartDate, initialEndDate) {
            // Convert initial dates to Date objects for easier comparison
            const initialStart = new Date(initialStartDate);
            const initialEnd = new Date(initialEndDate);

            // Find the time slots that are adjacent or overlapping with the initial range
            const overlappingSlots = timeSlots.filter(slot => {
              const slotStart = new Date(slot.attributes.start);
              const slotEnd = new Date(slot.attributes.end);

              return slotStart <= initialEnd && slotEnd >= initialStart;
            });

            // If no overlapping slots found, return the original time slots
            if (overlappingSlots.length === 0) {
              return timeSlots;
            }

            // Find the minimum start date and maximum end date among the overlapping slots
            const minStart = overlappingSlots.reduce((min, slot) => {
              const slotStart = new Date(slot.attributes.start);
              return slotStart < min ? slotStart : min;
            }, new Date(overlappingSlots[0].attributes.start));

            const maxEnd = overlappingSlots.reduce((max, slot) => {
              const slotEnd = new Date(slot.attributes.end);
              return slotEnd > max ? slotEnd : max;
            }, new Date(overlappingSlots[0].attributes.end));

            const mergedSlotId = overlappingSlots[0].id;

            // Create a new merged time slot
            const mergedSlot = {
              id: mergedSlotId,
              type: 'timeSlot',
              attributes: {
                type: 'time-slot/time',
                seats: 1,
                start: minStart,
                end: maxEnd,
              },
            };

            // Filter out the overlapping slots and add the merged slot
            const remainingSlots = timeSlots.filter(slot => {
              const slotStart = new Date(slot.attributes.start);
              const slotEnd = new Date(slot.attributes.end);

              return !(slotStart <= initialEnd && slotEnd >= initialStart);
            });

            return [...remainingSlots, mergedSlot];
          }

          const mergedSlots = mergeTimeSlots(validTimeSlots, paddedStartDate, paddedEndDate);

          const adjustedNoticeHoursSlots = adjustNoticeHoursTimeslots(mergedSlots, noticeHrs);

          const getTimeSlotsForDate = (selectedDate) => {
            return adjustedNoticeHoursSlots.filter(slot => {
              const startDate = moment(slot.attributes.start).format('YYYY-MM-DD');
              const endDate = moment(slot.attributes.end).format('YYYY-MM-DD');
              return (
                startDate === selectedDate ||
                endDate === selectedDate ||
                (startDate < selectedDate && endDate > selectedDate)
              );
            });
          };

          function splitByGaps(list, gapHours = 1) {
            if (list.length === 0) {
              return [];
            }
            const slots = [];
            let currentSlot = [new Date(list[0])];
            for (let i = 1; i < list.length; i++) {
              let prevTime = new Date(list[i - 1]);
              let currTime = new Date(list[i]);
              let diffHours = (currTime - prevTime) / (1000 * 60 * 60); // Convert milliseconds to hours

              if (diffHours > gapHours) {
                slots.push(currentSlot); // Save previous slot
                currentSlot = []; // Start a new slot
              }

              currentSlot.push(currTime);
            }
            if (currentSlot.length > 0) {
              slots.push(currentSlot); // Add the last slot
            }
            return slots;
          }


          function formatTimestamps(slots) {
            return slots.map(slot => {
              // Convert UTC timestamp to local time based on the given timezone
              const date = new Date(slot);
              const options = {
                timeZone: timeZone,
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
              };

              // Format the time using Intl.DateTimeFormat
              const formatter = new Intl.DateTimeFormat('en-US', options);
              const formattedTime = formatter.format(date);

              return {
                timeOfDay: formattedTime, // e.g., "11:00 AM"
                timestamp: slot.valueOf() // Keep original UTC timestamp
              };
            });
          }

          function getFormattedTime(time) {
            const date = new Date(time);
            const options = {
              timeZone: timeZone,
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
            };

            // Format the time using Intl.DateTimeFormat
            const formatter = new Intl.DateTimeFormat('en-US', options);
            return formatter.format(date);
          }

          function hasGapMoreThan30Minutes(selectedTime, dropOffList) {
            const THIRTY_MINUTES = 30 * 60 * 1000; // 30 minutes in milliseconds

            // Convert selected time to Date object
            const selectedDate = new Date(selectedTime);

            // Convert dropoffHours to Date objects
            const dropoffDates = dropOffList.map(time => new Date(time));

            // Sort dropoff times in ascending order
            dropoffDates.sort((a, b) => a - b);

            for (let i = 0; i < dropoffDates.length; i++) {
              const current = dropoffDates[i];

              // If selected time matches a dropoff time, check next time
              if (current.getTime() === selectedDate.getTime()) {
                if (i < dropoffDates.length - 1) {
                  const next = dropoffDates[i + 1];
                  // Check if the gap to the next time is more than 30 minutes
                  if (next - current > THIRTY_MINUTES) {
                    return true;
                  }
                }
                return false; // No gap found
              }
            }
            return false; // Selected time not in dropoff list
          }

          function findSlotForDate(slots, selectedDate) {
            let selectedTime = new Date(selectedDate);
            for (let i = 0; i < slots.length; i++) {
              let startTime = slots[i][0];
              let endTime = slots[i][slots[i].length - 1];
              if (selectedTime >= startTime && selectedTime <= endTime) {
                return i;
              }
            }
            return 0;
          }

          const onBookingDatesChange = dateRange => {
            if (!dateRange.startDate && !dateRange.endDate) {
              form.change('bookingStartDate', null);
              form.change('bookingEndDate', null);
              form.change('bookingStartTime', null);
              form.change('bookingEndTime', null);

              setUpdatedStartDate(null)
              setUpdatedEndDate(null)
              setUpdatedStartTime(null)
              setUpdatedEndTime(null)
            }
            const startDate = dateRange.startDate ? timeOfDayFromLocalToTimeZone(dateRange.startDate, timeZone): null;
            const endDate = dateRange.endDate ? timeOfDayFromLocalToTimeZone(dateRange.endDate, timeZone): null;

            if (startDate && endDate) {
              const pickupStartDate = moment(startDate).format('YYYY-MM-DD');
              const pickupEndDate = moment(endDate).format('YYYY-MM-DD');
              const availableTimeSlots = getTimeSlotsForDate(pickupStartDate);
              const availableEndTimeSlots = getTimeSlotsForDate(pickupEndDate);
              const pickupHrs = getAvailableTimeSlots(
                availableTimeSlots,
                pickupStartDate,
              );
              const dropoffHrs = getAvailableTimeSlots(
                availableEndTimeSlots,
                pickupEndDate,
              );

              const isSamePickup_Dropoff = moment(pickupEndDate).isSame(
                pickupStartDate,
                'day',
              );

              if (isSamePickup_Dropoff) {
                // remove the last time slot if 11:30 PM
                const lastSlot = pickupHrs[pickupHrs.length - 1];
                const lastSlotTime = moment(lastSlot).format('HH:mm');
                if (lastSlotTime === '23:30') {
                  pickupHrs.pop();
                }
              }

              if (!isSamePickup_Dropoff) {
                const splittedPickupSlots = splitByGaps(pickupHrs);
                const splittedDropoffSlots = splitByGaps(dropoffHrs);
                const pickupSlots = splittedPickupSlots[splittedPickupSlots.length - 1];
                const dropoffSlots = splittedDropoffSlots[0];

                this.setState({availableStartTimes: formatTimestamps(pickupSlots), availableEndTimes: formatTimestamps(dropoffSlots)});
              } else {
                this.setState({availableStartTimes: formatTimestamps(pickupHrs), availableEndTimes: formatTimestamps(dropoffHrs)});
              }
            }

            form.batch(() => {
              form.change('bookingStartDate', { date: moment(dateRange.startDate).toDate() });
              form.change('bookingEndDate', { date: moment(dateRange.endDate).toDate() });
              form.change('bookingStartTime', null);
              form.change('bookingEndTime', null);
            });

            if (startDate) {
              setUpdatedStartDate(moment(dateRange.startDate).toDate())
            }
            if (endDate) {
              setUpdatedEndDate(moment(dateRange.endDate).toDate())
            }
            setUpdatedStartTime(null)
            setUpdatedEndTime(null)
          }

          const isSamePickupDropoff = moment(updatedStartDate).isSame(
            updatedEndDate,
            'day',
          );

          const onBookingStartTimeChange = e => {

            const time = moment(+e.target.value)

            form.batch(() => {
              form.change('bookingStartTime', e.target.value);
              form.change('bookingStartDate', { date: moment(+e.target.value).toDate() });
            });


            if (isSamePickupDropoff) {
              const dropoffSlots = this.state.availableEndTimes
              const dropTimeSlots = dropoffSlots.map(slot => utcToZonedTime(slot.timestamp, timeZone));
              const dropoffHours_List = dropTimeSlots;
              const pickupDate = new Date(time);
              let filteredDropOffList = dropoffHours_List
                .map(time => new Date(time))
                .filter(time => time > pickupDate); // Rule 1: Drop-off must be after pickup
              const slots = splitByGaps(filteredDropOffList);
              const selectedSlot = findSlotForDate(slots, time);
              console.log("selectted time", time);
              console.log("dropoffHours_List", JSON.stringify(dropoffHours_List));

              const isBeforeGap = hasGapMoreThan30Minutes(time, dropoffHours_List);
              console.log('isBeforeGap:', (isBeforeGap));

              form.batch(() => {
                form.change('bookingEndTime', null);
              })
              setUpdatedEndTime(null)

              this.setState({sameDayEndTimes: formatTimestamps(isBeforeGap ? [] : slots[selectedSlot])});
            }

            setUpdatedStartTime(e.target.value)

            const updatedValues = {
              ...values,
              bookingStartTime: e.target.value,
              bookingStartDate: { date: moment(e.target.value).toDate() }
            }

            if (updatedStartTime && updatedEndDate) {
              onInitiateSpeculativeUpdateBooking && onInitiateSpeculativeUpdateBooking(updatedValues)
            }
          };

          const onBookingEndTimeChange = e => {
            form.batch(() => {
              form.change('bookingEndTime', e.target.value);
              form.change('bookingEndDate', { date: moment(+e.target.value).toDate() });
            });

            setUpdatedEndTime(e.target.value)

            const updatedValues = {
              ...values,
              bookingEndTime: e.target.value,
              bookingEndDate: { date: moment(e.target.value).toDate() }
            }

            if (updatedStartTime && updatedEndDate) {
              onInitiateSpeculativeUpdateBooking && onInitiateSpeculativeUpdateBooking(updatedValues)
            }
          };

          const onTripExtensionDateChange = tripEndDate => {
            const endDate = tripEndDate ? timeOfDayFromLocalToTimeZone(tripEndDate, timeZone): null;

            if (endDate) {
              setSelectedTripEndDate(prevState => ({
                ...prevState,
                endDate: moment(tripEndDate, timeZone)
              }));

              const selectedDate = new Date(endDate);
              const isSameDayExtension = isSameDay(rawInitialValues.bookingEndDate.date, selectedDate);
              if (isSameDayExtension) {
                const bookingEndFormat = new Date(rawInitialValues.bookingEndDate.date);
                const latestAvailableTime = availableUptoDate;
                const isSameLatestAvailableTime = isSameDay(availableUptoDate, selectedDate);
                console.log('isSameLatestAvailableTime', isSameLatestAvailableTime);
                if (!isSameLatestAvailableTime) {
                  // end of the day time
                  const endOfDayTime = new Date(moment(selectedDate).tz(timeZone).endOf('day').toISOString());
                  const timeSlotsHrs = [];
                  for (
                    let time = moment(bookingEndFormat);
                    time.isSameOrBefore(moment(endOfDayTime));
                    time.add(30, 'minutes')
                  ) {
                    timeSlotsHrs.push({
                      timeOfDay: getFormattedTime(time),
                      timestamp: time.valueOf()
                    });
                  }
                  // delete first slot
                  timeSlotsHrs.shift();
                  this.setState({ availableEndTimes: timeSlotsHrs });
                  form.batch(() => {
                    form.change('bookingEndDate', { date: moment(tripEndDate).toDate() });
                    form.change('bookingEndTime', null);
                  });

                  if (endDate) {
                    setUpdatedEndDate(moment(tripEndDate).toDate())
                  }
                  setUpdatedEndTime(null)
                  return;
                }
                let timeSlotsHrs = [];
                for (
                  let time = moment(bookingEndFormat);
                  time.isSameOrBefore(moment(latestAvailableTime));
                  time.add(30, 'minutes')
                ) {
                  timeSlotsHrs.push({
                    timeOfDay: getFormattedTime(time),
                    timestamp: time.valueOf()
                  });
                }
                console.log('timeSlotsHrs for same', timeSlotsHrs, endDate);
                // delete first slot
                timeSlotsHrs.shift();
                this.setState({ availableEndTimes: timeSlotsHrs });
              } else {
                const selectedDateFormat = new Date(moment(selectedDate).tz(timeZone).startOf('day').toISOString());
                const endOfSelectedDate = new Date(moment(selectedDate).tz(timeZone).endOf('day').toISOString());
                const latestAvailableTime = availableUptoDate;
                const timeSlotsHrs = [];

                for (
                  let time = moment(selectedDateFormat);
                  time.isSameOrBefore(moment(latestAvailableTime)) && time.isSameOrBefore(moment(endOfSelectedDate));
                  time.add(30, 'minutes')
                ) {
                  timeSlotsHrs.push({
                    timeOfDay: getFormattedTime(time),
                    timestamp: time.valueOf()
                  });
                }
                console.log('timeSlotsHrs for diff', timeSlotsHrs, endDate);
                // delete first slot
                timeSlotsHrs.shift();
                this.setState({ availableEndTimes: timeSlotsHrs });
              }
            }

            form.batch(() => {
              form.change('bookingEndDate', { date: moment(tripEndDate).toDate() });
              form.change('bookingEndTime', null);
            });

            if (endDate) {
              setUpdatedEndDate(moment(tripEndDate).toDate())
            }
            setUpdatedEndTime(null)
          }

          const tripExtensionEndTimeChange = e => {
            form.batch(() => {
              form.change('bookingEndTime', e.target.value);
              form.change('bookingEndDate', { date: moment(+e.target.value).toDate() });
            });

            setUpdatedEndTime(e.target.value)

            const updatedValues = {
              ...values,
              bookingEndTime: e.target.value,
              bookingEndDate: { date: moment(e.target.value).toDate() }
            }

            if (updatedEndDate) {
              onInitiateSpeculativeUpdateBooking && onInitiateSpeculativeUpdateBooking(updatedValues)
            }
          };

          return (
            <Form onSubmit={handleSubmit} currentUser={currentUser} className={classes}>
              {monthlyTimeSlots && timeZone ? (
                <Fragment>
                  <FormSpy
                    subscription={{ values: true }}
                    currentUser={currentUser}
                    onChange={props => {
                      const { values } = props;
                    }}
                  />

                  {/*Trip modification range selector*/}
                  {!isTripExtension && (
                    <BookingRangeSelector
                      id="dateRange"
                      initialValues={initialRangeDates}
                      onDatesChange={onBookingDatesChange}
                      onSubmit={handleSubmit}
                      onFetchTimeSlots={onFetchTimeSlots}
                      timeZone={timeZone}
                      monthlyTimeSlots={monthlyTimeSlots}
                      location={location}
                      listingId={listingId}
                      transaction={transaction}
                      allTimeslots={adjustedNoticeHoursSlots}
                      listing={listing}
                      rawInitialValues={rawInitialValues}
                      boundsMergedTimeSlots={boundsMergedTimeSlots}
                      updatedStartDate={updatedStartDate}
                      updatedEndDate={updatedEndDate}
                      updatedStartTime={updatedStartTime}
                      availableStartTimes={this.state.availableStartTimes}
                      availableEndTimes={isSamePickupDropoff ? this.state.sameDayEndTimes : this.state.availableEndTimes}
                      onBookingStartTimeChange={onBookingStartTimeChange}
                      onBookingEndTimeChange={onBookingEndTimeChange}
                    />
                  )}

                  {
                    isTripExtension && (
                      <div className={css.extensionCalendar}>
                        <TripExtensionDateSelector
                          onTripExtensionDateChange={onTripExtensionDateChange}
                          selectedTripEndDate={selectedTripEndDate}
                          validTimeSlots={allTimeslots}
                          latestAvailableDateTime={availableUptoDate}
                          transaction={transaction}
                          timeZone={timeZone}
                        />
                      </div>
                    )
                  }

                  { updatedEndDate && isTripExtension && (
                    <div className={css.bookingDatesSelectors}>
                      <FieldSelect
                        labelClassName={css.label}
                        name="bookingEndTime"
                        id="bookingEndTimeSelect"
                        className={css.endTimeDropDown}
                        selectClassName={css.select}
                        label={endTimeLabel}
                        onChange={tripExtensionEndTimeChange}
                      >
                        <option value="">Select dropoff time</option>
                        {this.state.availableEndTimes.map((p, index) => (
                          <option key={`bookingEndTime_${p.timestamp}`} value={p.timestamp}>
                            {p.timeOfDay}
                          </option>
                        ))}
                      </FieldSelect>
                    </div>
                  )}

                  {isNewCar && !isLongTermBooking && (
                    <div className={css.newCarDiscountNotes}>
                      {' '}
                      <FormattedMessage id="BookingDatesForm.newCarDiscountNotes" />{' '}
                    </div>
                  )}

                  {Object.keys(this.state.timeError).length ? (
                    <div className={css.error}>
                      {Object.keys(this.state.timeError).join('. \n')}
                    </div>
                  ) : null}

                  {updateBookingFormError &&
                    ((updatedStartDate && updatedEndDate && updatedStartTime && updatedEndTime && !isTripExtension) || isTripExtension)
                    && (
                    <div className={css.error}>{updateBookingFormError}</div>
                  )}
                  {((updatedStartDate && updatedStartTime && updatedEndDate && updatedEndTime && !isTripExtension) || (isTripExtension && updatedEndDate && updatedEndTime)) ? (
                    <div className={css.submitSection}>
                      <div className={css.submitSectionInner}>
                        {transaction ? <div className={css.submitSectionPrice}></div> : null}
                        <div className={css.submitSectionPrice}>
                          <PrimaryButton
                            type="submit"
                            disabled={isSubmitButtonDisable || disableUpdateTripButton}
                            inProgress={isSubmitButtonInProgress}
                            id={requestButtonId}
                          >
                            {
                              (!isShowEstimatedBreakdown && !isTripExtension) ?
                                isInstantBooking ? isTripExtension ?
                                    <FormattedMessage id="BookingDatesForm.extendTrip" /> :
                                    <FormattedMessage id="BookingDatesForm.modifyTrip" /> :
                                  <FormattedMessage id="StripePaymentForm.submitPaymentInfo" />
                                : <FormattedMessage id="BookingDatesForm.clickToProceed" />
                            }
                          </PrimaryButton>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </Fragment>
              ) : null}

              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingDatesForm.ownListing'
                      : 'BookingDatesForm.youWontBeChargedInfo'
                  }
                />
              </p>
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  monthlyTimeSlots: null,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  isNewCar: false,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
  isLongTerm: bool,
  isLongTermBooking: bool,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
