import classNames from 'classnames';
import { cloneDeep, get } from 'lodash';
import moment from 'moment-timezone';
import { array, bool, func, instanceOf, object, oneOfType, shape, string } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Prompt, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import deliveryIcon from '../../assets/newCheckoutPage/deliveryIcon.svg';
import mileageIcon from '../../assets/tripPage/mileageIcon.svg';
import thumbIcon from '../../assets/tripPage/thumb.svg';
import premiumProtectionIcon from '../../assets/tripPage/Premium_Cover.png';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import FuelIcon from '../../assets/fuel.svg';
import TollIcon from '../../assets/toll.svg';
import {
  ensureBooking,
  ensureCurrentUser,
  ensureListing,
  ensurePaymentMethodCard,
  ensureStripeCustomer,
  ensureTransaction,
  ensureUser,
  restoreTransaction,
} from '../../util/data';
import {
  calculateBookingDays,
  getDefaultTimeZoneOnBrowser,
  minutesBetween,
} from '../../util/dates';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { findRouteByRouteName, pathByRouteName } from '../../util/routes';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { createSlug, parse } from '../../util/urlHelpers';
import { updateUserData } from '../../ducks/user.duck';

import {
  AddOnsSection,
  AvatarMedium,
  Button,
  CheckoutBreakdownLongTerm,
  IconSpinner,
  Logo,
  Modal,
  NamedLink,
  NamedRedirect,
  Page,
  ResponsiveImage,
} from '../../components';
import BookingBreakdownNew from '../../components/BookingBreakdown/BookingBreakdownNew';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';
import { handleCardPayment, retrievePaymentIntent } from '../../ducks/stripe.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { StripePaymentForm } from '../../forms';
import { formatMoney } from '../../util/currency';
import {
  isTransactionChargeDisabledError,
  isTransactionInitiateAmountTooLowError,
  isTransactionInitiateBookingTimeNotAvailableError,
  isTransactionInitiateInvalidVoucher,
  isTransactionInitiateListingNotFoundError,
  isTransactionInitiateMissingStripeAccountError,
  isTransactionZeroPaymentError,
  transactionInitiateOrderStripeErrors,
} from '../../util/errors';
import {
  TRANSITION_ENQUIRE,
  TRANSITION_REQUEST_PAYMENT_NORMAL_COMMERCIAL,
  TRANSITION_REQUEST_PAYMENT_NORMAL_PRIVATE,
  TRANSITION_REQUEST_PAYMENT_YOUNG_COMMERCIAL,
  TRANSITION_REQUEST_PAYMENT_YOUNG_PRIVATE,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_REQUEST,
  txIsPaymentExpired,
  txIsPaymentPending,
} from '../../util/transaction';

import { emitter, Experiment, Variant } from '@marvelapp/react-ab-test';
import {
  getMileageValuefromSliderMileageValue,
  sliderOptionsList,
} from '../../components/AddOnsSection/AddonsUtils';
import AlertBox from '../../components/AlertBox/AlertBox';
import LineItemBookingPeriodNew from '../../components/BookingBreakdown/LineItemBookingPeriodNew';
import {
  ADDON_NAME__MAXIMUM_PROTECTION,
  ADDON_NAME__MILEAGE_PACKAGE,
  ADDON_NAME__PREMIUM_PROTECTION,
} from '../../util/constants/addons';
import { distanceCountHelper } from '../../util/distanceCountHelper';
import {
  EVENT_BOOK_CREATED_REQUEST_FAIL_GUEST,
  EVENT_BOOK_CREATED_REQUEST_GUEST,
  EVENT_BOOK_EXCESS_2_SUCCESS_GUEST,
  EVENT_BOOK_EXCESS_SUCCESS_GUEST,
  EVENT_BOOK_REQUEST_ACCEPTED_GUEST,
  EVENT_BOOK_REQUEST_ACCEPTED_HOST,
  EVENT_BOOK_SAVED_CARD_DETAILS,
  EVENT_BOOK_SENT_REQUEST_SUCCESS_GUEST,
  EVENT_BOOK_USED_PRESAVED_DETAILS,
  EVENT_DELIVERY_ADDON_ADDED,
  EVENT_DELIVERY_ADDON_REMOVED,
  EVENT_EXCESS_2_ADDON_ADDED,
  EVENT_EXCESS_2_ADDON_REMOVED,
  EVENT_EXCESS_ADDON_ADDED,
  EVENT_EXCESS_ADDON_REMOVED,
  EVENT_ORDER_COMPLETED,
  EVENT_TRIP_MODIFICATION_REQUEST_SENT_GUEST,
  SEND_REQUEST_BOOKING_BUTTON_ID,
  SERVER_EVENT_BOOK_CREATED_REQUEST_FAIL_HOST,
  SERVER_EVENT_BOOK_RECEIVED_REQUEST_SUCCESS_HOST,
} from '../../util/gtm/gtmConstants';
import { createRawPropertiesForGTM } from '../../util/gtm/gtmCreateProperties';
import {
  initiateEventFromTransaction,
  initiateExperimentEventFromListing,
  pushDataLayer,
  pushGTMBookEvent,
  pushGTMBookEventAfterOrderCompleted,
} from '../../util/gtm/gtmHelpers';
import { calculateLongTermPriceWithFeeOneMonth } from '../../util/longTermRentalHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  sendSmoveUserBookingNotification,
  sendStripeErrorSlackNotification,
} from '../../util/slackNotify';
import { getUpdateObjs, makeChildUpdateBookingDates } from '../../util/updateTransaction';
import { requestToUpdateBooking, transitionUnpaidDuesTransaction } from '../TransactionPage/TransactionPage.duck';
import css from './CheckoutPage.css';
import {
  confirmPayment,
  confirmPaymentDeposit,
  confirmPaymentUnpaidDues,
  fetchDataForUpdateBookingCheckoutPage,
  fetchDepositTx,
  getOpenInvoices,
  initiateOrder,
  makeUpdateBookingRequest,
  openInvoicesRequest,
  sendMessage,
  setInitialValues,
  speculateTransaction,
  stripeCustomer,
} from './CheckoutPage.duck';
import {
  $isInitiateUpdateBookingRequestPending,
  $isInitiateUpdateBookingRequestSuccess,
} from './CheckoutPage.selectors';
import { checkCanUseMastercardPromo } from './CheckoutPage.helpers';
import { GTAG_ACTIONS, sendG4AEvent } from '../../util/gtag';
import { clearData, storeData, storedData } from './CheckoutPageSessionHelpers';
import UnpaidInvoices from '../../components/UnpaidInvoices/UnpaidInvoices';
import { IconInfo } from '../../forms/EditTripForm/BookingTimeForm';
const { Money } = sdkTypes;

const STORAGE_KEY = 'CheckoutPage';
const LIMIT_DISTANCE = 6;
const LIMIT_START_HOUR = 9;
const LIMIT_END_HOUR = 21;
let particularVASParams = {
  booking_vas_excess: false,
  booking_vas_excess_2: false
};

let EXPERIMENT_DATA = {};
let initialPageData = null;
let mapAbSegment = '';

const { LatLng } = sdkTypes;
// Stripe PaymentIntent statuses, where user actions are already completed
// https://stripe.com/docs/payments/payment-intents/status
export const STRIPE_PI_USER_ACTIONS_DONE_STATUSES = ['processing', 'requires_capture', 'succeeded'];

// Payment charge options
const ONETIME_PAYMENT = 'ONETIME_PAYMENT';
const PAY_AND_SAVE_FOR_LATER_USE = 'PAY_AND_SAVE_FOR_LATER_USE';
export const USE_SAVED_CARD = 'USE_SAVED_CARD';

const paymentFlow = (selectedPaymentMethod, saveAfterOnetimePayment) => {
  // Payment mode could be 'replaceCard', but without explicit saveAfterOnetimePayment flag,
  // we'll handle it as one-time payment
  return selectedPaymentMethod === 'defaultCard'
    ? USE_SAVED_CARD
    : saveAfterOnetimePayment
    ? PAY_AND_SAVE_FOR_LATER_USE
    : ONETIME_PAYMENT;
};

const initializeOrderPage = (initialValues, routes, dispatch) => {
  const OrderPage = findRouteByRouteName('OrderDetailsPage', routes);

  // Transaction is already created, but if the initial message
  // sending failed, we tell it to the OrderDetailsPage.
  dispatch(OrderPage.setInitialValues(initialValues));
};

const checkIsPaymentExpired = existingTransaction => {
  return txIsPaymentExpired(existingTransaction)
    ? true
    : txIsPaymentPending(existingTransaction)
    ? minutesBetween(existingTransaction.attributes.lastTransitionedAt, new Date()) >= 15
    : false;
};

const getTransactionBookings = transactions => {
  if (!transactions || !Array.isArray(transactions)) return null;
  const transactionsWithBookings = transactions.filter(
    tx => tx.booking && tx.booking.type === 'booking'
  );
  const bookings = transactionsWithBookings.map(tx => tx.booking);
  return bookings.length > 0 ? bookings : bookings;
};

// return true if the time of booking1 matches exactly
// the time (days and hours) of booking2, false otherwise
const compareTwoBookingsTime = (booking1, booking2) => {
  const { displayStart: booking1StartTime, displayEnd: booking1EndTime } = booking1.attributes;
  const { displayStart: booking2StartTime, displayEnd: booking2EndTime } = booking2.attributes;
  if (!booking1StartTime || !booking1EndTime || !booking2StartTime || !booking2EndTime) {
    return false;
  }
  const b1StartTime = new Date(booking1StartTime).getTime();
  const b1EndTime = new Date(booking1EndTime).getTime();
  const b2StartTime = new Date(booking2StartTime).getTime();
  const b2EndTime = new Date(booking2EndTime).getTime();
  if (b1StartTime !== b2StartTime || b1EndTime !== b2EndTime) return false;
  return true;
};

// Count the number of distinct trips, trips that have same time and number are counted as one
const findNumberOfDistinctTrips = transactions => {
  const bookings = getTransactionBookings(transactions);
  const bookingLength = bookings.length;
  let visitedBookings = new Array(bookings.length);
  let finished = false;
  let processedBookingsCount = 0;
  let numberOfDistinctTrips = 0;

  for (var i = 0; i < bookingLength; i++) {
    const bookingI = cloneDeep(bookings[i]);
    if (!visitedBookings[i]) {
      for (var j = i + 1; j < bookingLength; j++) {
        if (!visitedBookings[j]) {
          const bookingJ = cloneDeep(bookings[j]);
          const areTwoBookingMadeAtTheSameTime = compareTwoBookingsTime(bookingI, bookingJ);
          if (areTwoBookingMadeAtTheSameTime) {
            visitedBookings[j] = true;
            processedBookingsCount++;
            if (processedBookingsCount === bookingLength) {
              finished = true;
              break;
            }
          }
        }
      }
      numberOfDistinctTrips++;
      if (finished) break;
      visitedBookings[i] = true;
      processedBookingsCount++;
      if (processedBookingsCount === bookingLength) break;
    }
  }
  return numberOfDistinctTrips;
};

export const checkoutStepConfiguration = {
  CHECKOUT_OPEN_INVOICES: 'openInvoices',
  CHECKOUT_ADD_ONS: 'addOns',
  CHECKOUT_PAYMENT: 'payments',
};

export class CheckoutPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageData: {},
      dataLoaded: false,
      submitting: false,
      isRetryButtonClicked: false,
      shouldRedirectToSearchPage: false,
      isUsingMastercard: false,
      enteredCard: null,
      nextLocation: {},
      isOpenConfirmLeaveModal: false,
      isBlocking: true,
      isDeliveryState: false,
      totalAmount: 0,
      isPtotectionPlanState: false,
      isFuelInclusionState: false,
      checkoutStep: checkoutStepConfiguration.CHECKOUT_ADD_ONS,
      checkoutStepName: checkoutStepConfiguration.CHECKOUT_ADD_ONS,
      isExcessReductionState: false,
      isExcessReduction2State: false,
      isMileagePackageIncludedState: false,
      isFuelSelectedManually: false,
      isAlert: false,
      selectedMileage: 0,
      showUnpaidDuesModal: false,
    };
    this.stripe = null;
    this.onStripeInitialized = this.onStripeInitialized.bind(this);
    this.loadInitialData = this.loadInitialData.bind(this);
    this.handlePaymentIntent = this.handlePaymentIntent.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.pushEventEnterFormGTM = this.pushEventEnterFormGTM.bind(this);
    this.handleChangeAddOn = this.handleChangeAddOn.bind(this);
    this.handleSelectedMileageAddOn = this.handleSelectedMileageAddOn.bind(this);
  }

  componentDidMount() {
    if (window) {
      this.loadInitialData();
    }
    mapAbSegment = 'B';

    if (!mapAbSegment) {
      emitter.defineVariants('mileageExperiment', ['A', 'B'], [0, 100]);
      emitter.addPlayListener((experimentName, variantName) => {
        EXPERIMENT_DATA.experimentName = experimentName;
        EXPERIMENT_DATA.variantName = variantName;
        initialPageData = variantName;
      });
    }

    if (EXPERIMENT_DATA) {
      if (mapAbSegment === 'A') {
        initiateExperimentEventFromListing({
          props: this.props,
          event: 'dl_mileage_vas_exp_A',
          experimentData: EXPERIMENT_DATA,
        });
      } else if (mapAbSegment === 'B') {
        initiateExperimentEventFromListing({
          props: this.props,
          event: 'dl_mileage_vas_exp_B',
          experimentData: EXPERIMENT_DATA,
        });
      } else {
        // Do nothing
      }
    }
    // this.checkoutPageManagingStep();
  }

  checkoutPageManagingStep = stepNumber => {
    // const { location, history } = this.props;
    // const { searchParams, ...rest } = parse(location.search);
    // let checkoutStep = checkoutStepConfiguration.CHECKOUT_ADD_ONS;
    // if (searchParams && !searchParams.checkoutStep) {
    //   checkoutStep = checkoutStepConfiguration.CHECKOUT_ADD_ONS;
    // } else if (
    //   searchParams &&
    //   searchParams.checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS
    // ) {
    //   checkoutStep = checkoutStepConfiguration.CHECKOUT_ADD_ONS;
    // } else if (
    //   searchParams &&
    //   searchParams.checkoutStep === checkoutStepConfiguration.CHECKOUT_PAYMENT
    // ) {
    //   checkoutStep = checkoutStepConfiguration.CHECKOUT_PAYMENT;
    // }
    this.setState({
      checkoutStep: stepNumber,
    });
    this.loadInitialData();
  };

  pushEventEnterFormGTM(event, additionalProperties) {
    const { speculatedTransaction } = this.props;
    initiateEventFromTransaction({
      props: this.props,
      transaction: { ...speculatedTransaction, ...additionalProperties },
      event,
    });
  }

  handleRefreshInvoices = () => {
    const { fetchOpenInvoices } = this.props;
    fetchOpenInvoices();
  };

  /**
   * Load initial data for the page
   *
   * Since the data for the checkout is not passed in the URL (there
   * might be lots of options in the future), we must pass in the data
   * some other way. Currently the ListingPage sets the initial data
   * for the CheckoutPage's Redux store.
   *
   * For some cases (e.g. a refresh in the CheckoutPage), the Redux
   * store is empty. To handle that case, we store the received data
   * to window.sessionStorage and read it from there if no props from
   * the store exist.
   *
   * This function also sets of fetching the speculative transaction
   * based on this initial data.
   */
  loadInitialData() {
    const {
      bookingData,
      bookingDates,
      listing,
      transaction,
      fetchSpeculatedTransaction,
      fetchOpenInvoices,
      openInvoices,
      fetchStripeCustomer,
      history,
      currentUser,
      timeSlotsObj,
      onFetchDataForUpdateBookingCheckoutPage,
    } = this.props;
    // Fetch currentUser with stripeCustomer entity
    // Note: since there's need for data loading in "componentWillMount" function,
    //       this is added here instead of loadData static function.
    fetchStripeCustomer();

    const isUpdateBooking = get(this.props, 'location.state.updateBooking', false);
    if (isUpdateBooking) {
      onFetchDataForUpdateBookingCheckoutPage(this.props.location.state);
    } else {
      fetchOpenInvoices();
    }

    // Browser's back navigation should not rewrite data in session store.
    // Action is 'POP' on both history.back() and page refresh cases.
    // Action is 'PUSH' when user has directed through a link
    // Action is 'REPLACE' when user has directed through login/signup process
    const hasNavigatedThroughLink = history.action === 'PUSH' || history.action === 'REPLACE';

    const hasDataInProps = !!(bookingData && bookingDates && listing) && hasNavigatedThroughLink;
    if (hasDataInProps && currentUser) {
      // Store data only if data is passed through props and user has navigated through a link.
      storeData(bookingData, bookingDates, listing, transaction, currentUser, STORAGE_KEY, {
        timeSlotsObj,
      });
    }

    // NOTE: stored data can be empty if user has already successfully completed transaction.
    const pageData = hasDataInProps
      ? { bookingData, bookingDates, listing, transaction, timeSlotsObj }
      : storedData(STORAGE_KEY);

    // Check if a booking is already created according to stored data.
    const tx = pageData ? pageData.transaction : null;
    const isBookingCreated = tx && tx.booking && tx.booking.id;

    const shouldFetchSpeculatedTransaction =
      pageData &&
      pageData.listing &&
      pageData.listing.id &&
      pageData.bookingData &&
      pageData.bookingDates &&
      pageData.bookingDates.bookingStart &&
      pageData.bookingDates.bookingEnd &&
      (currentUser || pageData.temporaryUserData) &&
      !isBookingCreated;

    if (shouldFetchSpeculatedTransaction && !isUpdateBooking) {
      const listingId = pageData.listing.id;
      const user = currentUser ? currentUser : pageData.temporaryUserData;
      const { bookingStart, bookingEnd } = pageData.bookingDates;
      const { listing } = pageData;
      const {
        isPaidAmount,
        paidAmount,
        diffHours,
        tripBookingStart,
        tripBookingEnd,
        updateBookingStart,
        updateBookingEnd,
      } = pageData.bookingData;
      const { location } = this.props;

      const commercialListing = listing.attributes.publicData.insurance === 'commercial';

      const isMinimumPrice = listing.attributes.price.amount <= 6000;
      const currentYear = new Date().getFullYear();
      const youngDriver =
        user &&
        user.attributes.profile.protectedData &&
        user.attributes.profile.protectedData.dateOfBirth &&
        currentYear - user.attributes.profile.protectedData.dateOfBirth.year <= 24;

      let bookingProcess = isPaidAmount
        ? config.updateBookingChargingProcessAlias
        : config.masterProcessAlias;
      let transition = null;
      if (!youngDriver) {
        if (commercialListing) {
          transition = TRANSITION_REQUEST_PAYMENT_NORMAL_COMMERCIAL;
        } else {
          transition = TRANSITION_REQUEST_PAYMENT_NORMAL_PRIVATE;
        }
      } else {
        if (commercialListing) {
          transition = TRANSITION_REQUEST_PAYMENT_YOUNG_COMMERCIAL;
        } else {
          transition = TRANSITION_REQUEST_PAYMENT_YOUNG_PRIVATE;
        }
      }

      if (isPaidAmount) {
        transition = TRANSITION_UPDATE_BOOKING_CHILD_TX_REQUEST;
      }
      const currentBookingDateStart = new Date(
        new Date(moment(tripBookingStart)).toISOString().slice(0, -1)
      );
      const currentBookingDateEnd = new Date(
        new Date(moment(tripBookingEnd)).toISOString().slice(0, -1)
      );
      const currentBookingStart = new Date(
        new Date(moment(updateBookingStart)).toISOString().slice(0, -1)
      );
      const currentBookingEnd = new Date(
        new Date(moment(updateBookingEnd)).toISOString().slice(0, -1)
      );
      const displayBookingDateStart = new Date(moment(updateBookingStart));
      const displayBookingDateEnd = new Date(moment(updateBookingEnd));

      const timeFromTripStart = moment().diff(moment(displayBookingDateStart), 'hours', true);
      const timeFromThreeDays = moment().add(3, 'days');

      const speculatedData = {
        listingId,
        bookingStart: location.state && location.state.updateBooking ? bookingStart : bookingStart,
        bookingEnd: location.state && location.state.updateBooking ? bookingEnd : bookingEnd,
        bookingDisplayStart:
          location.state && location.state.updateBooking ? bookingStart : bookingStart,
        bookingDisplayEnd: location.state && location.state.updateBooking ? bookingEnd : bookingEnd,
        customerPhoneNumber: user.attributes.profile.protectedData.phoneObj,
        customerLocation: user.attributes.profile.protectedData.location,
        isDrivelahGo: !!listing.attributes.metadata.isDrivelahGo,
        credits: pageData.bookingData.signupCredits,
        voucherCode: pageData.bookingData.voucherCode,
        payForFuel: pageData.bookingData.payForFuel,
        isPaidAmount,
        diffHours,
        currentTx: pageData.bookingData.currentTx,
        ...(pageData.bookingData.peakHoursDiff > 0 && {
          peakHoursDiff: pageData.bookingData.peakHoursDiff,
        }),
        ...(pageData.bookingData.regularHoursDiff > 0 && {
          regularHoursDiff: pageData.bookingData.regularHoursDiff,
        }),
        // paidAmount,
        isDelivery: this.state.isDeliveryState,
        isExcessReduction: this.state.isExcessReductionState,
        isExcessReduction2: this.state.isExcessReduction2State,
        isMileagePackageIncluded: this.state.isMileagePackageIncludedState,
        // isMileagePackageIncluded: true,
        selectedMileage: this.state.selectedMileage,
        mileageExperiment: mapAbSegment,
        currentBookingStart:
          location.state && location.state.updateBooking
            ? moment(currentBookingStart).format('MMM DD, YYYY HH:mm')
            : null,
        currentBookingEnd:
          location.state && location.state.updateBooking
            ? moment(currentBookingEnd).format('MMM DD, YYYY HH:mm')
            : null,
        currentBookingStartTimestamp:
          location.state && location.state.updateBooking
            ? new Date(currentBookingStart).getTime()
            : null,
        currentBookingEndTimestamp:
          location.state && location.state.updateBooking
            ? new Date(currentBookingEnd).getTime()
            : null,
        currentBookingDateStart:
          location.state && location.state.updateBooking
            ? moment(currentBookingDateStart).format('MMM DD, YYYY HH:mm')
            : null,
        currentBookingDateEnd:
          location.state && location.state.updateBooking
            ? moment(currentBookingDateEnd).format('MMM DD, YYYY HH:mm')
            : null,
        currentBookingDateStartTimestamp:
          location.state && location.state.updateBooking
            ? new Date(currentBookingDateStart).getTime()
            : null,
        currentBookingDateEndTimestamp:
          location.state && location.state.updateBooking
            ? new Date(currentBookingDateEnd).getTime()
            : null,
        stripeTKey: pageData.bookingData.stripeTKey,
        displayBookingDateStart:
          location.state && location.state.updateBooking
            ? moment(displayBookingDateStart).format('MMM DD, YYYY HH:mm')
            : null,
        displayBookingDateEnd:
          location.state && location.state.updateBooking
            ? moment(displayBookingDateEnd).format('MMM DD, YYYY HH:mm')
            : null,
      };

      // Fetch speculated transaction for showing price in booking breakdown
      // NOTE: if unit type is line-item/units, quantity needs to be added.
      // The way to pass it to checkout page is through pageData.bookingData

      fetchSpeculatedTransaction(
        speculatedData,
        bookingProcess,
        isMinimumPrice,
        pageData.listing,
        transition
      );
    }

    this.setState({ pageData: pageData || {}, dataLoaded: true });
  }

  handlePaymentIntent(handlePaymentParams) {
    const {
      currentUser,
      stripeCustomerFetched,
      onInitiateOrder,
      onHandleCardPayment,
      onConfirmPayment,
      onSendMessage,
      onSavePaymentMethod,
      onConfirmPaymentDeposit,
      onConfirmPaymentUnpaidDues,
      // onCheckBookingOverlap,
      fetchedDepositTransaction,
    } = this.props;
    const { shouldReduceBookingDeposit, isFuelInclusionState: isFuelInclusion } = this.state;

    const {
      pageData,
      speculatedTransaction,
      message,
      paymentIntent,
      selectedPaymentMethod,
      saveAfterOnetimePayment,
    } = handlePaymentParams;

    const storedTx = ensureTransaction(pageData.transaction);

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );

    let createdPaymentIntent = null;

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredDefaultPaymentMethod.id
    );
    const stripePaymentMethodId = hasDefaultPaymentMethod
      ? ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId
      : null;

    const selectedPaymentFlow = paymentFlow(selectedPaymentMethod, saveAfterOnetimePayment);

    // Step 1: initiate order by requesting payment from Marketplace API
    const fnRequestPayment = fnParams => {
      // fnParams should be { listingId, bookingStart, bookingEnd }
      const { bookingProcess, isMinimumPrice, listing, ...fnRestParams } = fnParams;
      const hasPaymentIntents =
        storedTx.attributes.protectedData && storedTx.attributes.protectedData.stripePaymentIntents;

      // If paymentIntent exists, order has been initiated previously.
      return hasPaymentIntents
        ? Promise.resolve(storedTx)
        : onInitiateOrder(
            fnRestParams,
            storedTx.id,
            bookingProcess,
            isMinimumPrice,
            listing,
            selectedPaymentFlow,
            handlePaymentParams,
            shouldReduceBookingDeposit
          );
    };

    const fnRequestUpdateBooking = fnParams => {
      const { onRequestToUpdateBooking, location } = this.props;
      const updateBooking =
        location.state && location.state.updateBooking ? location.state.updateBooking : false;

      if (updateBooking) {
        const updateBookingParams = pageData.bookingData.updateBookingParams;
        const { transaction, transition, transactionId, listing } = updateBookingParams;

        const {
          updateBookingStart,
          updateBookingEnd,
          currentTx,
          regularHoursDiff,
          peakHoursDiff,
        } = pageData.bookingData;

        const params = {
          createUpdateBooking: true,
          transaction,
          transition,
          transactionId,
          listing,
          bookingStart: updateBookingStart,
          bookingEnd: updateBookingEnd,
          currentTx,
          regularHoursDiff,
          peakHoursDiff,
          protectedData: {
            childTransaction: [
              ...getUpdateObjs(transaction),
              {
                ...makeChildUpdateBookingDates(updateBookingStart, updateBookingEnd),
                txId: fnParams.id.uuid,
              },
            ],
          },
        };

        onRequestToUpdateBooking(params);
        initiateEventFromTransaction({
          props: this.props,
          transaction: pageData.transaction,
          event: EVENT_TRIP_MODIFICATION_REQUEST_SENT_GUEST,
        });
      }

      return fnParams;
    };

    const fnHandleUnpaidDues = async (fnParams) => {
      const order = ensureTransaction(fnParams);
      const unpaidDuesTransaction = order.unpaidDues;
      const {openInvoices}    = this.props;
      console.log("openInvoices: ", openInvoices);
      const unpaidDues = {
        unpaidInvoices: openInvoices?.invoices?.map(invoice => ({
          id: invoice.invoiceId,
          amount: invoice.total
        })) || [],
        totalUnpaidAmount: openInvoices?.totalUnpaidDues || 0
      };

      // Return early if no unpaid dues transaction exists
      if (!unpaidDuesTransaction) {
        return fnParams;
      }

      const { stripe, card } = handlePaymentParams;

      // Get payment intent client secret from transaction protected data
      const hasPaymentIntents =
        unpaidDuesTransaction.attributes.protectedData &&
        unpaidDuesTransaction.attributes.protectedData.stripePaymentIntents;

      if (!hasPaymentIntents) {
        throw new Error('Missing StripePaymentIntents key in unpaid dues transaction protected data');
      }

      const { stripePaymentIntentClientSecret } = hasPaymentIntents
        ? unpaidDuesTransaction.attributes.protectedData.stripePaymentIntents.default
        : null;

      // Setup payment arguments based on whether using default payment method
      const args = ensuredDefaultPaymentMethod
        ? [stripePaymentIntentClientSecret, { payment_method: ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId }]
        : [stripePaymentIntentClientSecret, card];

      try {
        // Process the payment
        const stripePay = await stripe.handleCardPayment(...args);

        if (stripePay.error) {
          throw new Error('Failed to process payment for unpaid dues');
        }

        console.log('unpaid dues stripePay: ', unpaidDues);

        // Dispatch the transition action
        await this.props.dispatch(transitionUnpaidDuesTransaction({
          unpaidDues,
          transaction: unpaidDuesTransaction,
          paymentResult: stripePay
        }));

        // Return successful payment result
        return fnParams;

      } catch (error) {
        console.log('Exception in unpaid dues payment processing:', error);
        throw error;
      }
    };

    const fnHandleDepositCardPayment = fnParams => {
      const order = ensureTransaction(fnParams);
      const depositTx = order.deposit || fetchedDepositTransaction;
      if (!depositTx) {
        return Promise.resolve({
          mainTx: order,
          deposit: false,
        });
      }
      const hasPaymentIntents =
        depositTx.attributes.protectedData &&
        depositTx.attributes.protectedData.stripePaymentIntents;

      if (!hasPaymentIntents) {
        throw new Error(
          `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
        );
      }

      const { stripePaymentIntentClientSecret } = hasPaymentIntents
        ? depositTx.attributes.protectedData.stripePaymentIntents.default
        : null;

      const { stripe, card, billingDetails, paymentDepositIntent } = handlePaymentParams;
      const stripeElementMaybe = selectedPaymentFlow !== USE_SAVED_CARD ? { card } : {};
      // Note: payment_method could be set here for USE_SAVED_CARD flow.
      // { payment_method: stripePaymentMethodId }
      // However, we have set it already on API side, when PaymentIntent was created.
      const hasPaymentIntentUserActionsDone =
        paymentDepositIntent &&
        STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentDepositIntent.status);
      const paymentParams =
        selectedPaymentFlow !== USE_SAVED_CARD
          ? {
              payment_method_data: {
                billing_details: billingDetails,
              },
            }
          : {};

      const params = {
        stripePaymentIntentClientSecret,
        orderId: depositTx.id,
        stripe,
        ...stripeElementMaybe,
        paymentParams,
        order: depositTx,
        userId: this.props.currentUser.id.uuid,
        mainTx: order,
        deposit: true,
      };
      return hasPaymentIntentUserActionsDone
        ? Promise.resolve({
            orderId: depositTx.id,
            paymentIntent: paymentDepositIntent,
            order: depositTx,
            mainTx: order,
            deposit: true,
            userId: this.props.currentUser.id.uuid,
          })
        : onHandleCardPayment(params);
    };

    // Step 2: pay using Stripe SDK
    const fnHandleCardPayment = fnParams => {
      // fnParams should be returned transaction entity

      const order = ensureTransaction(fnParams);
      if (order.id) {
        // Store order.
        const { bookingData, bookingDates, listing } = pageData;
        storeData(bookingData, bookingDates, listing, order, currentUser, STORAGE_KEY);
        this.setState({ pageData: { ...pageData, transaction: order } });
      }

      const hasPaymentIntents =
        order.attributes.protectedData && order.attributes.protectedData.stripePaymentIntents;
      if (!hasPaymentIntents) {
        throw new Error(
          `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
        );
      }

      const { stripePaymentIntentClientSecret } = hasPaymentIntents
        ? order.attributes.protectedData.stripePaymentIntents.default
        : null;

      const { stripe, card, billingDetails, paymentIntent } = handlePaymentParams;
      const stripeElementMaybe = selectedPaymentFlow !== USE_SAVED_CARD ? { card } : {};

      // Note: payment_method could be set here for USE_SAVED_CARD flow.
      // { payment_method: stripePaymentMethodId }
      // However, we have set it already on API side, when PaymentIntent was created.
      const paymentParams =
        selectedPaymentFlow !== USE_SAVED_CARD
          ? {
              payment_method_data: {
                billing_details: billingDetails,
              },
            }
          : {};

      const params = {
        stripePaymentIntentClientSecret,
        orderId: order.id,
        stripe,
        ...stripeElementMaybe,
        paymentParams,
        order,
        userId: this.props.currentUser.id.uuid,
      };

      // If paymentIntent status is not waiting user action,
      // handleCardPayment has been called previously.
      const hasPaymentIntentUserActionsDone =
        paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);
      return hasPaymentIntentUserActionsDone
        ? Promise.resolve({ transactionId: order.id, paymentIntent, order })
        : onHandleCardPayment(params);
    };

    // Step 3: complete order by confirming payment to Marketplace API
    // Parameter should contain { paymentIntent, transactionId } returned in step 2
    const fnConfirmPaymentDeposit = ({ deposit, transactionId, order, mainTx }) => {
      if (deposit) {
        return onConfirmPaymentDeposit({
          order,
        }).then(() => {
          return mainTx;
        });
      } else {
        return Promise.resolve(mainTx);
      }
    };

    const fnConfirmPaymentUnpaidDues = ({ unpaidDues, transactionId, order, mainTx }) => {
      if (unpaidDues) {
        return onConfirmPaymentUnpaidDues({
          order,
        }).then(() => {
          return mainTx;
        });
      } else {
        return Promise.resolve(mainTx);
      }
    };
    const fnConfirmPayment = fnParams => {
      createdPaymentIntent = fnParams.paymentIntent;
      const { listing, bookingData } = pageData;
      const { location } = this.props;
      const { isPaidAmount } = bookingData;
      const transactionId = location.state && location.state.transactionId;

      return onConfirmPayment({
        ...fnParams,
        savedListing: listing,
        isPaidAmount,
        userId: this.props.currentUser.id.uuid,
        parentTransaction: { transactionId },
      });
    };

    // Step 4: send initial message
    const fnSendMessage = fnParams => {
      return onSendMessage({ ...fnParams, message });
    };

    // Step 5: optionally save card as defaultPaymentMethod
    const fnSavePaymentMethod = fnParams => {
      const pi = createdPaymentIntent || paymentIntent;

      if (selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE) {
        return onSavePaymentMethod(ensuredStripeCustomer, pi.payment_method)
          .then(response => {
            if (response.errors) {
              return { ...fnParams, paymentMethodSaved: false };
            }
            return { ...fnParams, paymentMethodSaved: true };
          })
          .catch(e => {
            // Real error cases are catched already in paymentMethods page.
            return { ...fnParams, paymentMethodSaved: false };
          });
      } else {
        return Promise.resolve({ ...fnParams, paymentMethodSaved: true });
      }
    };

    // Here we create promise calls in sequence
    // This is pretty much the same as:
    // fnRequestPayment({...initialParams})
    //   .then(result => fnHandleCardPayment({...result}))
    //   .then(result => fnConfirmPayment({...result}))
    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaymentIntentCreation = composeAsync(
      fnRequestPayment,
      fnRequestUpdateBooking,
      fnHandleUnpaidDues,
      // fnConfirmPaymentUnpaidDues,
      fnHandleDepositCardPayment,
      fnConfirmPaymentDeposit,
      fnHandleCardPayment,
      fnConfirmPayment,
      fnSendMessage,
      fnSavePaymentMethod
    );

    const { listing, bookingData } = this.state.pageData;
    const { isPaidAmount } = bookingData;

    const isMinimumPrice = listing.attributes.price.amount <= 6000;
    const commercialListing = listing.attributes.publicData.insurance === 'commercial';
    const currentYear = new Date().getFullYear();
    const youngDriver =
      currentUser &&
      currentUser.attributes.profile.protectedData &&
      currentUser.attributes.profile.protectedData.dateOfBirth &&
      currentYear - currentUser.attributes.profile.protectedData.dateOfBirth.year <= 24;

    // let bookingProcess = config.masterProcessAlias;
    let bookingProcess = isPaidAmount
      ? config.updateBookingChargingProcessAlias
      : config.masterProcessAlias;
    let transition = null;
    if (!youngDriver) {
      if (commercialListing) {
        transition = TRANSITION_REQUEST_PAYMENT_NORMAL_COMMERCIAL;
      } else {
        transition = TRANSITION_REQUEST_PAYMENT_NORMAL_PRIVATE;
      }
    } else {
      if (commercialListing) {
        transition = TRANSITION_REQUEST_PAYMENT_YOUNG_COMMERCIAL;
      } else {
        transition = TRANSITION_REQUEST_PAYMENT_YOUNG_PRIVATE;
      }
    }

    if (isPaidAmount) {
      transition = TRANSITION_UPDATE_BOOKING_CHILD_TX_REQUEST;
    }

    // Create order aka transaction
    // NOTE: if unit type is line-item/units, quantity needs to be added.
    // The way to pass it to checkout page is through pageData.bookingData
    const tx = speculatedTransaction ? speculatedTransaction : storedTx;

    // Note: optionalPaymentParams contains Stripe paymentMethod,
    // but that can also be passed on Step 2
    // stripe.handleCardPayment(stripe, { payment_method: stripePaymentMethodId })
    const { hasMastercardPromoBeenUsed } = currentUser.attributes.profile.metadata;
    const optionalPaymentParams =
      selectedPaymentFlow === USE_SAVED_CARD && hasDefaultPaymentMethod
        ? { paymentMethod: stripePaymentMethodId }
        : selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE
        ? { setupPaymentMethodForSaving: true }
        : {};

    const shouldUseMastercardPromoLineItem =
      this.state.isUsingMastercard && checkCanUseMastercardPromo(currentUser);

    const orderParams = {
      transition,
      listingId: pageData.listing.id,
      bookingStart: isPaidAmount
        ? new Date(tx.attributes.protectedData.bookingDisplayStart)
        : tx.booking.attributes.start,
      bookingEnd: isPaidAmount
        ? new Date(tx.attributes.protectedData.bookingDisplayEnd)
        : tx.booking.attributes.end,
      bookingDisplayStart: isPaidAmount
        ? new Date(tx.attributes.protectedData.bookingDisplayStart)
        : tx.booking.attributes.displayStart,
      bookingDisplayEnd: isPaidAmount
        ? new Date(tx.attributes.protectedData.bookingDisplayEnd)
        : tx.booking.attributes.displayEnd,
      protectedData: tx.attributes.protectedData,

      preauthenListingId: config.preauthenListingId,
      depositListingId: config.depositListingId,
      preauthenListingIdWithDepositDiscount: config.preauthenListingIdWithDepositDiscount,
      quantity: isPaidAmount
        ? calculateBookingDays(
            tx.attributes.protectedData.bookingDisplayStart,
            tx.attributes.protectedData.bookingDisplayEnd
          )
        : calculateBookingDays(
            tx.booking.attributes.displayStart,
            tx.booking.attributes.displayEnd
          ),
      credits: pageData.bookingData.signupCredits,
      voucherCode: pageData.bookingData.voucherCode,
      isPaidAmount: pageData.bookingData.isPaidAmount,
      ...(pageData.bookingData.regularHoursDiff &&
        pageData.bookingData.regularHoursDiff > 0 && {
          regularHoursDiff: pageData.bookingData.regularHoursDiff,
        }),
      ...(pageData.bookingData.peakHoursDiff &&
        pageData.bookingData.peakHoursDiff > 0 && {
          peakHoursDiff: pageData.bookingData.peakHoursDiff,
        }),
      currentTx: pageData.bookingData.currentTx,
      diffHours: pageData.bookingData.diffHours,
      // paidAmount: pageData.bookingData.paidAmount,
      isMinimumPrice,
      bookingProcess,
      listing: pageData.listing,
      userId: this.props.currentUser.id.uuid,
      shouldUseMastercardPromoLineItem,
      isDelivery: this.state.isDeliveryState,
      isExcessReduction: this.state.isExcessReductionState,
      ...(isFuelInclusion ? { isFuelInclusion } : {}),
      isExcessReduction2: this.state.isExcessReduction2State,
      mileageExperiment: mapAbSegment,
      isMileagePackageIncluded: this.state.isMileagePackageIncludedState,
      selectedMileage: this.state.selectedMileage,
      ...optionalPaymentParams,
    };

    return handlePaymentIntentCreation(orderParams);
  }

  handleSubmit(values) {
    //Clear code in localStorage for voucher if present
    if(localStorage.getItem('code')) {
      localStorage.removeItem('code')
    }

    if (this.state.submitting) {
      return;
    }

    const isUpdateBooking = get(this.props, 'location.state.updateBooking', false);
    if (isUpdateBooking) {
      this.setState({ submitting: true });

      return this.props.onMakeUpdateBookingRequest(this.props.location.state, this.props.history);
    }

    this.setState({ submitting: true, isBlocking: false });

    const {
      history,
      speculatedTransaction,
      currentUser,
      paymentIntent,
      dispatch,
      listing,
      intl,
      paymentDepositIntent,
      onUpdateUserData,
    } = this.props;
    const { card, message, paymentMethod, formValues } = values;
    const {
      name,
      addressLine1,
      addressLine2,
      postal,
      city,
      state,
      country,
      saveAfterOnetimePayment,
    } = formValues;

    // Billing address is recommended.
    // However, let's not assume that <StripePaymentAddress> data is among formValues.
    // Read more about this from Stripe's docs
    // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment-no-element
    const addressMaybe =
      addressLine1 && postal
        ? {
            address: {
              city: city,
              country: country,
              line1: addressLine1,
              line2: addressLine2,
              postal_code: postal,
              state: state,
            },
          }
        : {};
    const billingDetails = {
      name,
      email: ensureCurrentUser(currentUser).attributes.email,
      // ...addressMaybe,
    };

    const requestPaymentParams = {
      pageData: this.state.pageData,
      speculatedTransaction,
      stripe: this.stripe,
      card,
      billingDetails,
      message,
      paymentIntent,
      selectedPaymentMethod: paymentMethod,
      saveAfterOnetimePayment: !!saveAfterOnetimePayment,
      paymentDepositIntent,
    };

    const { listing: savedListing, transaction } = this.state.pageData;
    const existingTransaction = ensureTransaction(transaction);
    const ensuredSpeculatedTransaction = ensureTransaction(speculatedTransaction);
    const tx = existingTransaction.booking ? existingTransaction : ensuredSpeculatedTransaction;

    if(tx && tx.guestIdentityVerificationStatus) {
      if(tx && tx.guestIdentityVerificationStatus && tx.guestIdentityVerificationStatus !== 'confirmed') {
        history.push('/user-verification');
      }
    }
    const isExcessReduction =
      tx && tx.attributes.protectedData && tx.attributes.protectedData.isExcessReduction;
    const isExcessReduction2 =
      tx && tx.attributes.protectedData && tx.attributes.protectedData.isExcessReduction2;

    const listingGTM = ensureListing(listing || savedListing || (tx && tx.listing));
    const rawProperties = createRawPropertiesForGTM({
      props: this.props,
      button: {
        buttonId: SEND_REQUEST_BOOKING_BUTTON_ID,
        text: intl.formatMessage({ id: 'StripePaymentForm.submitPaymentInfo' }),
      },
      listing: listingGTM,
      transaction: tx,
    });
    let particularVASParamsFrom = particularVASParams;
    if (isExcessReduction) {
      particularVASParamsFrom = { ...particularVASParamsFrom, booking_vas_excess_from: 'checkout' };
      this.pushEventEnterFormGTM(EVENT_BOOK_EXCESS_SUCCESS_GUEST, particularVASParamsFrom);
    } else if (isExcessReduction2) {
      particularVASParamsFrom = {
        ...particularVASParamsFrom,
        booking_vas_excess_2_from: 'checkout',
      };
      this.pushEventEnterFormGTM(EVENT_BOOK_EXCESS_2_SUCCESS_GUEST, particularVASParamsFrom);
    } else {
      // Do nothing
    }
    pushGTMBookEvent(rawProperties, EVENT_BOOK_CREATED_REQUEST_GUEST);
    if (paymentMethod === 'defaultCard') {
      pushGTMBookEvent(rawProperties, EVENT_BOOK_USED_PRESAVED_DETAILS);
    }

    this.handlePaymentIntent(requestPaymentParams)
      .then(res => {
        const { messageSuccess, paymentMethodSaved } = res;
        this.setState({ submitting: false });
        let orderId = requestPaymentParams.pageData.bookingData.isPaidAmount
          ? requestPaymentParams.pageData.bookingData.transactionId
          : res.orderId;

        // Update payment method verified
        const protectedData = {
          isPaymentMethodVerified: true,
        };
        onUpdateUserData({
          protectedData,
        }).then(() => {
          console.log('UPDATED isPaymentMethodVerified');
        });

        const routes = routeConfiguration();
        const initialMessageFailedToTransaction = messageSuccess ? null : orderId;
        const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, { id: orderId.uuid });
        const initialValues = {
          initialMessageFailedToTransaction,
          savePaymentMethodFailed: !paymentMethodSaved,
        };

        initializeOrderPage(initialValues, routes, dispatch);

        const particularParams = { existTransaction: true };
        rawProperties.ui.button = null;
        rawProperties.transaction.id = orderId;
        if (!!saveAfterOnetimePayment) {
          pushGTMBookEvent(rawProperties, EVENT_BOOK_SAVED_CARD_DETAILS, particularParams);
        }
        // Enhanced Data Conversion
        if (tx && orderId && currentUser) {
          const payInTotal = tx.attributes.payinTotal;
          const enhancedConversionData = {
            event: 'order_complete',
            order_id: orderId, // fetch the order id variable
            order_value: payInTotal.amount, // fetch the order value variable
            order_currency: 'USD',
            enhanced_conversion_data: {
              email: currentUser.attributes.email, // change sadadastfgnf@gmail.com with user dynamic email var
            },
          };
          pushDataLayer(enhancedConversionData);
        }

        pushGTMBookEvent(rawProperties, EVENT_BOOK_SENT_REQUEST_SUCCESS_GUEST, particularParams);
        pushGTMBookEvent(rawProperties, EVENT_BOOK_SENT_REQUEST_SUCCESS_GUEST, particularParams);
        sendG4AEvent(rawProperties.transaction, GTAG_ACTIONS.ACTION_PURCHASE, {});
        pushGTMBookEvent(rawProperties, SERVER_EVENT_BOOK_RECEIVED_REQUEST_SUCCESS_HOST, {
          ...particularParams,
          userDiffActionTaker: true,
          isServerSideEvent: true,
        });
        const instantBooking = get(listingGTM, 'attributes.publicData.instantBooking');
        if (instantBooking) {
          pushGTMBookEvent(rawProperties, EVENT_BOOK_REQUEST_ACCEPTED_HOST, {
            ...particularParams,
            userDiffActionTaker: true,
          });
          pushGTMBookEvent(rawProperties, EVENT_BOOK_REQUEST_ACCEPTED_GUEST, particularParams);
        }
        clearData(STORAGE_KEY);
        if (
          currentUser &&
          currentUser.attributes &&
          currentUser.attributes.profile &&
          currentUser.attributes.profile.metadata &&
          Object.keys(currentUser.attributes.profile.metadata).length &&
          currentUser.attributes.profile.metadata.smoveType3 &&
          currentUser.attributes.profile.metadata.smoveType3 === 'HF'
        ) {
          sendSmoveUserBookingNotification({
            transactionId: orderId.uuid,
            hostId: currentUser.id.uuid,
          });
        }
        pushGTMBookEventAfterOrderCompleted(rawProperties, EVENT_ORDER_COMPLETED, particularParams);
        history.push(orderDetailsPath);
      })
      .catch(err => {
        console.error(err);
        this.setState({ submitting: false });
        rawProperties.ui.button = null;
        rawProperties.fail_reason =
          err.code && err.decline_code
            ? `${err.code} ${err.decline_code}`
            : 'Stripe card error code is not given';
        pushGTMBookEvent(rawProperties, EVENT_BOOK_CREATED_REQUEST_FAIL_GUEST);
        pushGTMBookEvent(rawProperties, SERVER_EVENT_BOOK_CREATED_REQUEST_FAIL_HOST, {
          userDiffActionTaker: true,
          isServerSideEvent: true,
        });
        //Reading the error code and decline code to show the error message, if not given then showing the default error message.
        let errorMessage =
          err.code && err.decline_code
            ? `${err.code} ${err.decline_code}`
            : err.message || 'Stripe card error code is not given';
        sendStripeErrorSlackNotification({
          transactionId: currentUser.id.uuid,
          error: errorMessage,
        });
      });
  }

  onRetrievePaymentDepositIntent = ({ depositId, stripe }) => {
    const { onFetchDepositTx, onRetrievePaymentIntent } = this.props;
    onFetchDepositTx({ id: depositId }).then(deposit => {
      const { stripePaymentIntentClientSecret } =
        deposit.attributes.protectedData && deposit.attributes.protectedData.stripePaymentIntents
          ? deposit.attributes.protectedData.stripePaymentIntents.default
          : {};
      onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret, deposit: true });
    });
  };

  onStripeInitialized(stripe) {
    this.stripe = stripe;

    const { paymentIntent, onRetrievePaymentIntent } = this.props;
    const tx = this.state.pageData ? this.state.pageData.transaction : null;

    // We need to get up to date PI, if booking is created but payment is not expired.
    const shouldFetchPaymentIntent =
      this.stripe && !paymentIntent && tx && tx.id && !checkIsPaymentExpired(tx);
    if (shouldFetchPaymentIntent) {
      const { stripePaymentIntentClientSecret } =
        tx.attributes.protectedData && tx.attributes.protectedData.stripePaymentIntents
          ? tx.attributes.protectedData.stripePaymentIntents.default
          : {};
      const { depositTx } = tx.attributes.protectedData || {};

      // Fetch up to date PaymentIntent from Stripe
      onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret });
      if (depositTx) {
        this.onRetrievePaymentDepositIntent({ depositId: depositTx, stripe });
      }
    }
  }

  handleNavigation = nextLocation => {
    if (this.props.isInitiateUpdateBookingRequestSuccess) {
      return ;
    }
    // this.setState({ isOpenConfirmLeaveModal: true, nextLocation });
    // if (!this.state.isBlocking) return true;
    // return false;
    const { history } = this.props;
    this.setState(
      {
        isBlocking: false,
        isOpenConfirmLeaveModal: false,
      },
      () => {
        history && history.push(nextLocation);
      }
    );
  };

  onConfirmLeave = () => {
    const { nextLocation } = this.state;
    const { history } = this.props;
    this.setState(
      {
        isBlocking: false,
        isOpenConfirmLeaveModal: false,
      },
      () => {
        history && history.push(nextLocation);
      }
    );
  };

  handleChangeAddOn = (e, addon) => {
    if (e.currentTarget.id === 'addOn1')
      this.setState({ isDeliveryState: !this.state.isDeliveryState }, () => {
        // this.loadInitialData();
        if (this.state.isDeliveryState) {
          this.pushEventEnterFormGTM(EVENT_DELIVERY_ADDON_ADDED);
        } else {
          this.pushEventEnterFormGTM(EVENT_DELIVERY_ADDON_REMOVED);
        }
      });
    const isExcessReductionChecked = e.currentTarget.id === 'addOn2';
    const isExcessReduction2Checked = e.currentTarget.id === 'addOn3';
    const isMileagePackageIncludedChecked = e.currentTarget.id === 'addOn4';
    if (isExcessReductionChecked || isExcessReduction2Checked || isMileagePackageIncludedChecked) {
      const updatedState = {
        ...(isExcessReductionChecked && {
          isExcessReduction2State: false,
          isExcessReductionState: !this.state.isExcessReductionState,
        }),
        ...(isExcessReduction2Checked && {
          isExcessReductionState: false,
          isExcessReduction2State: !this.state.isExcessReduction2State,
        }),
        ...(isMileagePackageIncludedChecked && {
          isMileagePackageIncludedState: !this.state.isMileagePackageIncludedState,
        }),
      };
      this.setState(updatedState, () => {
        // this.loadInitialData();

        if (
          this.state.isExcessReductionState ||
          this.state.isExcessReduction2State ||
          this.state.isFuelInclusionState ||
          this.state.isMileagePackageIncludedState
        ) {
          if (this.state.isExcessReductionState) {
            particularVASParams = {
              ...particularVASParams,
              booking_vas_excess: true,
              booking_vas_excess_2: false,
            };
            this.pushEventEnterFormGTM(EVENT_EXCESS_ADDON_ADDED, particularVASParams);
            this.pushEventEnterFormGTM(EVENT_EXCESS_2_ADDON_REMOVED, particularVASParams);
          } else if (this.state.isExcessReduction2State) {
            particularVASParams = {
              ...particularVASParams,
              booking_vas_excess_2: true,
              booking_vas_excess: false,
            };
            this.pushEventEnterFormGTM(EVENT_EXCESS_2_ADDON_ADDED, particularVASParams);
            this.pushEventEnterFormGTM(EVENT_EXCESS_ADDON_REMOVED, particularVASParams);
          } else {

          }
        }
      });
    }
    // if (e.currentTarget.id === 'addOn3') {
    // this.setState({
    //       isExcessReduction2State: !this.state.isExcessReduction2State
    //     },
    //     () => {
    //       this.loadInitialData();
    //       if(this.state.isExcessReduction2State) {
    //         this.pushEventEnterFormGTM(EVENT_EXCESS_ADDON_ADDED);
    //       } else {
    //         this.pushEventEnterFormGTM(EVENT_EXCESS_ADDON_REMOVED);
    //       }
    //     }
    //   );
    // }
  };

  handleSelectedMileageAddOn = (mileage, flag) => {
    this.setState({ isMileagePackageIncludedState: flag, selectedMileage: mileage }, () => {
      // this.loadInitialData();

      if (this.state.isMileagePackageIncludedState) {
        // if (this.state.isExcessReductionState) {
        //   particularVASParams = {
        //     ...particularVASParams,
        //     booking_vas_excess: true,
        //     booking_vas_excess_2: false,
        //   };
        //   this.pushEventEnterFormGTM(EVENT_EXCESS_ADDON_ADDED, particularVASParams);
        //   this.pushEventEnterFormGTM(EVENT_EXCESS_2_ADDON_REMOVED, particularVASParams);
        // } else if (this.state.isExcessReduction2State) {
        //   particularVASParams = {
        //     ...particularVASParams,
        //     booking_vas_excess_2: true,
        //     booking_vas_excess: false,
        //   };
        //   this.pushEventEnterFormGTM(EVENT_EXCESS_2_ADDON_ADDED, particularVASParams);
        //   this.pushEventEnterFormGTM(EVENT_EXCESS_ADDON_REMOVED, particularVASParams);
        // } else {
        // }
      }
    });
  };

  getTotalPrice = totalAmount => {
    this.setState({
      totalAmount,
    });
  };

  render() {
    const {
      scrollingDisabled,
      speculateTransactionInProgress,
      speculateTransactionError,
      speculatedTransaction: speculatedTransactionMaybe,
      openInvoicesInProgress,
      openInvoicesError,
      openInvoices,
      initiateOrderError,
      confirmPaymentError,
      intl,
      params,
      currentUser,
      onManageDisableScrolling,
      handleCardPaymentError,
      paymentIntent,
      retrievePaymentIntentError,
      stripeCustomerFetched,
      location,
      bookingOverlapError,
      retrievePaymentDepositIntentError,
      paymentDepositIntent,
      history
    } = this.props;

    const {
      isBlocking = true,
      isOpenConfirmLeaveModal,
      isFuelInclusionState: isFuelInclusion,
    } = this.state;

    let { checkoutStep } = this.state;

    const isLongTermRental = get(
      speculatedTransactionMaybe,
      'attributes.protectedData.isLongTermRental'
    );
    // Since the listing data is already given from the ListingPage
    // and stored to handle refreshes, it might not have the possible
    // deleted or closed information in it. If the transaction
    // initiate or the speculative initiate fail due to the listing
    // being deleted or closec, we should dig the information from the
    // errors and not the listing data.
    const listingNotFound =
      isTransactionInitiateListingNotFoundError(speculateTransactionError) ||
      isTransactionInitiateListingNotFoundError(initiateOrderError);

    const voucherIsInvalid = isTransactionInitiateInvalidVoucher(initiateOrderError);
    const isLoading = !this.state.dataLoaded || speculateTransactionInProgress || openInvoicesInProgress;

    const { listing, bookingDates, transaction } = this.state.pageData;

    // Fetching price of per km from listing.
    const distanceKm = get(listing, "attributes.publicData.pricing.distanceKm","");

    // checking if the prices are as per distance charging. Putting it true for now
    const isDistanceBased = true;

    const isInstantBooking =
      listing && listing.attributes && listing.attributes.publicData.instantBooking;

    const existingTransaction = ensureTransaction(transaction);
    const speculatedTransaction = ensureTransaction(speculatedTransactionMaybe, {}, null);
    const currentTransaction = ensureTransaction(speculatedTransactionMaybe, {}, null);
    const currentListing = ensureListing(listing);
    const currentBooking = ensureBooking(currentTransaction.booking);
    const currentAuthor = ensureUser(currentListing.author);
    const timezone = currentTransaction
      ? (currentTransaction.attributes &&
          currentTransaction.attributes.protectedData &&
          currentTransaction.attributes.protectedData.transactionTimezone) ||
        'Australia/Sydney'
      : (currentListing.attributes &&
          currentListing.attributes.publicData &&
          currentListing.attributes.publicData.listingTimezone) ||
        'Australia/Sydney';

    let insuranceType = currentListing.attributes.publicData.insurance;
    const listingTitle = currentListing.attributes.title;
    const title = intl.formatMessage({ id: 'CheckoutPage.title' }, { listingTitle });
    const isPayForFuel = get(speculatedTransaction, 'attributes.protectedData.payForFuel');
    const isDelivery = currentListing.attributes.publicData.delivery;

    const currentUserLocation = get(
      currentUser,
      'attributes.profile.protectedData.location.selectedPlace.origin'
    );
    const listingLocation = get(listing, 'attributes.geolocation');
    const distance =
      currentUserLocation &&
      listingLocation &&
      distanceCountHelper(currentUserLocation, listingLocation);
    const startTransaction =
      currentTransaction && currentTransaction.attributes.protectedData.bookingDisplayStart;
    const endTransaction =
      currentTransaction && currentTransaction.attributes.protectedData.bookingDisplayEnd;
    const startHour = startTransaction && moment(startTransaction).format('HH');
    const endHour = endTransaction && moment(endTransaction).format('HH');
    const endMinutes = endTransaction && moment(endTransaction).format('mm');
    let isLessEndTime = false;

    if (endHour < LIMIT_END_HOUR) {
      isLessEndTime = true;
    }
    if (endHour == LIMIT_END_HOUR) {
      isLessEndTime = endMinutes && parseInt(endMinutes) == 0;
    }

    const showDelivery =
      isDelivery && distance <= LIMIT_DISTANCE && startHour >= LIMIT_START_HOUR && isLessEndTime;
    const pageProps = { title, scrollingDisabled, className: css.root };

    const topbar = (
      <div className={css.topbar}>
        <NamedLink className={css.home} name="LandingPage">
          <Logo
            className={css.logoMobile}
            title={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
            format="mobile"
          />
          <Logo
            className={css.logoDesktop}
            alt={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
            format="desktop"
          />
        </NamedLink>
      </div>
    );

    if (isLoading) {
      return (
        <Page {...pageProps}>
          {topbar}
          <div className={css.loading}>
            <IconSpinner />
            <div>Please wait...</div>
          </div>
        </Page>
      );
    }

    checkoutStep = openInvoices?.length > 0 ? checkoutStepConfiguration.CHECKOUT_OPEN_INVOICES : checkoutStep;
    let openInvoicesUI = '';

    if (checkoutStep === checkoutStepConfiguration.CHECKOUT_OPEN_INVOICES) {
      openInvoicesUI = (
        <UnpaidInvoices openInvoices={openInvoices} onRefreshInvoices={this.handleRefreshInvoices} />
      );
    }

    const isOwnListing =
      currentUser &&
      currentUser.id &&
      currentAuthor &&
      currentAuthor.id &&
      currentAuthor.id.uuid === currentUser.id.uuid;

    const authorDisplayName =  currentAuthor.attributes && currentAuthor.attributes.profile && currentAuthor.attributes.profile.displayName;

    const hasListingAndAuthor = !!(currentListing.id && currentAuthor.id);
    const hasBookingDates = !!(
      bookingDates &&
      bookingDates.bookingStart &&
      bookingDates.bookingEnd
    );
    const hasRequiredData = hasListingAndAuthor && hasBookingDates;
    const canShowPage = hasRequiredData && !isOwnListing;
    const shouldRedirect = !isLoading && !canShowPage;

    // Redirect back to ListingPage if data is missing.
    // Redirection must happen before any data format error is thrown (e.g. wrong currency)
    if (shouldRedirect) {
      // eslint-disable-next-line no-console
      console.error('Missing or invalid data for checkout, redirecting back to listing page.', {
        transaction: speculatedTransaction,
        bookingDates,
        listing,
      });
      return <NamedRedirect name="ListingPage" params={params} />;
    }

    if (this.state.shouldRedirectToSearchPage) {
      const searchParams = parse(location.search);
      const searchPageParams = searchParams && searchParams['searchPageParams'];
      const defaultSearchPageParams =
        '?address=Singapore&bounds=1.4708809%2C104.04157989999999%2C1.216611%2C103.60650989999999';
      return (
        <NamedRedirect name="SearchPage" search={searchPageParams || defaultSearchPageParams} />
      );
    }

    // Show breakdown only when speculated transaction and booking are loaded
    // (i.e. have an id)
    const tx = existingTransaction.booking ? existingTransaction : speculatedTransaction;
    const txBooking = ensureBooking(tx.booking);
    const { profile } = ensureCurrentUser(currentUser).attributes;
    const hasMastercardPromoBeenUsed =
      profile && profile.metadata && profile.metadata.hasMastercardPromoBeenUsed;
    const isNewCar = get(listing, 'attributes.metadata.isNewCar');
    const canUseMastercardPromo = isNewCar
      ? false
      : checkCanUseMastercardPromo(ensureCurrentUser(currentUser));
    const timeZone = getDefaultTimeZoneOnBrowser(timezone) || 'Australia/Sydney';
    const timezoneFromListing = listing && listing.attributes.publicData.listingTimezone || 'Australia/Sydney';

    const breakdownNormal = (
      <BookingBreakdownNew
        timeZone={timeZone}
        className={css.bookingBreakdown}
        isCheckoutPage={true}
        userRole="customer"
        currentUser={currentUser}
        unitType={config.bookingUnitType}
        transaction={restoreTransaction(tx, this.state.isUsingMastercard && canUseMastercardPromo)}
        booking={txBooking}
        shouldShowMastercardPromoLineItem={this.state.isUsingMastercard}
        getTotalPrice={this.getTotalPrice}
        checkoutStep={this.state.checkoutStep}
        changeCheckoutStep={step => this.setState({ checkoutStep: step })}
        replaceTotalText={location && location.state && location.state.updateBooking ? 'Balance due' : null}
        onManageDisableScrolling={onManageDisableScrolling}
        isTripExtension={location && location.state && location.state.isTripExtension ? location.state && location.state.isTripExtension : false}
        parentTransactionId={location && location.state && location.state.parentTransactionId ? location.state.parentTransactionId : null}
        isListingPriceIncreased={location && location.state && location.state.isListingPriceIncreased ? location.state.isListingPriceIncreased : null}
        history={history}
      />
    );

    const breakdownLongTerm = (
      <div className={css.remainBreakdownLongTerm}>
        <h3 className={css.priceBreakdownLongTermTitle}>
          <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
        </h3>
        <CheckoutBreakdownLongTerm
          timeZone={timeZone}
          className={css.bookingBreakdown}
          userRole="customer"
          unitType={config.bookingUnitType}
          transaction={restoreTransaction(
            tx,
            this.state.isUsingMastercard && canUseMastercardPromo
          )}
          booking={txBooking}
          shouldShowMastercardPromoLineItem={this.state.isUsingMastercard}
          hideBookingPeriod={true}
          hidePaymentMade
        />
      </div>
    );

    const breakdown = tx.id && (isLongTermRental ? breakdownLongTerm : breakdownNormal);

    const isPaymentExpired = checkIsPaymentExpired(existingTransaction);
    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
      ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id
    );

    // Allow showing page when currentUser is still being downloaded,
    // but show payment form only when user info is loaded.
    const showPaymentForm = !!(
      currentUser &&
      hasRequiredData &&
      !listingNotFound &&
      // !initiateOrderError &&
      !speculateTransactionError &&
      !retrievePaymentIntentError &&
      !isPaymentExpired &&
      !bookingOverlapError &&
      !retrievePaymentDepositIntentError
    );

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const listingLink = (
      <NamedLink
        name="ListingPage"
        params={{ id: currentListing.id.uuid, slug: createSlug(listingTitle) }}
      >
        <FormattedMessage id="CheckoutPage.errorlistingLinkText" />
      </NamedLink>
    );

    const isAmountTooLowError = isTransactionInitiateAmountTooLowError(initiateOrderError);
    const isChargeDisabledError = isTransactionChargeDisabledError(initiateOrderError);
    const isBookingTimeNotAvailableError =
      isTransactionInitiateBookingTimeNotAvailableError(initiateOrderError) || bookingOverlapError;
    const stripeErrors = transactionInitiateOrderStripeErrors(initiateOrderError);

    let initiateOrderErrorMessage = null;
    let showErrorAlert = null;
    let retryButton = null;
    let listingNotFoundErrorMessage = null;

    const redirectToListingButton = (
      <Button
        className={css.retryButton}
        inProgress={this.state.isRetryButtonClicked}
        onClick={() => {
          if (typeof window !== 'undefined') {
            const checkoutStrPos = window.location.href.indexOf('/checkout');
            const currentListingUrl = window.location.href.substr(0, checkoutStrPos);
            this.setState({ isRetryButtonClicked: true });
            window.location.href = currentListingUrl;
          }
        }}
      >
        <FormattedMessage id={'CheckoutPage.retryButton'} />
      </Button>
    );

    const reloadButton = (
      <Button
        className={css.retryButton}
        inProgress={this.state.isRetryButtonClicked}
        onClick={() => {
          if (typeof window !== 'undefined') {
            this.setState({ isRetryButtonClicked: true });
            window.location.reload();
          }
        }}
      >
        <FormattedMessage id={'CheckoutPage.retryButton'} />
      </Button>
    );

    const redirectToSearchPageButton = (
      <Button
        className={css.retryButton}
        onClick={() => {
          if (typeof window !== 'undefined') {
            this.setState({ shouldRedirectToSearchPage: true });
          }
        }}
      >
        <FormattedMessage id={'CheckoutPage.retryButton'} />
      </Button>
    );

    // if(this.state.isAlert){
    //   const voucherInvalidMessage = <FormattedMessage id="CheckoutPage.invalidVoucher" />
    //
    //   showErrorAlert = (
    //     <AlertBox title="Voucher is invalid" message={voucherInvalidMessage} type="error" button={redirectToSearchPageButton} />
    //   )
    // }

    if (voucherIsInvalid) {
      const voucherInvalidMessage = <FormattedMessage id="CheckoutPage.invalidVoucher" />;

      showErrorAlert = (
        <AlertBox title="Voucher is invalid" message={voucherInvalidMessage} type="error" />
      );
    } else if (listingNotFound) {
      const listingNotFoundErrorMessage = (
        <FormattedMessage id="CheckoutPage.listingNotFoundError" />
      );
      showErrorAlert = (
        <AlertBox
          title="Voucher is invalid"
          message={listingNotFoundErrorMessage}
          type="error"
          button={redirectToSearchPageButton}
        />
      );
    } else if (isAmountTooLowError) {
      const initiateOrderAmountTooLow = (
        <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
      );
      showErrorAlert = (
        <AlertBox
          title="Order amount is too low"
          message={initiateOrderAmountTooLow}
          type="error"
          button={redirectToListingButton}
        />
      );
    } else if (isBookingTimeNotAvailableError) {
      const bookingTimeNotAvailableMessage = (
        <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
      );
      showErrorAlert = (
        <AlertBox
          title="Booking time not available"
          message={bookingTimeNotAvailableMessage}
          type="error"
          button={redirectToListingButton}
        />
      );
    } else if (isChargeDisabledError) {
      const chargeDisabledMessage = <FormattedMessage id="CheckoutPage.chargeDisabledMessage" />;
      showErrorAlert = (
        <AlertBox
          title="Charge disabled"
          message={chargeDisabledMessage}
          type="error"
          button={redirectToListingButton}
        />
      );
    } else if (stripeErrors && stripeErrors.length > 0) {
      // NOTE: Error messages from Stripes are not part of translations.
      // By default they are in English.
      const stripeErrorsAsString = stripeErrors.join(', ');
      const initiateOrderStripeError = (
        <FormattedMessage
          id="CheckoutPage.initiateOrderStripeError"
          values={{ stripeErrors: stripeErrorsAsString }}
        />
      );
      showErrorAlert = (
        <AlertBox
          title="Failed to confirm your payment!"
          message="Please try again with another credit/debit card."
          type="error"
        />
      );
    } else if (initiateOrderError) {
      // Generic initiate order error
      if (initiateOrderError.name === 'insufficient_funds') {
        const initiateOrderErrorInsufficientFunds = (
          <FormattedMessage
            id="CheckoutPage.initiateOrderErrorInsufficientFunds"
            values={{ listingLink }}
          />
        );
        showErrorAlert = (
          <AlertBox
            title="Infufficient funds"
            message={initiateOrderErrorInsufficientFunds}
            type="error"
            button={reloadButton}
          />
        );
      } else if (initiateOrderError.status === 'card_error') {
        const initiateOrderErrorCardError = (
          <FormattedMessage
            id="CheckoutPage.initiateOrderErrorCardError"
            values={{ listingLink, message: initiateOrderError.message }}
          />
        );
        showErrorAlert = (
          <AlertBox
            title="Card error"
            message={initiateOrderErrorCardError}
            type="error"
            button={reloadButton}
          />
        );
      } else if (initiateOrderError.status === 'initiate_faliure') {
          showErrorAlert = (
            <AlertBox
              title="Failed"
              message='Something went wrong!! Failed to create transaction, please try again later'
              type="error"
              button={reloadButton}
              autoCloseTime={6000}
            />
          );
      } else if (initiateOrderError.message) {
        if (initiateOrderError.status === 411) {
          const initiateOrderOverlapMessage = (
            <FormattedMessage id="CheckoutPage.initiateOrderOverlapMessage" />
          );
          showErrorAlert = (
            <AlertBox
              title="Order error"
              message={initiateOrderOverlapMessage}
              type="error"
              button={redirectToListingButton}
            />
          );
        } else {
          const initiateOrderErrorWithMessage = (
            <FormattedMessage
              id="CheckoutPage.initiateOrderErrorWithMessage"
              values={{ listingLink, message: initiateOrderError.message }}
            />
          );
          showErrorAlert = (
            <AlertBox
              title="Order error"
              message={initiateOrderErrorWithMessage}
              type="error"
              button={redirectToListingButton}
            />
          );
        }
        retryButton = redirectToListingButton;
      } else {
        const initiateOrderErrorMessages = (
          <FormattedMessage id="CheckoutPage.initiateOrderError" values={{ listingLink }} />
        );
        showErrorAlert = (
          <AlertBox
            title="Order error"
            message={initiateOrderErrorMessages}
            type="error"
            button={reloadButton}
          />
        );
      }
    }
    const speculateTransactionErrorMessage = speculateTransactionError ? (
      <p className={css.speculateError}>
        <FormattedMessage id="CheckoutPage.speculateTransactionError" />
      </p>
    ) : null;
    let speculateErrorMessage = null;

    if (isTransactionInitiateMissingStripeAccountError(speculateTransactionError)) {
      const providerStripeAccountMissingError = (
        <FormattedMessage id="CheckoutPage.providerStripeAccountMissingError" />
      );
      showErrorAlert = (
        <AlertBox
          title="Stripe account missing"
          message={providerStripeAccountMissingError}
          type="error"
          button={redirectToSearchPageButton}
        />
      );
    } else if (isTransactionInitiateBookingTimeNotAvailableError(speculateTransactionError)) {
      const bookingTimeNotAvailableMessage = (
        <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
      );
      showErrorAlert = (
        <AlertBox
          title="Booking time not available"
          message={bookingTimeNotAvailableMessage}
          type="error"
          button={redirectToListingButton}
        />
      );
    } else if (isTransactionZeroPaymentError(speculateTransactionError)) {
      const initiateOrderAmountTooLow = (
        <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
      );
      showErrorAlert = (
        <AlertBox
          title="Order amount too low"
          message={initiateOrderAmountTooLow}
          type="error"
          button={redirectToListingButton}
        />
      );
    } else if (speculateTransactionError) {
      const speculateFailedMessage = <FormattedMessage id="CheckoutPage.speculateFailedMessage" />;
      showErrorAlert = (
        <AlertBox
          title="Failed to confirm your payment! "
          message="Please try again with another credit/debit card."
          type="error"
        />
      );
    }

    if (voucherIsInvalid) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.invalidVoucher" />
        </p>
      );
      retryButton = redirectToListingButton;
    } else if (listingNotFound) {
      listingNotFoundErrorMessage = (
        <p className={css.notFoundError}>
          <FormattedMessage id="CheckoutPage.listingNotFoundError" />
        </p>
      );
      retryButton = redirectToSearchPageButton;
    } else if (isAmountTooLowError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
      retryButton = redirectToListingButton;
    } else if (isBookingTimeNotAvailableError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
      retryButton = redirectToListingButton;
    } else if (isChargeDisabledError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.chargeDisabledMessage" />
        </p>
      );
      retryButton = redirectToListingButton;
    } else if (stripeErrors && stripeErrors.length > 0) {
      // NOTE: Error messages from Stripes are not part of translations.
      // By default they are in English.
      const stripeErrorsAsString = stripeErrors.join(', ');
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage
            id="CheckoutPage.initiateOrderStripeError"
            values={{ stripeErrors: stripeErrorsAsString }}
          />
        </p>
      );
      retryButton = reloadButton;
    } else if (initiateOrderError) {
      // Generic initiate order error

      if (initiateOrderError.name === 'insufficient_funds') {
        initiateOrderErrorMessage = (
          <p className={css.orderError}>
            <FormattedMessage
              id="CheckoutPage.initiateOrderErrorInsufficientFunds"
              values={{ listingLink }}
            />
          </p>
        );
        retryButton = reloadButton;
      } else if (initiateOrderError.status === 'card_error') {
        initiateOrderErrorMessage = (
          <p className={css.orderError}>
            <FormattedMessage
              id="CheckoutPage.initiateOrderErrorCardError"
              values={{ listingLink, message: initiateOrderError.message }}
            />
          </p>
        );
        retryButton = reloadButton;
      } else if (initiateOrderError.message) {
        if (initiateOrderError.status === 411) {
          initiateOrderErrorMessage = (
            <p className={css.orderError}>
              <FormattedMessage id="CheckoutPage.initiateOrderOverlapMessage" />
            </p>
          );
        } else {
          initiateOrderErrorMessage = (
            <p className={css.orderError}>
              <FormattedMessage
                id="CheckoutPage.initiateOrderErrorWithMessage"
                values={{ listingLink, message: initiateOrderError.message }}
              />
            </p>
          );
        }
        retryButton = redirectToListingButton;
      } else {
        initiateOrderErrorMessage = (
          <p className={css.orderError}>
            <FormattedMessage id="CheckoutPage.initiateOrderError" values={{ listingLink }} />
          </p>
        );
        retryButton = reloadButton;
      }
    }

    // const speculateTransactionErrorMessage = speculateTransactionError ? (
    //   <p className={css.speculateError}>
    //     <FormattedMessage id="CheckoutPage.speculateTransactionError" />
    //   </p>
    // ) : null;
    // let speculateErrorMessage = null;

    if (isTransactionInitiateMissingStripeAccountError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.providerStripeAccountMissingError" />
        </p>
      );
      retryButton = redirectToSearchPageButton;
    } else if (isTransactionInitiateBookingTimeNotAvailableError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
      retryButton = redirectToListingButton;
    } else if (isTransactionZeroPaymentError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
      retryButton = redirectToListingButton;
    } else if (speculateTransactionError) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.speculateFailedMessage" />
        </p>
      );
      retryButton = reloadButton;
    }

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;
    const transactionData = restoreTransaction(
      tx,
      this.state.isUsingMastercard && canUseMastercardPromo
    );

    const unitTranslationKey = isLongTermRental
      ? 'ListingPage.perMonth'
      : isDaily
      ? 'CheckoutPage.perDay'
      : 'CheckoutPage.perUnit';

    const longTermPrice = calculateLongTermPriceWithFeeOneMonth(speculatedTransactionMaybe);
    const price = isLongTermRental ? longTermPrice : currentListing.attributes.price;
    const formattedPrice = formatMoney(intl, price, 2);
    const detailsSubTitle = `${formattedPrice} ${intl.formatMessage({ id: unitTranslationKey })}`;

    const showInitialMessageInput =
      existingTransaction && existingTransaction.attributes.lastTransition === TRANSITION_ENQUIRE;

    // Get first and last name of the current user and use it in the StripePaymentForm to autofill the name field
    const userName = null;
      // currentUser && currentUser.attributes
      //   ? `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`
      //   : null;

    // If paymentIntent status is not waiting user action,
    // handleCardPayment has been called previously.
    const hasPaymentIntentUserActionsDone =
      (paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status)) ||
      (paymentDepositIntent &&
        STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentDepositIntent.status));

    // If your marketplace works mostly in one country you can use initial values to select country automatically
    // e.g. {country: 'FI'}

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const protectedData = ensuredCurrentUser.attributes.profile.protectedData || {};
    const { blockNo, building, floorUnit, city, country, postalCode } = protectedData;
    const { selectedPlace } = protectedData.location || {};
    const { address, origin } = selectedPlace || {};

    const initalValuesForStripePayment = {
      name: userName,
      blockNo,
      addressLine1: blockNo + ' ' + address,
      addressLine2: floorUnit,
      location: {
        search: address,
        selectedPlace: {
          address,
          origin: new LatLng(origin && origin.lat, origin && origin.lng),
        },
      },
      building,
      floorUnit,
      city,
      country,
      postalCode,
      postal: postalCode,
    };

    const leavingPopup = (
      <div className={css.confirmPopup}>
        <div className={css.message}>
          {' '}
          <FormattedMessage
            id="CheckoutPage.confirmLeaveMessage"
            values={{ firstName: profile && profile.firstName }}
          />{' '}
        </div>
        <div className={css.buttonWrapper}>
          <Button
            className={css.buttonConfirmLeave}
            onClick={() => this.setState({ isOpenConfirmLeaveModal: false })}
          >
            <FormattedMessage id="CheckoutPage.confirmStay" />
          </Button>
          <Button className={css.buttonConfirmLeave} onClick={this.onConfirmLeave}>
            <FormattedMessage id="CheckoutPage.confirmLeave" />
          </Button>
        </div>
      </div>
    );

    const addOnsContent = [
      {
        id: 2,
        isUnit: '/day',
        isBanner: true,
        bannerText: 'Popular - 78% of our users opt for protection plan',
        bannerMobileText: 'Popular',
        bannerHighlightType: '',
        addOnName: ADDON_NAME__PREMIUM_PROTECTION,
        addonUniqueName: 'PREMIUM_PROTECTION',
        addOnIcon: premiumProtectionIcon,
        description: 'At just $10/day, reduce your potential liability by half',
        brief: 'For just $10/day, save up to $4280 on your liability',
        isAdded: false,
        addOnAmount: 10,
        isExpanded: false,
      },
      {
        id: 3,
        isUnit: '/day',
        isBanner: false,
        bannerText: '68% of users opt for this',
        bannerMobileText: '68% of users opt for this',
        bannerHighlightType: 'Popular',
        addOnName: ADDON_NAME__MAXIMUM_PROTECTION,
        addonUniqueName: 'MAXIMUM_PROTECTION',
        addOnIcon: premiumProtectionIcon,
        description: 'Maximise your protection & reduce liability to only $500',
        brief: 'For just $18/day reduce your liability to ZERO and get complete peace of mind.',
        isAdded: false,
        addOnAmount: 18,
        isExpanded: false,
      },
      {
        id: 4,
        isUnit: '/km',
        isBanner: false,
        bannerText: 'Popular',
        bannerMobileText: 'Popular',
        isTooltip: true,
        addOnName: ADDON_NAME__MILEAGE_PACKAGE,
        addonUniqueName: 'MILEAGE_PACKAGE',
        addOnIcon: mileageIcon,
        description:
          'Include more kms for $0.29 per km (50% discount). If you exceed the included mileage, then you will be charged $0.60 per additional km driven.',
        brief: 'For just $18/day reduce your liability to ZERO and get complete peace of mind.',
        isAdded: false,
        addOnAmount: 0.29,
        isExpanded: false,
      },
      {
        id: 1,
        isUnit: '',
        isBanner: false,
        bannerText: '',
        bannerMobileText: '',
        isTooltip: true,
        addOnName: 'Home Delivery',
        addonUniqueName: 'HOME_DELIVERY',
        addOnIcon: deliveryIcon,
        description: 'Get the car delivered right to your doorstop.',
        brief: 'Get the car delivered right to your home.',
        isAdded: false,
        addOnAmount: 20,
        isExpanded: false,
      },
    ];

    // Data needed for addon slider
    let startDateTimeMaybe =
      transactionData &&
      transactionData.attributes &&
      transactionData.attributes.protectedData &&
      transactionData.attributes.protectedData.updateBookingStartTimestamp;
    let endDateTimeMaybe =
      transactionData &&
      transactionData.attributes &&
      transactionData.attributes.protectedData &&
      transactionData.attributes.protectedData.bookingDisplayEnd;

   const startTimeForCalculatingCancellation = transactionData?.booking?.attributes?.displayStart;

    const totalTripHours = moment(endDateTimeMaybe).diff(moment(startDateTimeMaybe), 'hours', true);
    const hoursToTripStart = moment(startTimeForCalculatingCancellation).diff(moment(), 'hours', true);
    const futureHourCancelTime = moment().add(1, 'hours').tz(timezone).format('Do MMM h:mm a');
    const cancelTime =  hoursToTripStart > 72 ? moment(startTimeForCalculatingCancellation).subtract(72, 'hours').tz(timezone).format('Do MMM h:mm a'): futureHourCancelTime;
    const cancelTimeDiff = moment(startDateTimeMaybe).subtract(72, 'hours').diff(moment()) > 0;
    const showCancelTime = moment(startDateTimeMaybe).diff(moment()) >= 0 ? true : false;
    const bookingDays = calculateBookingDays(startDateTimeMaybe, endDateTimeMaybe);
    const sliderOptions = sliderOptionsList(totalTripHours);
    const customerSelectedMileageValue = this.state.selectedMileage
      ? sliderOptions.defaultValue >= this.state.selectedMileage
        ? sliderOptions.defaultValue
        : this.state.selectedMileage
      : sliderOptions.defaultValue;
    const sliderDefaultValue = getMileageValuefromSliderMileageValue(
      customerSelectedMileageValue,
      totalTripHours
    );

    const isUpdateBooking = get(location, 'state.updateBooking', false);
    const isTripExtension = get(location, 'state.isTripExtension', false);

    const checkoutTitle = () => {
      if (checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS && !location?.state?.updateBooking) return 'Customize your trip';

      if (checkoutStep === checkoutStepConfiguration.CHECKOUT_PAYMENT && location?.state?.updateBooking) return location?.state?.isTripExtension ? 'Extend Trip' : 'Modify Trip';

      return 'Pay & complete booking';
      }

    return (
      <>
        <Prompt when={isBlocking} message={this.handleNavigation} />
        {/* <Modal
          isOpen={isOpenConfirmLeaveModal}
          onClose={() => {
            this.setState({ isOpenConfirmLeaveModal: false });
          }}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {leavingPopup}
        </Modal> */}
        <Page {...pageProps}>
          {topbar}
          {showErrorAlert}
          {openInvoicesUI ? openInvoicesUI : (
          <Experiment name="mileageExperiment">
            <Variant name="A">
              <div className={css.contentContainer}>
                <div className={css.contentRow}>
                  <div className={css.checkoutPageTitles}>

                    <div className={css.stepTitle}>
                      {checkoutTitle()}
                    </div>

                  </div>
                </div>

                <div className={css.contentRow}>
                  <div className={css.aspectWrapper}>
                    <ResponsiveImage
                      rootClassName={css.rootForImage}
                      alt={listingTitle}
                      image={firstImage}
                      variants={['landscape-crop', 'landscape-crop2x']}
                    />
                  </div>
                  <div className={classNames(css.avatarWrapper, css.avatarMobile)}>
                    <AvatarMedium user={currentAuthor} disableProfileLink />
                  </div>
                  <div className={css.checkoutSectionLeft}>
                    {checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS &&
                    !(location && location.state && location.state.updateBooking) ? (
                      <div className={css.bookListingContainer}>
                        <AddOnsSection
                          totalTripHours={totalTripHours}
                          customerSelectedMileage={customerSelectedMileageValue}
                          bookingDays={bookingDays ? bookingDays : 1}
                          sliderOptions={sliderOptions}
                          bookingDates={bookingDates}
                          sliderDefaultValue={sliderDefaultValue.slideValue}
                          currentUser={currentUser}
                          mapAbSegment={mapAbSegment}
                          addOnsContent={addOnsContent.filter(a => !a.unavailable)}
                          transaction={transactionData}
                          isProvider={false}
                          intl={intl}
                          diffHours={this.props.diffHours}
                          loadInitialData={this.loadInitialData}
                          listing={currentListing}
                          updateCheckoutSteps={stepNumber =>
                            this.checkoutPageManagingStep(stepNumber)
                          }
                          isDelivery={showDelivery}
                          isDeliveryState={this.state.isDeliveryState}
                          isExcessReductionState={this.state.isExcessReductionState}
                          isExcessReduction2State={this.state.isExcessReduction2State}
                          isMileagePackageIncludedState={this.state.isMileagePackageIncludedState}
                          isMileageIncludedState={this}
                          isFuelInclusionState={this.state.isFuelInclusionState}
                          handleChangeAddOn={this.handleChangeAddOn}
                          handleSelectedMileageAddOn={this.handleSelectedMileageAddOn}
                          selectedMileage={this.state.selectedMileage}
                          isCheckoutPage={true}
                          isFuelSelectedManually={this.state.isFuelSelectedManually}

                        />
                      </div>
                    ) : (
                      <div className={css.bookListingContainer}>
                        {/* TODO detailsContainerLeft */}
                        {/*  image is reomved from here */}



                         <section className={css.paymentContainer}>
                          {retrievePaymentIntentError || retrievePaymentDepositIntentError ? (
                            <p className={css.orderError}>
                              <FormattedMessage
                                id="CheckoutPage.retrievingStripePaymentIntentFailed"
                                values={{ listingLink }}
                              />
                            </p>
                          ) : null}
                          {/* {retryButton} */}
                          {showPaymentForm ? (
                            <StripePaymentForm
                              className={css.paymentForm}
                              onSubmit={this.handleSubmit}
                              inProgress={this.state.submitting}
                              formId="CheckoutPagePaymentForm"
                              paymentInfo={intl.formatMessage({ id: 'CheckoutPage.paymentInfo' })}
                              authorDisplayName={currentAuthor.attributes.profile.displayName}
                              showInitialMessageInput={showInitialMessageInput}
                              initialValues={initalValuesForStripePayment}
                              initiateOrderError={initiateOrderError}
                              handleCardPaymentError={handleCardPaymentError}
                              confirmPaymentError={confirmPaymentError}
                              currentUser={currentUser}
                              transaction={transactionData}
                              isProvider={false}
                              intl={intl}
                              hasHandledCardPayment={hasPaymentIntentUserActionsDone}
                              loadingData={!stripeCustomerFetched}
                              defaultPaymentMethod={
                                hasDefaultPaymentMethod
                                  ? currentUser.stripeCustomer.defaultPaymentMethod
                                  : null
                              }
                              isUsingMastercard={this.state.isUsingMastercard}
                              setIsUsingMastercard={val => {
                                this.setState({ isUsingMastercard: val });
                              }}
                              paymentIntent={paymentIntent}
                              onStripeInitialized={this.onStripeInitialized}
                              isInstantBooking={isInstantBooking}
                              submitButtonId={SEND_REQUEST_BOOKING_BUTTON_ID}
                              pushEventEnterFormGTM={this.pushEventEnterFormGTM}
                              isLongTermRental={isLongTermRental}
                              isPayForFuel={isPayForFuel}
                              isFuelInclusion={isFuelInclusion}
                              isDelivery={showDelivery}
                              isTotalPriceVisible={true}
                              isDeliveryState={this.state.isDeliveryState}
                              onChangeDelivery={this.onDeliveryChange}
                              isUpdateBooking={isUpdateBooking}
                              isTripExtension={isTripExtension}
                              openInvoicesTotal={openInvoices?.totalUnpaidDues > 0 ? new Money(Math.round(openInvoices.totalUnpaidDues * 100), config.currency) : null}
                            />
                          ) : null}
                          {isPaymentExpired ? (
                            <p className={css.orderError}>
                              <FormattedMessage
                                id="CheckoutPage.paymentExpiredMessage"
                                values={{ listingLink }}
                              />
                            </p>
                          ) : null}
                        </section>
                        <div className={classNames(css.detailsContainerLeft,css.hideElementDesktop)}>
                            <div className={css.detailsLeftAspectWrapper}>
                              <ResponsiveImage
                                rootClassName={css.rootForImage}
                                alt={listingTitle}
                                image={firstImage}
                                variants={['landscape-crop', 'landscape-crop2x']}
                              />
                            </div>
                            <div className={css.avatarWrapperLeft}>
                              <AvatarMedium user={currentAuthor} disableProfileLink />
                            </div>
                          <div className={css.detailsHeadings}>
                            <h2 className={css.detailsTitle}>{listingTitle}</h2>
                            {/* <p className={css.detailsSubtitle}>{detailsSubTitle}</p> */}
                            <LineItemBookingPeriodNew
                              booking={transactionData.booking}
                              unitType={unitType}
                              timezone={timezoneFromListing}
                              transaction={tx}
                            />
                          </div>

                        </div>

                        <div className={css.priceBreakdownContainer}>
                          <h3>Trip price breakdown</h3>
                          {/* {speculateTransactionErrorMessage} */}
                          {breakdown}
                          {openInvoices?.totalUnpaidDues > 0 && (
                          <div className={css.unpaidDuesContainer}>
                            <div className={css.unpaidDuesHeader}>
                              <span>Unpaid dues</span>
                              <span>
                                ${(openInvoices?.totalUnpaidDues).toFixed(2)}
                              </span>
                            </div>
                            <div className={css.unpaidDuesInfo}>
                              <span>
                                You will be charged ${(openInvoices?.totalUnpaidDues).toFixed(2)} for your unpaid dues.
                                <button
                                  className={css.learnMoreBtn}
                                  onClick={() => this.setState({ showUnpaidDuesModal: true })}
                                >
                                  Learn more
                                </button>
                              </span>
                            </div>

                            <Modal
                              isOpen={this.state.showUnpaidDuesModal}
                              onClose={() => this.setState({ showUnpaidDuesModal: false })}
                              onManageDisableScrolling={onManageDisableScrolling}
                            >
                              <UnpaidInvoices
                                openInvoices={openInvoices?.invoices}
                                onRefreshInvoices={this.handleRefreshInvoices}
                              />
                            </Modal>
                          </div>
                        )}
                        </div>
                        {<div className={classNames(css.cancelContainer,css.hideElementDesktop)}>
                            <div className={css.cancelText}>
                                <img src={thumbIcon} alt="Addon preview" />
                                <p>
                                  {isInstantBooking ? ` You can cancel the trip anytime. 100% refund if the cancellation is
                                  done before ${cancelTime}`: `You're in control: You won't be charged until ${authorDisplayName} accepts your booking. Withdraw hassle-free before that with no deductions.`}
                                </p>
                            </div>
                        </div>}


                        {(checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS &&
                          !(location && location.state && location.state.updateBooking)) ||
                        hoursToTripStart < 72 ? (
                          ''
                        ) : location && location.state && location.state.updateBooking ? (
                          ''
                        ) : (
                          <div className={css.cancellationDepositContainer}>
                            {/* {speculateTransactionErrorMessage} */}
                            <h3>Free cancellation</h3>
                            <p>
                              {isInstantBooking ? ` You can cancel the trip anytime. 100% refund if the cancellation is
                              done before ${cancelTime}`: `You're in control: You won't be charged until ${authorDisplayName} accepts your booking. Withdraw hassle-free before that with no deductions.`}
                            </p>
                          </div>
                        )}

                        {/* TODO TripAddonContainer */}
                        {location && location.state && location.state.updateBooking ? (
                          ''
                        ) : (
                          <div className={css.detailsAddonContainerMain}>
                            <h3>Trip customization</h3>
                            <div class={css.addonBreakdown}>
                              {mapAbSegment && mapAbSegment === 'B' ? null : (
                                <div class={css.addonLineItem}>
                                  <span class={css.addonLineItemLabel}>Total mileage</span>
                                  <span class={css.addonLineItemValue}>
                                    {this.state.selectedMileage < sliderOptions.defaultValue
                                      ? `${sliderOptions.defaultValue} x ${bookingDays}`
                                      : this.state.selectedMileage === sliderOptions.max
                                      ? 'Unlimited'
                                      : `${this.state.selectedMileage} x ${bookingDays}`}{' '}
                                    km{' '}
                                    {this.state.selectedMileage < sliderOptions.max ? (
                                      <span
                                        class={css.editSingleAddon}
                                        onClick={() =>
                                          this.setState({
                                            checkoutStep:
                                              checkoutStepConfiguration.CHECKOUT_ADD_ONS,
                                          })
                                        }
                                      >
                                        {' '}
                                        Add more
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                  </span>
                                </div>
                              )}
                              <div class={css.addonLineItem}>
                                <span class={css.addonLineItemLabel}>Protection plan</span>
                                <span class={css.addonLineItemValue}>
                                  {this.state.isExcessReduction2State
                                    ? 'Maximum '
                                    : this.state.isExcessReductionState
                                    ? 'Premium '
                                    : 'Basic '}
                                  {!(
                                    this.state.isExcessReductionState ||
                                    this.state.isExcessReduction2State
                                  ) ? (
                                    <span
                                      onClick={() =>
                                        this.setState({
                                          checkoutStep: checkoutStepConfiguration.CHECKOUT_ADD_ONS,
                                        })
                                      }
                                      class={css.editSingleAddon}
                                    >
                                      Upgrade now{' '}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </div>
                            </div>
                            {/* {breakdown} */}
                          </div>
                        )}
                      </div>
                    )}

                    {/* <div className={css.heading}>
                    <h1 className={css.title}>{title}</h1>
                    <div className={css.author}>
                      <FormattedMessage
                        id="CheckoutPage.hostedBy"
                        values={{ name: currentAuthor.attributes.profile.displayName }}
                      />
                    </div>
                  </div> */}
                  </div>

                  <div className={css.checkoutSectionRight}>
                    <div className={css.detailsContainerDesktop}>
                      {checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS &&
                      !(location && location.state && location.state.updateBooking) ? (
                        <div className={css.detailsContainerTop}>
                          <div className={css.detailsAspectWrapper}>
                            <ResponsiveImage
                              rootClassName={css.rootForImage}
                              alt={listingTitle}
                              image={firstImage}
                              variants={['landscape-crop', 'landscape-crop2x']}
                            />
                          </div>
                          <div className={css.avatarWrapper}>
                            <AvatarMedium user={currentAuthor} disableProfileLink />
                          </div>
                          <div className={css.detailsHeadings}>
                            <h2 className={css.detailsTitle}>{listingTitle}</h2>
                            {/* <p className={css.detailsSubtitle}>{detailsSubTitle}</p> */}
                            <LineItemBookingPeriodNew
                              booking={transactionData.booking}
                              unitType={unitType}
                              timezone={timezoneFromListing}
                              transaction={tx}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className={css.detailsContainerTop}>
                            <div className={css.detailsAspectWrapper}>
                              <ResponsiveImage
                                rootClassName={css.rootForImage}
                                alt={listingTitle}
                                image={firstImage}
                                variants={['landscape-crop', 'landscape-crop2x']}
                              />
                            </div>

                            <div className={css.avatarWrapper}>
                              <AvatarMedium user={currentAuthor} disableProfileLink />
                            </div>
                          <div className={css.detailsHeadings}>

                            <h2 className={css.detailsTitle}>{listingTitle}</h2>
                            {/* <p className={css.detailsSubtitle}>{detailsSubTitle}</p> */}
                            <LineItemBookingPeriodNew
                              booking={transactionData.booking}
                              unitType={unitType}
                              timezone={timezoneFromListing}
                              transaction={tx}
                            />
                          </div>
                         </div>

                          <div className={css.detailsContainerMain}>
                            <h3>Trip price breakdown</h3>
                            {breakdown}
                            {openInvoices?.totalUnpaidDues > 0 && (
                            <div className={css.unpaidDuesContainer}>
                              <div className={css.unpaidDuesHeader}>
                                <span>Unpaid dues</span>
                                <span>
                                  ${(openInvoices?.totalUnpaidDues).toFixed(2)}
                                </span>
                              </div>
                              <div className={css.unpaidDuesInfo}>
                                <span>
                                  You will be charged ${(openInvoices?.totalUnpaidDues).toFixed(2)} for your unpaid dues.
                                  <button
                                    className={css.learnMoreBtn}
                                    onClick={() => this.setState({ showUnpaidDuesModal: true })}
                                  >
                                    Learn more
                                  </button>
                                </span>
                              </div>

                              <Modal
                                isOpen={this.state.showUnpaidDuesModal}
                                onClose={() => this.setState({ showUnpaidDuesModal: false })}
                                onManageDisableScrolling={onManageDisableScrolling}
                              >
                                <UnpaidInvoices
                                  openInvoices={openInvoices?.invoices}
                                  onRefreshInvoices={this.handleRefreshInvoices}
                                />
                              </Modal>
                            </div>
)}
                          </div>
                          {<div className={css.cancelContainer}>
                            <div className={css.cancelText}>
                                <img src={thumbIcon} alt="Addon preview" />
                                <p>
                                  {isInstantBooking ? ` You can cancel the trip anytime. 100% refund if the cancellation is
                                  done before ${cancelTime}`: `You're in control: You won't be charged until ${authorDisplayName} accepts your booking. Withdraw hassle-free before that with no deductions.`}
                                </p>
                            </div>
                          </div>}
                        </>
                      )}
                      {speculateTransactionErrorMessage}
                      {/* <InsurancePanel
                    listing={currentListing}
                    className={css.insuranceWrapper}
                    hasBookingData={!!currentTransaction.id && !!currentBooking.id}
                    insuranceType={insuranceType}
                    onManageDisableScrolling={onManageDisableScrolling}
                  /> */}
                    </div>
                    {(checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS &&
                      !(location && location.state && location.state.updateBooking))
                    ? (
                      ''
                    ) : location && location.state && location.state.updateBooking ? (
                      ''
                    ) : (
                      <div className={css.detailsContainerDesktopCancellation}>
                        {location && location.state && location.state.updateBooking ? (
                          ''
                        ) : (
                          <div className={css.detailsContainerMain}>
                            <h3>Free cancellation</h3>
                            <p>
                              {isInstantBooking ? ` You can cancel the trip anytime. 100% refund if the cancellation is
                              done before ${cancelTime}`: `You're in control: You won't be charged until ${authorDisplayName} accepts your booking. Withdraw hassle-free before that with no deductions.`}
                            </p>
                          </div>
                        )}

                        {/* <InsurancePanel
                    listing={currentListing}
                    className={css.insuranceWrapper}
                    hasBookingData={!!currentTransaction.id && !!currentBooking.id}
                    insuranceType={insuranceType}
                    onManageDisableScrolling={onManageDisableScrolling}
                  /> */}
                      </div>
                    )}
                  </div>
                  {/* Info slider */}
                  {/*<InfoPromptSlider currentAuthor={currentAuthor} listing={listing} />*/}
                </div>
              </div>
            </Variant>
            <Variant name="B">
              <div className={css.contentContainer}>
                <div className={css.contentRow}>
                  <div className={css.checkoutPageTitles}>
                    {/* <div className={css.checkoutBreadcrumbs}>
                  Checkout &gt; Step{' '}
                  {checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS && !(location && location.state && location.state.updateBooking)
                    ? '1'
                    : '2'}{' '}
                  of 2
                </div> */}
                    <div className={css.stepTitle}>
                    {checkoutTitle()}
                    </div>
                    {/* {checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS && !(location && location.state && location.state.updateBooking) ? (
                  <p>
                    Make your trip safer and convenient by adding one of the awesome Drive lah add-ons. */}
                    {/* Some of the add-ons are charged after the trip is completed. */}
                    {/* </p>
                ) : (
                  <p>Select a payment method for paying for your trip.</p>
                )} */}
                  </div>
                </div>

                <div className={css.contentRow}>
                  <div className={css.aspectWrapper}>
                    <ResponsiveImage
                      rootClassName={css.rootForImage}
                      alt={listingTitle}
                      image={firstImage}
                      variants={['landscape-crop', 'landscape-crop2x']}
                    />
                  </div>
                  <div className={classNames(css.avatarWrapper, css.avatarMobile)}>
                    <AvatarMedium user={currentAuthor} disableProfileLink />
                  </div>
                  <div className={css.checkoutSectionLeft}>
                    {checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS &&
                    !(location && location.state && location.state.updateBooking) ? (
                      <div className={css.bookListingContainer}>
                        <AddOnsSection
                          totalTripHours={totalTripHours}
                          customerSelectedMileage={customerSelectedMileageValue}
                          bookingDays={bookingDays ? bookingDays : 1}
                          sliderOptions={sliderOptions}
                          bookingDates={bookingDates}
                          sliderDefaultValue={sliderDefaultValue.slideValue}
                          currentUser={currentUser}
                          mapAbSegment={mapAbSegment}
                          addOnsContent={addOnsContent.filter(a => !a.unavailable)}
                          transaction={transactionData}
                          isProvider={false}
                          intl={intl}
                          diffHours={this.props.diffHours}
                          loadInitialData={this.loadInitialData}
                          listing={currentListing}
                          updateCheckoutSteps={stepNumber =>
                            this.checkoutPageManagingStep(stepNumber)
                          }
                          isDelivery={showDelivery}
                          isDeliveryState={this.state.isDeliveryState}
                          isExcessReductionState={this.state.isExcessReductionState}
                          isExcessReduction2State={this.state.isExcessReduction2State}
                          isMileagePackageIncludedState={this.state.isMileagePackageIncludedState}
                          isMileageIncludedState={this}
                          isFuelInclusionState={this.state.isFuelInclusionState}
                          handleChangeAddOn={this.handleChangeAddOn}
                          handleSelectedMileageAddOn={this.handleSelectedMileageAddOn}
                          selectedMileage={this.state.selectedMileage}
                          isCheckoutPage={true}
                          isFuelSelectedManually={this.state.isFuelSelectedManually}
                        />
                      </div>
                    ) : (
                      <div className={css.bookListingContainer}>
                        {/* TODO detailsContainerLeft */}
                        <section className={css.paymentContainer}>
                          {/* {initiateOrderErrorMessage}
                      {listingNotFoundErrorMessage}
                      {speculateErrorMessage} */}
                          {retrievePaymentIntentError || retrievePaymentDepositIntentError ? (
                            <p className={css.orderError}>
                              <FormattedMessage
                                id="CheckoutPage.retrievingStripePaymentIntentFailed"
                                values={{ listingLink }}
                              />
                            </p>
                          ) : null}
                          { (location && location.state && location.state.updateBooking) &&
                            (
                              <div className={css.extensionModificationContainer}>
                                {/*<h3>Trip price breakdown</h3>*/}
                                {/* {speculateTransactionErrorMessage} */}
                                {breakdown}
                              </div>
                            )
                          }
                          {/* {retryButton} */}
                          {showPaymentForm ? (
                            <StripePaymentForm
                              className={css.paymentForm}
                              onSubmit={this.handleSubmit}
                              inProgress={this.state.submitting}
                              formId="CheckoutPagePaymentForm"
                              paymentInfo={intl.formatMessage({ id: 'CheckoutPage.paymentInfo' })}
                              authorDisplayName={currentAuthor.attributes.profile.displayName}
                              showInitialMessageInput={showInitialMessageInput}
                              initialValues={initalValuesForStripePayment}
                              initiateOrderError={initiateOrderError}
                              handleCardPaymentError={handleCardPaymentError}
                              confirmPaymentError={confirmPaymentError}
                              currentUser={currentUser}
                              transaction={transactionData}
                              isProvider={false}
                              intl={intl}
                              hasHandledCardPayment={hasPaymentIntentUserActionsDone}
                              loadingData={!stripeCustomerFetched}
                              defaultPaymentMethod={
                                hasDefaultPaymentMethod
                                  ? currentUser.stripeCustomer.defaultPaymentMethod
                                  : null
                              }
                              isUsingMastercard={this.state.isUsingMastercard}
                              setIsUsingMastercard={val => {
                                this.setState({ isUsingMastercard: val });
                              }}
                              paymentIntent={paymentIntent}
                              onStripeInitialized={this.onStripeInitialized}
                              isInstantBooking={isInstantBooking}
                              submitButtonId={SEND_REQUEST_BOOKING_BUTTON_ID}
                              pushEventEnterFormGTM={this.pushEventEnterFormGTM}
                              isLongTermRental={isLongTermRental}
                              isPayForFuel={isPayForFuel}
                              isFuelInclusion={isFuelInclusion}
                              isDelivery={showDelivery}
                              isTotalPriceVisible={true}
                              isDeliveryState={this.state.isDeliveryState}
                              onChangeDelivery={this.onDeliveryChange}
                              isUpdateBooking={isUpdateBooking}
                              isTripExtension={isTripExtension}
                              openInvoicesTotal={openInvoices?.totalUnpaidDues > 0 ? new Money(Math.round(openInvoices.totalUnpaidDues * 100), config.currency) : null}
                            />
                          ) : null}
                          {isPaymentExpired ? (
                            <p className={css.orderError}>
                              <FormattedMessage
                                id="CheckoutPage.paymentExpiredMessage"
                                values={{ listingLink }}
                              />
                            </p>
                          ) : null}

                          {!isInstantBooking && (
                            <div className={css.nonInstantBooking}>
                              <IconInfo fill="#000000"/>
                              <p className={css.updateBookingInstant}>
                                <FormattedMessage id="BookingTimeForm.nonInstantText" values={{ action: isTripExtension ? 'extension': 'modification' }}/>
                              </p>
                            </div>

                          )}
                        </section>
                        { !(location && location.state && location.state.updateBooking) && (
                        <div className={css.detailsContainerLeft}>
                          <div className={css.detailsLeftAspectWrapper}>
                            <ResponsiveImage
                              rootClassName={css.rootForImage}
                              alt={listingTitle}
                              image={firstImage}
                              variants={['landscape-crop', 'landscape-crop2x']}
                            />
                          </div>
                          <div className={css.avatarWrapperLeft}>
                            <AvatarMedium user={currentAuthor} disableProfileLink />
                          </div>
                          <div className={css.detailsHeadings}>
                            <h2 className={css.detailsTitle}>{listingTitle}</h2>
                            {/* <p className={css.detailsSubtitle}>{detailsSubTitle}</p> */}
                            <LineItemBookingPeriodNew
                              booking={transactionData.booking}
                              unitType={unitType}
                              timezone={timezoneFromListing}
                              transaction={tx}
                            />
                          </div>
                        </div>
                        )}
                        { !(location && location.state && location.state.updateBooking) &&
                          (
                            <div className={css.priceBreakdownContainerModify}>
                            {/*<h3>Trip price breakdown</h3>*/}
                            {/* {speculateTransactionErrorMessage} */}
                            {breakdown}
                          </div>
                          )
                        }

                        {(checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS &&
                          !(location && location.state && location.state.updateBooking))
                         ? (
                          ''
                        ) : location && location.state && location.state.updateBooking ? (
                          ''
                        ) : (
                          <div className={css.cancellationDepositContainer}>
                            {/* {speculateTransactionErrorMessage} */}
                            <h3>Free cancellation</h3>
                            <p>
                              {isInstantBooking ? ` You can cancel the trip anytime. 100% refund if the cancellation is
                              done before ${cancelTime}`: `You're in control: You won't be charged until ${authorDisplayName} accepts your booking. Withdraw hassle-free before that with no deductions.`}
                            </p>
                          </div>
                        )}
                          {distanceKm && isDistanceBased && !(location && location.state && location.state.updateBooking) && (
                            <div class={css.afterTripDistanceChargeDetails}>
                            <h3>Due later</h3>
                            <div class={css.distanceLineItem}>
                              <div class={css.distanceLineItemLabel}>
                                <img src={FuelIcon} alt="Menu" className={css.fuelIcon} />
                                <p>
                                Distance charge: Due later @ {distanceKm}/km
                                <span>
                                Fuel is included. Refuel during the trip? Submit your receipt at trip end for reimbursement.
                                </span>
                                </p>
                              </div>
                              <div class={css.distanceLineItemLabel}>
                                <img src={TollIcon} alt="Menu" className={css.fuelIcon} />
                                <p>
                                Tolls encountered during trip
                                </p>
                              </div>
                            </div>
                          </div>
                          )}
                        {/* TODO TripAddonContainer */}
                        {location && location.state && location.state.updateBooking ? (
                          ''
                        ) : (
                          <div className={css.detailsAddonContainerMain}>
                            <h3>Trip customization</h3>
                            <div class={css.addonBreakdown}>
                              {mapAbSegment && mapAbSegment === 'B' ? null : (
                                <div class={css.addonLineItem}>
                                  <span class={css.addonLineItemLabel}>Total mileage</span>
                                  <span class={css.addonLineItemValue}>
                                    {this.state.selectedMileage < sliderOptions.defaultValue
                                      ? `${sliderOptions.defaultValue} x ${bookingDays} days`
                                      : this.state.selectedMileage === sliderOptions.max
                                      ? 'Unlimited'
                                      : `${this.state.selectedMileage} x ${bookingDays} days`}{' '}
                                    {''}
                                    km{' '}
                                    {this.state.selectedMileage < sliderOptions.max ? (
                                      <span
                                        class={css.editSingleAddon}
                                        onClick={() =>
                                          this.setState({
                                            checkoutStep:
                                              checkoutStepConfiguration.CHECKOUT_ADD_ONS,
                                          })
                                        }
                                      >
                                        {' '}
                                        Add more
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                  </span>
                                </div>
                              )}
                              <div class={css.addonLineItem}>
                                <span class={css.addonLineItemLabel}>Protection plan</span>
                                <span class={css.addonLineItemValue}>
                                  {this.state.isExcessReduction2State
                                    ? 'Maximum '
                                    : this.state.isExcessReductionState
                                    ? 'Premium '
                                    : 'Basic '}
                                  {!(
                                    this.state.isExcessReductionState ||
                                    this.state.isExcessReduction2State
                                  ) ? (
                                    <span
                                      onClick={() =>
                                        this.setState({
                                          checkoutStep: checkoutStepConfiguration.CHECKOUT_ADD_ONS,
                                        })
                                      }
                                      class={css.editSingleAddon}
                                    >
                                      Upgrade now{' '}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </div>
                              {/* <div class={css.addonLineItem}>
                                <span class={css.addonLineItemLabel}>Fuel Package</span>
                                <span class={css.addonLineItemValue}>
                                  {this.state.isFuelInclusionState ? 'Included' : 'Not included '}
                                  {!this.state.isFuelInclusionState ? (
                                    <span
                                      onClick={() =>
                                        this.setState({
                                          checkoutStep: checkoutStepConfiguration.CHECKOUT_ADD_ONS,
                                        })
                                      }
                                      class={css.editSingleAddon}
                                    >
                                      {this.state.isFuelInclusionState ? 'Remove' : ' Add now'}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </div> */}
                            </div>
                            {/* {breakdown} */}
                          </div>
                        )}
                      </div>
                    )}

                    {/* <div className={css.heading}>
                    <h1 className={css.title}>{title}</h1>
                    <div className={css.author}>
                      <FormattedMessage
                        id="CheckoutPage.hostedBy"
                        values={{ name: currentAuthor.attributes.profile.displayName }}
                      />
                    </div>
                  </div> */}
                  </div>
                  { !(location && location.state && location.state.updateBooking) &&
                  <div className={css.checkoutSectionRight}>
                    <div className={css.detailsContainerDesktop}>
                      {checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS &&
                      !(location && location.state && location.state.updateBooking) ? (
                        <div className={css.detailsContainerTop}>
                          <div className={css.detailsAspectWrapper}>
                            <ResponsiveImage
                              rootClassName={css.rootForImage}
                              alt={listingTitle}
                              image={firstImage}
                              variants={['landscape-crop', 'landscape-crop2x']}
                            />
                          </div>
                          <div className={css.avatarWrapper}>
                            <AvatarMedium user={currentAuthor} disableProfileLink />
                          </div>
                          <div className={css.detailsHeadings}>
                            <h2 className={css.detailsTitle}>{listingTitle}</h2>
                            {/* <p className={css.detailsSubtitle}>{detailsSubTitle}</p> */}
                            <LineItemBookingPeriodNew
                              booking={transactionData.booking}
                              unitType={unitType}
                              timezone={timezoneFromListing}
                              transaction={tx}
                            />
                          </div>
                        </div>
                      ) : (
                        !(location && location.state && location.state.updateBooking) && <div className={css.detailsContainerMain}>
                          {distanceKm && isDistanceBased ?
                          (<h3>Due Now</h3>) : (<h3>Trip price breakdown</h3>)
                          }
                          {breakdown}
                        </div>
                      )}
                      {speculateTransactionErrorMessage}
                      {/* <InsurancePanel
                    listing={currentListing}
                    className={css.insuranceWrapper}
                    hasBookingData={!!currentTransaction.id && !!currentBooking.id}
                    insuranceType={insuranceType}
                    onManageDisableScrolling={onManageDisableScrolling}
                  /> */}
                    </div>
                    {checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS &&
                    !(location && location.state && location.state.updateBooking) ? (
                      ''
                    ) : location && location.state && location.state.updateBooking ? (
                      ''
                    ) : (
                      <div
                        className={classNames(
                          css.detailsContainerDesktopCancellation,
                          css.cancellationContainerMain
                        )}
                      ></div>
                    )}
                    {(checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS &&
                      !(location && location.state && location.state.updateBooking))
                   ? (
                      ''
                    ) : location && location.state && location.state.updateBooking ? (
                      ''
                    ) : (
                      <div className={css.detailsContainerDesktopCancellation}>
                        {location && location.state && location.state.updateBooking ? (
                          ''
                        ) : (
                          <div className={css.detailsContainerMain}>
                            <h3>Free cancellation</h3>
                            <p>
                              {isInstantBooking ? ` You can cancel the trip anytime. 100% refund if the cancellation is
                              done before ${cancelTime}`: `You're in control: You won't be charged until ${authorDisplayName} accepts your booking. Withdraw hassle-free before that with no deductions.`}
                            </p>
                          </div>
                        )}

                        {/* <InsurancePanel
                    listing={currentListing}
                    className={css.insuranceWrapper}
                    hasBookingData={!!currentTransaction.id && !!currentBooking.id}
                    insuranceType={insuranceType}
                    onManageDisableScrolling={onManageDisableScrolling}
                  /> */}
                      </div>
                    )}
                  </div>
                  }
                  {/* Info slider */}
                  {/*<InfoPromptSlider currentAuthor={currentAuthor} listing={listing} />*/}
                </div>
              </div>
            </Variant>
          </Experiment>
          )}
        </Page>
      </>
    );
  }
}

CheckoutPageComponent.defaultProps = {
  initiateOrderError: null,
  confirmPaymentError: null,
  listing: null,
  bookingData: {},
  bookingDates: null,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  currentUser: null,
  openInvoices: [],
  paymentIntent: null,
};

CheckoutPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  listing: propTypes.listing,
  bookingData: object,
  bookingDates: shape({
    bookingStart: instanceOf(Date).isRequired,
    bookingEnd: instanceOf(Date).isRequired,
  }),
  fetchStripeCustomer: func.isRequired,
  stripeCustomerFetched: bool.isRequired,
  fetchSpeculatedTransaction: func.isRequired,
  speculateTransactionInProgress: bool.isRequired,
  speculateTransactionError: propTypes.error,
  speculatedTransaction: propTypes.transaction,
  fetchOpenInvoices: func.isRequired,
  openInvoices: object.isRequired,
  openInvoicesInProgress: bool.isRequired,
  // openInvoicesError: propTypes.error,
  transaction: propTypes.transaction,
  currentUser: propTypes.currentUser,
  params: shape({
    id: string,
    slug: string,
  }).isRequired,
  makeUpdateBookingRequest: func.isRequired,
  onConfirmPayment: func.isRequired,
  onInitiateOrder: func.isRequired,
  onHandleCardPayment: func.isRequired,
  onRetrievePaymentIntent: func.isRequired,
  onSavePaymentMethod: func.isRequired,
  onSendMessage: func.isRequired,
  initiateOrderError: propTypes.error,
  confirmPaymentError: propTypes.error,
  // handleCardPaymentError comes from Stripe so that's why we can't expect it to be in a specific form
  handleCardPaymentError: oneOfType([propTypes.error, object]),
  paymentIntent: object,

  // from connect
  dispatch: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const {
    listing,
    bookingData,
    bookingDates,
    stripeCustomerFetched,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    openInvoicesInProgress,
    openInvoicesError,
    openInvoices,
    transaction,
    initiateOrderError,
    confirmPaymentError,
    bookingOverlapError,
    timeSlotsObj,
    depositTx,
  } = state.CheckoutPage;
  const { currentUser } = state.user;
  const {
    handleCardPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    retrievePaymentDepositIntentError,
    paymentDepositIntent,
  } = state.stripe;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    stripeCustomerFetched,
    bookingData,
    bookingDates,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    openInvoicesInProgress,
    openInvoicesError,
    openInvoices,
    transaction,
    listing,
    initiateOrderError,
    handleCardPaymentError,
    confirmPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    bookingOverlapError,
    timeSlotsObj,
    retrievePaymentDepositIntentError,
    paymentDepositIntent,
    isInitiateUpdateBookingRequestPending: $isInitiateUpdateBookingRequestPending(state),
    isInitiateUpdateBookingRequestSuccess: $isInitiateUpdateBookingRequestSuccess(state),
    fetchedDepositTransaction: depositTx,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  fetchSpeculatedTransaction: (
    params,
    bookingProcess = null,
    minimumPrice = false,
    savedListing = null,
    transition
  ) =>
    dispatch(speculateTransaction(params, bookingProcess, minimumPrice, savedListing, transition)),
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  fetchOpenInvoices: () => dispatch(getOpenInvoices()),
  onRequestToUpdateBooking: params => dispatch(requestToUpdateBooking(params)),
  onInitiateOrder: (
    params,
    transactionId,
    bookingProcess = null,
    minimumPrice = false,
    savedListing = null,
    selectedPaymentFlow = null,
    handlePaymentParams = null
  ) =>
    dispatch(
      initiateOrder(
        params,
        transactionId,
        bookingProcess,
        minimumPrice,
        savedListing,
        selectedPaymentFlow,
        handlePaymentParams
      )
    ),
  onRetrievePaymentIntent: params => dispatch(retrievePaymentIntent(params)),
  onHandleCardPayment: params => dispatch(handleCardPayment(params)),
  onConfirmPayment: params => dispatch(confirmPayment(params)),
  onConfirmPaymentDeposit: params => dispatch(confirmPaymentDeposit(params)),
  onConfirmPaymentUnpaidDues: params => dispatch(confirmPaymentUnpaidDues(params)),
  onSendMessage: params => dispatch(sendMessage(params)),
  onSavePaymentMethod: (stripeCustomer, stripePaymentMethodId) =>
    dispatch(savePaymentMethod(stripeCustomer, stripePaymentMethodId)),
  // onCheckBookingOverlap: (listingId, start, end) =>
  // dispatch(checkBookingOverlap(listingId, start, end)),
  onFetchDepositTx: params => dispatch(fetchDepositTx(params)),
  onFetchDataForUpdateBookingCheckoutPage: updateBookingData => dispatch(fetchDataForUpdateBookingCheckoutPage(updateBookingData)),
  onMakeUpdateBookingRequest: (updateBookingData, history) => dispatch(makeUpdateBookingRequest(updateBookingData, history)),
  onUpdateUserData: params => dispatch(updateUserData(params)),
});

const CheckoutPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CheckoutPageComponent);

CheckoutPage.setInitialValues = initialValues => setInitialValues(initialValues);

CheckoutPage.displayName = 'CheckoutPage';

export default CheckoutPage;
