import React, { useEffect, useState } from "react"
import { FormattedDate, FormattedMessage } from 'react-intl';
import { compose } from "redux";
import IdVerification from '../../components/PickAndDropPanel/PickAndDropSteps/IdentityVerification.js';
import css from "../../components/PickAndDropPanel/index.css"
import InspectExteriorComponent from "../../components/PickAndDropPanel/PickAndDropSteps/InspectExterior.js"
import InspectInteriorComponent  from "../../components/PickAndDropPanel/PickAndDropSteps/InspectInterior.js"
import OdometerReadingComponent from "../../components/PickAndDropPanel/PickAndDropSteps/OdometerReading.js"
import get from 'lodash/get';
import { RiCloseLargeFill } from 'react-icons/ri';
import ProgressBarComponent from "../../components/PickAndDropPanel/ProgressBar/ProgressBar.js";
import { useSelector, useDispatch } from 'react-redux';
import Feedback from "../../components/PickAndDropPanel/PickAndDropSteps/Feedback.js";
import { resetSteps, setPickTotalSteps, setDropTotalSteps, nextPickStep, setVerificationStatus, setPickUpStep} from '../../components/PickAndDropPanel/ProgressBar/ProgressBar.duck.js';
import StartTripComponent from "../../components/PickAndDropPanel/PickAndDropSteps/StartPickTrip.js";
import EndTripComponent from "../../components/PickAndDropPanel/PickAndDropSteps/EndDropTrip.js"
import { useParams } from "react-router-dom"
import { getStateFromLocalStorage, saveStateToLocalStorage } from "../../util/localStorageHelper.js";

const PickupAndDropComponent = ({
    isPickUp,
    isDropUp,
    currentUser,
    onNoteChange,
    intl,
    isCustomer,
    transaction,
    showStartTripPanel,
    setActivePhotoSection,
    activePhotoSection,
    currentPhotos,
    allTripPhotos,
    onUploadInteriorPhotoToMetadata,
    shouldShowDropOffSection,
    shouldShowPickUpSection,
    uploadInteriorPhotoProgress,
    showPickUpSection,
    isDropOff,
    onPushEventGTMPickUpDropOff,
    ownListing,
    isShuEnabledCar,
    transitInProgress,
    transitionMaybe,
    onTransit,
    isProvider,
    isDrivelahGo,
    showDropOffSection,
    isPickupRequested,
    distanceFromPickUp,
    clearCurrentPhotos,
    timeZone,
    engineStart,
    pickUpOdometer,
    dropOffOdometer,
    exteriorPhotoFun,
    isLockboxActive,
    listing,
    // lockbox ,
    findDeviceByType,
    isOlderVersion
    //
}
) => {
    const { id } = useParams();

    const progressBarState = useSelector(state => state && state.ProgressBarReducer) || {};

    const { dropStep, pickStep, verificationStatus, isProgressBarEnabled } = progressBarState;

    // Default initial state
    const defaultState = {
        exteriorPhotos: [],
        interiorPhotos: [],
        odometerPhotos: [],
        fuelGaugePhotos: [],
        cleanlinessValue: {
            rating: 0,
            reason: [],
            comment: '',
        },
        fuelReceiptPhotos: [],
    };

    // Load state from localStorage or use defaultState
    const [state, setState] = useState(() => getStateFromLocalStorage(`retain_pickup_dropoff_state/${id}`, defaultState));

    // Extract individual state values for easier use
    const {
        interiorPhotos,
        odometerPhotos,
        fuelGaugePhotos,
        cleanlinessValue,
        fuelReceiptPhotos,
        exteriorPhotos
    } = state;

  const activeState = [
    exteriorPhotos.length > 0 ? true : undefined,
    interiorPhotos.length > 0 ? true : undefined,
    odometerPhotos.length > 0 ? true : undefined,
    fuelGaugePhotos.length > 0 ? true : undefined,
    fuelReceiptPhotos.length > 0 ? true : undefined
  ];

  const dispatch = useDispatch();
    const { displayStart, displayEnd } = get(transaction, 'booking.attributes', {});
    const timeZoneMaybe = timeZone ? { timeZone } : null;
    const intialStep = (isPickUp || showPickUpSection) ? pickStep : dropStep;
    const [activeTab, setActiveTab] = useState(intialStep);
    const requireVerificationOnTrip = get(currentUser, 'attributes.profile.publicData.requireVerificationOnTrip');
    const onFidoCheckId =
    transaction &&
    transaction.attributes &&
    transaction.attributes.metadata &&
    transaction.attributes.metadata.onFidoCheckId;
    const onFidoFaceReport = get(currentUser, 'attributes.profile.metadata.onfidoFaceReport');
    const lockbox = get(transaction, 'listing.attributes.publicData.lockbox');

    const DROP_OFF_STEPS = 3;
    const PICKUP_WITHOUT_ID_VERIFICATION_STEPS = 4;
    const PICKUP_STEPS = 5;

    const dateFormatOptions = {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    };

    const FormattedDate =({value, dateFormatOptions, timeZoneMaybe})=> {
        return <FormattedDate value={value} {...dateFormatOptions} {...timeZoneMaybe} />
    }

    useEffect(() => {
        setActiveTab(intialStep);
        console.log(verificationStatus, "verificationStatus")
    }, [intialStep, verificationStatus]);

    const isRequiredFaceVerification = () => {
        return requireVerificationOnTrip || requireVerificationOnTrip == undefined
      }

    const isFaceVerificationInitiated = () => {
        return !!onFidoCheckId
      }

      console.log(isRequiredFaceVerification(), "isRequiredFaceVerification() && !isFaceVerificationInitiated()")

    useEffect(() => {
        if (isPickUp || showPickUpSection) {
          if (isRequiredFaceVerification()) {
            if (isFaceVerificationInitiated()) {
                console.log('PICK UP testing >>', progressBarState);
                dispatch(setPickUpStep(2));
            }
            dispatch(setPickTotalSteps(PICKUP_STEPS))
          }
          else {
            dispatch(setPickTotalSteps(PICKUP_WITHOUT_ID_VERIFICATION_STEPS))
          }
        }
        else {
            dispatch(setDropTotalSteps(DROP_OFF_STEPS))
        }
    }, []);

    // Update localStorage whenever the state changes
    useEffect(() => {
        if (id) {
            saveStateToLocalStorage(`retain_pickup_dropoff_state/${id}`, state);
        }
    }, [id, state]);

    // Update state dynamically
    const updateState = (key, value) => {
        setState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    useEffect(()=>{
        if (verificationStatus) {
            dispatch(setVerificationStatus(verificationStatus));
        } else {
            const existingVerificationStatus = get(transaction, 'attributes.metadata.onfidoFaceReport.result', 'pending');

            console.log('indetity testing > setting verification status >', existingVerificationStatus);

            dispatch(setVerificationStatus(existingVerificationStatus));
        }

      },[]);

    const pickUpSteps = [
        isRequiredFaceVerification() ? (
        <IdVerification
            key={0}
            isPickUp={isPickUp}
            isDropUp={isDropUp}
            currentUser = {currentUser}
            transaction = {transaction}
            onTransit = {onTransit}
        />
        ) : null,
         <InspectExteriorComponent
            key={1}
            isPickUp={isPickUp}
            isDropUp={isDropUp}
            currentUser={currentUser}
            isCustomer={isCustomer}
            intl={intl}
            transaction={transaction}
            onNoteChange={onNoteChange}
            setActivePhotoSection = {setActivePhotoSection}
            setExteriorPhotos = {(photos) => updateState('exteriorPhotos', photos)}
            exteriorPhotos = {exteriorPhotos}
            exteriorPhotoFun = {exteriorPhotoFun}
        />,

        <InspectInteriorComponent
            key={2}
            isPickUp={isPickUp}
            isDropUp={isDropUp}
            currentUser={currentUser}
            isCustomer={isCustomer}
            intl={intl}
            onNoteChange={onNoteChange}
            isDrivelahGo={isDrivelahGo}
            lockbox={lockbox}
            transaction={transaction}
            setActivePhotoSection = {setActivePhotoSection}
            setFuelGaugePhotos = {(photos) => updateState('fuelGaugePhotos', photos)}
            fuelGaugePhotos = {fuelGaugePhotos}
            isLockboxActive = {isLockboxActive}
            isOlderVersion={isOlderVersion}
        />,

        <OdometerReadingComponent
            key={3}
            isPickUp={isPickUp}
            isDropUp={isDropUp}
            currentUser={currentUser}
            isDrivelahGo={isDrivelahGo}
            isCustomer={isCustomer}
            intl={intl}
            onNoteChange={onNoteChange}
            setActivePhotoSection = {setActivePhotoSection}
            setOdometerPhotos = {(photos) => updateState('odometerPhotos', photos)}
            odometerPhotos = {odometerPhotos}
            pickUpOdometer={pickUpOdometer}
            dropOffOdometer={dropOffOdometer}
        />,

        <Feedback
            key={4}
            isPickUp={isPickUp}
            currentUser={currentUser}
            isCustomer={isCustomer}
            intl={intl}
            setInteriorPhotos ={(photos) => updateState('interiorPhotos', photos)}
            setActivePhotoSection = {setActivePhotoSection}
            interiorPhotos = {interiorPhotos}
            cleanlinessValue={cleanlinessValue}
            setCleanlinessValue ={(value) => updateState('cleanlinessValue', value)}
            isDrivelahGo = {isDrivelahGo}
            isProvider={isProvider}
            isDropOff={isDropOff}
            onTransit={onTransit} odometerPhotos = {odometerPhotos}
            fuelGaugePhotos = {fuelGaugePhotos}
            exteriorPhotos = {exteriorPhotos}
            transaction={transaction}
            currentPhotos={currentPhotos}
            activePhotoSection={activeState}
            allTripPhotos={allTripPhotos}
            onUploadInteriorPhotoToMetadata={onUploadInteriorPhotoToMetadata}
            shouldShowPickUpSection={shouldShowPickUpSection}
            shouldShowDropOffSection={shouldShowDropOffSection}
            uploadInteriorPhotoProgress={uploadInteriorPhotoProgress}
            transitInProgress={transitInProgress}
            showPickUpSection={showPickUpSection}
            onPushEventGTMPickUpDropOff={onPushEventGTMPickUpDropOff}
            ownListing={ownListing}
            transitionMaybe={transitionMaybe}
            isShuEnabledCar={isShuEnabledCar}
            distanceFromPickUp={distanceFromPickUp}
            timeZone={timeZone}
        />,

        <StartTripComponent
            key={5}
            isPickUp={isPickUp}
            displayTime={displayEnd}
            dateFormatOptions={dateFormatOptions}
            timeZoneMaybe={timeZoneMaybe}
            isOlderVersion={isOlderVersion}
            shouldShowPickUpSection={shouldShowPickUpSection}
            showPickUpSection={showPickUpSection}
            shouldShowDropOffSection={shouldShowDropOffSection}
        />
    ]

    const pickUpstepTabs = pickUpSteps
        .filter(component => component !== null)
        .reduce((acc, component, index) => {
            if (component) {
                acc[index + 1] = component;
            }
            return acc;
    }, {});


    const dropOffSteps = {
 
        1: <InspectInteriorComponent
            isPickUp={ isPickUp }
            isDropUp={isDropUp}
            currentUser={currentUser}
            isCustomer={isCustomer}
            intl={intl}
            onNoteChange={onNoteChange}
            isDrivelahGo={isDrivelahGo}
            lockbox ={lockbox}
            transaction={transaction}
            setActivePhotoSection = {setActivePhotoSection}
            fuelGaugePhotos = {fuelGaugePhotos}
            fuelReceiptPhotos = {fuelReceiptPhotos}
            isOlderVersion={isOlderVersion}
            setFuelGaugePhotos = {(photos) => updateState('fuelGaugePhotos', photos)}
            setFuelReceiptPhotos={(photos) => updateState('fuelReceiptPhotos', photos)}
         />,

        2: <OdometerReadingComponent
            isPickUp={ isPickUp }
            isDropUp={isDropUp}
            currentUser={currentUser}
            isCustomer={isCustomer}
            isDrivelahGo={isDrivelahGo}
            intl={intl}
            onNoteChange={onNoteChange}
            setActivePhotoSection = {setActivePhotoSection}
            setOdometerPhotos = {(photos) => updateState('odometerPhotos', photos)}
            odometerPhotos = {odometerPhotos}
            pickUpOdometer={pickUpOdometer}
            dropOffOdometer={dropOffOdometer}
            />,

        3: <InspectExteriorComponent
            isPickUp={isPickUp}
            isDropUp={isDropUp}
            currentUser={currentUser}
            isCustomer={isCustomer}
            intl={intl}
            onNoteChange={onNoteChange}
            setActivePhotoSection = {setActivePhotoSection}
            setExteriorPhotos = {(photos) => updateState('exteriorPhotos', photos)}
            exteriorPhotos = {exteriorPhotos}
            exteriorPhotoFun = {exteriorPhotoFun}
           />,

        4: <EndTripComponent
            isPickUp={isPickUp}
            currentUser={currentUser}
            isCustomer={isCustomer}
            intl={intl}
            setInteriorPhotos = {(photos) => updateState('interiorPhotos', photos)}
            setActivePhotoSection = {setActivePhotoSection}
            interiorPhotos = {interiorPhotos}
            odometerPhotos = {odometerPhotos}
            fuelGaugePhotos = {fuelGaugePhotos}
            exteriorPhotos = {exteriorPhotos}
            transaction = {transaction}
            activePhotoSection = {activeState}
            currentPhotos = {currentPhotos}
            allTripPhotos = {allTripPhotos}
            onUploadInteriorPhotoToMetadata={onUploadInteriorPhotoToMetadata}
            shouldShowDropOffSection = {shouldShowDropOffSection}
            shouldShowPickUpSection = { shouldShowPickUpSection }
            uploadInteriorPhotoProgress = {uploadInteriorPhotoProgress}
            transitInProgress={transitInProgress}
            showPickUpSection = {showPickUpSection}
            onPushEventGTMPickUpDropOff={onPushEventGTMPickUpDropOff}
            ownListing = {ownListing}
            transitionMaybe = {transitionMaybe}
            isShuEnabledCar = {isShuEnabledCar}
            isProvider = {isProvider}
            isDrivelahGo = {isDrivelahGo}
            isDropOff = {isDropOff}
            onTransit = {onTransit}
            showDropOffSection = {showDropOffSection}
            distanceFromPickUp = {distanceFromPickUp}
            displayTime={displayEnd}
            dateFormatOptions={dateFormatOptions}
            timeZoneMaybe={timeZoneMaybe}
            isPickupRequested = {isPickupRequested}
            isLockBox={!!lockbox}
            engineStart={engineStart}
            fuelReceiptPhotos = {fuelReceiptPhotos}
            isLockboxActive  = {isLockboxActive}
            listing = {listing}
            findDeviceByType = {findDeviceByType}
            timeZone={timeZone}
        />
    };

    const stepTabs = (isPickUp || showPickUpSection) ? pickUpstepTabs : dropOffSteps;
    console.log('stepTabs', stepTabs)

    const handleClose =()=> {
        dispatch(resetSteps());
        showStartTripPanel();
        clearCurrentPhotos();
    }

    return (
        <div>
            <div className={`${css.mt} `}>
            <div className={css.stepContainer}>
                <div className={css.responsiveCloseIcon}>
                    <RiCloseLargeFill className={css.closeIcon} onClick={() => handleClose() }/>
                    { isProgressBarEnabled && (
                        <div className={css.progressBarContainer}>
                             <ProgressBarComponent  isPickUp={isPickUp}/>
                        </div>
                    )}
                </div>
            </div>
              {stepTabs[activeTab]}
            </div>
        </div>
    )
}

const PickupAndDropPage = compose()(PickupAndDropComponent)

export default PickupAndDropPage;
