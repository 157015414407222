import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import ProgressBar from '../ProgressBar/ProgressBar';
import css from '../index.css';
import {InspectInteriorContentText} from './constant'
import UploadSection from '../UploadSection'
import { nextDropStep, nextPickStep } from '../ProgressBar/ProgressBar.duck';
import IconSpinner from '../../IconSpinner/IconSpinner';
import LOCK from '../../../assets/newPickupAndDropoff/lock-car.svg';
import DLGO from '../../../assets/newPickupAndDropoff/dlgo-car.svg';
import { getLockboxCode, unlockShuCar } from '../../../util/notification';
import callhost from '../../../assets/newPickupAndDropoff/callhost.svg';
import FuelGauge from '../../../components/PickAndDropPanel/Components/FuelGuageMeter/index';
import UploadIcon from '../Components/Icons/uploadIcon';
import { CANCEL_BOOKING_BUTTON_ID } from '../../../util/gtm/gtmConstants';
import { IconClose, Modal, ModalHelp, PrimaryButton } from '../../../components';
import CodeGen from '../Components/LockBoxCode';
import AlertBox from '../../AlertBox/AlertBox';
import { Button } from '../../../components';
import { uploadFiles } from '../../../util/fileUpload';
import infoIcon from '../../../assets/newPickupAndDropoff/infoIcon.svg'
import sampleReceipt from '../../../assets/newPickupAndDropoff/sampleFuelReceipt.jpeg'
const InspectInteriorComponent = ({
  isPickUp,
  currentUser,
  intl,
  isCustomer,
  onNoteChange,
  isDrivelahGo,
  lockbox,
  transaction,
  setFuelGaugePhotos,
  setActivePhotoSection,
  fuelGaugePhotos,
  setFuelReceiptPhotos,
  fuelReceiptPhotos,
  isOlderVersion,
}) => {
  const dispatch = useDispatch();
  const [isUploaded, setIsUploaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showFuelSectionFirst, setShowFuelSectionFirst] = useState(false);
  const [isHostCall, setIsHostCall] = useState(true);
  const [fuelRecieptSection, setFuelRecieptSection] = useState(false);
  const [nettFuel, setNettFuel] = useState(0);
  const [lastImageURl, setLastImageUrl] = useState('');
  const [showCancelHelpModal, setShowCancelHelpModal] = useState(false);
  const [isExpectedLength, setIsExpectedLength] = useState(0);
  const [lockBoxDigits, setLockBoxDigits] = useState(null);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const [isFuelRecieptModalOpen, setIsFuelRecieptModalOpen] = useState(false);
  const [isSampleReceiptOpen, setIsSampleReceiptOpen] = useState(false);
  const [fuelRecieptValue, setFuelRecieptValue] = useState('');
  const [fuelRecieptQuantity, setFuelRecieptQuantity] = useState('');
  const [selectedFuelRecieptImage, setSelectedFuelRecieptImage] = useState(null);
  const apiData = {
    user_id: transaction.customer.attributes.profile.publicData.shuUserId,
    booking_id: transaction.id.uuid,
    fleet_id: transaction.listing.attributes.publicData.license_plate_number,
  };

  useEffect(() => {
    if (isPickUp) {
      if (isDrivelahGo) {
        unlockShuCar(apiData);
        console.log('car unlocked successfully!');
      }
    }
  }, [isPickUp]);

  const verificationStatus = useSelector(state => state.ProgressBarReducer.verificationStatus);
  const uploadSectionRef = useRef(null);
  const fuelImageUploader = useRef(null);

  const getHostPhoneNumber = transaction => {
    if (
      transaction &&
      transaction.customer &&
      transaction.customer.attributes &&
      transaction.customer.attributes.profile &&
      transaction.customer.attributes.profile.publicData &&
      transaction.customer.attributes.profile.publicData.guestIdentityVerificationStatus ===
        'confirmed'
    ) {
      return get(transaction, 'attributes.protectedData.hostPhoneNumber', null);
    }
    return null;
  };

  const hostPhoneNumber = getHostPhoneNumber(transaction);

  useEffect(() => {
    if (fuelGaugePhotos && fuelGaugePhotos.length) {
      const updatedPhotos = fuelGaugePhotos.map(photo => ({
        ...photo,
        note: nettFuel,
      }));
      setFuelGaugePhotos(updatedPhotos);
    }
  }, [nettFuel]);

  useEffect(() => {
    // Set nettfuel from fuelGaugePhotos if it is already uploaded
    if (fuelGaugePhotos && fuelGaugePhotos.length) {
      const fuelGaugePhoto = fuelGaugePhotos[0];
      setNettFuel(fuelGaugePhoto.note);
    }
  }, []);

  //functions
  const getTextContent = (isUploaded, showFuelSectionFirst) => {
    if (isUploaded) {
      return InspectInteriorContentText.FuelGaugeLevelView;
    }

    if (showFuelSectionFirst) {
      return isPickUp
        ? InspectInteriorContentText.FuelGaugeLevelViewPhotos
        : InspectInteriorContentText.FuelGaugeLevelViewPhotosDropoff;
    }

    const exteriorView = { ...InspectInteriorContentText.InspectExteriorView };

    exteriorView.img = isDrivelahGo ? DLGO : lockbox ? LOCK : exteriorView.img;
    exteriorView.message =
      isDrivelahGo && !lockbox
        ? 'The car has been unlocked. You are just a few steps away from embarking on your trip.'
        : lockbox
        ? `The car keys are in the lockbox, which may be on the car near the driver’s window or around the host’s property/parking space. If you still can’t locate it, contact the host.  Call now. Please follow these steps:`
        : exteriorView.message;
    exteriorView.callText =
      isDrivelahGo && !lockbox
        ? 'Car not unlocked?'
        : lockbox
        ? 'Facing issues opening the lockbox?'
        : exteriorView.callText;

    return exteriorView;
  };

  const handleAddMorePhotos = () => {
    if (uploadSectionRef.current) {
      uploadSectionRef.current.handleClickUpload();
    }
  };

  const ActionButton = () => (
    <div className={`${css.warningDesktopWrapper} ${css.addMoreButtonWrepper} ${css.interiorLink}`}>
      <button
        className={css.addMoreButton}
        onClick={
          currentTextContent.callText === 'Car not unlocked?' ||
          currentTextContent.callText === 'Facing issues opening the lockbox?'
            ? () => setShowCancelHelpModal(true)
            : () => setIsHostCall(false)
        }
      >
        {currentTextContent.callText}
      </button>
    </div>
  );

  // variables
  const currentTextContent = getTextContent(isUploaded, showFuelSectionFirst);
  const isVerificationInProgress =
    currentTextContent.buttonText === 'Continue to next step' &&
    verificationStatus === 'inProgress';
  const isLockboxCondition =
    lockbox && isHostCall && !isDrivelahGo && currentTextContent.title !== 'Fuel gauge level';
  const shouldShowFuelGauge = !fuelRecieptSection && isExpectedLength && isUploaded;
  const shouldShowFuelReceipt = !isPickUp && fuelRecieptSection && isExpectedLength;

  const isDisabled =
    loading ||
    isVerificationInProgress ||
    (isLockboxCondition && loading) ||
    (shouldShowFuelGauge && !Boolean(nettFuel - 0.6 > 0));

  const listingId = transaction.listing.id.uuid;
  const handleNextStep = () => {
    switch (currentTextContent.buttonText) {
      case 'Take photos':
        handleAddMorePhotos();
        if (lockbox) {
          setIsHostCall(true);
        }
        break;
      case 'Continue to interior inspection':
        setShowFuelSectionFirst(true);
        break;
      case 'Continue to next step':
        if (!isPickUp && !fuelRecieptSection) {
          setFuelRecieptSection(true);
        } else {
          isPickUp ? dispatch(nextPickStep()) : dispatch(nextDropStep());
        }
        break;
      default:
        console.warn('Unhandled button text:', currentTextContent.buttonText);
    }
  };

  // const handleFileInputClick = () => {
  //   document.getElementById('fileInput').click();
  // };

  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file) {
      console.log('File selected:', file.name);
      // Add your file handling logic here
    }
  };

  useEffect(() => {
    if (!isPickUp && !isExpectedLength) {
      setShowFuelSectionFirst(true);
      setFuelRecieptSection(false);
    }
  }, [isExpectedLength]);

  useEffect(() => {
    const getCode = async () => {
      await generateLockBoxCode(listingId);
    };
    if (lockbox && isPickUp) {
      getCode();
    }
  }, []);

  const generateLockBoxCode = async (listingId, force) => {
    try {
      setLoading(true);
      const data = await getLockboxCode(listingId, force);
      setLockBoxDigits(data && data.code.split(''));
    } catch (error) {
      console.log(error.response.data.error);
      setAlertMessage(error.response.data.error);
      setAlert(true);
    } finally {
      setLoading(false);
    }
  };

  const regenerateLockBoxCode = (
    <Button
      className={css.retryButton}
      onClick={() => {
        setAlert(false);
        setAlertMessage(null);
        generateLockBoxCode(listingId, true);
      }}
    >
      Generate Again
    </Button>
  );

  const fuelReciptsImages =
    fuelReceiptPhotos &&
    fuelReceiptPhotos.length > 0 &&
    fuelReceiptPhotos.map((ele, index) => (
      <>
        <div className={css.fuelRecipt}>
          <div className={css.removePhotoBtn} onClick={() => handleRemoveFuelRecipt(index)}>
            <IconClose size={'small'} />
          </div>
          <div className={css.fuelReciptDescription}>
            <p>Fuel recipt #{index + 1}</p>
            <p>{`$${ele.fuelAmount} (${ele.fuelQuantity} litres)`}</p>
          </div>
          <div className={css.fuelReciptImage}>
            <img
              src={ele.fileUrl}
              className={css.fuelReciptPhoto}
              onClick={() => window.open(ele.fileUrl, '_blank')}
            />
          </div>
        </div>
      </>
    ));
  // useEffect(() => {
  //   console.log(isExpectedLength);
  // }, [isExpectedLength]);

  const handleFuelRecieptValueChange = e => {
    console.log('Fuel > handleFuelRecieptValueChange >', e.target.value);
    setFuelRecieptValue(e.target.value);
  };

  const handleFuelRecieptQuantityChange = e => {
    console.log('Fuel > handleFuelRecieptQuantityChange >', e.target.value);
    setFuelRecieptQuantity(e.target.value);
  };

  const handleFuelRecieptImageUpload = e => {
    if (fuelImageUploader.current) {
      fuelImageUploader.current.click();
    }
  };

  const handleFuelRecieptImageChange = e => {
    const input = e.target;
    const selectedFiles = Array.from(input.files).filter(file => file.type.startsWith('image/'));
    if (selectedFiles.length) {
      uploadingFuelRecipts(selectedFiles.map((file, index) => ({ index, file })));
    }
  };

  const uploadingFuelRecipts = async files => {
    if (files.length) {
      try {
        setLoading(true);
        const uploadPromises = files.map(file => {
          const formData = new FormData();
          formData.append('file', file.file, file.file.name);
          // if(setLastImageUrl) {
          //   setFilesName(file.file.name)
          // }
          formData.append('userId', currentUser.id.uuid);
          formData.append('tripPhotos', true);
          console.log('Fuel >> I am form data to be uploaded >>>>>', formData);

          return uploadFiles(formData).then(response => {
            if (response.status !== 200) {
              throw new Error('Upload failed');
            }
            return response.json();
          });
        });
        const responses = await Promise.all(uploadPromises);
        console.log('Fuel >> I am response after uploaded >>>>>', responses);
        const newPhotos = responses.map((item, index) => ({
          fileUrl: item.url,
          id: 4,
          note: item.note,
          type: 'fuelReceiptPhotos',
          isPickUp: true,
          timestamp: new Date().getTime() * (index + 1),
          isJustUploaded: true,
        }));
        console.log('Fuel >> after response newPhotos >>>>>', newPhotos);
        // setUploadedPhotos(prevPhotos => [...prevPhotos, ...newPhotos]);
        setSelectedFuelRecieptImage(newPhotos);
        setActivePhotoSection(newPhotos);
        setLoading(false);
        // if(type == "exteriorPhotos") exteriorPhotoFun(newPhotos)
      } catch (error) {
        console.error('Upload error:', error);
        // setUploadFileError(true);
        setLoading(false);
        // setIsUploaded(false)
      }
    }
  };

  console.log('Fuel > selectedFuelRecieptImage >', selectedFuelRecieptImage);

  const handleSaveFuelRecieptButton = () => {
    if (!selectedFuelRecieptImage) return;

    const data = selectedFuelRecieptImage[0];

    const fuelReciptImageData = [
      {
        fileUrl: data.fileUrl,
        note: '',
        timestamp: data.timestamp,
        isCustomer: isCustomer,
        isPickUp: isPickUp,
        fuelReceiptPhotos: true,
        fuelAmount: fuelRecieptValue,
        fuelQuantity: fuelRecieptQuantity,
      },
    ];
    setFuelReceiptPhotos([...fuelReceiptPhotos, ...fuelReciptImageData]);
    setFuelRecieptValue('');
    setFuelRecieptQuantity('');
    setSelectedFuelRecieptImage(null);
    setIsFuelRecieptModalOpen(false);
  };

  const handleRemoveFuelRecipt = indexToRemove => {
    console.log('Fuel > INDEX >>>', indexToRemove);
    const filteredPhotosData = fuelReceiptPhotos.filter((_, index) => index !== indexToRemove);
    setFuelReceiptPhotos(filteredPhotosData);
  };

  const unlockApiFromModal = () => {
    if (isPickUp) {
      if (isDrivelahGo) {
        setLoading(true);
        try {
          unlockShuCar(apiData);
          console.log('car unlocked successfully!');
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  return (
    <>
      <div className={`${css.stepContainer} ${css.mb}`}>
        <div className={css.wrapper}>
          <div className={css.contentWrapper}>
            <h1>{currentTextContent.title}</h1>
            <p>
              {' '}
              {lockbox ? (
                <>
                  {currentTextContent.message.split('Call')[0]}
                  <a className={css.userTelephone} href={`tel:${hostPhoneNumber}`}>
                    Call Now.
                  </a>
                  {currentTextContent.message.split('Call now.')[1]}
                </>
              ) : (
                currentTextContent.message
              )}
            </p>
          </div>
          {alert && (
            <AlertBox
              title="LockBox error"
              message={`${alertMessage}`}
              type="error"
              button={regenerateLockBoxCode}
            />
          )}
          {!isUploaded && !loading && (
            <div className={css.imgWrapper}>
              {lockbox && !showFuelSectionFirst ? (
                <CodeGen codeNumber={lockBoxDigits} isPickup={isPickUp} />
              ) : (
                <img src={currentTextContent.img} alt={currentTextContent.title} />
              )}
            </div>
          )}
          <UploadSection
            ref={uploadSectionRef}
            intl={intl}
            currentUser={currentUser}
            isPickUp={isPickUp}
            isCustomer={isCustomer}
            onNoteChange={onNoteChange}
            setIsUploaded={setIsUploaded}
            setLoading={setLoading}
            photosData={fuelGaugePhotos}
            setPhotosData={setFuelGaugePhotos}
            type={'fuelGaugePhotos'}
            setActivePhotoSection={setActivePhotoSection}
            id={isDrivelahGo && isPickUp ? 4 : 3}
            note={nettFuel}
            setIsExpectedLength={setIsExpectedLength}
          />
        </div>
        {(isDrivelahGo || lockbox) && !isUploaded ? <ActionButton /> : ''}

        {!!shouldShowFuelGauge && (
          <div>
            <FuelGauge nettFuel={nettFuel} setNettFuel={setNettFuel} />
          </div>
        )}
        {!!shouldShowFuelReceipt && (
          <div className={css.fuelRecieptContainer}>
            <div className={css.fuelLevelContainer}>
              <span className={css.label}>Fuel level</span>
              <span className={css.fuelLevel}>{Math.round(nettFuel)}%</span>
            </div>
            <div className={css.receiptContainer}>
              <p className={css.receiptQuestion}>Did you top up fuel during your trip?</p>
              {!isOlderVersion && (
                <p className={css.receiptDetail}>
                  You can claim refunds for fuel you topped up during the trip. Simply add receipts
                  and we will refund the relevant amount.
                </p>
              )}
              {fuelReciptsImages}
              <button
                className={css.addReciptsButton}
                onClick={() => {
                  console.log('Fuel > add recipt button click >');
                  setIsFuelRecieptModalOpen(true);
                }}
              >
                Add fuel receipts
              </button>
              {/* <div className={css.uploadBox} onClick={handleAddMorePhotos}>
                <div className={css.uploadIcon}>
                  <UploadIcon />
                </div>
                <p className={css.uploadText}>
                  {lastImageURl || 'Attach fuel receipt to avail refund'}
                </p>
                <input
                  id="fileInput"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
              </div> */}
            </div>
          </div>
        )}
      </div>
      <div className={css.footerContainer}>
        <div className={`${css.footerWrapper} ${css.stepContainer}`}>
          <div className={css.footerProgressBar}>
            <ProgressBar isPickUp={isPickUp} />
          </div>
          <button className={css.footerButton} onClick={handleNextStep} disabled={isDisabled}>
            {loading ? 'Uploading...' : currentTextContent.buttonText}
            <div>
              {loading && (
                <div className={css.uploadLoading}>
                  <IconSpinner />
                </div>
              )}
            </div>
          </button>
        </div>
      </div>
      <ModalHelp
        onClose={() => setShowCancelHelpModal(false)}
        cancelButtonId={CANCEL_BOOKING_BUTTON_ID}
        isOpen={showCancelHelpModal}
        unlockApiFromModal={unlockApiFromModal}
        showUnlockOnModel={isPickUp && isDrivelahGo}
        unlockLoading={loading}
      />
      <Modal
        id="addingFuelReciptModal"
        isOpen={isFuelRecieptModalOpen}
        onClose={() => {
          setIsFuelRecieptModalOpen(false);
        }}
        containerClassName={css.modalContainer}
        onManageDisableScrolling={false}
        closeButtonMessage={' '}
      >
        <div>
          <p className={css.receiptQuestion}>Add fuel reciept</p>
          <div
            className={`${css.stepContainer} ${css.fuelWarningContainer} ${css.warningContainerFuelReceipt} ${css.adjustButtonPadding}`}
          >
            <div className={css.worningSection}>
              <div className={css.worningContext}>
                <div className={css.titleAndIcon}>
                  <p>
                    <b>Upload a clear fuel receipt</b>
                  </p>
                  <div className={css.instructionsIcon}>
                    <img src={infoIcon} alt="Warning icon" />
                  </div>
                </div>
                <ul className={css.instructionPoints}>
                  <li>Upload a photo of the full receipt with itemized charges. Ensure the photo is clear, close up, sharp and readable</li>
                  <li>Screenshots, bank transaction summaries, blurry or distant images wont be accepted and will delay your refund</li>
                </ul>
                <p
                  onClick={() => {
                    setIsSampleReceiptOpen(true);
                  }}
                  className={css.underLineTextMobile}
                >
                  <b>View sample fuel receipt</b>
                </p>
                <p
                  onClick={() => {
                    window.open(sampleReceipt, '_blank');
                  }}
                  className={css.underLineTextDesktop}
                >
                  <b>View sample fuel receipt</b>
                </p>
              </div>
            </div>
          </div>
          <div className={css.fuelReadingInput}>
            <p>Fuel Cost (in $)</p>
            <input
              type="number"
              // max={999999}
              // min={!isPickUp ? pickUpOdometer : 0}
              id="fuelValue"
              value={fuelRecieptValue}
              onChange={handleFuelRecieptValueChange}
              name="fuelValue"
              required
              placeholder="Enter the value of fuel added (in $)"
            />
          </div>
          <div className={css.fuelReadingInput}>
            <p>Fuel Quantity (in litres)</p>
            <input
              type="number"
              // max={999999}
              // min={!isPickUp ? pickUpOdometer : 0}
              id="fuelQuantity"
              value={fuelRecieptQuantity}
              onChange={handleFuelRecieptQuantityChange}
              name="fuelQuantity"
              required
              placeholder="Enter the quantity of fuel added (in litres)"
            />
          </div>
          <div className={css.uploadBoxForFuel} onClick={handleFuelRecieptImageUpload}>
            <div className={css.uploadIcon}>{loading ? <IconSpinner /> : <UploadIcon />}</div>
            <p className={css.uploadText}>{loading ? 'Uploading...' : 'Attach fuel receipt'}</p>
            {selectedFuelRecieptImage &&
              selectedFuelRecieptImage[0] &&
              selectedFuelRecieptImage[0].fileUrl && (
                <img
                  src={selectedFuelRecieptImage[0].fileUrl}
                  className={css.fuelRecieptCurrentPhoto}
                  onClick={() => window.open(selectedFuelRecieptImage[0].fileUrl, '_blank')}
                />
              )}
            <input
              ref={fuelImageUploader}
              id="fileInput"
              type="file"
              style={{ display: 'none' }}
              onChange={handleFuelRecieptImageChange}
            />
          </div>
          <div>
            <button
              className={css.saveReciptButton}
              disabled={!fuelRecieptValue || !fuelRecieptQuantity || !selectedFuelRecieptImage}
              onClick={() => {
                console.log('Fuel > save recipt button click >');
                handleSaveFuelRecieptButton();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isSampleReceiptOpen}
        onClose={() => {
          setIsSampleReceiptOpen(false);
        }}
        onManageDisableScrolling={false}
        closeButtonMessage={' '}
      >
        <div className={css.sampleReceipt}>
          <img src={sampleReceipt} alt="Sample Fuel Receipt" />
        </div>
      </Modal>
    </>
  );
};

export default InspectInteriorComponent;
