import React, { useEffect, useState } from 'react';
import 'react-dates/lib/css/_datepicker.css';
import SectionCarPhotos from './SectionCarPhotos';
import SectionCarPhotosOnlyView from './SectionCarPhotosOnlyView';

import css from './TripPanel.css';
import { flatten } from 'lodash';
import classNames from 'classnames';
import tick from '../../assets/tripPage/tick.svg';
import disabledTick from '../../assets/tripPage/tick-disabled.svg';
import moment from 'moment';
import DateInputComponent from '../FieldDateInput/DateInput';
import { isInclusivelyAfterDay } from 'react-dates';

const DropOffSection = props => {
  const {
    shouldShowDropOffSection,
    isCustomer,
    photoSectionList,
    photoSectionListCustomer,
    setActivePhotoSection,
    activePhotoSection,
    intl,
    onManageDisableScrolling,
    isProvider,
    isPickUp,
    currentUser,
    setTripPhotos,
    state,
    allTripPhotos,
    inputOdometerInvalid,
    onNoteChange,
    isLimitedUser,
    removePhoto,
    isDropOff,
    pickUpOdometer,
    isAdminAction,
    dropOffSentAtByAdmin,
    customDropOffReadingByAdmin,
    setDropOffSentAt,
    dropoffSentAt,
    timeZone,
    transactionId,
    listingId
  } = props;

  const flatTripPhotos =  [
    ...allTripPhotos,
  ];

  const isShowUpload = (isLimitedUser && isPickUp && isDropOff) || (!isDropOff && !isPickUp)

  const filterTripPhotos = flatten(flatTripPhotos).filter(i => !i.isPickUp);
  const classes = classNames(css.section_root);
  const holderClassName= dropOffSentAtByAdmin !== null ? css.activeSection : css.inactiveSection
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState('');
  const [maxDateTimeISOString, setMaxDateTimeISOString] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [availableTimes, setAvailableTimes] = useState([]);

  useEffect(() => {
    if (isLimitedUser) {
      fetchMaxDropoffTime();
    }
  }, [isLimitedUser]);

  useEffect(() => {
    if (selectedDate) {
      generateAvailableTimes();
    }
  }, [selectedDate, maxDateTimeISOString]);

  const fetchMaxDropoffTime = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/api/update-booking/get-max-dropoff-time?transactionId=${transactionId}&listingId=${listingId}`
      );
      const data = await response.json();
      if (data.status === 'success') {
        // Store the raw ISO string
        const originalMaxTime = moment.utc(data.max_allowed_dropoff_time);
        const maxTimeISOString = originalMaxTime.toISOString();
        setMaxDateTimeISOString(maxTimeISOString);

        // Set max date with full datetime to allow selection up to the max time
        setMaxDate(originalMaxTime);

        console.log('Original max dropoff time (ISO):', data.max_allowed_dropoff_time);
        console.log('Adjusted max dropoff time (ISO):', maxTimeISOString);
        console.log('Max datetime:', originalMaxTime.format('YYYY-MM-DD HH:mm:ss'));
      }
    } catch (error) {
      console.error('Failed to fetch max dropoff time:', error);
    }
  };

  const generateAvailableTimes = () => {
    if (!selectedDate || !maxDateTimeISOString) return;

    // Parse the date and max time in UTC to avoid timezone conversion issues
    const selectedDateUTC = moment.utc(moment(selectedDate).format('YYYY-MM-DD'));
    const maxDateTimeUTC = moment.utc(maxDateTimeISOString);

    console.log('Selected date (UTC):', selectedDateUTC.format('YYYY-MM-DD'));
    console.log('Max datetime (UTC):', maxDateTimeUTC.format('YYYY-MM-DD HH:mm:ss'));

    const isMaxDate = selectedDateUTC.isSame(maxDateTimeUTC, 'day');
    console.log('Is max date:', isMaxDate);

    const times = [];
    const startOfDay = selectedDateUTC.clone().startOf('day');

    // Get the hour and minute of max time if this is the max date
    let maxHour = null;
    let maxMinute = null;

    if (isMaxDate) {
      maxHour = maxDateTimeUTC.hour();
      maxMinute = maxDateTimeUTC.minute();
      console.log('Max hour:', maxHour, 'Max minute:', maxMinute);
    }

    // Generate times in 15 minute intervals (96 intervals in a day)
    for (let i = 0; i < 96; i++) {
      const timePoint = startOfDay.clone().add(i * 15, 'minutes');
      const hour = timePoint.hour();
      const minute = timePoint.minute();

      // If this is the max date, only include times before max time
      if (isMaxDate && (hour > maxHour || (hour === maxHour && minute > maxMinute))) {
        console.log('Skipping time:', timePoint.format('HH:mm'), '- Is after max time');
        continue;
      }

      times.push({
        value: timePoint.format('HH:mm'),
        label: timePoint.format('hh:mm A'),
        hour: hour,
        minute: minute
      });
    }

    console.log('Available times count:', times.length);
    if (times.length > 0) {
      console.log('First available time:', times[0].label);
      console.log('Last available time:', times[times.length - 1].label);
    }

    setAvailableTimes(times);

    // Set default time to first available time
    if (times.length > 0 && !selectedTime) {
      setSelectedTime(times[0].value);
      updateDropOffSentAt(selectedDate, times[0].value);
    }
  };

  const handleDateChange = (date) => {
    if (!date) return;
    console.log('Date changed to:', moment(date).format('YYYY-MM-DD'));
    setSelectedDate(date);
    setSelectedTime(''); // Reset time when date changes
  };

  const handleTimeChange = (e) => {
    const newTime = e.target.value;
    console.log('Time changed to:', newTime);
    setSelectedTime(newTime);
    updateDropOffSentAt(selectedDate, newTime);
  };

  const updateDropOffSentAt = (date, time) => {
    if (date && time) {
      // Create ISO string without timezone conversion
      const dateStr = moment(date).format('YYYY-MM-DD');
      const timeComponents = time.split(':');
      const hour = parseInt(timeComponents[0], 10);
      const minute = parseInt(timeComponents[1], 10);

      // Create a UTC moment object
      const combinedDateTime = moment.utc().year(moment(date).year())
        .month(moment(date).month())
        .date(moment(date).date())
        .hour(hour)
        .minute(minute)
        .second(0)
        .millisecond(0);

      const isoString = combinedDateTime.toISOString();
      console.log('Setting dropoff time to (ISO):', isoString);
      console.log('Date components:', dateStr, 'Time components:', hour, minute);

      setDropOffSentAt(isoString);
    }
  };

  return (
    <div className={css.pickUpPhotoSection}>
      <div className={css.titleContainer}>
        <div className={css.bookingStatusText} onClick={shouldShowDropOffSection}>
          <span>&#8249; back</span>
        </div>
        <h2 className={css.listingTitle}>
          {isLimitedUser ? 'Dropoff as Admin' : isCustomer ? 'Send drop-off request' : 'Confirm drop-off request'}
        </h2>
      </div>
      {isLimitedUser && (
        <div className={classNames(classes, holderClassName)}>
          <div className={classNames(css.sectionPhotoHolder)}>
            <div className={css.dropoffTimeTickContainer}>
              <img src={!dropOffSentAtByAdmin && !dropoffSentAt ? disabledTick : tick} alt="tick" className={css.tick} />
            </div>

            <div>
              <h2 className={css.photoTitle}>Select drop-off time</h2>
              <p className={css.photoDescription}>Select the date and time when the guest dropped off the car. Please ensure you input the date and time in {timeZone}. Use a convertor if needed to get the right time in this timezone.</p>
              <div className={css.dateTimePicker}>
                <DateInputComponent
                  id="dropoff_date_picker"
                  value={{ date: selectedDate }}
                  onChange={({ date }) => handleDateChange(date)}
                  isOutsideRange={(day) => {
                    if (maxDate) {
                      // Compare in UTC to avoid timezone issues
                      const dayUTC = moment.utc(moment(day).format('YYYY-MM-DD'));
                      const maxDateUTC = moment.utc(maxDate);

                      // Allow selection up to the max date
                      return dayUTC.isAfter(maxDateUTC, 'day')
                    }
                    // Default fallback: prevent selecting today or past dates
                    return isInclusivelyAfterDay(day, moment().add(1, 'day'));
                  }}
                  placeholderText="Select date"
                  showClearDate={false}
                  isDropoffTime={true}
                />

                <div className={css.timeContainer}>
                  <select
                    value={selectedTime}
                    onChange={handleTimeChange}
                    disabled={!isDropOff || !selectedDate}
                    className={css.timeInput}
                    style={{
                      width: '110px',
                      background: "transparent"
                    }}
                  >
                    <option value="" disabled>
                      Select time
                    </option>
                    {availableTimes.map(time => (
                      <option
                        key={time.value}
                        value={time.value}
                      >
                        {time.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {photoSectionList.map(i => {
        return (
          <SectionCarPhotos
            id={i.id}
            key={i.id}
            setActivePhotoSection={setActivePhotoSection}
            holderClassName={
              activePhotoSection[i.id] === true ? css.activeSection : css.inactiveSection
            }
            activeSection={activePhotoSection[i.id] === true}
            intl={intl}
            onManageDisableScrolling={onManageDisableScrolling}
            isCustomer={isCustomer && !isLimitedUser}
            isProvider={isProvider}
            currentUser={currentUser}
            isPickUp={false}
            setTripPhotos={data => setTripPhotos({ [i.data]: [...data] })}
            tripPhotos={state[i.data]}
            existedPhotos={filterTripPhotos}
            inputOdometerInvalid={inputOdometerInvalid}
            title={i.title}
            description={i.description}
            type={i.data}
            inboxLabel={i.inboxLabel}
            inboxPlaceholder={i.inboxPlaceholder}
            onNoteChange={onNoteChange}
            removePhoto={removePhoto}
            isShowUpload={isShowUpload}
            pickUpOdometer={pickUpOdometer}
            isAdminAction={isAdminAction}
            customDropOffReadingByAdmin={customDropOffReadingByAdmin}
          />
        );
      })}

      {isProvider && (
        <div className={css.uploadedTitleContainer}>
          <p className={css.uploadedTitle}>UPLOADED BY THE GUEST</p>
        </div>
      )}

      {isProvider &&
        photoSectionListCustomer.map(i => {
          return (
            <SectionCarPhotosOnlyView
              key={i.id}
              holderClassName={css.activeSection}
              intl={intl}
              onManageDisableScrolling={onManageDisableScrolling}
              isCustomer={isCustomer}
              isProvider={isProvider}
              currentUser={currentUser}
              isPickUp={isPickUp}
              setTripPhotos={data => setTripPhotos({ [i.data]: [...data] })}
              tripPhotos={state[i.data]}
              existedPhotos={filterTripPhotos}
              inputOdometerInvalid={inputOdometerInvalid}
              title={i.title}
              description={i.description}
              type={i.data}
              onNoteChange={onNoteChange}
            />
          );
        })}
    </div>
  );
};

export default DropOffSection;
