import React, { memo } from 'react';
import Slider from 'react-slick';
import { ListingCard } from '../../components';
import classNames from 'classnames';
import css from './YouMayAlsoLikeCarousel.css';
import { array, string } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import isEqual from 'lodash/isEqual';

const SMALL_MOBILE_SCREEN_WIDTH = 550;
const MEDIUM_SCREEN_WIDTH = 768;
const LARGE_SCREEN_WIDTH = 1025;

const YouMayAlsoLikeCarouselComponent = memo(
  props => {
    const {
      listings,
      className,
      rootClassName,
      intl,
      viewport,
      currentListingId,
      similarListing,
    } = props;

    const convertToListingFormat = (input) => {
      const imageId = input.listingUrl.split('/').pop();
      const imageUrlId = input.imageUrl;
  
      // Check if imageUrl is valid or not
      const images = imageUrlId
          ? [
              {
                  id: {
                      _sdkType: "UUID",
                      uuid: imageId
                  },
                  type: "image",
                  attributes: {
                      variants: {
                          "landscape-crop": {
                              height: 267,
                              width: 400,
                              url: `${imageUrlId}`,
                              name: "landscape-crop"
                          },
                          "landscape-crop2x": {
                              height: 533,
                              width: 800,
                              url: `${imageUrlId}`,
                              name: "landscape-crop2x"
                          }
                      }
                  }
              }
          ]
          : [];  // Assign null if no image URL
  
      return {
          id: { uuid: imageId },
          attributes: {
              title: input.title,
              price: {
                  currency: 'AUD',
                  value: input.price,
              },
              publicData: {
                  instantBooking: true,
                  numberTripDone: 27,
                  isSuperHost: false,
              },
              metadata: {
                  reviews: {
                      averageRating: input.rating,
                  },
                  isNewCar: false,
                  isDisinfected: false,
                  isDrivelahGo: false,
              },
              images: images  // Null if imageUrl is missing
          },
      };
  };
  
    const formattedListings = similarListing.map(convertToListingFormat);

    if (formattedListings.length === 1 && formattedListings[0].id.uuid === currentListingId)
      return null;

    const sortedListings = formattedListings.sort((a, b) => {
      if (!a.attributes.publicData.instantBooking && !b.attributes.publicData.instantBooking)
        return 1;
      return -1;
    });

    const isMobileLayout = viewport.width <= MEDIUM_SCREEN_WIDTH;

    const getSlidesToShow = () => {
      if (viewport.width <= SMALL_MOBILE_SCREEN_WIDTH) return 1.25;
      if (viewport.width <= MEDIUM_SCREEN_WIDTH) return 2;
      return 3;
    };

    const getMaximumCharsPerLine = () => {
      if (viewport.width <= SMALL_MOBILE_SCREEN_WIDTH) {
        return 10;
      }
      if (viewport.width >= MEDIUM_SCREEN_WIDTH && viewport.width <= LARGE_SCREEN_WIDTH) {
        return 16;
      }
      return 13;
    };

    const title = intl.formatMessage({ id: 'YouMayAlsoLikeCarousel.title' });

    const prevArrow = (
      <img
        width="30px"
        height="100px"
        style="background-color: black"
        src='data:image/svg+xml;utf8,<svg width="7" height="10" viewBox="0 0 7 10" xmlns="http://www.w3.org/2000/svg"><path d="M5.667 1c.094 0 .188.04.254.118.12.14.102.35-.038.47L1.85 5l4.032 3.412c.14.12.158.33.04.47-.12.14-.33.158-.47.04L1.117 5.254C1.043 5.19 1 5.098 1 5s.043-.19.118-.255L5.45 1.08c.064-.054.14-.08.217-.08" stroke="%23FFF" fill="%23FFF" fill-rule="evenodd"/></svg>'
      />
    );
    const nextArrow = (
      <img
        width="30px"
        height="100px"
        style="background-color: black"
        src='data:image/svg+xml;utf8,<svg width="7" height="10" viewBox="0 0 7 10" xmlns="http://www.w3.org/2000/svg"><path d="M1.333 9c-.094 0-.188-.04-.254-.118-.12-.14-.102-.35.038-.47L5.15 5 1.118 1.588c-.14-.12-.158-.33-.04-.47.12-.14.33-.158.47-.04l4.334 3.667C5.957 4.81 6 4.902 6 5s-.043.19-.118.255L1.55 8.92c-.064.054-.14.08-.217.08" stroke="%23FFF" fill="%23FFF" fill-rule="evenodd"/></svg>'
      />
    );

    const settings = {
      infinite: false,
      speed: 500,
      dots: false,
      slidesToShow: getSlidesToShow(),
      slidesToScroll: 1,
      arrows: viewport.width > LARGE_SCREEN_WIDTH,
      nextArrow,
      prevArrow,
      // centerMode: viewport.width < MEDIUM_SCREEN_WIDTH
    };

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <div className={css.title}>{title}</div>
        <Slider {...settings} className={css.slider}>
          {sortedListings.map(listing => {
            return (
              <div key={listing.id.uuid}>
                <ListingCard
                  listing={listing}
                  className={css.listingCard}
                  customNameLogoClassName={css.nameLogo}
                  customOwnerUsernameClassName={css.ownerUsername}
                  shouldClipTitle
                  maximumCharsPerLine={getMaximumCharsPerLine()}
                />
              </div>
            );
          })}
        </Slider>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return isEqual(prevProps.similarListing, nextProps.similarListing);
    }
);

YouMayAlsoLikeCarouselComponent.defaultProps = {
  allListings: [],
  currentListingId: null,
  className: null,
  rootClassName: null,
  intl: null,
};

YouMayAlsoLikeCarouselComponent.propTypes = {
  intl: intlShape.isRequired,
  allListings: array.isRequired,
  currentListingId: string.isRequired,
};

export default YouMayAlsoLikeCarouselComponent;
