import React from 'react';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  ExternalLink,
  Footer,
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
} from '../../components';

import css from './ContactUsPage.css';
import classNames from 'classnames';
import imageSource from '../../util/imageSource';

const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;
const DOMAIN_HELP_URL = process.env.REACT_APP_DOMAIN_HELP_URL;
const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook className={css.iconSize} />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter className={css.iconSize} />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram className={css.iconSize} />
    </ExternalLink>
  ) : null;
  return [fbLink, twitterLink, instragramLink].filter(v => v != null);
};

const ContactUsPage = props => {
  const { intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);

  const openChat = () => {
    if (typeof window !== 'undefined' || window) {
      window.Intercom('show');
    }
  };

  // prettier-ignore
  return (
    <StaticPage
      title="Contact Us | Drive mate"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'About Drivemate',
        name: 'Contact Us',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <img src={imageSource.contact.heroImage} className={css.heroImage} />
          <img src={imageSource.contact.heroImageMobile1x} srcSet={`${imageSource.contact.heroImageMobile1x} 1x, ${imageSource.contact.heroImageMobile2x} 2x`} className={css.heroImageMobile} />
          <div className={css.contentWrapper}>
            <div className={css.title}>
              <FormattedMessage id="ContactUsPage.heading" values={{ newline: (<span className={css.mobileNewline}><br /></span>) }} />
            </div>
            <div className={css.infoContactWrapper}>
              <div className={css.buttonsWrapper}>
                <a href="#" onClick={openChat} className={classNames(css.buttonLink, css.chatWithUs)}>
                  <FormattedMessage id="ContactUsPage.chatWithUs" />
                </a>
                <a href="tel:+61341602205" className={css.buttonLink}>
                  <FormattedMessage id="ContactUsPage.phone" />
                </a>
                <a href={`mailto:${SUPPORT_EMAIL}`} className={css.buttonLink}>
                  <FormattedMessage id="ContactUsPage.email" />
                </a>
                <a href="tel:+61341602205" className={css.buttonLink}>
                  <FormattedMessage id="ContactUsPage.roadSideAssistance" />
                </a>
              </div>
              <p className={css.description}>
                <span className={css.descriptionText}>
                  <FormattedMessage id="ContactUsPage.desc" values={{ newline: (<br />), bold: (<a href={`https://help.${DOMAIN_HELP_URL}/kb/en`} className={css.boldText}>Frequently Asked Questions</a>) }} />
                </span>
                {/* <span id="socialList" className={css.someLinks}>{socialMediaLinks}</span> */}
              </p>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default injectIntl(ContactUsPage);
