/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, {  useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser, userDisplayNameAsString } from '../../util/data';
import { NamedLink, NotificationBadge, ExternalLink } from '../../components';

import css from './TopbarMobileMenu.css';
import { pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import {
  EVENT_BROWSE_DASHBOARD,
  EVENT_BROWSE_HOWITWORKS
} from '../../util/gtm/gtmConstants';
import Avatar from '../Avatar/Avatar';
import { initiateListACarEventFromTab } from '../../util/gtm/gtmHelpers';
import { EVENT_LISTACAR_CLICKED_LIST_A_CAR, EVENT_BROWSE_TRUSTANDSAFE } from '../../util/gtm/gtmConstants';
import { createRawPropertiesForGTM } from '../../util/gtm/gtmCreateProperties';
import { createConversionEvents } from '../../util/conversions/conversionsHelpers';
import { EVENT_CONVERSION_STARTED_LISTING } from '../../util/conversions/conversionsConstants';
import arrowDown from '../../assets/searchPage/arrow-down-white.svg';
import { stringify } from '../../util/urlHelpers';

import { useHistory } from 'react-router';

import {createResourceLocatorString} from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

const DOMAIN_HELP_URL = process.env.REACT_APP_DOMAIN_HELP_URL;
const TopbarMobileMenu = props => {

  const history = useHistory();

  const [openDropdowns, setOpenDropdowns] = useState({});

  const toggleDropdown = (dropdownKey) => {
    setOpenDropdowns(prevState => ({
      ...prevState,
      [dropdownKey]: !prevState[dropdownKey],
    }));
  };

  const closeDropdownAndNavigate = (dropdownKey, navigateCallback) => {
    toggleDropdown(dropdownKey);
    navigateCallback();
  };

  const getSearchParams = () => {
      const url = createResourceLocatorString('SearchPage', routeConfiguration(), null, {
        address: 'Sydney',
        bounds: '-33.5781409%2C151.3430209%2C-34.118347%2C150.5209286',
        searchStep: 'location',
        mapSearch: false,
      });
      history.push(url);
  };

  const {
    isAuthenticated,
    currentPage,
    currentUser,
    notificationCount,
    onLogout,
    onClose,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const pushGTMBrowse = (eventGTM, eventAttribute) => {
    let eventCallback;
    if (eventAttribute) {
      eventAttribute.preventDefault();
      const href = eventAttribute.currentTarget.href;
      eventCallback = () => {
        if (typeof window !== 'undefined') {
          window.location.href = href;
        }
      };
    }
    pushGTMBrowseEvent({
      props,
      event: eventGTM,
      eventCallback,
    });
  };

  // const notificationCountBadge =
  //   notificationCount > 0 ? (
  //     <NotificationBadge className={css.notificationBadge} count={notificationCount} />
  //   ) : null;

  // const displayName = user.attributes.profile.firstName;
  // const currentPageClass = page => {
  //   const isAccountSettingsPage =
  //     page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
  //   return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  // };

  // const staticLinks = isAuthenticated ? null : (
  //   <>
  //     <div className={css.linkWrapper} onClick={() => pushGTMBrowse(EVENT_BROWSE_HOWITWORKS)}>
  //       <NamedLink name="HowItWorkPage" className={css.link}>
  //         <FormattedMessage id="TopbarMenu.howItWork" />
  //       </NamedLink>
  //     </div>
  //     <div className={css.linkWrapper} onClick={() => pushGTMBrowse(EVENT_BROWSE_RENTACAR)}>
  //       <NamedLink name="BecomeAGuestPage" className={css.link}>
  //         <FormattedMessage id="TopbarMenu.rentingACar" />
  //       </NamedLink>
  //     </div>
  //     <div className={css.linkWrapper} onClick={() => pushGTMBrowse(EVENT_BROWSE_SHAREACAR)}>
  //       <NamedLink name="BecomeAHostPage" className={css.link}>
  //         <FormattedMessage id="TopbarMenu.sharingYourCar" />
  //       </NamedLink>
  //     </div>
  //     {/* <div className={css.linkWrapper} onClick={() => null}>
  //       <NamedLink name="CovidLandingPage" className={css.link}>
  //         <FormattedMessage id="TopbarDesktop.linkCovidProgram" />
  //       </NamedLink>
  //     </div> */}
  //   </>
  // );

  const authenticatedLinks = isAuthenticated ? (
    <>
      <div className={css.linkWrapper}>
        <NamedLink
          name="SearchPage"
          onClick={(e) => {
            e.preventDefault(); 
            getSearchParams().then(searchParams => {
              const searchQuery = stringify(searchParams);
              const fullUrl = `${window.location.origin}/s?${searchQuery}`;
              window.location.href = fullUrl;
            });
          }}
          className={css.link}
        >
          <FormattedMessage id="TopbarDesktop.rentACar" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper}>
        <NamedLink className={css.link} name="NewListingPage"
                   onClick={() => {
                     initiateListACarEventFromTab({
                       props,
                       eventButtonParam: [EVENT_LISTACAR_CLICKED_LIST_A_CAR],
                     });
                     const properties = createRawPropertiesForGTM({
                       props,
                     });
                     createConversionEvents(properties, EVENT_CONVERSION_STARTED_LISTING, 'click');
                   }}>
          <FormattedMessage id="TopbarDesktop.hostCar" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper}>
        <div className={css.dropdown}>
        <span
          className={css.dropdownLink}
          onClick={() => toggleDropdown('dashboard')}
        >
          <FormattedMessage id="TopbarMenu.dashBoard" />
          <img
            src={arrowDown}
            alt="Expand dropdown"
            className={`${css.arrowIcon} ${openDropdowns['dashboard'] ? css.arrowIconOpen : ''}`}
          />
        </span>
          {openDropdowns['dashboard'] && (
            <>
              <div className={css.dropdownContent}>
                <NamedLink
                  className={css.link}
                  name="InboxPage"
                  params={{ tab: 'orders' }}
                  onClick={() => closeDropdownAndNavigate('dashboard', () => pushGTMBrowse(EVENT_BROWSE_DASHBOARD))}
                >
                  <FormattedMessage id="TopbarDesktop.renting" />
                </NamedLink>
              </div>
              <div className={css.dropdownContent}>
                <NamedLink
                  className={css.link}
                  name="InboxPage"
                  params={{ tab: 'sales' }}
                  onClick={() => closeDropdownAndNavigate('dashboard', () => pushGTMBrowse(EVENT_BROWSE_DASHBOARD))}
                >
                  <FormattedMessage id="TopbarDesktop.createListing" />
                </NamedLink>
              </div>
            </>
          )}
        </div>
      </div>
      <div className={css.linkWrapper}>
        <NamedLink name="ManageListingsPage" className={css.link}>
          <FormattedMessage id="TopbarDesktop.yourListingsLink" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper}>
        <NamedLink name="ProfileSettingsPage" className={css.link}>
          <FormattedMessage id="TopbarMenu.profileSettings" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper}>
        <NamedLink name="AccountSettingsPage" className={css.link}>
          <FormattedMessage id="TopbarMenu.accountSettings" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper}>
        <div className={css.dropdown}>
        <span
          className={css.dropdownLink}
          onClick={() => toggleDropdown('about')}>
          <FormattedMessage id="TopbarDesktop.aboutTab" />
          <img
            src={arrowDown}
            alt="Expand dropdown"
            className={`${css.arrowIcon} ${openDropdowns['about'] ? css.arrowIconOpen : ''}`}
          />
        </span>
          {openDropdowns['about'] && (
            <>
              
              <div className={css.dropdownContent}>
              <NamedLink name="HowItWorkPage" className={css.link}>
         <FormattedMessage id="TopbarMenu.howItWork" />
      </NamedLink>
      </div>
      <div className={css.dropdownContent}>
                <NamedLink className={css.link} name="TrustAndSafetyPage"
                           onClick={() => pushGTMBrowse(EVENT_BROWSE_TRUSTANDSAFE)}>
                  <FormattedMessage id="TopbarDesktop.insuranceProtection" />
                </NamedLink>
              </div>
              <div className={css.dropdownContent}>
                <ExternalLink className={css.link} href={`https://help.${DOMAIN_HELP_URL}/`}>
                  <FormattedMessage id="TopbarDesktop.helpCenter" />
                </ExternalLink>
              </div>
              <div className={css.dropdownContent}>
                <ExternalLink className={css.link} href="https://drivemate.au/blog">
                  <FormattedMessage id="Footer.blog" />
                </ExternalLink>
              </div>
              <div className={css.dropdownContent}>
                <NamedLink className={css.link} name="ReferralPage">
                  <FormattedMessage id="TopbarDesktop.referralPageLink" />
                </NamedLink>
              </div>
              <div className={css.dropdownContent}>
                <NamedLink className={css.link} name="ContactUsPage">
                  <FormattedMessage id="Footer.contactUs" />
                </NamedLink>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  ) : null;

  const logout = isAuthenticated ? (
    <div
      onClick={() => {
        onLogout();
        onClose();
      }}
      className={css.logout}
    >
      <FormattedMessage id="TopbarMenu.logout" />
    </div>
  ) : null;

  const signUp = isAuthenticated ? null : (
    <>
      <div className={css.linkWrapper}>
        <NamedLink name="SignupPage" className={classNames(css.link, css.authLink)}>
          <FormattedMessage id="TopbarMenu.signUp" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper}>
        <div className={css.dropdown}>
                <NamedLink name="SearchPage"
                           onClick={(e) => {
                                   e.preventDefault(); 
                                   getSearchParams().then(searchParams => {
                                     const searchQuery = stringify(searchParams);
                                     const fullUrl = `${window.location.origin}/s?${searchQuery}`;
                                     window.location.href = fullUrl;
                                   });
                                 }}
                           className={css.link}>
                  <FormattedMessage id="TopbarDesktop.rentACar" />
                </NamedLink>
            
          
        </div>
      </div>
      <div className={css.linkWrapper}>
        <div className={css.dropdown}>
        <NamedLink 
  className={css.link} 
  name="LoginPage"  
  onClick={isAuthenticated ? () => {
    initiateListACarEventFromTab({
      props,
      eventButtonParam: [EVENT_LISTACAR_CLICKED_LIST_A_CAR]
    });
    const properties = createRawPropertiesForGTM({
      props,
    });
    createConversionEvents(properties, EVENT_CONVERSION_STARTED_LISTING, 'click');
  } : null}  
>
  <FormattedMessage id="TopbarDesktop.hostCar" />
</NamedLink>
            
           
           
          
        </div>
      </div>
      <div className={css.linkWrapper}>
        <div className={css.dropdown}>
        <span
          className={css.dropdownLink}
          onClick={() => toggleDropdown('about')}>
          <FormattedMessage id="TopbarDesktop.aboutTab" />
          <img
            src={arrowDown}
            alt="Expand dropdown"
            className={`${css.arrowIcon} ${openDropdowns['about'] ? css.arrowIconOpen : ''}`}
          />
        </span>
          {openDropdowns['about'] && (
            <>
             <div className={css.dropdownContent}>
                <NamedLink className={css.link} name="HowItWorkPage" onClick={() => pushGTMBrowse(EVENT_BROWSE_HOWITWORKS)}>
                  <FormattedMessage id="TopbarDesktop.linkHowItWorks" />
                </NamedLink>
              </div>
              <div className={css.dropdownContent}>
                <NamedLink className={css.link} name="TrustAndSafetyPage" onClick={() => pushGTMBrowse(EVENT_BROWSE_TRUSTANDSAFE)}>
                  <FormattedMessage id="TopbarDesktop.insuranceProtection" />
                </NamedLink>
              </div>
              <div className={css.dropdownContent}>
                <ExternalLink className={css.link} href={`https://help.${DOMAIN_HELP_URL}/`}>
                  <FormattedMessage id="TopbarDesktop.helpCenter" />
                </ExternalLink>
              </div>
              <div className={css.dropdownContent}>
                <ExternalLink className={css.link} href="https://drivemate.au/blog">
                  <FormattedMessage id="Footer.blog" />
                </ExternalLink>
              </div>
              <div className={css.dropdownContent}>
                <NamedLink className={css.link} name="ContactUsPage">
                  <FormattedMessage id="Footer.contactUs"/>
                </NamedLink>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );

  return (
    <div className={css.root}>
      <div className={css.space} onClick={onClose} />
      <div className={css.content}>
        <div className={css.avatarWrapper}>
          <Avatar user={currentUser} />
          <div className={css.userName}>
            {isAuthenticated ? (
              userDisplayNameAsString(currentUser)
            ) : (
              <NamedLink name="LoginPage" className={classNames(css.link, css.authLink)}>
                <FormattedMessage id="TopbarMenu.login" />
              </NamedLink>
            )}
          </div>
        </div>
        <div className={css.links}>
          {signUp}
          {authenticatedLinks}
          {/* {staticLinks} */}
        </div>
        {logout}
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string, object } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  location: object,
};

export default TopbarMobileMenu;