import React, { memo, useState } from 'react';
import css from './LandingPage.css';
import classNames from 'classnames';
import vulcanPost from '../../assets/landingpage/vulcanPost.png';
import theStraitsTime from '../../assets/landingpage/theStraitsTimes.png';
import zaobao from '../../assets/landingpage/zaobao.png';
import { FormattedMessage } from 'react-intl';

import safeAndPro from '../../assets/landingpage/Benefit_SafeAndPro.png';
import moreChoiceNoHassle from '../../assets/landingpage/Benefit_MoreChoiceNoHassle.png';
import affordable from '../../assets/landingpage/Benefit_Affordable.png';
import alwaysNearby from '../../assets/landingpage/Benefit_AlwaysNearby.png';
import { Button, Modal, NamedLink } from '../../components';
import { sendGAEvent } from '../../util/googleAnalytics';
import { BROWSE_BUTTON_PLAY_VIDEO_ID, EVENT_BROWSE_SAWHTD } from '../../util/gtm/gtmConstants';
import imageSource from '../../util/imageSource';

export const Channels = () => (
  <div className={css.channels}>
    <div className={css.channel}>
      <img className={css.channelImageHorizontal} src={theStraitsTime} alt="THE STRAITS TIMES" />
    </div>
    <div className={css.channel}>
      <img className={css.channelImageVertical} src={imageSource.seoLandingPage.cna} alt="CNA" />
    </div>
    <div className={css.channel}>
      <img className={css.channelImageHorizontal} src={vulcanPost} alt="Vulcan Post" />
    </div>
    <div className={css.channel}>
      <img className={css.channelImageVertical} src={zaobao} alt="ZAOBAO" />
    </div>
  </div>
);

const SectionRealBenefits = memo(({ onManageDisableScrolling, onPushGTMSeeVideoEvent }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenVideo = () => {
    onPushGTMSeeVideoEvent(EVENT_BROWSE_SAWHTD, BROWSE_BUTTON_PLAY_VIDEO_ID);
    sendGAEvent({
      eventCategory: 'Others',
      eventAction: 'See How To Video',
    });
    setIsOpen(true);
  };

  return (
    <div className={css.sectionRealBenefits}>
      <Channels />

      <div className={css.benefitsSection}>
        <div className={classNames(css.sectionOptionTitle, css.sectionBenefitTitle)}>
          <FormattedMessage id="LandingPage.SectionRealBenefits.title" />
        </div>
        <div className={css.benefits}>
          <div className={css.benefit}>
            <div className={css.benefitImgWrapper}>
              <img className={css.benefitImg} src={safeAndPro} />
            </div>
            <div className={css.benefitName}>
              <FormattedMessage id="LandingPage.SectionRealBenefits.safeAndPersonal" />
            </div>
          </div>
          <div className={css.benefit}>
            <div className={css.benefitImgWrapper}>
              <img className={css.benefitImg} src={moreChoiceNoHassle} />
            </div>
            <div className={css.benefitName}>
              <FormattedMessage id="LandingPage.SectionRealBenefits.moreChoiceNoHassle" />
            </div>
          </div>
          <div className={css.benefit}>
            <div className={css.benefitImgWrapper}>
              <img className={css.benefitImg} src={affordable} />
            </div>
            <div className={css.benefitName}>
              <FormattedMessage id="LandingPage.SectionRealBenefits.affordable" />
            </div>
          </div>
          <div className={css.benefit}>
            <div className={css.benefitImgWrapper}>
              <img className={css.benefitImg} src={alwaysNearby} />
            </div>
            <div className={css.benefitName}>
              <FormattedMessage id="LandingPage.SectionRealBenefits.alwaysNearby" />
            </div>
          </div>
        </div>
        <div className={css.buttonWrapper}>
          <Button
            className={css.playVideo}
            onClick={handleOpenVideo}
            id={BROWSE_BUTTON_PLAY_VIDEO_ID}
          >
            Play video
          </Button>
          <NamedLink name="BecomeAGuestPage" className={css.readMore}>
            Read more ›
          </NamedLink>
        </div>
      </div>
      <Modal
        id="videoLandingpage"
        containerClassName={css.modalContainer}
        closeButtonClassName={css.modalCloseButton}
        contentClassName={css.contentModal}
        isOpen={isOpen}
        onClose={() => {
          const videoIframe = document.querySelector('#videoIframe');
          if (videoIframe) {
            const iframeSrc = videoIframe.src;
            videoIframe.src = iframeSrc;
          }
          setIsOpen(false);
        }}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ModalVideo />
      </Modal>
    </div>
  );
});

const ModalVideo = props => {
  return (
    <div className={css.videoContentWrapper}>
      <iframe
        id="videoIframe"
        className={css.iframeVideo}
        allowFullScreen={true}
        src="https://www.youtube.com/embed/OaOzjZiVaws"
      ></iframe>
    </div>
  );
};

export default SectionRealBenefits;
