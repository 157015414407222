import React, { useEffect, useState } from 'react';
import { bool, func, oneOf, shape } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';
import axios from 'axios';
import { createResourceLocatorString } from '../../util/routes';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import downlaodIcon from '../../assets/payouts/downloadIcon.svg'
import {
  getStripeConnectAccountLink,
  stripeAccountClearError,
} from '../../ducks/stripeConnectAccount.duck';
import {
  Button,
  Footer,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  Modal,
  NamedRedirect,
  Page,
  StripeConnectAccountStatusBox,
  UserNav,
} from '../../components';
import { StripeConnectAccountForm } from '../../forms';
import { TopbarContainer } from '..';
import { loadData, savePayoutDetails } from './StripePayoutPage.duck';
import { getConnectCalendarTabStatus } from '../../ducks/TabPanels.duck';
import {labelColor, labelBackgroudColor} from '../PaymentMethodsPage/PaymentMethodsPage'

import css from './StripePayoutPage.css';
import Loader from './Loader';
import { getOpenInvoices } from '../CheckoutPage/CheckoutPage.duck';

const STRIPE_ONBOARDING_RETURN_URL_SUCCESS = 'success';
const STRIPE_ONBOARDING_RETURN_URL_FAILURE = 'failure';
const STRIPE_ONBOARDING_RETURN_URL_TYPES = [
  STRIPE_ONBOARDING_RETURN_URL_SUCCESS,
  STRIPE_ONBOARDING_RETURN_URL_FAILURE,
];

// Create return URL for the Stripe onboarding form
const createReturnURL = (returnURLType, rootURL, routes) => {
  const path = createResourceLocatorString(
    'StripePayoutOnboardingPage',
    routes,
    { returnURLType },
    {}
  );
  const root = rootURL.replace(/\/$/, '');
  return `${root}${path}`;
};

const trimStringToMaxLength = (inputString, maxLength) => {
  if (inputString && inputString.length > maxLength) {
    return inputString.substring(0, maxLength);
  }

  return inputString;
};


// Get attribute: stripeAccountData
const getStripeAccountData = stripeAccount => stripeAccount.attributes.stripeAccountData || null;

// Get last 4 digits of bank account returned in Stripe account
const getBankAccountLast4Digits = stripeAccountData =>
  stripeAccountData && stripeAccountData.external_accounts.data.length > 0
    ? stripeAccountData.external_accounts.data[0].last4
    : null;

// Check if there's requirements on selected type: 'past_due', 'currently_due' etc.
const hasRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.requirements &&
  Array.isArray(stripeAccountData.requirements[requirementType]) &&
  stripeAccountData.requirements[requirementType].length > 0;

// Redirect user to Stripe's hosted Connect account onboarding form
const handleGetStripeConnectAccountLinkFn = (getLinkFn, commonParams) => type => () => {
  getLinkFn({ type, ...commonParams })
    .then(url => {
      window.location.href = url;
    })
    .catch(err => console.error(err));
};

const Capitalize = (str) =>{
  return str.charAt(0).toUpperCase() + str.slice(1);
}



const PayoutCard = ({pd: payoutData }) => {
  return (
    <div className={css.payoutCard}>
    <div className={css.carCard}>
      <div className={css.carDetails}>
        <div className={css.carImage}>
          <img src={payoutData.listing_image} alt="Car" />
        </div>
        <div className={css.carInfo}>
          <div className={css.statusDate}>
            {/* Payment initiation date */}
            <div class={css.paidOn}>{`Initiated On: ${moment.unix(payoutData.paid_on).format('D MMM')}`}</div>
          </div>
          {/* Guest Name */}
          <div className={css.bankName}>{payoutData.guest_name}</div>
          {/* Primary and Secondary Labels / Breadcrumbs  */}
          <div className={css.statusContainer}>
            <span className={css.primaryLabel} style={{ background: labelBackgroudColor[payoutData.label], color: labelColor[payoutData.label] }}>{payoutData.label}</span>
            <span className={css.secondaryLabel} style={{ background: labelBackgroudColor[payoutData.secondary_label], color: labelColor[payoutData.secondary_label] }}>{`${payoutData.secondary_label} payout`}</span>
            {payoutData.isDisputed && (
                <>
                <span className={css.tertiaryLabel} style={{ background: "rgba(0, 163, 173, 0.15)", color: "rgb(0, 163, 173)" }}>
                Disputed/Incidental
              </span>
                </>
              )}
          </div>
          {/* Booking dates */}
          {payoutData.start && payoutData.end && (
          <div className={css.carPlateDates}>
            <div className={css.bookingDates}>
              {`${moment(payoutData.start).format('D MMM')} - ${moment(payoutData.end).format('D MMM')}`}
            </div>
            {/* Car registration plate number */}
            <div className={css.carPlateNumber}>
              {payoutData.license_plate_number}
            </div>
          </div>
          )}
        </div>
      </div>
      {/* Final payout amount */}
      <div className={css.carPrice}>
        {`$${parseFloat(payoutData.amount / 100).toFixed(2)}`}
      </div>
    </div>
  </div>
  );
};


export const StripePayoutPageComponent = props => {
  const {
    currentUser,
    scrollingDisabled,
    getAccountLinkInProgress,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccountFetched,
    stripeAccount,
    onPayoutDetailsFormChange,
    onPayoutDetailsFormSubmit,
    onGetStripeConnectAccountLink,
    payoutDetailsSaveInProgress,
    payoutDetailsSaved,
    params,
    intl,

    connectCalendarTabVisibility,
    fetchCalendarVisibility,
    openInvoices,
    fetchOpenInvoices
  } = props;

  const [postData, setPostData] = useState([])
  const [emptyList, setEmptyList] = useState('');
  const [startingAfter, setStartingAfter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [csvMonthData, setCsvMonthData] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);

  const perPage = 10;

  console.log("start and end dates", startDate, endDate)

  const handleExportClick = async () => {
    try {
      if (selectedMonth && selectedYear) {
        // Calculate the start and end dates for the selected month and year
        const startDate = new Date(selectedYear, selectedMonth - 1, 1);
        const endDate = new Date(selectedYear, selectedMonth, 0);
  
        // Format dates as "DD-MM-YYYY"
        const startDateFormatted = formatDate(startDate);
        const endDateFormatted = formatDate(endDate);
        console.log(startDateFormatted, endDateFormatted)
        setExportLoading(true);
        setCsvMonthData('')
        const response = await axios.post(`${process.env.REACT_APP_API_SERVER_URL}/api/payouts/export`, {
          // Add your request data here
          // For example: { param1: 'value1', param2: 'value2' }
          userId: ensureCurrentUser(currentUser).id.uuid,
          start:startDateFormatted,
          end: endDateFormatted
        });
        // get formatted Data
  
        console.log("Request data", response.data);
        if(response && response.data && response.data.length > 0) {
          const csvDataList = [];
          const apiData = response.data;
          console.log('API DATA', apiData);
          apiData.map(data => {
            if(!data) return;
            let intdata = {
              id: data.id,
              status: Capitalize(data.status),
              initiatedAt: moment.unix(data.created).format(),
              startDate: data.transactionDetails ? data.transactionDetails.startDate : '',
              endDate: data.transactionDetails ? data.transactionDetails.endDate : '',
              amount: parseFloat(data.amount / 100).toFixed(2),
              licensePlateNumber: data.transactionDetails ? data.transactionDetails.licensePlateNumber : '',
              balanceTx: data.balance_transaction,
              txId: data.transactionDetails && data.transactionDetails.txId,
              type: data.transactionDetails && data.transactionDetails.type,
              guestName: data.transactionDetails && data.transactionDetails.guestName,
            }
            csvDataList.push(intdata);
          })
          const headerMapping = {
            id: "Payout Id",
            status: "Payout Status",
            initiatedAt: "Initiated on",
            amount: "Amount",
            licensePlateNumber: "Listing Car Plate Number",
            startDate: "Trip Start Date",
            endDate: "Trip End Date",
            balanceTx: "Stripe Tx Id",
            txId: "Transaction ID",
            type: "Payout Type",
            guestName: "Guest Name"
        };
        
          convertToCSV(csvDataList, headerMapping);
          setExportLoading(false);
          setShowModal(false);
          setCsvMonthData('');
        }
        else {
          setCsvMonthData('No data is there for this month');
          setExportLoading(false);
        }
        // Call your export API with startDateFormatted and endDateFormatted
        // Example: exportData(startDateFormatted, endDateFormatted);
  
      }
    }
    catch (error) {
      console.log("Error: ", error);
      setCsvMonthData('Data import Error');
      setExportLoading(false);
    }
   
    // Implement your export logic here
    // You can use the selectedMonth and selectedYear to fetch data for the selected month and year
  };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      setEmptyList('')
      console.log("Current user", ensureCurrentUser(currentUser))
      const response = await axios.post(`${process.env.REACT_APP_API_SERVER_URL}/api/payouts/v2/list`, {
        // Add your request data here
        // For example: { param1: 'value1', param2: 'value2' }
        userId: ensureCurrentUser(currentUser).id.uuid,
        startingAfter
      });
      console.log("Request data", response.data);
      const data = response.data && response.data.list;

      const postNewData = data && data.map(pd => pd && <PayoutCard pd={pd} />)
      const newData = [...postData, postNewData];
      setPostData(newData)
      if(response.data.hasMore) {
        if(response.data.endingBefore) {
          setStartingAfter(response.data.endingBefore)
        }
        else {
          setStartingAfter(null)
        }
      }
      else {
        setStartingAfter(null)
      }
      setLoading(false);
      if(!newData.length) {
        setEmptyList('There are no payouts to display')
      }

    } catch (error) {
      console.log("Error: ", error);
      setLoading(false);
      setEmptyList('There are no payouts to display')
    }
  };

  const convertToCSV = (jsonData, headerMapping) => {
    if (!jsonData) return;

    const mappedKeys = Object.keys(headerMapping);
    const mappedColumns = Object.values(headerMapping);

    const csvContent = "data:text/csv;charset=utf-8," +
      mappedColumns.join(",") + "\n" +
      jsonData.map(row => mappedKeys.map(key => row[key] || "").join(",")).join("\n");

    console.log("CSV Content:", csvContent);

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `Drivemate Payouts - ${selectedMonth} ${selectedYear}.csv`);
    document.body.appendChild(link);
    link.click();
  };


  const { returnURLType } = params;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  console.log("Current user", ensuredCurrentUser)
  const currentUserLoaded = !!ensuredCurrentUser.id;

  useEffect(() => {
    fetchCalendarVisibility();
    // Generate the list of months from January to December
    const monthOptions = Array.from({ length: 12 }, (_, index) => ({
      value: (index + 1).toString(),
      label: new Date(2020, index, 1).toLocaleString('default', { month: 'long' }),
    }));

    // Generate the list of years from 2020 to the current year
    const currentYear = new Date().getFullYear();
    const yearOptions = Array.from({ length: currentYear - 2019 }, (_, index) => ({
      value: (2020 + index).toString(),
      label: (2020 + index).toString(),
    }));

    setMonths(monthOptions);
    setYears(yearOptions);
  }, [])

  useEffect(() => {
    fetchData();
    fetchOpenInvoices();
  }, [currentUserLoaded])

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const newOffset = selectedPage * perPage;
  };

  const hasUnpaidInvoices = openInvoices?.totalUnpaidDues > 0;
  let tabs = [...config.custom.tabs];
  tabs = tabs.map(tab => ({ ...tab })).filter((tab) => {
    if (tab.linkProps.name === 'UnpaidInvoices' && !hasUnpaidInvoices) {
      return false;
    }
    if (tab.linkProps.name === "SyncCalenderPage" ){
      return Boolean(connectCalendarTabVisibility);
    }
    return true;
  })
  const currentTab = tabs.find((tab) => tab.linkProps.name === "StripePayoutPage")
    if (currentTab){
      currentTab.selected = true;
  }


  const stripeConnected = currentUserLoaded && !!stripeAccount && !!stripeAccount.id;

  const title = intl.formatMessage({ id: 'StripePayoutPage.title' });

  const formDisabled = getAccountLinkInProgress;

  const rootURL = config.canonicalRootURL;
  const routes = routeConfiguration();
  const successURL = createReturnURL(STRIPE_ONBOARDING_RETURN_URL_SUCCESS, rootURL, routes);
  const failureURL = createReturnURL(STRIPE_ONBOARDING_RETURN_URL_FAILURE, rootURL, routes);

  const accountId = stripeConnected ? stripeAccount.id : null;
  const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;
  const requirementsMissing =
    stripeAccount &&
    (hasRequirements(stripeAccountData, 'past_due') ||
      hasRequirements(stripeAccountData, 'currently_due'));

  const savedCountry = stripeAccountData ? stripeAccountData.country : null;

  const handleGetStripeConnectAccountLink = handleGetStripeConnectAccountLinkFn(
    onGetStripeConnectAccountLink,
    {
      accountId,
      successURL,
      failureURL,
    }
  );

  const returnedNormallyFromStripe = returnURLType === STRIPE_ONBOARDING_RETURN_URL_SUCCESS;
  const showVerificationError = returnURLType === STRIPE_ONBOARDING_RETURN_URL_FAILURE;
  const showVerificationNeeded = stripeConnected && requirementsMissing;

  // Redirect from success URL to basic path for StripePayoutPage
  if (returnedNormallyFromStripe && stripeConnected && !requirementsMissing) {
    return <NamedRedirect name="StripePayoutPage" />;
  }




  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="StripePayoutPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="StripePayoutPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav tabs={tabs} user={ensuredCurrentUser}/>
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="StripePayoutPage.headingTitle" />
            </h1>
            {!currentUserLoaded ? (
              <FormattedMessage id="StripePayoutPage.loadingData" />
            ) : (
              <StripeConnectAccountForm
                disabled={formDisabled}
                inProgress={payoutDetailsSaveInProgress}
                ready={payoutDetailsSaved}
                stripeBankAccountLastDigits={getBankAccountLast4Digits(stripeAccountData)}
                savedCountry={savedCountry}
                submitButtonText={intl.formatMessage({
                  id: 'StripePayoutPage.submitButtonText',
                })}
                stripeAccountError={
                  createStripeAccountError || updateStripeAccountError || fetchStripeAccountError
                }
                stripeAccountFetched={stripeAccountFetched}
                onChange={onPayoutDetailsFormChange}
                onSubmit={onPayoutDetailsFormSubmit}
                onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink}
                stripeConnected={stripeConnected}
              >
                {stripeConnected && (showVerificationError || showVerificationNeeded) ? (
                  <StripeConnectAccountStatusBox
                    type={showVerificationError ? 'verificationError' : 'verificationNeeded'}
                    inProgress={getAccountLinkInProgress}
                    onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                      'custom_account_verification'
                    )}
                  />
                ) : stripeConnected && savedCountry ? (
                  <StripeConnectAccountStatusBox
                    type="verificationSuccess"
                    inProgress={getAccountLinkInProgress}
                    disabled={payoutDetailsSaveInProgress}
                    onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                      'custom_account_update'
                    )}
                  />
                ) : null}
              </StripeConnectAccountForm>
            )}
          </div>
            <div className={css.payoutContainer}>
              <div className={css.payoutHeadingContainer}>
                <div className={css.payoutHeading}>
                  <h1 className={css.payoutTitle}>Past Payouts</h1>
                </div>
                <div className={css.downlaodIcon} onClick={() => setShowModal(true)}>
                   <img src={downlaodIcon} alt="" />
                </div>
              </div>
              
              <div className={css.payoutCardContainer}>
                  {!postData && loading && <Loader />  }
                  {emptyList ? <div className={css.emtyList}>{emptyList}</div>: ''}
                  { postData }
                  {postData && loading && <Loader />  }
                  {postData.length && !loading && !emptyList && startingAfter ? <div className={css.loadMore} onClick={() => fetchData()}>{'Load More Results'}</div>: null}
              </div>
          </div>
              
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
      <Modal
        // className={css.modalClass}
        isOpen={showModal}
        onManageDisableScrolling={false}
        closeTextClassName={css.closeTextModal}
        disableCloseBtn={false}
        containerClassName={css.modalContainer}
        forceClose={false}
        onClose={() => {
          setExportLoading(false);
          setCsvMonthData('')
          setShowModal(false);
        }}
      >
        <div>
          <div className={css.modalHeadingTitle}>{'Export Payouts'}</div>
         
          <div className={css.dateSelectors}>
          <div className={css.customDatepicker}>
            <select
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
            >
              <option value="">Select Month</option>
              {months.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className={css.customDatepickerEnd}>
            <select
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
            >
              <option value="">Select Year</option>
              {years.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>

          {csvMonthData ?  <div>{csvMonthData}</div>: ''}
          <div>
            <Button
                inProgress={exportLoading}
                onClick={handleExportClick}
                disabled={!selectedMonth || !selectedYear}
            >
              {'Export Payouts'}
            </Button>
          </div>
        </div>
      </Modal>
    </Page>
  );
};

StripePayoutPageComponent.defaultProps = {
  currentUser: null,
  createStripeAccountError: null,
  updateStripeAccountError: null,
  fetchStripeAccountError: null,
  stripeAccount: null,
  params: {
    returnURLType: null,
  },
};

StripePayoutPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  getAccountLinkInProgress: bool.isRequired,
  payoutDetailsSaveInProgress: bool.isRequired,
  createStripeAccountError: propTypes.error,
  updateStripeAccountError: propTypes.error,
  fetchStripeAccountError: propTypes.error,
  stripeAccount: propTypes.stripeAccount,
  payoutDetailsSaved: bool.isRequired,

  onPayoutDetailsFormChange: func.isRequired,
  onPayoutDetailsFormSubmit: func.isRequired,
  onGetStripeConnectAccountLink: func.isRequired,
  params: shape({
    returnURLType: oneOf(STRIPE_ONBOARDING_RETURN_URL_TYPES),
  }),

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    getAccountLinkInProgress,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccount,
    stripeAccountFetched,
  } = state.stripeConnectAccount;
  const { currentUser } = state.user;
  const { connectCalendarTabVisibility } = state.tabPanels
  const { openInvoices } = state.CheckoutPage;
  const { payoutDetailsSaveInProgress, payoutDetailsSaved } = state.StripePayoutPage;
  return {
    currentUser,
    getAccountLinkInProgress,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccount,
    stripeAccountFetched,
    payoutDetailsSaveInProgress,
    payoutDetailsSaved,
    scrollingDisabled: isScrollingDisabled(state),
    connectCalendarTabVisibility,
    openInvoices
  };
};

const mapDispatchToProps = dispatch => ({
  onPayoutDetailsFormChange: () => dispatch(stripeAccountClearError()),
  onPayoutDetailsFormSubmit: (values, isUpdateCall) =>
    dispatch(savePayoutDetails(values, isUpdateCall)),
  onGetStripeConnectAccountLink: params => dispatch(getStripeConnectAccountLink(params)),
  fetchCalendarVisibility: () => dispatch(getConnectCalendarTabStatus()),
  fetchOpenInvoices: () => dispatch(getOpenInvoices())
});

const StripePayoutPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(StripePayoutPageComponent);

StripePayoutPage.loadData = loadData;

export default StripePayoutPage;