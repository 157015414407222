import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser, ensureHostVerified } from '../../util/data';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { getOpenInvoices } from '../../containers/CheckoutPage/CheckoutPage.duck';
import {
  Footer,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  Page,
  UserNav,
} from '../../components';
import { TopbarContainer } from '../../containers';
import UnpaidInvoices from '../../components/UnpaidInvoices/UnpaidInvoices';
import config from '../../config';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import css from './UnpaidInvoicesPage.css';
import { getConnectCalendarTabStatus } from '../../ducks/TabPanels.duck';

export const UnpaidInvoicesPageComponent = props => {
  const {
    currentUser,
    scrollingDisabled,
    intl,
    openInvoices,
    onFetchOpenInvoices,
    connectCalendarTabVisibility,
    fetchCalendarVisibility
  } = props;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      await Promise.all([
        fetchCalendarVisibility(),
        onFetchOpenInvoices()
      ]);
      setIsLoading(false);
    };

    loadData();
  }, []);

  let tabs = [...config.custom.tabs];
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  tabs = tabs.map(tab => ({ ...tab })).filter((tab) => {
    if (!(ensuredCurrentUser && ensureHostVerified(ensuredCurrentUser))) {
      if (tab.linkProps.name === "MonthlyStatementPage") {
        return false;
      }
    }
    if (tab.linkProps.name === "SyncCalenderPage") {
      return Boolean(connectCalendarTabVisibility);
    }
    return true;
  });
  const currentTab = tabs.find((tab) => tab.linkProps.name === "UnpaidInvoices");
  if (currentTab) {
    currentTab.selected = true;
  }

//   const title = intl.formatMessage({ id: 'UnpaidInvoicesPage.title' });

  return (
    <Page  scrollingDisabled={scrollingDisabled} className={css.root}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="UnpaidInvoicesPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="UnpaidInvoicesPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav tabs={tabs} user={ensuredCurrentUser} />
        <LayoutWrapperMain>
        {isLoading ? (
            <div className={css.loading}>Loading...</div>
          ) : (
            <UnpaidInvoices
              openInvoices={openInvoices}
              onRefreshInvoices={onFetchOpenInvoices}
              showPayButton={true}
            />
          )}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

UnpaidInvoicesPageComponent.defaultProps = {
  currentUser: null,
  openInvoices: [],
};

const { bool, array, func } = PropTypes;

UnpaidInvoicesPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  openInvoices: array,
  onFetchOpenInvoices: func.isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {openInvoices} = state.CheckoutPage;
  const { connectCalendarTabVisibility } = state.tabPanels;
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    openInvoices: openInvoices?.invoices || [],
    connectCalendarTabVisibility,
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchOpenInvoices: () => dispatch(getOpenInvoices()),
  fetchCalendarVisibility: () => dispatch(getConnectCalendarTabStatus()),
});

const UnpaidInvoicesPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(UnpaidInvoicesPageComponent);

UnpaidInvoicesPage.loadData = () => {
  return async dispatch => {
    await Promise.all([
      dispatch(fetchCurrentUser()),
      dispatch(getOpenInvoices())
    ]);
  };
};

export default UnpaidInvoicesPage;