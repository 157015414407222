import axios from 'axios';
import config from '../config';

const url = config.apiUrl;

export const getRecommendedListings =  async (suburb ,priceRange ) => {
  try {
    const suburbValue = suburb.split(" ")[0]
    const res =  await axios.get(`${url}/api/listings/getMoEngageApi?noOfListingsToFetch=3&suburb=${suburbValue}&priceRange=${priceRange}`);
    return res
  } catch (error) {
    console.log(error);
  }
};