import React from 'react';
import {
  LINE_ITEM_INITIAL_PAYMENT,
  LINE_ITEM_INITIAL_PAYMENT_PROVIDER,
  LINE_ITEM_PROVIDER_COMMISSION,
  LINE_ITEM_UNITS,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import css from './ExtraDurationCharges.css';
import { FormattedMessage } from 'react-intl';

const { Money } = sdkTypes;

const ExtraDurationCharges = ({ transaction, isPriceDecreasedForModification, intl }) => {
  if (!transaction) return null;

  const initialPaymentLineItem =
    transaction &&
    transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_INITIAL_PAYMENT && !item.reversal
    );

  const modifyTxTotal = transaction.attributes.lineItems.reduce((acc, curr) => {
    const noIcludedLineItems = [
      LINE_ITEM_UNITS,
      LINE_ITEM_INITIAL_PAYMENT,
      LINE_ITEM_INITIAL_PAYMENT_PROVIDER,
      LINE_ITEM_PROVIDER_COMMISSION,
    ];

    if (noIcludedLineItems.indexOf(curr.code) === -1) {
      acc += curr.lineTotal.amount;
    }

    return acc;
  }, 0);

  const payinTotal = transaction.attributes.payinTotal;

  const rawBalance = payinTotal.amount - Math.abs(initialPaymentLineItem.lineTotal.amount);
  const totalPrice = isPriceDecreasedForModification ? Math.max(0, rawBalance) : rawBalance;

  const formattedTotalPrice = formatMoney(intl, new Money(totalPrice, payinTotal.currency), 2);

  const modifiedTotal = new Money(modifyTxTotal, 'AUD');

  const modifiedFormattedTotal = formatMoney(intl, modifiedTotal, 2);

  const initialFormattedTotal = formatMoney(intl, initialPaymentLineItem.lineTotal, 2);

  const initialTotalToPositiveAmount = initialFormattedTotal
    ? initialFormattedTotal.replace('-', '')
    : '';

  return (
    <div className={css.extraDurationChargesRoot}>
      <div>
        <div>
          <div className={css.extraDurationTitle}>Balance due</div>
          {isPriceDecreasedForModification ? (
            <div className={css.extraDurationText}>
              <FormattedMessage
                id="BookingTimeForm.priceDecreasedMessage"
                values={{
                  checkPolicy: (
                    <a
                      href="https://help.drivemate.au/kb/guide/en/drive-mate-trip-extension-policy-XwqgKab0IA/Steps/3997932"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      policy
                    </a>
                  ),
                }}
              />
            </div>
          ) : (
            <div className={css.extraDurationText}>
              Modified price - Original paid ie {modifiedFormattedTotal} -{' '}
              {initialTotalToPositiveAmount}
            </div>
          )}
        </div>
      </div>
      <div className={css.extraDurationValue}>{formattedTotalPrice}</div>
    </div>
  );
};

export default ExtraDurationCharges;
