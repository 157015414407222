import { appPost } from '../../util/api/appApi';

export const updateBookingRequest = ({bookingTxId, updateStart, updateEnd, isDistanceCharge, sdk, onlyUpdateParentTx}) => {
  console.log("isDistanceCharge data in api", isDistanceCharge);
  return appPost('update-booking/v1/request', {
    bookingTxId,
    updateStart: (new Date(updateStart)).getTime(),
    updateEnd: (new Date(updateEnd)).getTime(),
    onlyUpdateParentTx,
    isDistanceCharge: isDistanceCharge,
  }, sdk);
};

export const updateSpeculativeRequest = (bookingTxId, updateStart, updateEnd, isDistanceCharge, sdk) => {
  return appPost('update-booking/v1/speculative', {
    bookingTxId,
    updateStart: (new Date(updateStart)).getTime(),
    updateEnd: (new Date(updateEnd)).getTime(),
    isDistanceCharge: isDistanceCharge,
  }, sdk);
};

export const cancelUpdateBookingsRequest = (bookingTxId, sdk) => {
  return appPost('update-booking/cancel', {
    bookingTxId,
  }, sdk);
};

export const acceptUpdateBookingsRequest = (bookingTxId, sdk) => {
  return appPost('update-booking/accept', {
    bookingTxId,
  }, sdk);
};

export const cancelAllUpdateBookingsRequest = (bookingTxId, sdk) => {
  return appPost('update-booking/cancel-all', {
    bookingTxId,
  }, sdk);
};
