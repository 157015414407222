import React from 'react';
import css from './GrabBanner.css';
import deliveryCarImage from '../../assets/grab-banner/delivery-car-image.png';
import { NamedLink } from '../../components';
import Slider from 'react-slick';
import classNames from 'classnames';
import imageSource from '../../util/imageSource';

const GrabBannerDesktop = ({ isMobileLayout, currentUser }) => {
  const sliderConfig = {
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    initialSlide: 0,
    dots: true,
    autoplay: true,
    arrows: false,
    draggable: false,
    fade: true,
    autoplaySpeed: 5000
  };

  const bannerContent = [
    {
      id: 1,
      slideImage: imageSource.grabBanner1.donationBG,
      slideTitle: 'Try Home Delivery by Drive mate',
      slideDescription:
        'Get your favourite rental car delivered and picked up from your doorstep. Free for first 100 customers.',
      slideDescriptionMobile: 'Get your favourite rental car delivered and picked up from your doorstep. Free for first 100 customers.',
      slideIcon: deliveryCarImage,
      slideButtonText: 'Browse home delivery cars!',
      slideThemeClass: css.colorDark,
      arrowIcon: imageSource.grabBanner1.arrowBlue,
    },
    {
      id: 2,
      slideImage: imageSource.redirectBanner.dLbgLogo,
      slideTitle: 'Drive our cars and earn with Grab!',
      slideDescription:
        'Find and rent affordable cars on Drive mate that are Grab ready. Find your perfect car and start earning today.',
      slideDescriptionMobile: 'Find and rent affordable cars on Drive mate that are Grab ready.',
      slideIcon: imageSource.grabBanner1.grabIcon,
      slideButtonText: 'Browse cars',
      slideThemeClass: css.colorLight,
      arrowIcon: imageSource.grabBanner1.arrowYellow,
    },
    // {
    //   id: 2,
    //   slideImage: vaccineBg,
    //   slideTitle: 'Vaccinated? Get free credits worth $10',
    //   slideDescription:
    //     'Do your part for the & get vaccinated. Then submit your vaccination certificate & get Drive mate credits.',
    //   slideDescriptionMobile: 'Submit your vaccination certificate & get Drive mate credits',
    //   slideIcon: vaccineLogo,
    //   slideButtonText: 'Get Credits',
    //   slideThemeClass: css.colorDark,
    //   arrowIcon: arrowBlue,
    // },
  ];

  return (
    <div className={css.grabBannerContainer}>
      <Slider {...sliderConfig}>
        {bannerContent &&
          bannerContent.map(l => (
            <div key={l.id} className={css.grabBannerContentContent}>
              <div
                className={classNames(css.grabBannerContent, l.slideThemeClass)}
                style={{ backgroundImage: `url(${l.slideImage})` }}
              >
                <div className={css.contentRow}>
                  <div className={css.contentLeft}>
                    <div className={css.contentTitle}>{l.slideTitle}</div>
                    <p>{isMobileLayout ? l.slideDescriptionMobile : l.slideDescription}</p>
                    {l.id === 1 ? (
                      <NamedLink
                        name="SearchPage"
                        to={{
                          search:
                            '?address=sydney&bounds=33.41966205794024%2C151.750246034722%2C-34.31797734205976%2C150.66834496527798&pub_isPHV=true',
                        }}
                        className={css.btWrap}
                      >
                        {l.slideButtonText}
                        <span className={css.arrowIconOuter}>
                          <img src={l.arrowIcon} alt="" />
                        </span>
                      </NamedLink>
                    ) : (
                      <NamedLink
                        name="SearchPage"
                        to={{
                          search:
                            '?address=sydney&bounds=33.41966205794024%2C151.750246034722%2C-34.31797734205976%2C150.66834496527798&pub_isPHV=true&sort=-price&pub_delivery=true',
                        }}
                        className={css.btWrap}
                      >
                        {l.slideButtonText}
                        <span className={css.arrowIconOuter}>
                          <img src={l.arrowIcon} alt="" />
                        </span>
                      </NamedLink>
                    )}
                  </div>
                  <div className={css.contentRight}>
                    <span className={classNames(css.grabIcon, l.id === 1 ? css.adjustHeight : '')}>
                      <img src={l.slideIcon} alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default GrabBannerDesktop;
