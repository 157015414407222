import React from 'react';
import css from './GrabHostYourCarLandingPage.css';
// import classNames from 'classnames';
// import { FormattedMessage } from 'react-intl';
import imageSource from '../../util/imageSource';

const GrabIntroBanner = ({ isMobileLayout, handleSignupRedirect }, ...props) => {
  return (
    <div className={css.bannerIntroSection}>
      <div className={css.container}>
        <div className={css.row}>
          <div className={css.column6}>
            <div className={css.bannerIntroContent}>
              <div className={css.bannerLogoBlock}>
                {!isMobileLayout && <img src={imageSource.grabBanner.bannerLogo} alt="" />}
              </div>
              <h1>Is your car sitting idle?</h1>
              <p>Rent out your vehicle, and make some extra money, only with Drive mate</p>
              <ul className={css.bannerListing}>
                <li>
                  <span className={css.pointIcons}>
                    {isMobileLayout ? (
                      <img src={imageSource.grabBanner.approveBlueIconMobile} alt="" />
                    ) : (
                      <img src={imageSource.grabBanner.approveBlueIcon} alt="" />
                    )}
                  </span>
                  Approved by{' '}
                  <span className={css.contentIcon}>
                    {isMobileLayout ? (
                      <img src={imageSource.grabBanner.grabLogoWhite} alt="" />
                    ) : (
                      <img src={imageSource.grabBanner.grabLogo} alt="" />
                    )}
                  </span>
                </li>
                <li>
                  <span className={css.pointIcons}>
                    {isMobileLayout ? (
                      <img src={imageSource.grabBanner.saveMoneyIconMobile} alt="" />
                    ) : (
                      <img src={imageSource.grabBanner.saveMoneyIcon} alt="" />
                    )}
                  </span>
                  Earn passive income during your off days
                </li>
                <li>
                  <span className={css.pointIcons}>
                    {isMobileLayout ? (
                      <img src={imageSource.grabBanner.easyAccessIconMobile} alt="" />
                    ) : (
                      <img src={imageSource.grabBanner.easyAccessIcon} alt="" />
                    )}
                  </span>
                  Sign up now to get $100 Drive mate credits
                </li>
              </ul>
              <button onClick={e => handleSignupRedirect(e)} className={css.bannerButton}>
                Start earning now
              </button>
              <div className={css.mobileBannerLogo}>
                {isMobileLayout && <img src={imageSource.grabBanner.bannerLogoMobile} alt="" />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={css.bannerIntroImageSection}>
        <img src={imageSource.grabBanner.bannerBackground} alt="" />
      </div>
    </div>
  );
};

export default GrabIntroBanner;
