import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.css';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const TermsOfServicePageComponent = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <div style={{ color: '#00a3ad' }}>
        <h1>
          <strong>Terms Of Service</strong>
        </h1>
        {/*<p className={css.lastUpdated}>Last updated: 5th February 2019</p> */}
        <div>
          <h1>1. Overview</h1>
        </div>
        <div className={css.padLeft}>
          <p>
            Drive lah Australia Pty Ltd trading as Drive mate, ABN 19 640 421 496, Level 3,
            139 Collins Street, Melbourne VIC 3000 (<strong>Drive mate, we, us,</strong> or <strong>our</strong>) provides an
            online car sharing platform that enables vehicle owners to list their vehicles for
            rental (<strong>Hosts</strong>), and for people to book those vehicles (<strong>Guests</strong>), as well as
            associated services (collectively, the <strong>Services</strong>).
          </p>
          <p>
            The Services are made available on the Drive mate website
            <a href='http://www.drivemate.au'> http://www.drivemate.au</a> and associated mobile app (together, the <strong>Platform</strong>).
            Your use of the Services is subject to these Terms of Service (<strong>Terms</strong>), together
            with our Host policies set out <a href="https://help.drivemate.au/kb/en/drive-mate-2-0-hosting-policies-440265">here</a> and Guest policies <a href="https://help.drivemate.au/kb/en/driving-policies-2-0-440264">here</a> (as updated from time
            to time).
          </p>
        </div>

        <div>
          <h2>Structure</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            These Terms are made up of this Overview section, and three Parts. Where you
            are approved and registered to use the Services as a Host and/or a Guest, certain
            additional terms will apply. The Terms will apply as follows:
          </p>
          <ul>
            <li>
              <strong>Part A, Guest Terms:</strong> applies if you use the Services as a Guest.
            </li>
            <li>
              <strong>Part B, Host Terms:</strong> applies if you use the Services as a Host.
            </li>
            <li>
              <strong>Part C, General Terms:</strong> applies to all users of the Service.
            </li>
          </ul>
        </div>

        <div>
          <h2>Modifications</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            Drive mate may vary these Terms (including the Policies) at any time. If we make
            material changes to these Terms we will post the revised Terms on the Platform
            and update the "last updated" date. We will also provide you with notice of any
            material changes by email, notifications through the Platform, messaging service,
            or any other contact method made available by us and selected by you on or
            before the date they become effective.
          </p>
          <p>
            If you disagree with the revised Terms, you may terminate this agreement
            immediately as provided in these Terms. If you do not terminate your agreement
            before the date the revised Terms become effective, your continued access to or
            use of the Platform will constitute acceptance of the revised Terms. Any existing
            bookings will remain subject to the terms and policies in effect at the date the
            booking was made.
          </p>
        </div>

        <div>
          <h2>Inconsistency</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            If there is any inconsistency between these Terms, the Site Terms, or any Policy,
            these Terms will prevail to the extent of the inconsistency.
          </p>
        </div>

        <div>
          <h2>Last Updated</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            These Terms were last updated on 28 March, 2025.
          </p>
        </div>
<br/>
        <div>
          <h1 style={{fontSize:'30px'}} >Part A: Guest Terms</h1>
        </div>
<br/>
        <div>
          <h1>2. Registering as a Guest</h1>
        </div>

        <div>
          <h2>2.1 Eligibility</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            In order to search for and book Vehicles on the Platform, you must first register as
            a Guest by providing us with certain information. In order to become accepted as
            a Guest, you must meet our Eligibility Criteria set out <a href="#">here</a>. Any use of the
            Services by anyone that does not meet these eligibility requirements is expressly
            prohibited.
          </p>
        </div>

        <div>
          <h2>2.2 Changes to eligibility</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            If any of the details you provide us to assess your eligibility change, or if you have
            any reason to believe you may no longer meet the Eligibility Criteria, you must
            inform us immediately, and Drive mate may limit, suspend or terminate your
            access to the Services in order to ensure the integrity of our Services and protect
            the other users of our Services.
          </p>
        </div>
<br/>
        <div>
          <h1>3. Bookings, fees and cancellations</h1>
        </div>

        <div>
          <h2>3.1 Contract between you and the Host</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            When you receive the booking confirmation, a contract for services (a <strong>Booking</strong>) is
            formed directly between you and the Host. By making a Booking you are agreeing
            to the terms of the contract. The terms of the contract include all terms of the
            Booking, including without limitation our <a href="https://help.drivemate.au/kb/guide/en/drive-mate-cancellation-policy-guests-vlJp9xGuCf/Steps/755741">Cancellation Policy</a> and any other rules,
            standards, policies, or requirements identified in the Listing or during checkout
            that apply to the Booking.
          </p>
        </div>

        <div>
          <h2>3.2 Booking Fees</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            By making a Booking, you are agreeing to pay all charges for your Booking
            including:
          </p>
          <ol type="a">
            <li>the Trip Fees;</li>
            <li>Distance Fees (if any) in accordance with the <a href="https://help.drivemate.au/kb/guide/en/drive-mate-pricing-2-0-policy-j8QfIWlGvc/Steps/3997982">Fee Schedule</a>.</li>
            <li>taxes (such as GST); and</li>
            <li>
              any other fees and charges identified on the Platform at the time you
              make the Booking,
            </li>
          </ol>
          <p>
            (collectively, the <strong>Booking Fees</strong>).
          </p>
        </div>

        <div>
          <h2>3.3 Additional charges</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            By making a booking, you are also agreeing that we will charge the Payment
            Method used to make the Booking in order to collect Damage Claim amounts and
            other administrative fees in accordance with our Fees & Fines Schedule <a href="https://help.drivemate.au/kb/guide/en/drive-mate-2-0-fees-fines-policy-jWu1kBzuiI/Steps/4001725">here</a>.
          </p>
        </div>

        <div>
          <h2>3.4 Cancellations</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            You may cancel a booking at any time prior to the beginning of the Booking
            Period. If you cancel a Booking, any entitlement to a refund, and the amount that
            will be refunded to you will be determined in accordance with our <a href="https://help.drivemate.au/kb/guide/en/drive-mate-cancellation-policy-guests-vlJp9xGuCf/Steps/755741">Cancellation
            Policy</a>.
          </p>
        </div>
        <br/>
        <div>
          <h1>4. Payments</h1>
        </div>

        <div>
          <h2>4.1 Adding a Payment Method</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            When you add a Payment Method to your Account, you will be asked to provide
            billing information such as name, billing address, and financial instrument
            information either to Drive mate or its third-party payment processor(s). You
            authorise Drive mate and its payment processor(s) to collect and store your
            Payment Method information in accordance with Drive mate's Privacy Policy.
          </p>
        </div>

        <div>
          <h2>4.2 Verification of Payment Method</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            When you add or use a new Payment Method, Drive mate may verify the Payment
            Method by:
          </p>
          <ol type="a">
            <li>
              authorising your Payment Method for one or two nominal amounts
              via a payment service provider, and asking you to confirm those amounts; or
            </li>
            <li>requiring you to upload a billing statement</li>
          </ol>
        </div>

        <div>
          <h2>4.3 Retention of Payment Method</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            You may not remove the Payment Method associated with a reservation, during
            the period commencing from the date of your Booking and ending fourteen (14) 
            days following the end of the Booking Period, nor during any additional period of
            time needed to resolve any outstanding Damage Claim associated with such
            Booking.
          </p>
        </div>

        <div>
          <h2>4.4 Authorisation to charge</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            You authorise Drive mate to charge your Payment Method (including charging
            more than one Payment Method), either directly or indirectly, for all fees and
            other amounts due (including any applicable taxes) in connection with your
            Account, including Damage Claim amounts in accordance with these Terms.
          </p>
        </div>

        <div>
          <h2>4.5 Collection of amounts due and payable</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            Drive mate and its service providers will employ all legal methods available to
            collect amounts due, including the engagement of collection agencies or legal
            counsel. Drive mate, or the collection agencies we retain, may also report
            information about your Account to credit bureaus subject ti applicable privacy
            laws. As a result, late payments, missed payments, or other defaults on your
            Account may be reflected in your credit report. In addition to the amount due,
            delinquent accounts or chargebacks will be charged with fees and/or charges that
            are incidental to the collection of delinquent accounts or chargebacks including,
            but not limited to, collection fees, convenience fees, and/or other third party
            charges.
          </p>
        </div>
        <br/>
        <div>
          <h1>5. Guest Responsibilities</h1>
        </div>

        <div>
          <p>5.1  &nbsp;&nbsp; To the maximum extent permitted by law, you are responsible
            and liable for your own acts and omissions and are also responsible for the acts
            and omissions of anyone you invite to join or provide access to the Vehicle. For
            example, this means:
          </p>
          <ol type="a">
            <li>
              you are responsible for leaving the Vehicle (and related personal
              property) in the condition it was in when you collected it;
            </li>
            <li>
              you are responsible for paying all reasonable Damage Claim
              amounts; and
            </li>
            <li>
              you must act with integrity, treat others with respect and comply
              with applicable laws at all times.
            </li>
          </ol>
        </div>

        <div>
          <p>5.2 &nbsp;&nbsp;  You must:</p>
          <ol type="a">
            <li>
              have a current valid license to drive the Vehicle which has been
              submitted to Drive mate for verification and which is:
              <ol type="i">
                <li>
                  issued in an Australian state or territory or an international license
                  (with a valid International Driving Permit or an approved translation into English
                  if the license is not issued in English);
                </li>
                <li>appropriate for the class of the Vehicle; and</li>
                <li>not subject to any restriction or condition;</li>
              </ol>
            </li>
            <li>
              ensure that the Vehicle is used in a proper and skilful manner, and at
              all times in accordance with the Policies and the Terms, and the Vehicle's
              manufacturer requirements, recommendations and instruction manuals;
            </li>
            <li>
              ensure that all applicable rules and regulations, including any road
              traffic codes or rules of conduct in the relevant state jurisdiction are complied
              with;
            </li>
            <li>
              maintain the Vehicle at your own expense in the same condition as
              at the Pick Up Time (fair wear and tear only excepted, in accordance with the
              Policies);
            </li>
            <li>
              keep the Host fully informed of all material matters relating to the
              Vehicle, including complying with any applicable Policies regarding the reporting
              of any Accident, Damage or loss to the Vehicle;
            </li>
            <li>
              comply with the Australian Road Rules and all applicable laws
              while driving the Vehicle;
            </li>
            <li>return the Vehicle by the Drop Off Time for the Booking; and</li>
            <li>
              comply with all Policies regarding the pick-up and drop-off of the
              Vehicle from and to the Host.
            </li>
          </ol>
        </div>

        <div>
          <p>5.3 &nbsp;&nbsp; You must not:</p>
          <ol type="a">
            <li>
              allow the Vehicle to be driven by any person who is not an
              Authorised Driver:
            </li>
            <li>
              drive the Vehicle on:
              <ol type="i">
                <li>an Unsealed Road;</li>
                <li>roads that are prone to flooding or are flooded;</li>
                <li>beaches, sand dunes, streams, rivers, creeks, dams or off-road;</li>
                <li>any road where the police or an authority has issued a warning;</li>
                <li>any road that is closed; or</li>
                <li>any road where it would be unsafe to drive the Vehicle;</li>
              </ol>
            </li>
            <li>
              use the Vehicle in any alpine resort during the gazetted snow season
              or in any other area under conditions where a reasonable person would use snow
              chains;
            </li>
            <li>
              part with control of (including for the purposes of repair or
              maintenance), sell or offer for sale, underlet, sublet or lend the Vehicle or allow
              the creation of any mortgage, charge, lien or register or claim to be entitled to
              register any interest in the Vehicle under the <i>Personal Property Securities Act
              2009</i> (Cth), without the prior written consent of the Host;
            </li>
            <li>
              drive the Vehicle while your driver licence is cancelled or
              suspended, including as a result of an accumulation of demerit points;
            </li>
            <li>
              use the Vehicle:
              <ol type="i">
                <li>
                  for any commercial or non-personal use (including but not limited
                  to using the Vehicle as a private hire vehicle, or carry any passengers for payment
                  of any kind or for rideshare purposes);
                </li>
                <li>when it is damaged or unsafe;</li>
                <li>for any illegal purpose;</li>
                <li>
                  to move dangerous, hazardous, biohazardous, infectious,
                  inflammable goods or substances that pollute or contaminate, in quantities above
                  that used for domestic purposes;
                </li>
                <li>to propel or tow another vehicle or a trailer;</li>
                <li>to carry or transport illegal drugs or substances; or</li>
                <li>
                  in connection with the motor trade for experiments, tests, trials or
                  demonstration purposes;
                </li>
              </ol>
            </li>
            <li>
              use the Vehicle to carry:
              <ol type="i">
                <li>
                  more than the number of passengers for which the Vehicle is
                  licensed; or
                </li>
                <li>
                  any load that exceeds the limits for which the Vehicle was
                  designed, constructed, registered or licensed;
                </li>
              </ol>
            </li>
            <li>make any alterations to the Vehicle;</li>
            <ol type="i">
            <li>
              affix or install any accessories, equipment or devices on to the
              Vehicles without the Host's prior written consent;
            </li>
            <li>
              deliver up the Vehicle at the end of the Booking Period at the
              Designated Location in a tardy or late manner in contravention of the Policies,
              failing which the Policy on late returns shall apply; and
            </li>
            </ol>
            <li>
              drive the Vehicle:
              <ol type="i">
                <li>
                  whilst intoxicated or under the influence of drugs or alcohol or with
                  a blood alcohol content or level of drugs present in blood, urine or oral fluid that
                  exceeds the limit set by law;
                </li>
                <li>recklessly or dangerously; or</li>
                <li>
                  whilst there is Damage to the Vehicle or it is unroadworthy or
                  unsafe;
                </li>
              </ol>
            </li>
            <li>
              damage the Vehicle deliberately or recklessly or allow anyone else
              to do so;
            </li>
            <li>modify the Vehicle in any way; or</li>
            <li>sell, rent, lease or dispose of the Vehicle.</li>
          </ol>
        </div>

        <div>
          <p>5.4 &nbsp;&nbsp; You must, while in possession of the Vehicle:</p>
          <ol type="a">
            <li>
              ensure the Vehicle is locked when not in use or unattended and the
              keys or remote control device must be kept in the Guest's possession, or that of
              any Authorised Driver, at all times and never left in the ignition when the Vehicle
              is unattended; and
            </li>
            <li>
              take reasonable care of the Vehicle by:
              <ol type="i">
                <li>preventing it from being damaged;</li>
                <li>making sure that it is protected from the weather;</li>
                <li>
                  maintaining the engine and brake oils and coolant level and tyre
                  pressures;
                </li>
                <li>using the correct fuel type; and</li>
                <li>making sure it is not overloaded.</li>
              </ol>
            </li>
          </ol>
        </div>

        <div>
          <p>5.5 &nbsp;&nbsp; Unless specifically approved by the Host, you must not:</p>
          <ol type="a">
            <li>
              use the Vehicle for transporting any pets or animals, except
              assistance animals; or
            </li>
            <li>
              smoke in the Vehicle and the Guest must take reasonable steps to
              prevent passengers from doing so.
            </li>
          </ol>
          <p>
            Additional cleaning, disinfection and deodorising charges as set on the Platform
            will apply if this clause 5.5 is breached.
          </p>
        </div>

        <div>
          <p>5.6 &nbsp;&nbsp; The Guest must pay all tolls, speeding and traffic fines and
            infringements as well as any fines or charges imposed for parking or using the
            Vehicle or release of the Vehicle if it has been seized by a regulatory authority.
            [An administrative fee applies if Drive mate is required to nominate the Guest as
            the responsible driver if any toll, fine or infringement is unpaid.]
          </p>
        </div>
        <br/>
        <div>
          <h1>6. Damage to Vehicle</h1>
        </div>

        <div>
          <h2>6.1 Damage Policy</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            Subject to this clause 6 Drive mate's Damage Policy <a href="https://help.drivemate.au/kb/guide/en/drive-mate-damage-policy-C4z6IR7BVe/Steps/3997921">here</a> will apply for the
            duration of your Booking Period, also noting the varied levels of damage excess
            available to Guests per our fee schedule <a href="https://help.drivemate.au/kb/guide/en/drive-mate-pricing-2-0-policy-j8QfIWlGvc/Steps/3997982">here</a>.
          </p>
        </div>

        <div>
          <h2>6.2 Damage Excess</h2>
        </div>
        <div className={css.padLeft}>
          <ol type="a">
            <li>
              Subject to clause 6.3, during your Booking Period you will be
              indemnified for any Damage, theft or Third Party Loss in relation to the Vehicle,
              provided that you must first pay the Damage Excess.
            </li>
            <li>
              Your obligation to pay the Damage Excess under clause 6.4(a) may
              be waived where the other party to the Incident was insured and their insurance
              company accepts liability.
            </li>
            <li>
              Drive mate may charge your Payment Method an amount up to, but
              not exceeding, the Damage Excess:
              <ol type="i">
                <li>
                  for single vehicle Accidents, after Drive mate provided an estimate
                  of the amount to be charged for the Damage to you;
                </li>
                <li>
                  if the Vehicle has been stolen, after the Host and Drive mate have
                  made reasonable enquiries and, in their opinion, it is unlikely the Vehicle will be
                  recovered; and
                </li>
                <li>
                  for Accidents in which there is also Third Party Loss, after the Host
                  and Drive mate have made an estimate of the Guest's total liability. Supporting
                  documents and particulars of the claim for Third Party Loss will be forwarded to
                  the Guest as soon as practicable.
                </li>
              </ol>
            </li>
          </ol>
        </div>

        <div>
          <h2>6.3 Exclusions from Damage Cover</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            You are not eligible for Damage Cover, and will be liable for, any Excluded
            Damage.
          </p>
        </div>

        <div>
          <h2>6.4 Resolving Damage Claims</h2>
        </div>
        <div className={css.padLeft}>
          <p>If a Host provides evidence that you have: and/or</p>
          <ol type="a">
            <li>damaged the complaining Host's Vehicle;</li>
            <li>
              caused loss of booking income for bookings via the Platform or
              other consequential damages which result directly from the Damage caused under
              (a) above,
            </li>
          </ol>
          <p>
            (each of (a) and (b) being a <strong>Damage Claim</strong>),
          </p>
          <p>
            the complaining Host can notify Drive mate and/or seek compensation through the
            Platform. You will be notified of the Damage Claim and given an opportunity to
            respond. If you agree to pay, or if the Damage Claim is escalated to Drive mate
            and Drive mate reasonably determines that the Damage Claim is valid and you are
            responsible for the Damage Claim, Drive mate can collect the amount of the
            Damage Claim from you.
          </p>
        </div>

        <div>
          <h2>6.5 Damage Claim amounts</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            If you are responsible for Damage Claim amounts, under to clause 6.2, Drive mate
            may, on reasonable notice to you, charge the Payment Method used to make the
            Booking or any other Payment Method associated with your Account at the time
            of the Damage Claim or within a reasonable period thereafter in order to collect
            the Damage Claim amounts. To facilitate this charge, when you book a Listing,
            you are agreeing that Drive mate may charge the Payment Method used to book
            the Listing in order to collect Damage Claim amounts.
          </p>
        </div>
        <br/>
        <div>
          <h1>7. Accident and theft reporting</h1>
        </div>

        <div>
          <p>7.1 &nbsp;&nbsp; If you have an Accident or if you believe the Vehicle has stolen
            (each, an <strong>Incident</strong>) during your Booking Period, you must report the Incident to
            Drive mate as soon as practicable but in no case more than 24 hours of it
            occurring.
          </p>
        </div>

        <div>
          <p>7.2 &nbsp;&nbsp; If you believe the Vehicle has been stolen, you must report the
            Incident to the police immediately (and in any event, no longer than 8 hours) after
            becoming aware and also provide all information reasonably requested concerning
            the Incident and deliver a correctly completed Collision or Damage Report Form,
            including any police witness statements or reports, to us within 48 hours after the
            Incident.
          </p>
        </div>

        <div>
          <p>7.3 &nbsp;&nbsp; If you have an Accident where:</p>
          <ol type="a">
            <li>any person is injured;</li>
            <li>
              the other party has failed to stop or leaves the scene of the Accident
              without exchanging names and addresses; or
            </li>
            <li>
              the other party appears to be under the influence of drugs or alcohol,
              the Guest must also report the theft or Accident to the Police.
            </li>
          </ol>
        </div>

        <div>
          <p>7.4 &nbsp;&nbsp; If you have an Accident, you must:</p>
          <ol type="a">
            <li>
              exchange names and addresses, phone numbers and email addresses
              with the other driver;
            </li>
            <li>take a photo of the other person's driver licence;</li>
            <li>take the registration numbers of all vehicles involved;</li>
            <li>
              take as many photos as is reasonable showing:
              <ol type="i">
                <li>
                  the position of the vehicles before they are moved for towing or
                  salvage;
                </li>
                <li>the Damage to the Vehicle and any third party vehicle or property;</li>
                <li>
                  the general area where the Accident occurred, including any road or
                  traffic signs;
                </li>
              </ol>
            </li>
            <li>
              use reasonable endeavours to obtain the names, addresses, phone
              numbers and email addresses of all witnesses;
            </li>
            <li>
              forward all third party correspondence or court documents to the
              Host within 7 days of receipt;
            </li>
            <li>
              co-operate with the Host and Drive mate in the prosecution of any
              legal proceedings that may be instituted or the defence of any legal proceedings 
              which may be instituted against the Guest as a result of an Accident, including
              attending:
            </li>
          <ol type="i">
            <li>the office of the lawyer for the Host or Drive mate; or</li>
            <li>any court hearing; and</li>
          </ol>
          <li>not:</li>
          <ol type="i">
            <li>make any admission of fault;</li>
            <li>promise to pay any claim for Third Party Loss; or</li>
            <li>
              release the other party from any liability to pay for Damage as a
              result of an Accident, theft of attempted theft.
            </li>
          </ol>
          </ol>
        </div>
        <br/>
        <div>
          <h1 style={{fontSize:'30px'}} >Part B: Host Terms</h1>
        </div>
        <br/>
        <div>
          <h1>8. Hosting on Drive mate</h1>
        </div>

        <div>
          <h2>8.1 Contract between you and the Guest</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            When you accept a Booking request, or receive a Booking confirmation through
            the Platform, you are entering into a contract directly with the Guest, and are
            responsible for providing the Vehicle under the terms and at the price specified in
            your Listing. Any terms or conditions that you include in any supplemental
            contract with Guests must:
          </p>
          <ol type="a">
            <li>
              be consistent with these Terms, our Policies, and the information
              provided in your Listing; and
            </li>
            <li>be prominently disclosed in your Listing description.</li>
          </ol>
        </div>

        <div>
          <h2>8.2 Independent relationship</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            Your relationship with Drive mate is that of an independent individual or entity
            and not an employee, agent, joint venturer or partner of Drive mate. Drive mate
            does not direct or control your Vehicle and you understand that you have complete
            discretion whether and when to provide your Vehicle.
          </p>
        </div>
<br/>
        <div>
          <h1>9. Service Fees</h1>
        </div>

        <div>
          <h2>9.1 Service Fees</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            Drive mate charges fees (and applicable Taxes) to Hosts for the right to use the
            Platform ( <strong>Service Fees</strong> ). More information about what Service Fees apply and
            how they are calculated can be found on our Subscription Plans page <a href="https://help.drivemate.au/kb/guide/en/drive-mate-subscription-plans-policy-3BjGsEtOLn/Steps/787286">here</a>. Except
            as otherwise provided on the Platform and to the extent permitted by law, Service
            Fees are non-refundable.
          </p>
        </div>

        <div>
          <h2>9.2 Deduction of Service Fees</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            By accepting a Booking, you are also agreeing to pay applicable fees like the
            Service Fees (and applicable taxes) for that Booking. Drive mate will deduct
            amounts you owe from your payout unless we and you agree to a different
            method.
          </p>
        </div>

        <div>
          <h2>9.3 Changes to Service Fees</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            Drive mate reserves the right to change the Service Fees at any time, and will
            provide Hosts at least 30 days' notice of any fee changes before they become
            effective. Changes to the Service Fees will not affect bookings made prior to the
            effective date of the fee change. If you disagree with a fee change you may
            terminate this agreement at any time pursuant to clause 22.1.
          </p>
        </div>
        <br/>
        <div>
          <h1>10. Host obligations</h1>
        </div>

        <div>
          <h2>10.1 Creating and managing Listing</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            The Platform provides tools that make it easy for you to set up and manage a
            Listing. Your Listing must include complete and accurate information about your
            Listing (including Vehicle details), your price, and any rules or requirements that
            apply to your Guests or Listing. You are responsible for your acts and omissions
            as well as for keeping your Listing information (including availability) and
            content (like photos) up-to-date and accurate at all times, and are required to meet
            the expectations you have set in your Listing. You are responsible for setting your
            price and establishing rules and requirements for your Listing. You may not
            collect any additional fees or charges for your Listing outside of the Platform.
          </p>
        </div>

        <div>
          <h2>10.2 Availability of Vehicle</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            Once you have confirmed a Booking, you must make the Vehicle available or
            deliver the Vehicle as expected by the Guest. If you offer the Guest the option to
            pick up your vehicle at a specified location, you must supply the location of the
            Vehicle accurately to Drive mate and ensure that the Vehicle is available at that
            location at the time agreed in the Booking.
          </p>
        </div>

        <div>
          <h2>10.3 Status of Vehicle</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            You as the Host are solely responsible for ensuring your Vehicle(s) listed on the
            Platform:
          </p>
        </div>

        <div className={css.padLeft}>
          <ol type="a">
            <li>are roadworthy and in a safe and operable condition;</li>
            <li>are legally registered to be driven on public roads;</li>
            <li>are not subject to any applicable safety recalls; and</li>
            <li>
              at all times satisfy our Vehicle Eligibility Requirements <a href="#">here</a> and our
              Vehicle Maintenance Policy <a href="https://help.drivemate.au/kb/guide/en/drive-mate-maintenance-policy-dd1hNBMrJO/Steps/755724">here</a>.
            </li>
          </ol>
        </div>

        <div>
          <h2>10.4 Maintenance</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            You are required to regularly check your Vehicle for any defects in its operations
            or safety. You promise that, at all times, your Vehicle will be in safe and
            roadworthy condition, in good mechanical condition, and in full compliance with
            all applicable inspection and registration requirements, including any required
            safety inspections. You agree to respond to any applicable recall or similar safety
            notices and to complete any recommended action before allowing your Vehicle to
            be booked. In addition, if Drive mate believes that your Vehicle does not conform
            to reasonable standards, Drive mate may notify you and reserves the right to
            remove or decline listing your Vehicle until its concerns have been resolved.
            Drive mate may, but does not commit to, undertake efforts to ensure the safety of
            Vehicles booked through the Services.
          </p>
        </div>

        <div>
          <h2>10.5 Reporting Damage to Vehicle</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            If you believe that a Guest has caused any Damage to your Vehicle, you must
            report that Damage as soon as you become aware of it (and in any event, no more
            than 24 hours after the end of the Booking Period) and to provide reasonable
            cooperation in the investigation of the Damage.
          </p>
        </div>
        <br/>
        <div>
          <h1>11. Representations and warranties</h1>
        </div>
        <div className={css.padLeft}>
          <p>You represent and warrant with respect to each Vehicle you list on the Platform:</p>
          <ol type="a">
            <li>
              you are the registered owner of the Vehicle or have been duly
              authorised by the registered owner of the Vehicle to enter into these Terms;
            </li>
            <li>the Vehicle complies with the applicable Terms and Policies; and</li>
            <li>
              any information or specifications set out on the Platform or provided
              by you to the Guest in relation to the Vehicle is complete, accurate and not
              misleading in any way.
            </li>
          </ol>
        </div>
        <br/>
        <div>
          <h1>12. Vehicle Eligibility</h1>
        </div>
        <div className={css.padLeft}>
          <p>
            Only Vehicles which comply with our <a href="https://help.drivemate.au/kb/guide/en/drive-mate-car-eligibility-policy-6DDZvwnKMv/Steps/761166">Eligibility Policy</a> will be eligible to be
            listed on the Platform. Drive mate reserves the right to decline any Vehicle from
            being listed.
          </p>
        </div>
        <br/>
        <div>
          <h1>13. Host Damage Cover</h1>
        </div>
        <div className={css.padLeft}>
          <p>
            To the extent that a Guest fails to satisfy their primary obligation under the Terms
            to pay you, as a Host, for Damage Claims that they are responsible for, Drive mate
            agrees to guarantee that obligation by paying you the cost of repairing or replacing
            your Vehicle damaged or destroyed subject to all terms, conditions, and
            limitations contained in the Drive mate Protection Policy <a href="https://help.drivemate.au/kb/guide/en/drive-mate-protection-policy-gZrp4S8YGc/Steps/755720">here</a>.
          </p>
        </div>

        <br/>
        <div>
          <h1>14. Enabling Equipment</h1>
        </div>

        <div>
          <h2>14.1 Provision of Enabling Equipment</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            Drive mate may supply or make available to you certain Enabling Equipment,
            which may include equipment for which you have transferred ownership to Drive
            mate. Drive mate will either arrange for installation of the Enabling Equipment in
            your Vehicle(s) or send the Enabling Equipment to you for installation by the you
            or your chosen installer. Any Enabling Equipment supplied or otherwise made
            available by us to you remains our property at all times.
          </p>
        </div>

        <div>
          <h2>14.2 Obligations regarding Enabling Equipment</h2>
        </div>
        <div className={css.padLeft}>
          <ol type="a">
            <li>
              You must not, and must not allow any other person to tamper with,
              interfere with, or remove any part of any Enabling Equipment that is installed in
              your Vehicle.
            </li>
            <li>
              You must:
              <ol type="i">
                <li>
                  immediately inform Drive mate if any part any Enabling
                  Equipment is lost, stolen, damaged, breaks or ceases working properly;
                </li>
                <li>
                  remove and return to Drive mate any Enabling Equipment that is in
                  your possession before selling or transferring your Vehicle to another person, if
                  your agreement with Drive mate is terminated, or otherwise where Drive mate
                  reasonably requests you to return it.
                </li>
              </ol>
            </li>
          </ol>
        </div>
              <br/>
        <div>
          <h1 style={{fontSize:'30px'}} >Part C: General Terms</h1>
        </div>
        <br/>
        <div>
          <h1>15. Account registration</h1>
        </div>

        <div>
          <h2>15.1 Creating an Account</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            In order to apply to use the Services, you must first create an account with us by
            completing the application form on the Platform (<strong>Account</strong>). Drive mate is under
            no obligation to accept an application for registration as an Account holder and
            may remove Accounts at its discretion. You can create an Account by providing
            us your first and last name, email address, mobile phone number, and creating a
            password or connecting through an account with a third-party site or service (such
            as Facebook, Apple or Google).
          </p>
        </div>

        <div>
          <h2>15.2 Accuracy of information</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            You must provide accurate, current, and complete information during the
            registration, booking, and/or listing process. You must keep your Account up to
            date at all times. Drive mate is not responsible for any loss or Damage (including
            misdirected emails) which may arise from your failure to provide current, accurate
            and complete registration details.
          </p>
        </div>

        <div>
          <h2>15.3 Account security</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            You are responsible for the security of your username and password for the
            Platform. Drive mate may assume that anyone using your username and password
            is authorised by you to do so. Unauthorised use of your username and password
            will, under no circumstances, reduce your liability in connection with using the
            Services, including in respect of the warranties you give under these Terms. You
            must notify us immediately on becoming aware of any unauthorised use of your
            username or password or of any other security breach in connection with the
            Services or the Platform.
          </p>
        </div>

        <div>
          <h2>15.4 Verification</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            Where permitted, Drive mate has the right but not the obligation to
            undertake screenings, checks, and engage in processes designed to help verify the
            identities or check the backgrounds of users, including driving history and driver
            licence validity. Drive mate may in its sole discretion use third-party services to
            verify the information you provide to us and to obtain additional related
            information and corrections where applicable, and you hereby authorise Drive
            mate to request, receive, use, and store such information.
          </p>
        </div>

        <div>
          <h2>15.5</h2>
        </div>
        <br/>
        <div>
          <h1>16. Content</h1>
        </div>

        <div>
          <h2>16.1 Uploading Content</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            Parts of the Platform enable you to provide feedback, text, photos, audio, video,
            information and other content (<strong>Content</strong>). By providing Content, in whatever form
            and through whatever means, you grant Drive mate a non-exclusive, worldwide,
            royalty-free, perpetual, sub-licensable and transferable license, to access, use,
            store, copy, modify, prepare derivative works of, distribute, publish, transmit,
            stream, broadcast, and otherwise exploit in any manner such Content to provide
            and/or promote the Platform, in any media or platform, known or unknown to date
            and in particular on the internet and social networks. If Content includes Personal
            Information, such Content will only be used for these purposes if such use
            complies with applicable data protection laws in accordance with our Privacy
            Policy.
          </p>
        </div>

        <div>
          <h2>16.2 Content facilitated by us</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            Where Drive mate pays for the creation of Content or facilitates its creation, Drive
            mate may own that Content, in which case supplemental terms or disclosures will
            say that.
          </p>
        </div>

        <div>
          <h2>16.3 Your responsibility for Content</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            You are solely responsible for all Content that you provide and warrant that you
            either own it or are authorised to grant to Drive mate the rights described in these
            Terms. You are responsible and liable if any of your Content violates or infringes
            the intellectual property or privacy rights of any third party.
          </p>
        </div>

        <div>
          <h2>16.4 Indemnity</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            You agree to defend, indemnify, and hold Drive mate and its subsidiaries, officers,
            directors, employees, and agents, harmless from and against any third-party
            claims, liabilities, damages, losses, and expenses, including, without limitation,
            reasonable legal and accounting fees, arising out of or in any way connected with
            Content uploaded by you.
          </p>
        </div>
        <br/>
        <div>
          <h1>17. Obligations of Account Holders</h1>
        </div>

        <div>
          <p>17.1 &nbsp;&nbsp; In connection with your use of or access to the Services, you agree
            that you will not, nor advocate, encourage, request, or assist any third party to:
          </p>
          <ol type="a">
            <li>violate any applicable law; or</li>
            <li>dilute, tarnish, or otherwise harm the Drive mate brand in any way.</li>
          </ol>
        </div>

        <div>
          <p>17.2 &nbsp;&nbsp; You agree that you will at all times comply with the Policies. The
            Policies may be amended from time to time and it is your responsibility to monitor
            the Platform for updates to the Policies. A breach of any term of a Policy will be
            deemed a breach of these Terms.
          </p>
        </div>
<br/>
        <div>
          <h1>18. Taxes</h1>
        </div>

        <div>
          <p>18.1 &nbsp;&nbsp; All prices and payments in this Agreement are exclusive of
            applicable sales and use taxes, including VAT, GST and similar charges (the
           <strong> Taxes</strong>).
          </p>
        </div>

        <div>
          <p>18.2 &nbsp;&nbsp; The following provisions apply in the event that any Australian
            GST is payable in relation to this Agreement:
          </p>
          <ol type="a">
            <li>
              For the purposes of facilitating the issuing of recipient created tax
              invoices in accordance with the requirements of the GST Law, the parties agree
              that Drive mate will issue tax invoices in respect of any supplies under this
              Agreement;
            </li>
            <li>
              Drive mate:
              <ol type="i">
                <li>
                  acknowledges that it is currently registered for GST, and that it will
                  notify you if it ceases to be registered or if it ceases to satisfy any of the
                  requirements relating to the issuing of recipient created tax invoices under the
                  GST Law;
                </li>
                <li>
                  agrees to issue a copy of tax invoices in respect of supplies under
                  this agreement to you, showing your ABN as well as Drive mate's own ABN, and
                  to retain the original; and
                </li>
                <li>agrees to issue adjustment notes to you in relation to adjustment
                   events.
                </li>
              </ol>
            </li>
            <li>
              Should the requirements for issuing recipient created tax invoices
              alter under the GST Law, the parties agree to enter into good faith negotiations to
              amend this paragraph (c) to give effect to such changes.
            </li>
            <li>
              If Drive mate is required by any applicable law to make any
              deduction or withholding from any amount payable by Drive mate under or in
              relation to this Agreement, then Drive mate may reduce the amount payable by it
              by the amount of such deduction or withholding.
            </li>
          </ol>
        </div>
              <br/>
        <div>
          <h1>19. Disclaimers</h1>
        </div>

        <div>
          <h2>19.1 Scope of Services</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            Drive mate provides services that enable the sharing of Vehicles between Hosts
            and Guests. Except as otherwise provided in these Terms, Drive mate does not
            itself provide vehicle sharing, rental services, and/or insurance services and is not
            responsible for any of the acts or omissions of any of the users of its Service, the
            manufacturer of the Vehicle, or any third-party provider of services (e.g. in-
            vehicle GPS or other systems).
          </p>
        </div>

        <div>
          <h2>19.2 Warranty disclaimer</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            The Services are provided “as is”, without warranty of any kind, either express or
            implied. To the extent permitted by applicable law, without limiting the foregoing,
            Drive mate explicitly disclaims any warranties of merchantability, fitness for a
            particular purpose, quiet enjoyment, or non-infringement, and any warranties
            arising out of course of dealing or usage of trade. Drive mate makes no warranty
            that the Services, including, but not limited to, the Listing and/or any Vehicle, will
            meet your requirements or be available on an uninterrupted, secure, or error-free
            basis. Drive mate makes no warranty regarding the quality of any Listings,
            Vehicles, Hosts, Guests, the Services, or any content or the accuracy, timeliness,
            truthfulness, completeness, or reliability of any content obtained through the
            Service. No advice or information, whether oral or written, obtained from Drive
            mate, Drive mate's insurer, or its service providers or through the Services or
            content, will create any warranty not expressly made herein.
          </p>
        </div>
        <br/>
        <div>
          <h1>20. Liability</h1>
        </div>

        <div>
          <h2>20.1 Australian Consumer Law</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            As a Guest, you have consumer rights conferred by the Australian Consumer Law
            and neither this clause 20.1 nor any other provision of the Terms or the Policies
            excludes, restricts or modifies any implied terms, guarantees or rights you may
            have under those laws or any other Federal, State or Territory legislation.
          </p>
        </div>

        <div>
          <h2>20.2 Limitation of liability</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            Subject to clauses 20.1, 20.3 and <a href='https://help.drivemate.au/kb/guide/en/drive-mate-loss-of-use-policy-S81VF3hjoY/Steps/903299'> Drive mate's Loss of Use Policy</a>, neither party
            shall be liable, whether in contract, tort (including negligence), breach of statutory
            duty, or otherwise, arising under or in connection with this agreement for:
          </p>
          <ol type="a">
            <li>loss of profits, sales, business, or revenue;</li>
            <li>business interruption;</li>
            <li>loss of anticipated savings;</li>
            <li>loss or corruption of data or information;</li>
            <li>loss of business opportunity, goodwill or reputation; or</li>
            <li>any incidental, special, indirect or consequential loss or damages.</li>
          </ol>
        </div>

        <div>
          <h2>20.3 Liability not excluded</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            Nothing in this agreement shall limit or exclude any party's liability for:
          </p>
          <ol type="a">
            <li>death or personal injury resulting from negligence;</li>
            <li>fraud; and/or</li>
            <li>
              any other matter in respect of which any party is prohibited under
              applicable law from limiting or excluding liability.
            </li>
          </ol>
        </div>
        <br/>
        <div>
          <h1>21. Data privacy</h1>
        </div>
        <div className={css.padLeft}>
          <p>
            You acknowledge that by using the Service, you may obtain Personal Information
            of other users. You acknowledge and agree that you must not, during and after
            termination of these Terms, without the prior written consent of the other party,
            use, disclose or retain such Personal Information beyond facilitating a Booking or
            for other legal purposes. You agree to undertake reasonable security arrangements
            to prevent unauthorised access, collection, use, disclosure, copying, modification,
            disposal or similar risks with respect to the other. You must at all times, comply
            with the provisions of the Privacy Act 1988 (Cth) in respect of the other party's
            Personal Information.
          </p>
        </div>
        <br/>
        <div>
          <h1>22. Termination and suspension</h1>
        </div>

        <div>
          <h2>22.1 Termination</h2>
        </div>
        <div className={css.padLeft}>
          <p>
            You may discontinue your use of the Service at any time and Drive mate may
            terminate your access to the Services and remove any Listings for any reason or
            no reason to the extent permissible under applicable law.
          </p>
        </div>

        <div>
          <h2>22.2 Consequences of termination</h2>
        </div>
        <div className={css.padLeft}>
          <ol type="a">
            <li>
              Termination of access to the Service will not release a party from
              any obligations it incurred prior to the termination. Termination of this agreement
              will not have any effect on the disclaimers, waiver or liability limitations, or legal
              disputes provisions under the Terms and/or any fees due, and all of those terms
              will survive any termination of these Terms.
            </li>
            <li>
              If you are a Host and terminate your Account, any confirmed
              Booking(s) will be automatically cancelled and your Guests will receive a full
              refund.
            </li>
            <li>
              If you terminate your Account as a Guest, any confirmed Booking(s)
              will be automatically cancelled and any refund will be determined in accordance
              with our <a href="https://help.drivemate.au/kb/guide/en/drive-mate-cancellation-policy-guests-vlJp9xGuCf/Steps/755741">Cancellation Policy</a>.
            </li>
            <li>
              When this agreement has been terminated, you are not entitled to a
              restoration of your account or any of your Content.
            </li>
            <li>
              If your access to or use of the Platform has been limited, or your
              Account has been suspended, or this agreement has been terminated by us, you
              may not register a new account or access or use the Platform through an account
              of another user.
            </li>
          </ol>
        </div>
        <br/>
        <div>
          <h1>23. Force Majeure</h1>
        </div>
        <div className={css.padLeft}>
          <p>
            Notwithstanding any other provision of this Agreement, Drive mate is not liable
            for any failure to perform any of its obligations under this Agreement where that
            performance is delayed, prevented, restricted or interfered with for any of the
            following reasons: acts of God, acts of government, war or war-like situations,
            strikes, lock-outs, industrial action, riots, fires, floods, earthquakes, droughts,
            tempests, malicious attacks on the Platform, or any other event beyond Drive
            mate's reasonable control.
          </p>
        </div>
        <br/>
        <div>
          <h1>24. General</h1>
        </div>

        <div>
          <p>24.1 &nbsp;&nbsp; Any provision of these Terms which is wholly or partially void or
            unenforceable is severed to the extent that it is void or unenforceable. The validity
            or enforceability of the remainder of this Agreement is not affected.
          </p>
        </div>

        <div>
          <p>24.2 &nbsp;&nbsp; This Agreement is governed by the laws of New South Wales,
            Australia. Each party irrevocably and unconditionally submits to the non-
            exclusive jurisdiction of the courts of New South Wales and the Commonwealth
            of Australia.
          </p>
        </div>

        <div>
          <p>24.3 &nbsp;&nbsp; You must not assign or novate this agreement or otherwise deal
            with the benefit of it or a right under it, or purport to do so, without the prior
            written consent of Drive mate. Drive mate may assign or novate part or all of this
            agreement to any person as part of any restructure or sale of any or all of Drive
            mate's business.
          </p>
        </div>

        <div>
          <p>24.4 &nbsp;&nbsp; These Terms are the entire agreement between the parties about its
            subject matter and replaces all previous agreements, understandings,
            representations and warranties about that subject matter.
          </p>
        </div>

        <div>
          <p>24.5 &nbsp;&nbsp; Each party represents and warrants that it has not relied on any
            representations or warranties about the subject matter of these Terms except as
            expressly provided in these Terms.
          </p>
        </div>

        <div>
          <p>24.6 &nbsp;&nbsp; Your use of the Service is conducted electronically and you agree
            that Drive mate may communicate with you electronically for all aspects of your
            use of the Platform, including sending you electronic notices.
          </p>
        </div>

        <div>
          <p>24.7 &nbsp;&nbsp; The provisions of these Terms which by their nature survive
            termination or expiry of these terms of use will survive termination or expiry of
            these Terms.
          </p>
        </div>

        <div>
          <p>24.8 &nbsp;&nbsp; No waiver of a right or remedy under these Terms is effective
            unless it is in writing and signed by the party granting it. It is only effective in the
            specific instance and for the specific purpose for which it is granted.
          </p>
        </div>

        <div>
          <p>24.9 &nbsp;&nbsp; A single or partial exercise of a right or remedy under these Terms
            does not prevent a further exercise of that or of any other right or remedy.
          </p>
        </div>

        <div>
          <p>24.10 &nbsp;&nbsp; Failure to exercise or delay in exercising a right or remedy under
            these Terms does not operate as a waiver or prevent further exercise of that or of
            any other right or remedy.
          </p>
        </div>

        <div>
          <p>24.11 &nbsp;&nbsp; No rule of construction applies to the disadvantage of a party because
            that party was responsible for the preparation of this Agreement.
          </p>
        </div>
              <br/>
        <div>
          <h1>25. Definitions and Interpretation</h1>
        </div>

        <div>
          <h2>25.1 Definitions</h2>
        </div>
        <div className={css.padLeft}>
          <p>The following definitions apply in these Terms:</p>
          <p>
            <strong>Accident</strong> means an unintended and unforeseen incident, including:
          </p>
          <ol type="a">
            <li>
              a collision between the Vehicle and another vehicle or object,
              including animals and roadside infrastructure;
            </li>
            <li>rollovers; and</li>
            <li>weather events, including hail Damage,</li>
          </ol>
          <p>that results in Damage or Third Party Loss.</p>
          <p>
            <strong>Authorised Driver</strong> a person with a valid licence to drive in the jurisdiction the
            Vehicle is being driven in, who has been verified by Drive mate in accordance
            with its policies and notified to the Host as a driver of the Vehicle.
          </p>
          <p>
            <strong>Booking Fee</strong> has the meaning given in clause.
          </p>
          <p>
            <strong>Booking Period</strong> means the period of hire for the Vehicle as agreed between the
            parties in a Booker, or the date and time the Vehicle is returned to the Host at the
            Designated Location, whichever is the later.
          </p>
          <p>
            <strong>Damage</strong> means:
          </p>
          <ol type="a">
            <li>
              any loss or damage to the Vehicle including its parts, components
              and accessories, including the GPS unit, that is not fair wear and tear;
            </li>
            <li>towing and salvage costs;</li>
            <li>assessing fees; and</li>
            <li>Loss of Use,</li>
          </ol>
          <p>
            and for the removal of doubt, any Damage to the windscreen, headlights, lights or
            tyres that makes the Vehicle unroadworthy is not fair wear and tear.
          </p>
          <p>
            <strong>Damage Cover</strong> means the cover the Guest and any Authorised Drivers have for
            Damage, theft, attempted theft and Third Party Loss under clause 6, subject to the
            exclusions set out in clause 6.5.
          </p>
          <p>
            <strong>Damage Excess</strong> means the amount, including GST, the Guest must pay Drive
            mate in the event of an Accident or attempted theft that causes Damage or Third
            Party Loss or the Vehicle has been stolen and not recovered as set out in <a href='https://help.drivemate.au/kb/guide/en/drive-mate-protection-policy-gZrp4S8YGc/Steps/755720'> Drive
            mate's Protection Policy</a>.
          </p>
          <p>
            <strong>Designated Location</strong> means the designated location at which the Vehicle is to be
            collected or returned to the Host by the Guest, or such other location as parties
            may agree.
          </p>
          <p>
            <strong>Drop Off Time</strong> means the time and date the Guest and the Host agree for the
            drop-off of the Vehicle at the end of a Booking, or the time the the Host has
            confirmed via the Platform the return of the Vehicle to the Designated Location,
            whichever is later.
          </p>
          <p>
            <strong>Enabling Equipment</strong> means equipment supplied by Drive mate to a Host to
            facilitate or enable to Vehicle to be used for the Service, which may include any
            of the following:
          </p>
          <ol type="a">
            <li>an Otoplug and associated equipment;</li>
            <li>lockbox and window bracket(s);</li>
            <li>primary GPS; and</li>
            <li>secondary GPS.</li>
          </ol>
          <p>
            <strong>Excluded Damage</strong> means any of the following:
          </p>
          <ol type="a">
            <li>
              Damage or Third Party Loss arising from:
              <ol type="i">
                <li>a Serious Breach by the Guest; or</li>
                <li>
                  the use of the Vehicle by any driver who is not an Authorised
                  Driver or who is less than 21 or more than 75 years of age;
                </li>
              </ol>
            </li>
            <li>Overhead Damage;</li>
            <li>Underbody Damage;</li>
            <li>
              Damage caused by:
              <ol type="i">
                <li>immersion of the Vehicle in water;</li>
                <li>use of the incorrect fuel type;</li>
                <li>
                  Damage to the tyres of the Vehicle, other than by normal wear and
                  tear;
                </li>
              </ol>
            </li>
            <li>
              the full cost of replacing or repairing any accessories supplied by the
              Host including, but not limited to GPS units, lost keys, keyless start and remote
              control devices; and
            </li>
            <li>
              personal items that are left in or stolen from the Vehicle or for loss
              or Damage to property belonging to or in the custody of the Guest or any other
              person the Guest allows access to the Vehicle.
            </li>
          </ol>
          <p>
            <strong>Guest</strong> has the meaning given in the Overview.
          </p>
          <p>
            <strong>Host</strong> has the meaning given in the Overview.
          </p>
          <p>
            <strong>Listing</strong> means the listing of a Vehicle on the Platform by a Host.
          </p>
          <p>
            <strong>Overhead Damage</strong> means:
          </p>
          <ol type="a">
            <li>Damage to the Vehicle; and/or</li>
            <li>Third Party Loss,</li>
          <p>caused by:</p>
            <li>
              contact between the part of the Vehicle that is at or above the level
              of the top of the front windscreen with objects overhanging or obstructing its path;
            </li>
            <li>objects being placed on the roof of the Vehicle; or</li>
          </ol>
          <ol type="a" start="3">
            <li>the Guest or any person standing or sitting on the roof of the
          Vehicle.</li>
          </ol>
          <p>
            <strong>Payment Method</strong> means the payment method (i.e., PayPal account, bank account,
            a prepaid card, or a debit card) and associated information you provide to us.
          </p>
          <p>
            <strong>Personal Information</strong> has the meaning given in the Privacy Act 1988 (Cth).
          </p>
          <p>
            <strong>Pick Up Time</strong> means the time and date of which the later of the parties have
            confirmed via the Platform the provide the Vehicle to the Host at the Designated
            Location.
          </p>
          <p>
            <strong>Platform</strong> means Drive mate's website (https://drivemate.au/) and its associated
            mobile applications.
          </p>
          <p>
            <strong>Privacy Policy</strong> means Drive mate's Privacy Policy available <a href="#">here</a>.
          </p>
          <p>
            <strong>Serious Breach</strong> means:
          </p>
          <ol type="a">
            <li>
              a breach of clause 5 that causes Damage, theft of the Vehicle or
              Third Party Loss; or
            </li>
            <li>
              a breach of clause 7 that prevents Drive mate from properly
              investigating a claim arising from an Accident or theft or from prosecuting or
              defending any Accident or theft claim.
            </li>
          </ol>
          <p>
            <strong>Service Fee</strong> means the fee Drive mate charges Hosts as consideration of the
            provision of the Services.
          </p>
          <p>
            <strong>Third Party Loss</strong> means loss or damage to third party property, including other
            motor vehicles and any claim for third party loss of income.
          </p>
          <p>
            <strong>Trip Fees</strong> means the fees payable by Guests for the rental of the Vehicle, as set
            out on the Listing, exclusive of any Service Fees or other fees payable to Drive
            mate or any other third party.
          </p>
          <p>
            <strong>Unsealed Road</strong> means a road, other than a road that is undergoing temporary
            roadworks, that has been formed and constructed but is not sealed with a hard
            material such as tar, bitumen or concrete.
          </p>
          <p>
            <strong>Vehicle</strong> means a motor vehicle registered with the relevant state road authority as
            being operated by a Host and listed on the Platform by that Host for rent.
          </p>
        </div>

        <div>
          <h2>25.2 Interpretation</h2>
        </div>
        <div className={css.padLeft}>
          <p>In this Agreement, unless the context otherwise requires:</p>
          <ol type="a">
            <li>headings are to be ignored in interpreting these Terms;</li>
            <li>the Overview forms part of these Terms;</li>
            <li>
              the singular includes the plural (and vice versa) and a gender
              includes each other gender;
            </li>
            <li>
              a word derived from a defined term has a meaning that corresponds
              with that defined term;
            </li>
            <li>
              whenever the words "include” or “including" are used in these
              Terms, they are deemed to be followed by the words "without limitation";
            </li>
            <li>a reference to writing or written includes email;</li>
            <li>
              references to legislation include amendments to, and re-enactments
              of, that legislation; and
            </li>
            <li>
              references to a party include that party's successors and permitted
              assignees or transferees.
            </li>
          </ol>
        </div>
       
      </div>
    </div>
  );
};

const { bool } = PropTypes;

TermsOfServicePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const TermsOfService = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps),
  injectIntl,
)(TermsOfServicePageComponent);

export default TermsOfService;
