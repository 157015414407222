import classNames from 'classnames';
import React from 'react';
import flexPlusBanner from '../../assets/b-landing/flexPlusBanner.png';
import flexPlusBanner2x from '../../assets/b-landing/flexPlusBanner2x.png';
import flexPlusLogo from '../../assets/b-landing/flexPlusLogo.png';
import flexPlusLogo2x from '../../assets/b-landing/flexPlusLogo2x.png';
import flexIconInText from '../../assets/b-landing/flexIconInText.svg';
import BLandingCss from './BLandingPage.css';
import { NamedLink } from '../../components';
import css from '../LandingPageNew/LandingPageNew.css';
import imageSource from '../../util/imageSource';

const FlexPlusSectionBanner = () => {

  return (
    <div
      className={classNames(
        // BLandingCss.column12,
        // BLandingCss.gridBox,
        BLandingCss.gridHorizontal,
      )}
      style={{width: "100%", cursor: "pointer"}}
    >
      <a href="/subscription">
        <div className={BLandingCss.gridBoxInner}>
          <div className={BLandingCss.gridThumb}>
            <img
              srcSet={`${flexPlusBanner} 1x, ${flexPlusBanner2x} 2x`}
              alt=""
            />
            <img
              className={BLandingCss.overlayImage}
              // src={flexPlusLogo}
              srcSet={`${flexPlusLogo} 1x, ${flexPlusLogo2x} 2x`}
              alt="flex logo"
              style={{
                width: "183px",
                height: "63px",
                top: "38%",
                left: "24%"
              }}
            />
          </div>
          <div className={BLandingCss.gridContent}>
            <span className={BLandingCss.title}>Next level of car ownership</span>
            <div className="flexible-rental">
              <p>Flexible monthly car rentals. Access to subsidised rates. Only with <span><img
                src={flexIconInText} alt="flex logo" /></span></p>
            </div>
            <div className={css.contentBox}>
              <div className={css.list} style={{ display: 'flex', alignItems: 'center' }}>
                <img src={ imageSource.drivemateSection.CheckIcon} alt="" style={{ width: '16px', height: '16px' }} />
                <p style={{ margin: 0, marginLeft: '8px' }}>No commitments. Cancel anytime*</p>
              </div>
              <div className={css.list}
                   style={{ display: 'flex', alignItems: 'center', marginTop: "10px" }}>
                <img src={imageSource.drivemateSection.CheckIcon} alt="" style={{ width: '16px', height: '16px' }} />
                <p style={{ margin: 0, marginLeft: '8px' }}>Most Affordable</p>
              </div>
              <div className={css.list}
                   style={{ display: 'flex', alignItems: 'center', marginTop: "10px" }}>
                <img src={imageSource.drivemateSection.CheckIcon} alt="" style={{ width: '16px', height: '16px' }} />
                <p style={{ margin: 0, marginLeft: '8px' }}>Share your subscription to access
                  subsidised rates</p>
              </div>
            </div>
            <div className={BLandingCss.btnWrapper}>
              <NamedLink name="LandingPageFlex" className={BLandingCss.findMoreButton}>
                Find out more
              </NamedLink>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};
export default FlexPlusSectionBanner;
