/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { memo, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import BLandingCss from './BLandingPage.css';
import isEqual from 'lodash/isEqual';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { getSearchedPlaces, saveSearchedPlacesMobile } from '../../util/emailNotify';
import { encodeLatLngBounds, parse } from '../../util/urlHelpers';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router';
import { LocationAutocompleteInput, TrustBox } from '../../components';
import { Field, Form } from 'react-final-form';
import insurance from '../../assets/tokyo-marine-logo.png';
import landTransport from '../../assets/landingpage/landTransport.jpeg';
import GeocoderGoogleMaps, {
  CURRENT_LOCATION_ID,
} from '../../components/LocationAutocompleteInput/GeocoderGoogleMaps';
import SearchIconNew from '../../components/Topbar/SearchIconNew';
import { FormattedMessage } from 'react-intl';
import config from '../../config';
import { initiateEventFromListing } from '../../util/gtm/gtmHelpers';
import { EVENT_SEARCH_PERFORMED } from '../../util/gtm/gtmConstants';
import { useSelector } from 'react-redux';
import Typewriter from 'typewriter-effect';
import { createRawPropertiesForGTM } from '../../util/gtm/gtmCreateProperties';
import { EVENT_CONVERSION_PERFORMED_SEARCH } from '../../util/conversions/conversionsConstants';
import { createConversionEvents } from '../../util/conversions/conversionsHelpers';
import { gSend, GTAG_ACTIONS } from '../../util/gtag';
import imageSource from '../../util/imageSource';

const DEFAULT_BOUNDS = {
  _sdkType: 'LatLngBounds',
  ne: {
    _sdkType: 'LatLng',
    lat: 1.4708809,
    lng: 104.04157989999999,
  },
  sw: {
    _sdkType: 'LatLng',
    lat: 1.216611,
    lng: 103.60650989999999,
  },
};
export const defaultPredictions = (config.maps.search.suggestCurrentLocation
  ? [{ id: CURRENT_LOCATION_ID, predictionPlace: {} }]
  : []
).concat(config.maps.search.defaults);

export const defaultPredictionsExcludingCurrentLocation = config.maps.search.defaults;

export const defaultPredictionsWithoutCurrentLocation = [
  { id: CURRENT_LOCATION_ID, predictionPlace: {} },
];

const ImageComponent = ({ src1x, src2x, children, Imagealt }) => {
  return (
    <div className={BLandingCss.bannerSlideItem}>
      <img
        alt={Imagealt}
        srcSet={`${src1x} 1x, ${src2x} 2x`}
        className={BLandingCss.imageComponentImageBLanding}
      />
      <div className={BLandingCss.imageComponentContentBLanding}>{children}</div>
    </div>
  );
};

const SectionSearchBanner = memo(
  ({ isMobileLayout, onSelectedPrediction, currentUser }) => {
    const sliderConfig = {
      autoplay: true,
      dots: false,
      arrows: false,
      infinite: true,
      autoplaySpeed: 5000,
    };
    const geocoder = useRef(new GeocoderGoogleMaps()).current;
    let searchDropdown = useRef(null);
    const [predictions, setPredictions] = useState([]);
    const [isUserSearching, setIsUserSearching] = useState(false);
    const [locationLoader, setLocationLoader] = useState(false);
    const [isFocused, setIsFocused] = useState(null);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    let userSearchHistory = getSearchedPlaces();
    const currentSearchParams = useSelector(state => state.UI.searchParams);
    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
      document.addEventListener('click', handleOutsideClick, true);
    }, []);

    if (userSearchHistory && userSearchHistory.length) {
      userSearchHistory = userSearchHistory.filter(searchPlace => {
        return searchPlace['description'] && geocoder.getPredictionId(searchPlace);
      });
    }

    const newText = (
      <div className={BLandingCss.typeEffect}>
        <Typewriter
          options={{
            strings: ['<u>by the hour</u>', '<u>by the day</u>'],
            autoStart: true,
            loop: true,
          }}
        />
      </div>
    );

    const handleShowMenu = () => {
      if (isMobileLayout) {
        const { search } = location;
        const url = createResourceLocatorString('SearchPage', routeConfiguration(), null, {
          ...parse(search),
          searchStep: 'location',
          mapSearch: false,
          timezone: location && location.timezone,
          origin: location && location.origin,
        });
        history.push(url);
      }
    };

    // Touch devices need to be able to distinguish touches for scrolling and touches to tap

    const handleSelectPrediction = prediction => {
      const DEFAULT_TIME_AND_DATES = {
        location: {},
        selectedDates: {
          startDate: moment(),
          endDate: moment(),
        },
        selectedTimes: {
          startTime: moment(),
          endTime: moment(),
        },
        isDefault: true,
      };
      if (!isMobileLayout) {
        setLocationLoader(true);
        geocoder
          .getPlaceDetails(prediction.predictionPlace ? prediction.predictionPlace : prediction)
          .then(location => {
            let url = '';
            const { startDate, endDate } = DEFAULT_TIME_AND_DATES.selectedDates;
            const { startTime, endTime } = DEFAULT_TIME_AND_DATES.selectedTimes;
            let updatedStartDate, updatedEndDate, updatedStartTime, updatedEndTime;
            updatedStartDate = startDate.add(1, 'day').set({ hour: 9, minute: 0 });
            updatedEndDate = endDate.add(4, 'day').set({ hour: 9, minute: 0 });
            updatedStartTime = startTime.set({ hour: 9, minute: 0 });
            updatedEndTime = endTime.set({ hour: 9, minute: 0 });
            url = createResourceLocatorString('SearchPage', routeConfiguration(), null, {
              bounds: encodeLatLngBounds(
                location && location.bounds ? location.bounds : DEFAULT_BOUNDS
              ),
              dates: `${updatedStartDate.format('YYYY-MM-DD')},${updatedEndDate.format(
                'YYYY-MM-DD'
              )}`,
              hours: `${updatedStartTime.format('HH:mm')},${updatedEndTime.format('HH:mm')}`,
              searchType: 'daily',
              searchStep: 'preview',
              origin: location && location.origin,
              address: location && location.address,
              timezone: location && location.timezone,
              mapSearch: false,
            });

            onSelectedPrediction(location);
            initiateEventFromListing({
              props: {
                searchParams: {
                  ...currentSearchParams,
                  bounds: location && location.bounds ? location.bounds : DEFAULT_BOUNDS,
                },
              },
              event: EVENT_SEARCH_PERFORMED,
            });
            gSend(GTAG_ACTIONS.ACTION_SEARCH, {
              // TODO: Assign proper details.
              search_location: location && location.address,
              search_pickupdate: `${updatedStartDate.format('YYYY-MM-DD')}`,
              search_pickuptime: `${updatedStartTime.format('HH:mm')}`,
              search_dropoffdate: `${updatedEndDate.format('YYYY-MM-DD')}`,
              search_dropofftime: `${updatedEndDate.format('HH:mm')}`,
            });
            const properties = createRawPropertiesForGTM({
              props: currentUser,
            });
            createConversionEvents(properties, EVENT_CONVERSION_PERFORMED_SEARCH, 'click');

            if (prediction && prediction.predictionPlace) {
              saveSearchedPlacesMobile(prediction.predictionPlace);
            } else {
              saveSearchedPlacesMobile(prediction);
            }
            setLocationLoader(false);
            history.push(url);
          });
      }
    };

    const handleOutsideClick = e => {
      if (searchDropdown && searchDropdown.current && !searchDropdown.current.contains(e.target)) {
        setIsFocused(false);
      } else {
        setIsFocused(true);
        document.removeEventListener('click', handleOutsideClick, false);
      }
    };

    const _handleSearchType = p => {
      if (p && p.value !== '') {
        setIsUserSearching(true);
      } else {
        setIsUserSearching(false);
      }
    };

    /* eslint-disable jsx-a11y/no-static-element-interactions */
    const item = (prediction, index) => {
      const isHighlighted = index === highlightedIndex;
      const predictionId = geocoder.getPredictionId(prediction);
      return (
        <li
          className={classNames(
            isHighlighted ? BLandingCss.highlighted : null,
            BLandingCss.prediction
          )}
          key={predictionId}
          onClick={() => handleSelectPrediction(prediction)}
        >
          {predictionId === CURRENT_LOCATION_ID ? (
            <span className={BLandingCss.currentLocation}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <defs></defs>
                <path
                  fill="#026786"
                  class="a"
                  d="M13.9.111.9,6.108a1.515,1.515,0,0,0,.6,2.9H7v5.5a1.515,1.515,0,0,0,2.9.6l6-12.993a1.559,1.559,0,0,0-2-2Z"
                  transform="translate(-0.007 -0.001)"
                />
              </svg>
              <FormattedMessage id="LocationAutocompleteInput.currentLocation" />
            </span>
          ) : (
            geocoder.getPredictionAddress(prediction)
          )}
        </li>
      );
    };
    /* eslint-enable jsx-a11y/no-static-element-interactions */
    return (
      <div className={BLandingCss.sectionImagesBLanding}>
        <ImageComponent
          src1x={isMobileLayout ? imageSource.bannerImage.bannerImageMobile : imageSource.bannerImage.bannerImage}
          src2x={isMobileLayout ? imageSource.bannerImage.bannerImageMobile2x : imageSource.bannerImage.bannerImage2x}
          alt="Car rental made easy with Drive Mate"
        >
          <div className={BLandingCss.bannerContentBlock}>
            <div
              className={classNames(
                BLandingCss.sectionImageTitleBLanding,
                BLandingCss.hourlyTitleBLanding
              )}
            >
              <span>
                <i>Rent local</i>
                <br />
                <i>cars&nbsp;</i>
                <i>{newText}</i>
              </span>
            </div>
            <div className={BLandingCss.searchBar} onClick={handleShowMenu}>
              <div className={BLandingCss.searchBarContent}>
                <p>
                  With Drive mate, you can rent from a huge variety of nearby cars at great value.
                  Fully insured+.x
                </p>
                <div className={BLandingCss.searchBarOuter} ref={searchDropdown}>
                  <Form
                    onSubmit={() => {}}
                    render={() => {
                      return (
                        <Field name="location">
                          {props => (
                            <LocationAutocompleteInput
                              {...props}
                              autoFocus={false}
                              className={BLandingCss.fieldLocationBLanding}
                              iconClassName={BLandingCss.hideSearchIconBLanding}
                              inputClassName={BLandingCss.inputClassNameBLanding}
                              predictionsClassName={BLandingCss.searchPredictionsBLanding}
                              onPredictionChange={setPredictions}
                              placeholder="Enter your location to view cars"
                              inputRef={_handleSearchType}
                            >
                              <div className={BLandingCss.searchIconWrapper}>
                                <SearchIconNew rootClassName={BLandingCss.searchIcon} />
                              </div>
                            </LocationAutocompleteInput>
                          )}
                        </Field>
                      );
                    }}
                  />
                  <div className={BLandingCss.searchIconWrapper}>
                    {locationLoader ? (
                      <div className={BLandingCss.locationLoader}>
                        <span></span>
                      </div>
                    ) : (
                      <SearchIconNew rootClassName={BLandingCss.searchIcon} />
                    )}
                  </div>
                  <div
                    className={classNames(
                      BLandingCss.predictionsDropDown,
                      isFocused && !isMobileLayout && BLandingCss.isVisible
                    )}
                  >
                    <ul className={BLandingCss.predictionsCurrentLocations}>
                      {defaultPredictionsWithoutCurrentLocation &&
                      predictions.length > 0 &&
                      predictions[0]['description']
                        ? predictions
                            .filter(item => {
                              const desc = (item['description'] || '').toLowerCase();
                              return desc.includes('australia');
                            })
                            .map(item)
                        : defaultPredictionsWithoutCurrentLocation.map((value, index) =>
                            item(value, index)
                          )}
                    </ul>
                    {!isUserSearching && userSearchHistory && userSearchHistory.length ? (
                      <div className={BLandingCss.searchLabels}>
                        <FormattedMessage id="LocationAutocompleteInput.recentSearches" />
                      </div>
                    ) : null}
                    {!isUserSearching && userSearchHistory && userSearchHistory.length ? (
                      <ul className={BLandingCss.predictions}>
                        {userSearchHistory &&
                        userSearchHistory.length > 0 &&
                        userSearchHistory[0]['description']
                          ? userSearchHistory.map((value, index) => item(value, index))
                          : userSearchHistory.map((value, index) => item(value, index))}
                      </ul>
                    ) : null}
                    {predictions &&
                    predictions.length > 0 &&
                    predictions[0]['description'] ? null : (
                      <div className={BLandingCss.searchLabels}>
                        <FormattedMessage id="LocationAutocompleteInput.searchSuggestion" />
                      </div>
                    )}

                    {userSearchHistory && userSearchHistory.length ? (
                      <ul className={BLandingCss.predictions}>
                        {predictions && predictions.length > 0 && predictions[0]['description']
                          ? null
                          : defaultPredictionsExcludingCurrentLocation.map((value, index) =>
                              item(value, index)
                            )}
                      </ul>
                    ) : (
                      <ul className={BLandingCss.predictions}>
                        {predictions && predictions.length > 0 && predictions[0]['description']
                          ? null
                          : defaultPredictionsExcludingCurrentLocation.map((value, index) =>
                              item(value, index)
                            )}
                      </ul>
                    )}
                    {/* <GeocoderAttribution className={BLandingCss.attributionClassName} /> */}
                  </div>
                </div>
              </div>
              {!isMobileLayout && (
                <div className={BLandingCss.insuranceListingWrapper}>
                  {/* <div
                    className={BLandingCss.trustPilotLogo}
                    style={{ flex: '0 0 33.333%', maxWidth: '33.3333%', padding: '0 15px' }}
                  >
                    <TrustBox />
                  </div> */}
                  <div
                    className={BLandingCss.insuranceListingImageOuter}
                    style={{ flex: '0 0 33.333%', maxWidth: '33.3333%', padding: '0 15px' }}
                  >
                    <div className={BLandingCss.insuranceListingText}>Insurance provided by</div>
                    <img className={BLandingCss.insuranceListingImage} src={insurance} />
                  </div>
                  <div
                    className={BLandingCss.approvedByCompanyLogoOuter}
                    style={{ flex: '0 0 33.333%', maxWidth: '33.3333%', padding: '0 15px' }}
                  >
                    <div className={BLandingCss.insuranceListingText}>Approved by</div>
                    <img
                      className={BLandingCss.approvedByCompanyLogo}
                      src={landTransport}
                      alt="Land Transport"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </ImageComponent>
      </div>
    );
  },
  (prev, next) => isEqual(prev, next)
);

export default SectionSearchBanner;
