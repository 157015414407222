import React, { Component } from 'react';
import { func, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ExternalLink, Modal } from '../../components';

import css from './InsurancePanelNew.css';
import drivelahSecurity from '../../assets/insurance-panel/drivelah-security.svg';
import { checkIsOldCar } from '../../util/dates';
import allianzLogo from '../../assets/insurance-panel/allianz-logo.svg';
import gtLogo from '../../assets/insurance-panel/gt-logo.png';

const INSURANCE_PRIVATE = 'private';
const INSURANCE_COMMERCIAL = 'commercial';

class InsurancePanelNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };
  }

  openInsurancePolicy = (type, url) => {
    this.setState({
      isModalOpen: true,
    });
  };

  render() {
    const {
      rootClassName,
      className,
      showInsurance,
      insuranceType,
      listing,
      onManageDisableScrolling,
      onReadInsurance,
    } = this.props;

    const classes = classNames(rootClassName, className);
    const isOldCar = checkIsOldCar(listing);

    const panelTitle = <FormattedMessage id="InsurancePanel.InsurancePanelTitle" />;
    const panelParagraph = <FormattedMessage id="InsurancePanel.InsurancePanelPara" />;

    return (
      <div className={classes}>
        <div className={css.insuranceSection}>
          <h5>{panelTitle}</h5>
          <p>{panelParagraph}</p>
          <img src={drivelahSecurity} alt="drive mate protection" />
        </div>
        <ExternalLink onClick={this.openInsurancePolicy} className={css.insuranceBottomImgText}>
          <FormattedMessage id="InsurancePanel.commercialInsuranceButton" />
        </ExternalLink>
        <Modal
          id="staticPageModal"
          isOpen={!!this.state.isModalOpen}
          onClose={() => {
            this.setState({
              isModalOpen: false,
            });
          }}
          containerClassName={css.modalContainer}
          onManageDisableScrolling={onManageDisableScrolling}
          closeButtonMessage={<FormattedMessage id="ModalMissingInformation.closeStaticModal" />}
        >
          <div className={css.insuranceListingWrapper}>
            <h3>Our partners</h3>
            <div className={css.insuranceLogoWrapper}>
              <a href="https://www.allianz.com/en.html" target="_blank">
                <img src={gtLogo} className={css.insuranceLogo} alt="tokio-marine-logo" />
              </a>
              <a href="https://www.gtins.com.au/" target="_blank">
                <img src={allianzLogo} className={css.insuranceLogo} alt="ntuc-income-logo" />
              </a>
            </div>
            <h3>Comprehensive insurance throughout Australia</h3>
            <ul className={css.insurancePointsList}>
              <li>Covers vehicle damage due to accident, fire or storm, or malicious damage</li>
              <li>Covers theft of vehicle</li>
              <li>$35 million cover for 3rd party damage</li>
              <li>Maximum damage liability of $3000 (ex GST) for Section I and II each</li>
              <li>Additional excess per section of $1000 for drivers younger than 24 years</li>
              <li>
                Additional excess per section of $1000 for drivers with less than 2 years driving
                experience
              </li>
              <li>Policy applicable only for the approved driver during the period of rental</li>
            </ul>
            {/* {showInsurance && insuranceType === INSURANCE_PRIVATE && (
              <div className={css.insuranceBottom}>
                <ExternalLink
                  onClick={onReadInsurance}
                  className={css.insuranceBottomImgText}
                  href={insurancePdf}
                >
                  <FormattedMessage id="InsurancePanel.privateInsuranceButton" />
                </ExternalLink>
              </div>
            )} */}
          </div>
        </Modal>
      </div>
    );
  }
}

InsurancePanelNew.defaultProps = {
  rootClassName: null,
  className: null,
};

InsurancePanelNew.propTypes = {
  rootClassName: string,
  className: string,
  onReadInsurance: func,
};

export default InsurancePanelNew;
