import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { get } from 'lodash';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';

import {
  getUserTxRole,
} from '../../util/transaction';
import { ensureBooking, ensureUser, restoreTransaction } from '../../util/data';
import { $bookingUpdateTxsByParentTxId } from '../EditTripPage/EditTripPage.selectors';

import {
  BookingInfoSection,
  IconSpinner,
  Logo,
  NamedLink,
  Page,
  PartnerInfo,
} from '../../components';
import {  loadData } from './TripDetailsPage.duck';

import css from './TripDetailsPage.css';
import { formatMoney } from '../../util/currency';
import { Money } from 'sharetribe-flex-sdk/src/types';
import TripReceipt from '../../components/TripReceipt/TripReceipt';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';

const PROVIDER = 'provider';
const CUSTOMER = 'customer';
const MAX_MOBILE_SCREEN_WIDTH = 768;
const MAX_TABLET_SCREEN_WIDTH = 1024;

// TransactionPage handles data loading for Sale and Order views to transaction pages in Inbox.
export const TripDetailsPageComponent = props => {
  const { action } = useParams();
  const {
    bookingUpdateTxs,
    scrollingDisabled,
    transaction: parentTransaction,
    transactionUpdateBooking,
    intl,
    transactionRole,
    viewport,
    currentUser,
    history,
  } = props;

  console.log('TRIP DETAILS PAGE > props >', props);
  // console.log('TRIP DETAILS PAGE > props > distanceTransaction >', distanceTransaction);

  const [dataLoaded, setDataLoaded] = useState(false);
  const [hostTransactionData, setHostTransactionData] = useState(null);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const isProviderRole = action === 'provider';

  useEffect(() => {
    const fetchHostTransactionData = async () => {
      if (parentTransaction?.id?.uuid) {
        try {
          setLoading(true);
          const response = await axios.post(`${process.env.REACT_APP_API_SERVER_URL}/api/host-payout/transactions/details`, {
            transactionId: parentTransaction.id.uuid,
          });
          console.log('Host transaction data:', response);
          setHostTransactionData(response.data.data);
        } catch (error) {
          console.error('Error fetching host transaction data:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    if (parentTransaction && !dataLoaded) {
      setDataLoaded(true);
      if (isProviderRole) {
        fetchHostTransactionData();
      }
    }
  }, [parentTransaction, dataLoaded, isProviderRole]);

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const isTabletLayout = viewport.width < MAX_TABLET_SCREEN_WIDTH;

  const currentListing = parentTransaction && parentTransaction.listing;
  const currentProvider = parentTransaction && ensureUser(parentTransaction.provider);
  const currentCustomer = parentTransaction && ensureUser(parentTransaction.customer);
  const listingTitle = currentListing && currentListing.attributes.title;
  const title = intl.formatMessage({ id: 'TripDetailsPage.title' }, { title: listingTitle });
  const isLoading = !dataLoaded;
  const parentCreatedAt = parentTransaction && parentTransaction.attributes.createdAt;

  const currentUserRole =
    currentUser &&
    currentUser.id &&
    parentTransaction &&
    getUserTxRole(currentUser.id, parentTransaction);
  
  const isCustomerRole = action === 'customer' ||  action === 'customer-web';

  console.log(isCustomerRole, isProviderRole, currentUserRole);


  if (isCustomerRole) {
    return <TripReceipt {...props} />;
  }


  const pageProps = {
    title,
    scrollingDisabled,
    className: css.root,
  };

  const topbar = (
    <div className={css.topbar}>
      <NamedLink className={css.home} name="LandingPage">
        <Logo
          className={css.logoMobile}
          title={intl.formatMessage({
            id: 'CheckoutPage.goToLandingPage',
          })}
          format="mobile"
        />
        <Logo
          className={css.logoDesktop}
          alt={intl.formatMessage({
            id: 'CheckoutPage.goToLandingPage',
          })}
          format="desktop"
        />
      </NamedLink>
    </div>
  );

  if (isLoading) {
    return (
      <Page {...pageProps}>
        {topbar}
        <div className={css.loading}>
          <IconSpinner />
          <div>
            <FormattedMessage id={'TripDetailsPage.pleaseWait'} />
          </div>
        </div>
      </Page>
    );
  }

  // Prepare data for ProviderBreakdown using API data
  const breakdownData = hostTransactionData ? {
    tripPrice: parseFloat(hostTransactionData.tripPrice),
    tripdrivemateFees: parseFloat(hostTransactionData.tripdrivemateFees),
    modifiedTripPayouts: parseFloat(hostTransactionData.modifiedTripPayouts),
    modifiedTripdrivemateFees: parseFloat(hostTransactionData.modifiedTripdrivemateFees),
    calTripPrice: parseFloat(hostTransactionData.calTripPrice),
    distanceTripPrice: parseFloat(hostTransactionData.distanceTripPrice),
    distanceDrivemateFees: parseFloat(hostTransactionData.distanceDrivemateFees),
    calOtherEarnings: parseFloat(hostTransactionData.calOtherEarnings),
    tollsTotal: parseFloat(hostTransactionData.tollsTotal),
    excessMileage: parseFloat(hostTransactionData.excessMileage),
    lateCharge: parseFloat(hostTransactionData.lateCharge),
    fuelCharge: parseFloat(hostTransactionData.fuelCharge),
    flexPlus: parseFloat(hostTransactionData.flexPlus),
    fuelRefund: parseFloat(hostTransactionData.fuelRefund),
    miscChargeToHost: parseFloat(hostTransactionData.miscChargeToHost),
    miscPayoutToHost: parseFloat(hostTransactionData.miscPayoutToHost),
    cleanlinessTotal: parseFloat(hostTransactionData.cleanlinessTotal),
    subscription: parseFloat(hostTransactionData.subscription),
    damageTotal: parseFloat(hostTransactionData.damageTotal),
    calTripEarnings: parseFloat(hostTransactionData.calTripEarnings),
    gstIncluded: hostTransactionData.gstIncluded,
    abnNumber: hostTransactionData.abnNumber,
  } : null;
  console.log('breakdownData', breakdownData);

  const renderBreakdownSection = (title, items, condition = true) => {
    if (!condition) return null;

    const getHeadStyle = (isTotal, negative) => ({
      color: isTotal ? '#00A3AD' : negative ? '#D91320' : '#1A1A1A',
      fontWeight: isTotal ? 700 : 500,
    });

    const getLabelStyle = (isTotal) => ({
      color: isTotal ? '#00A3AD' : '#1A1A1A',
      fontWeight: isTotal ? 700 : 500,
    });

    const getAmount = (value, negative) => {
      if (value == null || isNaN(value)) return '';
      
      return formatMoney(
        intl,
        new Money(value, 'AUD')
      );
    };

    return (
      <div className={css.section}>
        <h3 className={css.sectionTitle}>
          <FormattedMessage id={title} />
        </h3>
        {items.map((item, index) =>
          item?.value != null && (
            <div key={index} className={css.lineItem}>
              {item.label === 'TripDetailPage.drivemateABN' ? (
                <div className={css.abnText}>
                  <FormattedMessage id={item.label} />: {item.value}
                </div>
              ) : (
                <>
                  <div className={css.lineItemContent}>
                    <span className={css.itemLabel} style={getLabelStyle(item.isTotal)}>
                      <FormattedMessage id={item.label} />
                    </span>
                    <span className={css.dottedLine}></span>
                    <span
                      className={css.itemValue}
                      style={getHeadStyle(item.isTotal, item?.negative)}
                    >
                      {getAmount(item.value, item?.negative)}
                    </span>
                  </div>
                </>
              )}
            </div>
          )
        )}
        <hr className={css.sectionDivider} />
      </div>
    );
  }
    

  const renderBreakdown = () => {
    if (!breakdownData) return null;

    const sections = [
      {
        title: 'TripDetailPage.hiringEarnings',
        items: [
          {
            label: 'TripDetailPage.durationCharge',
            value: breakdownData.tripPrice + breakdownData.tripdrivemateFees + breakdownData.modifiedTripPayouts + breakdownData.modifiedTripdrivemateFees,
          },
          {
            label: 'TripDetailPage.drivemateFees',
            value: -(breakdownData.tripdrivemateFees + breakdownData.modifiedTripdrivemateFees),
            negative: true,
          },
          {
            label: 'TripDetailPage.total',
            value: breakdownData.calTripPrice + breakdownData.modifiedTripPayouts,
            isTotal: true,
            negative: breakdownData.calTripPrice + breakdownData.modifiedTripPayouts < 0,
          },
        ],
      },
      {
        title: 'TripDetailPage.drivingEarnings',
        condition: breakdownData.distanceTripPrice > 0,
        items: [
          {
            label: 'TripDetailPage.distanceCharge',
            value: breakdownData.distanceTripPrice + breakdownData.distanceDrivemateFees,
          },
          {
            label: 'TripDetailPage.drivemateFees',
            value: -breakdownData.distanceDrivemateFees,
            negative: true,
          },
          {
            label: 'TripDetailPage.total',
            value: breakdownData.distanceTripPrice,
            isTotal: true,
            negative: breakdownData.distanceTripPrice < 0,
          },
        ],
      },
      {
        title: 'TripDetailPage.otherEarnings',
        condition: breakdownData.calOtherEarnings > 0,
        items: [
          ...(breakdownData.tollsTotal !== 0 ? [{
            label: 'TripDetailPage.tollCharge',
            value: breakdownData.tollsTotal,
            negative: breakdownData.tollsTotal < 0,
          }] : []),
          ...(breakdownData.excessMileage !== 0 ? [{
            label: 'TripDetailPage.excessMilage',
            value: breakdownData.excessMileage,
            negative: breakdownData.excessMileage < 0,
          }] : []),
          ...(breakdownData.lateCharge !== 0 ? [{
            label: 'TripDetailPage.lateCharges',
            value: breakdownData.lateCharge,
            negative: breakdownData.lateCharge < 0,
          }] : []),
          ...(breakdownData.fuelCharge !== 0 ? [{
            label: 'TripDetailPage.fuelCharge',
            value: breakdownData.fuelCharge,
            negative: breakdownData.fuelCharge < 0,
          }] : []),
          ...(breakdownData.flexPlus !== 0 ? [{
            label: 'TripDetailPage.flexPlus',
            value: breakdownData.flexPlus,
            negative: breakdownData.flexPlus < 0,
          }] : []),
          ...(breakdownData.fuelRefund !== 0 ? [{
            label: 'TripDetailPage.fuelRefund',
            value: breakdownData.fuelRefund,
            negative: breakdownData.fuelRefund < 0,
          }] : []),
          ...(breakdownData.miscChargeToHost !== 0 ? [{
            label: 'TripDetailPage.miscCharges',
            value: breakdownData.miscChargeToHost,
            negative: breakdownData.miscChargeToHost < 0,
          }] : []),
          ...(breakdownData.miscPayoutToHost !== 0 ? [{
            label: 'TripDetailPage.miscPayout',
            value: breakdownData.miscPayoutToHost,
            negative: breakdownData.miscPayoutToHost < 0,
          }] : []),
          ...(breakdownData.cleanlinessTotal !== 0 ? [{
            label: 'TripDetailPage.cleanlinessTotal',
            value: breakdownData.cleanlinessTotal,
            negative: breakdownData.cleanlinessTotal < 0,
          }] : []),
          ...(breakdownData.subscription !== 0 ? [{
            label: 'TripDetailPage.subscription',
            value: breakdownData.subscription,
            negative: breakdownData.subscription < 0,
          }] : []),
          ...(breakdownData.damageTotal !== 0 ? [{
            label: 'TripDetailPage.totalDamage',
            value: breakdownData.damageTotal,
            negative: breakdownData.damageTotal < 0,
          }] : []),
          {
            label: 'TripDetailPage.total',
            value: breakdownData.calOtherEarnings,
            isTotal: true,
          },
        ],
      },
      {
        title: 'TripDetailPage.yourtotalEarnings',
        items: [
          {
            label: 'TripDetailPage.totalEarnings',
            value: breakdownData.calTripEarnings,
            isTotal: true,
          },
          { label: 'TripDetailPage.includedGST', value: breakdownData.gstIncluded },
          { label: 'TripDetailPage.drivemateABN', value: breakdownData.abnNumber },
        ],
      },
    ];

    return (
      <div className={css.container}>
        {sections.map((section, index) => renderBreakdownSection(section.title, section.items, section.condition))}
      </div>
    );
  };

  return (
    <Page {...pageProps}>
      {topbar}
      <div className={css.contentContainer}>
        <div className={css.contentRow}>
          <div className={css.addonsPageTitles}>
            <div className={css.addonsBreadcrumbs}>
              {parentTransaction && (
                <NamedLink
                  className={css.arrowBack}
                  name={isCustomerRole ? 'OrderDetailsPage' : 'SaleDetailsPage'}
                  params={{ id: parentTransaction.id.uuid }}
                >
                  <span className={css.arrowBackText}>&#8249;</span>
                  {'Back'}
                </NamedLink>
              )}
            </div>
            <div className={css.stepTitle}>
              {isCustomerRole ? 'Trip cost details' : 'Trip earnings'}
            </div>
          </div>
        </div>
        <div className={css.contentRow}>
          <div className={css.addonsSectionLeft}>
            <div className={css.detailsContainerMain}>
              {renderBreakdown()}
            </div>
          </div>
          {(
            <div className={css.addonsSectionRight}>
              <div>
                {parentTransaction && currentListing && (
                  <BookingInfoSection
                    transaction={parentTransaction}
                    isCustomer={isCustomerRole}
                    showAvatar={false}
                    listing={currentListing}
                    currentProvider={currentProvider}
                    intl={intl}
                  />
                )}
                <PartnerInfo
                  currentUserRole={transactionRole}
                  transaction={parentTransaction}
                  otherUser={isProviderRole ? currentCustomer : currentProvider}
                  show={true}
                  isMobileLayout={isMobileLayout}
                  userTypeText={intl.formatMessage({
                    id: isCustomerRole
                      ? 'TripDetailsPage.userTypeText'
                      : 'TripDetailsPage.userTypeTextHost',
                  })}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};

TripDetailsPageComponent.defaultProps = {
  currentUser: null,
  transaction: null,
  parentTransaction: null,
};

const { bool, func, oneOf, shape, string, arrayOf, number } = PropTypes;

TripDetailsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  transaction: propTypes.transaction,
  parentTransaction: propTypes.transaction,
  transactionRole: oneOf([PROVIDER, CUSTOMER]).isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    transactionRef,
    nextLongTermTransaction,
    childLongTermTransactions,
    currentChildLongTermTransaction,
  } = state.TripDetailsPage;
  const { currentUser } = state.user;
  const { transactionUpdateBookingRef } = state.TransactionPage;

  const transactionsUpdateBooking = getMarketplaceEntities(
    state,
    transactionUpdateBookingRef ? [transactionUpdateBookingRef] : []
  );
  const transactionUpdateBooking =
    transactionsUpdateBooking.length > 0 ? transactionsUpdateBooking[0] : null;

  if (nextLongTermTransaction && transactionsUpdateBooking) {
    transactionsUpdateBooking.nextTransaction = nextLongTermTransaction;
    transactionsUpdateBooking.childTransaction = childLongTermTransactions;
    transactionsUpdateBooking.currentChildTransaction = currentChildLongTermTransaction;
  }

  const transactions = getMarketplaceEntities(state, transactionRef ? [transactionRef] : []);
  const transaction = transactions.length > 0 ? transactions[0] : null;
  if (nextLongTermTransaction && transaction) {
    transaction.nextTransaction = nextLongTermTransaction;
    transaction.childTransaction = childLongTermTransactions;
    transaction.currentChildTransaction = currentChildLongTermTransaction;
  }

  const bookingTxId = transaction ? transaction.id.uuid : null;

  return {
    currentUser,
    transaction,
    transactionUpdateBooking,
    bookingUpdateTxs: $bookingUpdateTxsByParentTxId(state, bookingTxId),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
  };
};

const TripDetailsPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(TripDetailsPageComponent);

TripDetailsPage.loadData = loadData;

export default TripDetailsPage;
