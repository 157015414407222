import React, { useEffect, useState } from 'react';
import { bool, func, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { ensureCurrentUser, ensurePaymentMethodCard, ensureStripeCustomer } from '../../util/data';
import { propTypes } from '../../util/types';
import { deletePaymentMethod, savePaymentMethod } from '../../ducks/paymentMethods.duck';
import { handleCardSetup } from '../../ducks/stripe.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import {
  Footer,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  Page,
  SavedCardDetails,
  UserNav,
  Modal,
  Button
} from '../../components';
import { updateUserData } from '../../ducks/user.duck';
import { TopbarContainer } from '../../containers';
import { PaymentMethodsForm } from '../../forms';
import { createStripeSetupIntent, loadData, stripeCustomer } from './PaymentMethodsPage.duck.js';
import get from 'lodash/get';
import axios from 'axios';
import moment from 'moment';
import css from './PaymentMethodsPage.css';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { getConnectCalendarTabStatus } from '../../ducks/TabPanels.duck';
import { pushGTMGuestVerify } from '../../util/gtm/gtmHelpers';
import { EVENT_GUEST_VERIFY_ADD_CREDITCARD } from '../../util/gtm/gtmConstants';
import visaImg from '../../assets/paymentIcons/visa.png';
import paypalImg from '../../assets/paymentIcons/paypal.png';
import mastercardImg from '../../assets/paymentIcons/mastercard.png';
import americanexpressImg from '../../assets/paymentIcons/americanexpress.png';
import Loader from './Loader';
import downlaodIcon from '../../assets/payouts/downloadIcon.svg'
import { getOpenInvoices } from '../CheckoutPage/CheckoutPage.duck.js';

const { LatLng } = sdkTypes;

const trimStringToMaxLength = (inputString, maxLength) => {
  if (inputString && inputString.length > maxLength) {
    return inputString.substring(0, maxLength);
  }

  return inputString;
};

const brandImage = {
  "visa": visaImg,
  "mastercard": mastercardImg,
  "paypal": paypalImg,
  "amex": americanexpressImg,
}

export const labelColor = {
  "Uncaptured": "rgb(124, 124, 124)",
  "Succeeded": "rgb(0, 163, 173)",
  "Cancelled": "rgb(124, 124, 124)",
  "Failed": "rgb(217, 19, 32)",
  "Refunded": "rgb(2, 103, 134)",
  "Partially refunded": "rgb(2, 103, 134)",
  "Trip": "rgb(38, 128, 235)",
  "Refundable deposit": "rgb(20, 115, 230)",
  "Drive lah Go deposit": "rgb(20, 115, 230)",
  "Modification": "rgb(255, 205, 5)",
  "Fuel": "rgb(124, 232, 0)",
  "Subscription": "rgb(74, 74, 74)",
  "Paid": "rgb(0, 163, 173)",
  "Pending": "rgb(255, 205, 5)",
  "Other": "rgb(0, 163, 173)"
}

export const labelBackgroudColor = {
  "Uncaptured": "rgba(124, 124, 124, 0.15)",
  "Succeeded": "rgba(0, 163, 173, 0.15)",
  "Cancelled": "rgba(124, 124, 124, 0.15)",
  "Failed": "rgba(217, 19, 32, 0.15)",
  "Refunded": "rgba(2, 103, 134, 0.15)",
  "Partially refunded": "rgba(2, 103, 134, 0.15)",
  "Trip": "rgba(38, 128, 235, 0.15)",
  "Refundable deposit": "rgba(20, 115, 230, 0.15)",
  "Drive lah Go deposit": "rgba(20, 115, 230, 0.15)",
  "Modification": "rgba(255, 205, 5, 0.15)",
  "Fuel": "rgba(124, 232, 0, 0.15)",
  "Subscription": "rgba(74, 74, 74, 0.15)",
  "Paid": "rgba(0, 163, 173, 0.15)",
  "Pending": "rgb(255, 205, 5, o.15)",
  "Other": "rgba(0, 163, 173, 0.15)"
}

const PayoutCard = ({pd: paymentsData}) => {
  return (
    <div className={css.payoutCard} onClick={() => {
      if(paymentsData.receipt_url){
        window.open(paymentsData.receipt_url, "_blank")
      }
      }} >
    <div className={css.carCard}>
      <div className={css.carDetails}>
        {/* Car Image */}
        <div className={css.carImage}>
          <img src={paymentsData.listing_image} alt="Car" />
        </div>
        <div className={css.carInfo}>
          {/* Primary and Secondary Labels / Breadcrumbs */}
          <div className={css.topBox}>
            <div className={css.statusBox}>
              <p className={css.divContainer} style={{ background: labelBackgroudColor[paymentsData.label], color: labelColor[paymentsData.label] }}>
                {paymentsData.label}
              </p>
              <p className={css.divContainer} style={{ background: labelBackgroudColor[paymentsData.secondary_label], color: labelColor[paymentsData.secondary_label] }}>
                {paymentsData.secondary_label}
              </p>
              {paymentsData.isDisputed && (
                <>
                <p className={css.divContainer} style={{ background: "rgba(0, 163, 173, 0.15)", color: "rgb(0, 163, 173)" }}>
                Disputed/Incidental
              </p>
                </>
              )}
            </div>
            {/* Transaction Date */}
            <div className={css.paidOnBox}>
              {moment.unix(paymentsData.paid_on).format('D MMM')}
            </div>
          </div>
          <div className={css.bankName}>
            {/* Payment Method / card number used */}
            {paymentsData.card_number && paymentsData.card_brand && (
            <p className={css.cardBox}>
              <img className={css.brandImage} src={brandImage[paymentsData.card_brand]} /> ****{paymentsData.card_number}
            </p>
            )}
            {!paymentsData.card_number && !paymentsData.card_brand && (
            <p className={css.cardBox}></p>
            )}
            {/* Amount charged */}
            <p className={css.amountBox}>
              {(
                paymentsData.label === "Partially refunded" && (
                  <>
                    {`$${parseFloat(paymentsData.amount / 100).toFixed(2)}(-$${parseFloat(paymentsData.refunded_amount / 100).toFixed(2)})`}
                  </>
                )
              ) || (paymentsData.isStrikethrough && (
                <>
                  <s>
                    {`$${parseFloat(paymentsData.amount / 100).toFixed(2)}`}
                  </s>
                </>
              )) || (
                  <>
                    {`$${parseFloat(paymentsData.amount / 100).toFixed(2)}`}
                  </>
                )}
            </p>
          </div>
          {/* Booking dates */}
          {paymentsData.start && paymentsData.end && (
          <div className={css.carPlateDates}>
            <div className={css.bookingDates}>
              {`${moment(paymentsData.start).format('D MMM')} - ${moment(paymentsData.end).format('D MMM')}`}
            </div>
            {/* Car Name */}
            <div className={css.carPlateNumber}>
              {trimStringToMaxLength(paymentsData.listing_name, 20)}
            </div>
          </div>
          )}
        </div>
      </div>
    </div>
  </div>
  );
};

const PaymentMethodsPageComponent = props => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cardState, setCardState] = useState(null);
  const [postData, setPostData] = useState([])
  const [emptyList, setEmptyList] = useState('');
  const [startingAfter, setStartingAfter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [csvMonthData, setCsvMonthData] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);


  const {
    currentUser,
    addPaymentMethodError,
    deletePaymentMethodError,
    createStripeCustomerError,
    handleCardSetupError,
    deletePaymentMethodInProgress,
    onCreateSetupIntent,
    onHandleCardSetup,
    onSavePaymentMethod,
    onDeletePaymentMethod,
    fetchStripeCustomer,
    scrollingDisabled,
    onManageDisableScrolling,
    intl,
    stripeCustomerFetched,
    onUpdateUserData,
    connectCalendarTabVisibility,
    fetchCalendarVisibility,
    openInvoices,
    fetchOpenInvoices
  } = props;

  useEffect(() => {
    fetchCalendarVisibility();
    // Generate the list of months from January to December
    const monthOptions = Array.from({ length: 12 }, (_, index) => ({
      value: (index + 1).toString(),
      label: new Date(2020, index, 1).toLocaleString('default', { month: 'long' }),
    }));

    // Generate the list of years from 2020 to the current year
    const currentYear = new Date().getFullYear();
    const yearOptions = Array.from({ length: currentYear - 2019 }, (_, index) => ({
      value: (2020 + index).toString(),
      label: (2020 + index).toString(),
    }));

    setMonths(monthOptions);
    setYears(yearOptions);
  }, [])

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const convertToCSV = (jsonData, headerMapping) => {
    if (!jsonData) return;

    const mappedKeys = Object.keys(headerMapping);
    const mappedColumns = Object.values(headerMapping);

    const csvContent = "data:text/csv;charset=utf-8," +
      mappedColumns.join(",") + "\n" +
      jsonData.map(row => mappedKeys.map(key => row[key] || "").join(",")).join("\n");

    console.log("CSV Content:", csvContent);

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `Drivemate Payments - ${selectedMonth} ${selectedYear}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  const handleExportClick = async () => {
    try {
      if (selectedMonth && selectedYear) {
        // Setting up start and end dates of selected month
        const startDate = new Date(selectedYear, selectedMonth - 1, 1);
        const endDate = new Date(selectedYear, selectedMonth, 0);

        const startDateFormatted = formatDate(startDate);
        const endDateFormatted = formatDate(endDate);

        setExportLoading(true);
        setCsvMonthData('')

        const response = await axios.post(`${process.env.REACT_APP_API_SERVER_URL}/api/payouts/export/payments`, {
          userId: ensureCurrentUser(currentUser).id.uuid,
          start: startDateFormatted,
          end: endDateFormatted
        });

        if (response && response.data && response.data.length > 0) {

          // Setting up header titles for columns.
          const headerMapping = {
            payment_date: "Payment Date",
            payment_amount: "Amount",
            payment_status: "Status",
            payment_type: "Payment Type",
            listing_name: "Listing Name",
            trip_start_date: "Trip Start",
            trip_end_date: "Trip End",
            last_4_digits_card: "Card Number"
          };

          // Data that goes into above colums. (Array of objetcs)
          const csvDataList = [];

          const apiData = response.data;
          apiData.map(ele => {
            if (!ele) return;
            let intdata = {
              payment_date: moment.unix(ele.paid_on).format(),
              payment_amount: parseFloat(ele.invoice_amount / 100).toFixed(2),
              payment_status: ele.label,
              payment_type: ele.secondary_label,
              listing_name: ele.listing_name,
              trip_start_date: ele.start,
              trip_end_date: ele.end,
              last_4_digits_card: `**** ${ele.card_number}`
            }
            csvDataList.push(intdata);
          })

          convertToCSV(csvDataList, headerMapping);

          setExportLoading(false);
          setShowModal(false);
          setCsvMonthData('');
        }
        else {
          setCsvMonthData('No data is there for this month');
          setExportLoading(false);
        }
      }
    }
    catch (error) {
      console.log("Error: ", error);
      setCsvMonthData('Data import Error');
      setExportLoading(false);
    }
  };

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const protectedData = ensuredCurrentUser.attributes.profile.protectedData || {};
  const { blockNo, building, floorUnit, city, country, postalCode } = protectedData;
  const { selectedPlace } = protectedData.location || {};
  const { address, origin } = selectedPlace || {};

  const currentUserLoaded = !!ensuredCurrentUser.id;

  const userName = currentUserLoaded
  ? `${ensuredCurrentUser.attributes.profile.firstName} ${ensuredCurrentUser.attributes.profile.lastName}`
  : null;

  const initalValuesForStripePaymentValues = {
    name: userName,
    blockNo,
    addressLine1: address,
    addressLine2: blockNo,
    location: {
      search: address,
      selectedPlace: {
        address,
        origin: new LatLng(origin && origin.lat, origin && origin.lng),
      },
    },
    building,
    floorUnit,
    city,
    country,
    postalCode,
    postal: postalCode,
  };
  const [initalValuesForStripePayment, setInitalValuesForStripePayment] = useState(initalValuesForStripePaymentValues);


  console.log("start and end dates", startDate, endDate)
  console.log("current user", ensureCurrentUser(currentUser))
  const fetchData = async () => {
    try {
      setLoading(true);
      setEmptyList('')
      const response = await axios.post(`${process.env.REACT_APP_API_SERVER_URL}/api/payouts/v2/list/payments`, {
        // Add your request data here
        // For example: { param1: 'value1', param2: 'value2' }
        userId: ensureCurrentUser(currentUser).id.uuid,
        startingAfter
      });
      console.log("Request data", response.data);
      const data = response.data && response.data.list;

      const postNewData = data && data.map(pd => pd && <PayoutCard pd={pd} />)
      const newData = [...postData, postNewData];
      setPostData(newData);

      if(response.data.hasMore) {
        if(response.data.endingBefore) {
          setStartingAfter(response.data.endingBefore)
        }
       else {
          setStartingAfter(null)
       }
      }
      else {
        setStartingAfter(null)
      }
      setLoading(false);
      setLoading(false);
      if(!newData.length) {
        setEmptyList('You have not done any payments on the platform so far.')
      }
    } catch (error) {
      console.log("Error: ", error);
      setLoading(false);
      setEmptyList('You have not done any payments on the platform so far.')
    }
  };


  const getClientSecret = setupIntent => {
    return setupIntent && setupIntent.attributes ? setupIntent.attributes.clientSecret : null;
  };

  const getPaymentParams = (currentUser, formValues) => {
    const { name, addressLine1, addressLine2, postal, state, city, country } = formValues;
    const addressMaybe =
      addressLine1 && postal
        ? {
            address: {
              city: city,
              country: country,
              line1: addressLine1,
              line2: addressLine2,
              postal_code: postal,
              state: state,
            },
          }
        : {};
    const billingDetails = {
      name,
      email: ensureCurrentUser(currentUser).attributes.email,
      ...addressMaybe,
    };

    const paymentParams = {
      payment_method_data: {
        billing_details: billingDetails,
      },
    };

    return paymentParams;
  };

  const handleSubmit = params => {
    setIsSubmitting(true);

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const stripeCustomer = ensuredCurrentUser.stripeCustomer;
    const { stripe, card, formValues } = params;
    setInitalValuesForStripePayment(formValues);

    onCreateSetupIntent()
      .then(setupIntent => {
        const stripeParams = {
          stripe,
          card,
          setupIntentClientSecret: getClientSecret(setupIntent),
          paymentParams: getPaymentParams(currentUser, formValues),
        };

        return onHandleCardSetup(stripeParams);
      })
      .then(result => {
        const newPaymentMethod = result.setupIntent.payment_method;
        // Note: stripe.handleCardSetup might return an error inside successful call (200), but those are rejected in thunk functions.

        return onSavePaymentMethod(stripeCustomer, newPaymentMethod);
      })
      .then(() => {
        // Update currentUser entity and its sub entities: stripeCustomer and defaultPaymentMethod
        fetchStripeCustomer();
        setIsSubmitting(false);
        setCardState('default');
      })
      .catch(error => {
        console.error(error);
        setIsSubmitting(false);
      });
  };

  const handleRemovePaymentMethod = () => {
    onDeletePaymentMethod().then(() => {
      onUpdateUserData({
        publicData: {
          allowRemoveCard: false,
        },
      }).catch(e => console.error(e));
      fetchStripeCustomer();
    });
  };

  const title = intl.formatMessage({ id: 'PaymentMethodsPage.title' });


  const hasDefaultPaymentMethod =
    currentUser &&
    ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
    ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id;

  // Get first and last name of the current user and use it in the StripePaymentForm to autofill the name field


 
  const card = hasDefaultPaymentMethod
    ? ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).attributes.card
    : null;

  const showForm = cardState === 'replaceCard' || !hasDefaultPaymentMethod;
  const showCardDetails = !!hasDefaultPaymentMethod;

  useEffect(() => {
    fetchCalendarVisibility();
    fetchOpenInvoices();
  }, []);

  useEffect(() => {
    fetchData();
  }, [currentUserLoaded])
  const hasUnpaidInvoices = openInvoices?.totalUnpaidDues > 0;
  let tabs = [...config.custom.tabs];
  tabs = tabs
    .map(tab => ({ ...tab }))
    .filter(tab => {
      // Filter out UnpaidInvoices tab if there are no unpaid invoices
    if (tab.linkProps.name === 'UnpaidInvoices' && !hasUnpaidInvoices) {
      return false;
    }
      if (tab.linkProps.name === 'SyncCalenderPage') {
        return Boolean(connectCalendarTabVisibility);
      }
      return true;
    });
  const currentTab = tabs.find(tab => tab.linkProps.name === 'PaymentMethodsPage');
  if (currentTab) {
    currentTab.selected = true;
  }

  const preventRemoveCard =
    !currentUserLoaded ||
    !get(ensuredCurrentUser, 'attributes.profile.metadata.canRemovePaymentMethod', false);

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="PaymentMethodsPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="PaymentMethodsPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav tabs={tabs} user={currentUser} />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="PaymentMethodsPage.heading" />
            </h1>
            {!stripeCustomerFetched ? null : (
              <React.Fragment>
                {showCardDetails ? (
                  <SavedCardDetails
                    card={card}
                    onManageDisableScrolling={onManageDisableScrolling}
                    onChange={setCardState}
                    currentUser={currentUser}
                    onDeleteCard={handleRemovePaymentMethod}
                    deletePaymentMethodInProgress={deletePaymentMethodInProgress}
                    preventRemoveCard={true}
                  />
                ) : null}
                {showForm ? (
                  <PaymentMethodsForm
                    className={css.paymentForm}
                    formId="PaymentMethodsForm"
                    initialValues={initalValuesForStripePayment}
                    onSubmit={handleSubmit}
                    handleRemovePaymentMethod={handleRemovePaymentMethod}
                    hasDefaultPaymentMethod={hasDefaultPaymentMethod}
                    addPaymentMethodError={addPaymentMethodError}
                    deletePaymentMethodError={deletePaymentMethodError}
                    createStripeCustomerError={createStripeCustomerError}
                    handleCardSetupError={handleCardSetupError}
                    inProgress={isSubmitting}
                  />
                ) : null}
              </React.Fragment>
            )}
          </div>
          <div className={css.payoutContainer}>
              <div className={css.payoutHeadingContainer}>
                <div className={css.payoutHeading}>
                  <h1 className={css.payoutTitle}>Past Payments</h1>
                </div>
                <div className={css.downlaodIcon} onClick={() => setShowModal(true)}>
                <img src={downlaodIcon} alt="" />
                </div>
              </div>
              
              <div className={css.payoutCardContainer}>
                  {!postData && loading && <Loader />  }
                  {emptyList ? <div className={css.emtyList}>{emptyList}</div>: ''}
                  { postData }
                  {postData && loading && <Loader />  }
                  {postData.length && !loading && !emptyList && startingAfter ? <div className={css.loadMore} onClick={() => fetchData()}>{'Load More Results'}</div>: null}
              </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
      <Modal
        // className={css.modalClass}
        isOpen={showModal}
        onManageDisableScrolling={false}
        closeTextClassName={css.closeTextModal}
        disableCloseBtn={false}
        containerClassName={css.modalContainer}
        forceClose={false}
        onClose={() => {
          setExportLoading(false);
          setCsvMonthData('')
          setShowModal(false);
        }}
      >
        <div>
          <div className={css.modalHeadingTitle}>{'Export Payments'}</div>
          <div className={css.dateSelectors}>
            <div className={css.customDatepicker}>
              <select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
              >
                <option value="">Select Month</option>
                {months.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className={css.customDatepickerEnd}>
              <select
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
              >
                <option value="">Select Year</option>
                {years.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {csvMonthData ? <div>{csvMonthData}</div> : ''}
          <div>
            <Button
              inProgress={exportLoading}
              onClick={handleExportClick}
              disabled={!selectedMonth || !selectedYear}
            >
              {'Export Payments'}
            </Button>
          </div>
        </div>
      </Modal>
    </Page>
  );
};

PaymentMethodsPageComponent.defaultProps = {
  currentUser: null,
  addPaymentMethodError: null,
  deletePaymentMethodError: null,
  createStripeCustomerError: null,
  handleCardSetupError: null,
};

PaymentMethodsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  addPaymentMethodError: object,
  deletePaymentMethodError: object,
  createStripeCustomerError: object,
  handleCardSetupError: object,
  onCreateSetupIntent: func.isRequired,
  onHandleCardSetup: func.isRequired,
  onSavePaymentMethod: func.isRequired,
  onDeletePaymentMethod: func.isRequired,
  fetchStripeCustomer: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  const {
    deletePaymentMethodInProgress,
    addPaymentMethodError,
    deletePaymentMethodError,
    createStripeCustomerError,
  } = state.paymentMethods;

  const { stripeCustomerFetched } = state.PaymentMethodsPage;
  const { connectCalendarTabVisibility } = state.tabPanels;
  const { handleCardSetupError } = state.stripe;
  const { openInvoices } = state.CheckoutPage;
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    deletePaymentMethodInProgress,
    addPaymentMethodError,
    deletePaymentMethodError,
    createStripeCustomerError,
    handleCardSetupError,
    stripeCustomerFetched,
    connectCalendarTabVisibility,
    openInvoices
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  onHandleCardSetup: params => dispatch(handleCardSetup(params)),
  onCreateSetupIntent: params => dispatch(createStripeSetupIntent(params)),
  onSavePaymentMethod: (stripeCustomer, newPaymentMethod) =>
    dispatch(savePaymentMethod(stripeCustomer, newPaymentMethod)),
  onDeletePaymentMethod: params => dispatch(deletePaymentMethod(params)),
  onUpdateUserData: data => dispatch(updateUserData(data)),
  fetchCalendarVisibility: () => dispatch(getConnectCalendarTabStatus()),
  fetchOpenInvoices: () => dispatch(getOpenInvoices()),
});

const PaymentMethodsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(PaymentMethodsPageComponent);

PaymentMethodsPage.loadData = loadData;

export default PaymentMethodsPage;