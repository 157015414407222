import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';

import css from './StripeOnboardingAlert.css';

// Due to the layout structure, do not render the banner on the following pages


const StripeOnboardingAlert = props => {
  const {
    rootClassName,
    className,
    isAuthenticated,
    currentUser,
    currentUserStripeAccountRestricted,
    onGetStripeConnectAccountLink
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const user = ensureCurrentUser(currentUser);

  const showBanner =
    user.id &&
    isAuthenticated &&
    currentUserStripeAccountRestricted;

  const { firstName, lastName } = user.attributes.profile;


  return showBanner ? (
    <div className={classes}>
      <p className={css.text}>
        <FormattedMessage id="StripeOnboardingAlert.message" values={{ firstName, lastName }} />
        <span class={css.spanButtonClass}  onClick={onGetStripeConnectAccountLink} ><FormattedMessage id="StripeOnboardingAlert.payoutLink" /></span>
        {/* <Button rootClassName={css.button} onClick={handleStipeOnboarding}>
      </Button> */}
      </p>
     
    </div>
  ) : null;
};

StripeOnboardingAlert.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  authScopes: [],
  currentPage: null,
};

const { array, bool, func, string } = PropTypes;

StripeOnboardingAlert.propTypes = {
  rootClassName: string,
  className: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  currentUser: propTypes.currentUser,
  onLogout: func.isRequired,
  currentPage: string,
};

export default StripeOnboardingAlert;
