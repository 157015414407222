import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import moment from 'moment';
import config from '../../config';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { drivelahApiPost } from '../../util/apiHelper';
import { getCurrentUser } from '../../util/browserStorageHelper';
import { storableError } from '../../util/errors';
import { fetchMonthlyTimeSlots } from '../TransactionPage/TransactionPage.duck';
import {
  denormalisedEntities,
  denormalisedResponseEntities,
  updatedEntities,
} from '../../util/data';
import {
  TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT,
  txIsEnquired,
} from '../../util/transaction';
import { types as sdkTypes } from '../../util/sdkLoader';
import * as Actions from './EditTripPage.actions';
import * as selectors from './EditTripPage.selectors';
import { $bookingTx, $lastBookingUpdateByTxId } from './EditTripPage.selectors';
import * as editTripHelpers from './EditTripPage.helpers';
import routeConfiguration from '../../routeConfiguration';
import {
  createResourceLocatorString,
  findRouteByRouteName,
  pathByRouteName,
} from '../../util/routes';
import { createSlug } from '../../util/urlHelpers';
import * as editTripApi from './EditTip.api';
import { timestampToDate } from '../../util/dates';
import { apiErrorsMap } from '../../util/api/apiErrorsTypes';
import { sendNotification } from '../../util/notification';
import { updateBookingRequest } from './EditTip.api';

const { UUID } = sdkTypes;
const { apiUrl } = config;

const listingRelationship = txResponse => {
  return txResponse.data.data.relationships.listing.data;
};

export const fetchTransaction = (id, txRole) => (dispatch, getState, sdk) => {
  dispatch(Actions.fetchTransactionRequest());
  let txResponse = null;

  return sdk.transactions
    .show(
      {
        id,
        include: [
          'customer',
          'customer.profileImage',
          'customer.attributes.protectedData',
          'customer.attributes.profile.protectedData',
          'customer.attributes.profile.publicData',
          'provider',
          'provider.profileImage',
          'provider.attributes.protectedData',
          'provider.attributes.profile.protectedData',
          'provider.attributes.profile.publicData',
          'listing',
          'listing.images',
          'booking',
          'reviews',
          'reviews.author',
          'reviews.subject',
        ],
        ...IMAGE_VARIANTS,
      },
      { expand: true }
    )
    .then(async response => {
      txResponse = response;
      const listingId = listingRelationship(response).id;
      const entities = updatedEntities({}, response.data);
      const listingRef = { id: listingId, type: 'listing' };
      const transactionRef = { id, type: 'transaction' };
      const denormalised = denormalisedEntities(entities, [listingRef, transactionRef]);
      const listing = denormalised[0];
      const transaction = denormalised[1];

      const isLongTerm = get(transaction, 'attributes.protectedData.isLongTermRental');
      if (isLongTerm) {
        const { currentUser } = getState().user;
        const cachedUser = getCurrentUser();
        const userId = (cachedUser && cachedUser.id.uuid) || (currentUser && currentUser.id.uuid);
        const childTransactionIds = get(transaction, 'attributes.metadata.childTransactionIds');
        const getBunchTransactionURL = 'transactions/retrieve-bunch-transactions';
        const data = {
          userId,
          transactionIds: childTransactionIds,
        };
        const childTransactionsRes = await drivelahApiPost(getBunchTransactionURL, data);
        const transactionParent = get(txResponse, 'data.data');
        const childTransactions = get(childTransactionsRes, 'data.data');
        dispatch(Actions.addCurrentChildLongTermTransaction(childTransactions));
        const nextTransactionId = get(transactionParent, 'attributes.metadata.nextTransaction');
        const currentChildTransactionId = get(
          transactionParent,
          'attributes.metadata.currentChildTransaction'
        );
        const nextTransaction = childTransactions.find(
          child => child.id.uuid === nextTransactionId
        );
        const currentChildTransaction = childTransactions.find(
          child => child.id.uuid === currentChildTransactionId
        );
        dispatch(Actions.addNextLongTermTransaction(nextTransaction));
        dispatch(Actions.addCurrentChildLongTermTransaction(currentChildTransaction));
      }
      // Fetch time slots for transactions that are in enquired state
      const canFetchTimeslots =
        txRole === 'customer' &&
        config.enableAvailability &&
        transaction &&
        txIsEnquired(transaction);

      if (canFetchTimeslots) {
        dispatch(fetchTimeSlots(listingId));
      }

      fetchMonthlyTimeSlots(dispatch, listing);

      const canFetchListing = listing && listing.attributes && !listing.attributes.deleted;
      if (canFetchListing) {
        if (txRole === 'customer') {
          return sdk.listings.show({
            id: listingId,
            include: ['author', 'author.profileImage', 'images'],
            ...IMAGE_VARIANTS,
          });
        } else {
          return sdk.ownListings.show({
            id: listingId,
            include: ['author', 'author.profileImage', 'images'],
            ...IMAGE_VARIANTS,
          });
        }
      } else {
        return response;
      }
    })
    .then(response => {
      const finalTxResponse = txResponse;

      dispatch(addMarketplaceEntities(finalTxResponse));
      dispatch(addMarketplaceEntities(response));
      dispatch(Actions.fetchTransactionSuccess(finalTxResponse));
      return response;
    })
    .catch(e => {
      dispatch(Actions.fetchTransactionError(storableError(e)));
      throw e;
    });
};

const IMAGE_VARIANTS = {
  'fields.image': [
    // Profile images
    'variants.square-small',
    'variants.square-small2x',

    // Listing images:
    'variants.landscape-crop',
    'variants.landscape-crop2x',
  ],
};

const isNonEmpty = value => {
  return typeof value === 'object' || Array.isArray(value) ? !isEmpty(value) : !!value;
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

const fetchTimeSlots = listingId => (dispatch, getState, sdk) => {
  dispatch(Actions.fetchTimeSlotsRequest);

  // Time slots can be fetched for 90 days at a time,
  // for at most 180 days from now. If max number of bookable
  // day exceeds 90, a second request is made.

  const maxTimeSlots = 90;
  // booking range: today + bookable days -1
  const bookingRange = config.dayCountAvailableForBooking - 1;
  const timeSlotsRange = Math.min(bookingRange, maxTimeSlots);

  const start = moment
    .utc()
    .startOf('day')
    .toDate();
  const end = moment()
    .utc()
    .startOf('day')
    .add(timeSlotsRange, 'days')
    .toDate();
  const params = { listingId, start, end };

  return dispatch(timeSlotsRequest(params))
    .then(timeSlots => {
      const secondRequest = bookingRange > maxTimeSlots;

      if (secondRequest) {
        const secondRange = Math.min(maxTimeSlots, bookingRange - maxTimeSlots);
        const secondParams = {
          listingId,
          start: end,
          end: moment(end)
            .add(secondRange, 'days')
            .toDate(),
        };

        return dispatch(timeSlotsRequest(secondParams)).then(secondBatch => {
          const combined = timeSlots.concat(secondBatch);
          dispatch(Actions.fetchTimeSlotsSuccess(combined));
        });
      } else {
        dispatch(Actions.fetchTimeSlotsSuccess(timeSlots));
      }
    })
    .catch(e => {
      dispatch(Actions.fetchTimeSlotsError(storableError(e)));
    });
};

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = params => (dispatch, getState) => {
  const txId = new UUID(params.id);
  const state = getState().AddOnsPage;
  const txRef = state.transactionRef;
  const txRole = params.transactionRole;

  // In case a transaction reference is found from a previous
  // data load -> clear the state. Otherwise keep the non-null
  // and non-empty values which may have been set from a previous page.
  const initialValues = txRef ? {} : pickBy(state, isNonEmpty);
  dispatch(Actions.setInitialValues(initialValues));

  // Sale / order (i.e. transaction entity in API)
  return Promise.all([dispatch(fetchTransaction(txId, txRole))]);
};

const updateBookingDatesSet = value => dispatch => {
  dispatch(Actions.updateBookingDatesSet(value));
};

export const tripDatesSet = () => (dispatch, getState) => {
  const state = getState();
  const tripBookingDates = selectors.$tripBookingDates(state);
  dispatch(Actions.tripDatesSet(cloneDeep(tripBookingDates)));
};

export const initiateSpeculativeUpdateBooking = dates => (dispatch, getState, sdk) => {
  const startDate = moment(dates.bookingStartDate.date).format('YYYY-MM-DD');
  const startTime = moment(+dates.bookingStartTime).format('HH:mm');
  const startDateTime = moment(+dates.bookingStartTime).toDate();
  const endDate = moment(dates.bookingEndDate.date).format('YYYY-MM-DD');
  const endTime = moment(+dates.bookingEndTime).format('HH:mm');
  const endDateTime = moment(+dates.bookingEndTime).toDate();
  const updateDates = { start: startDateTime, end: endDateTime };

  dispatch(updateBookingDatesSet(updateDates));

  const state = getState();

  const parentTx = selectors.$bookingTx(state);
  console.log("parentTx", parentTx);
  const isDistanceCharge = get(parentTx, 'attributes.protectedData.isDistanceCharge', false);
  console.log("isDistanceCharge", isDistanceCharge);

  // if (!selectors.$shouldMakeEstimateRequest(state)) {
  //   return ;
  // }

  dispatch(Actions.estimatedTxRequestPending());

  editTripApi.updateSpeculativeRequest($bookingTx(state).id.uuid, updateDates.start, updateDates.end, isDistanceCharge, sdk)
    .then(response => {
      dispatch(Actions.estimatedTxRequestSuccess(denormalisedResponseEntities(response)[0]));
    })
    .catch(error => {
      dispatch(Actions.estimatedTxRequestError(apiErrorsMap[error.message]));
    })
  ;
};

export const updateBookingClick = (history, isTripExtension, transactionId, isListingPriceIncreased) => (dispatch, getState, sdk) => {
  const state = getState();
  const bookingTx = selectors.$bookingTx(state);
  const ubDates = selectors.$updateBookingDates(state);
  const hoursDiff = selectors.$hoursDiff(state);
  const isSameDates = selectors.$isSameTripAndUpdateDates(state);
  const routes = routeConfiguration();
  const uvk = get(state, 'user.currentUser.attributes.profile.privateData.userVerificationKey', null);
  const onlyUpdateParentTx = !selectors.$isShowEstimatedBreakdown(state);

  const isDistanceCharge = get(bookingTx, 'attributes.protectedData.isDistanceCharge', false);
  console.log("isDistanceCharge in click", isDistanceCharge);

  if (onlyUpdateParentTx) {
    dispatch(Actions.updateBookingInitiateRequest());
    editTripApi.updateBookingRequest({
      bookingTxId: bookingTx.id.uuid,
      updateStart: ubDates.start,
      updateEnd: ubDates.end,
      isDistanceCharge,
      sdk,
      onlyUpdateParentTx
    })
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(Actions.updateBookingInitiateSuccess());
        const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, {
          id: bookingTx.id.uuid,
        });
        sendNotification({
          userId: bookingTx.customer.id.uuid,
          transactionId: bookingTx.id.uuid,
          transition: TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR,
          uvk,
        });
        history.push(orderDetailsPath);
      })
      .catch(error => dispatch(Actions.updateBookingInitiateError(apiErrorsMap[error.message])))
    ;
  } else {
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
    const listing = bookingTx.listing;
    const initialValues = {
      listing,
      bookingData: {
        transactionId: bookingTx.id,
      },
      bookingDates: {
        bookingStart: timestampToDate(ubDates.start),
        bookingEnd: timestampToDate(ubDates.end),
      },
    };
    dispatch(setInitialValues(initialValues));


    const currUser = state.user.currentUser;
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) }
      ),
      {
        transactionId: bookingTx.id,
        updateBooking: true,
        isTripExtension: isTripExtension,
        parentTransactionId: transactionId,
        bookingTxId: bookingTx.id.uuid,
        currUserId: currUser.id.uuid,
        isListingPriceIncreased: isListingPriceIncreased,
        updateStart: (new Date(ubDates.start)).getTime(),
        updateEnd: (new Date(ubDates.end)).getTime(),
      }
    );
  }
};

export const acceptUpdateBooking = bookingTxId => (dispatch, getState, sdk) => {
  const state = getState();
  const uvk = get(state, 'user.currentUser.attributes.profile.privateData.userVerificationKey', null);
  const userId = get(state, 'user.currentUser.id.uuid', null);

  dispatch(Actions.acceptUpdateBookingRequest());
  return editTripApi.acceptUpdateBookingsRequest(bookingTxId, sdk)
    .then(response => {
      if (response && response.data && response.data.data.attributes.lastTransition === TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF) {
        sendNotification({
          userId,
          transactionId: bookingTxId,
          transition: TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
          uvk,
        });
      } else {
        sendNotification({
          userId,
          transactionId: bookingTxId,
          transition: TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT,
          uvk,
        });
      }
      dispatch(addMarketplaceEntities(response));
      dispatch(Actions.acceptUpdateBookingSuccess());
    })
    .catch(error => {
      dispatch(Actions.acceptUpdateBookingError(error.message))
      return error.message;
    })
  ;
};

export const cancelUpdateBooking = bookingTxId => (dispatch, getState, sdk) => {
  const state = getState();
  const uvk = get(state, 'user.currentUser.attributes.profile.privateData.userVerificationKey', null);
  const userId = get(state, 'user.currentUser.id.uuid', null);

  dispatch(Actions.cancelUpdateBookingRequest());
  editTripApi.cancelUpdateBookingsRequest(bookingTxId, sdk)
    .then(response => {
      sendNotification({
        userId,
        transactionId: bookingTxId,
        transition: TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP,
        uvk,
      });
      dispatch(addMarketplaceEntities(response));
      dispatch(Actions.cancelUpdateBookingSuccess());
      dispatch(fetchLastUpdateBookingTx(bookingTxId));
    })
    .catch(error => dispatch(Actions.cancelUpdateBookingError(error.message)))
  ;
};

export const cancelAllUpdateBookingTxs = bookingTxId => (dispatch, getState, sdk) => {
  dispatch(Actions.cancelAllUpdateBookingTxsRequest());
  return editTripApi.cancelAllUpdateBookingsRequest(bookingTxId, sdk)
    .then(() => dispatch(Actions.cancelAllUpdateBookingTxsSuccess()))
    .catch(error => dispatch(Actions.cancelAllUpdateBookingTxsError(error.message)))
  ;
};

export const fetchUpdateBookingTxs = parentTx => (dispatch, getState, sdk) => {
  dispatch(Actions.fetchUpdateBookingTxsRequest());
  const reqs = editTripHelpers.getBookingUpdatesTxsIds(parentTx).map(buTxId => {
    return sdk.transactions.show({ id: new UUID(buTxId) });
  });
  Promise.all(reqs)
    .then(responses => {
      responses.forEach(response => dispatch(addMarketplaceEntities(response)));
      dispatch(Actions.fetchUpdateBookingTxsSuccess());
    })
    .catch(() => dispatch(Actions.fetchUpdateBookingTxsError()))
  ;
};

export const fetchLastUpdateBookingTx = bookingTxId => (dispatch, getState, sdk) => {
  const lastBookingUpdateTx = $lastBookingUpdateByTxId(getState(), bookingTxId);
  if (!lastBookingUpdateTx || (lastBookingUpdateTx && !lastBookingUpdateTx.txId)) {
    return ;
  }
  dispatch(Actions.fetchLastUpdateBookingTxRequest());
  return sdk.transactions.show({ id: new UUID(lastBookingUpdateTx.txId) })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(Actions.fetchLastUpdateBookingTxSuccess());
    })
    .catch(() => dispatch(Actions.fetchLastUpdateBookingTxError()))
  ;
};
