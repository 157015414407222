import React from 'react';
import css from './GrabRentACarLandingPage.css';
import classNames from 'classnames';
// import { FormattedMessage } from 'react-intl';
import imageSource from '../../util/imageSource';

const DriveLahVsCompanies = ({ isMobileLayout }) => {
  const gridContent = [
    {
      contentImage: imageSource.grabBanner.image4,
      contentTitle: 'Flexibility',
      contentDescription:
        ' Got time on your hands? You can rent a car for a few hours, a day or a week with Drive mate. No Contract, zero commitments.',
    },
    {
      contentImage: imageSource.grabBanner.image5,
      contentTitle: 'Affordability',
      contentDescription:
        ' Rent that is 30-40% more affordable than other rental options. Now, you don’t have to worry about profits.',
    },
    {
      contentImage: imageSource.grabBanner.image6,
      contentTitle: 'Availability ',
      contentDescription:
        ' With a huge fleet of cars, there’ll be a car near you, always. Rent a car in minutes.',
    },
  ];
  const sliderConfig = {
    // className: css.slider,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 4,
    initialSlide: 0,
    infinite: false,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          initialSlide: 0,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className={css.driveLahVsCompanies}>
      <div className={css.container}>
        <div className={classNames(css.row, css.secTitle)}>
          <div className={classNames(css.column6, css.contentHeading)}>
            <h2>
              Drive mate vs <br /> Other Rental Companies
            </h2>
            <p>Why choosing Drive mate is a no-brainer?</p>
          </div>
          <div className={classNames(css.column6, css.contentGrid)}>
            <table className={css.comparisonTable}>
              <thead>
                <tr>
                  <th></th>
                  <th className={css.drivelahPoints} width="255">
                    <span className={css.curveBorder}>Drive mate</span>
                  </th>
                  <th className={css.otherPoints} width="255">
                    <span className={css.curveBorder}>Other Rental Companies</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Price
                  </td>
                  <td className={css.drivelahPoints}>
                    <strong>$4/hr &amp; $29/day</strong> no mileage cap
                  </td>
                  <td className={css.otherPoints}>$8/hr &amp; $60/day limited mileage</td>
                </tr>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Simple Rent Structure
                  </td>
                  <td className={css.drivelahPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.checkBlue} alt="" />
                      </span>
                      Same all day
                    </div>
                  </td>
                  <td className={css.otherPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.crossRed} alt="" />
                      </span>
                      Duration, km &amp; time of the day
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Long Lease Commitment
                  </td>
                  <td className={css.drivelahPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.crossBlue} alt="" />
                      </span>
                      None
                    </div>
                  </td>
                  <td className={css.otherPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.checkRed} alt="" />
                      </span>
                      Fixed Contract
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Wide Car Variety
                  </td>
                  <td className={css.drivelahPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.checkBlue} alt="" />
                      </span>
                      50+ models
                    </div>
                  </td>
                  <td className={css.otherPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.crossRed} alt="" />
                      </span>
                      4-5 models
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Car pickup points
                  </td>
                  <td className={css.drivelahPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.checkBlue} alt="" />
                      </span>
                      Always nearby
                    </div>
                  </td>
                  <td className={css.otherPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.crossRed} alt="" />
                      </span>
                      Limited
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Hidden Charges
                  </td>
                  <td className={css.drivelahPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.crossBlue} alt="" />
                      </span>
                      None
                    </div>
                  </td>
                  <td className={css.otherPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.checkRed} alt="" />
                      </span>
                      Yes
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={css.pointsTitle} align="right">
                    Late Return Charges
                  </td>
                  <td className={css.drivelahPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.crossBlue} alt="" />
                      </span>
                      Grace Period
                    </div>
                  </td>
                  <td className={css.otherPoints}>
                    <div className={css.contentData}>
                      <span className={css.icon}>
                        <img src={imageSource.grabBanner.checkRed} alt="" />
                      </span>
                      Strict Terms
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td className={css.pointsTitle} align="right"></td>
                  <td className={css.drivelahPoints}>
                    <span className={css.curveBorder}></span>
                  </td>
                  <td className={css.otherPoints}>
                    <span className={css.curveBorder}></span>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriveLahVsCompanies;
