import React from 'react';
import { get } from 'lodash';
import moment from 'moment-timezone';

import css from './TripModificationPeriod.css';
import editIcon from '../../assets/edit-icon-white.svg';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';

const TripExtensionModficationPeriod = ({ transaction, isTripExtension, history, parentTransactionId }) => {
  if (!transaction) return null;

  const compareTripDurations = (
    newStart,
    newEnd,
    oldStart,
    oldEnd,
    ) => {
    const MS_TO_HOURS = 1000 * 60 * 60; // Convert ms to hours
    const newDuration = (newEnd - newStart) / MS_TO_HOURS;
    const oldDuration = (oldEnd - oldStart) / MS_TO_HOURS;
    const differenceInHours = newDuration - oldDuration;
    const absDifference = Math.abs(differenceInHours);

    // Round to nearest whole number
    const roundedDifference = Math.round(absDifference);

    let differenceFormatted = '';
    if (roundedDifference >= 24) {
      const days = Math.round(roundedDifference / 24);
      differenceFormatted = `${differenceInHours > 0 ? 'extra' : 'less'
      } ${days} day${days > 1 ? 's' : ''}`;
    } else if (roundedDifference >= 1) {
      differenceFormatted = `${differenceInHours > 0 ? 'extra' : 'less'
      } ${roundedDifference} hour${roundedDifference > 1 ? 's' : ''}`;
    } else {
      const minutes = Math.round(absDifference * 60);
      differenceFormatted = `${differenceInHours > 0 ? 'extra' : 'less'
      } ${minutes} min${minutes > 1 ? 's' : ''}`;
    }

    return {
      isSameOrShorter: newDuration < oldDuration,
      differenceInHours,
      differenceFormatted,
    };
  };

  const tz =
    (transaction &&
      transaction.listing &&
      transaction.listing.attributes.publicData.listingTimezone) ||
    'Australia/Sydney';

  const { bookingDisplayStart, bookingDisplayEnd } = get(
    transaction,
    'attributes.protectedData.initialTx.attributes.protectedData',
    {}
  );

  const originalFormattedStart =
    bookingDisplayStart &&
    moment(bookingDisplayStart)
      .tz(tz)
      .format('D MMM h:mmA');
  const originalFormattedEnd =
    bookingDisplayEnd &&
    moment(bookingDisplayEnd)
      .tz(tz)
      .format('D MMM h:mmA');


  const { updateBookingStartTimestamp, updateBookingEndTimestamp } = get(
    transaction,
    'attributes.protectedData',
    {}
  );

  const updatedFormattedStart =
    updateBookingStartTimestamp && moment(updateBookingStartTimestamp).format('D MMM h:mmA');
  const updatedFormattedEnd =
    updateBookingEndTimestamp && moment(updateBookingEndTimestamp).format('D MMM h:mmA');

  const handleClick = () => {
    const routes = routeConfiguration();
    history.push(createResourceLocatorString('EditTripPage', routes, { id: parentTransactionId, action: isTripExtension ? 'extend-trip': 'modify-trip' }, {}),);
  };

  const extraDuration = compareTripDurations(
    updateBookingStartTimestamp, updateBookingEndTimestamp, bookingDisplayStart, bookingDisplayEnd
  );
  console.log('extraDuration', extraDuration)

  return (
    <div className={css.updatedDatesWrapper}>
      <div className={css.modificationDatesContainer}>
        <div className={css.headText}>
          {isTripExtension ? 'New trip end' : 'Modified trip dates'}
        </div>
        <div className={css.datesContainerWrapper}>
          <div className={css.item}>
            {isTripExtension ? (
              <span className={css.updated}>{updatedFormattedEnd}</span>
            ) : (
              <span className={css.updated}>
                {updatedFormattedStart} - {updatedFormattedEnd}
              </span>
            )}
          </div>
          <div className={css.editButton} onClick={handleClick}>
            <img className={css.editIcon} src={editIcon} alt="edit icon" />
          </div>
        </div>
      </div>
      {isTripExtension ? (
          <div className={css.tripPeriodText}>
            You are extending your trip by {extraDuration.differenceFormatted}. Drop-off changes from {originalFormattedEnd} to {updatedFormattedEnd}.
            <span className={css.clickButton} onClick={handleClick}>
            Click here to edit
          </span>
          </div>) : (
          <div className={css.tripPeriodText}>
          You are booking for {extraDuration.differenceFormatted}. Trip dates change from{' '}
          {originalFormattedStart} and {originalFormattedEnd} to the one's above.
          <span className={css.clickButton} onClick={handleClick}>
            Click here to edit
          </span>
        </div>
        )
      }
    </div>
  );
};

export default TripExtensionModficationPeriod;
