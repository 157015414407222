const CLOUDINARY_BASE_URL = 'https://dv0eqz2t0y9gj.cloudfront.net';

const imageSource = {
  bannerImage: {
    bannerImage: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/08-2024/banner-image.webp`,//done
    banner2Image: `${CLOUDINARY_BASE_URL}/drivelah/landingpage/bannerImage2.webp`,//done
    bannerImage2x: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/08-2024/banner-image@2x.webp`,//done
    bannerImageMobile: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/08-2024/banner-mobile-image.webp`,//done
    bannerImageMobile2x: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/08-2024/banner-mobile-image@2x.webp`,//done
    banner2Image2x: `${CLOUDINARY_BASE_URL}/drivelah/landingpage/bannerImage2@2x.webp`,//done
    banner2ImageMobile: `${CLOUDINARY_BASE_URL}/drivelah/landingpage/bannerImage2Mobile.webp`,//done
    banner2ImageMobile2x: `${CLOUDINARY_BASE_URL}/drivelah/landingpage/bannerImage2Mobile@2x.webp`,//done
    rentersImage: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/renters-img.webp`,//done
    videoThumb: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/video-thumb.webp`,//done
    flexThumb: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/flexDesk.webp`,//done
  },
  drivemateSection:{
    leftImg : `${CLOUDINARY_BASE_URL}/drivemate/assets/drivemateSection/left.png`,
    rightImage : `${CLOUDINARY_BASE_URL}/drivemate/assets/drivemateSection/rightImg.png`,
    CheckIcon : `${CLOUDINARY_BASE_URL}/drivemate/assets/drivemateSection/check-circle-solid.svg`,
    profileImg : `${CLOUDINARY_BASE_URL}/drivemate/assets/drivemateSection/profileImg.png`,
    videoIcon : `${CLOUDINARY_BASE_URL}/drivemate/assets/drivemateSection/videoIcon.svg`,
    guest : `${CLOUDINARY_BASE_URL}/drivemate/assets/drivemateSection/guest.svg`,
    tracking : `${CLOUDINARY_BASE_URL}/drivemate/assets/drivemateSection/tracking.svg`,
    insurance : `${CLOUDINARY_BASE_URL}/drivemate/assets/drivemateSection/insurance.svg`,
    imageMobile : `${CLOUDINARY_BASE_URL}/drivemate/assets/drivemateSection/imageMobile.png`,
    bgMobile : `${CLOUDINARY_BASE_URL}/drivemate/assets/drivemateSection/bgMobile.png`,
    bankSectionImg : `${CLOUDINARY_BASE_URL}/drivemate/assets/drivemateSection/bankSectionImg.png`,
  },
  redirectBanner: {
    antiCompCar: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/redirect-banner-image.webp`,//done
    antiCompCarMobile: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/redirect-banner-image-mobile.webp`,//done
    dLbgLogo: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/drive-lah-bg-logo.webp`,//done
    dLbgLogoMobile: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/drive-lah-bg-logo-mobile.webp`,//done
  },
  grabBanner: {
    bannerBackground: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/banner-background.webp`,//done
    bannerBackgroundRent: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/rent-banner.webp`,//done
    bannerLogo: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/banner-logo.png`,//done
    bannerLogoMobile: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/banner-logo-mobile.png`,//done
    grabLogo: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/grab-rentals-desktop.png`,//done
    grabLogoWhite: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/grab-rentals-mobile.png`,//done
    approveBlueIcon: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/approval-symbol-in-badge-blue@2x.png`,//done
    saveMoneyIcon: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/noun_Save Money_776848@2x.png`,//done
    easyAccessIcon: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/noun_easy_3428320@2x.png`,//done
    approveBlueIconMobile: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/approval-symbol-in-badgeM@2x.png`,//done
    saveMoneyIconMobile: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/noun_Save Money_776848M@2x.png`,//done
    easyAccessIconMobile: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/1024px-Dollar_sign_in_circle.svg@2x.png`,//done
    checkRed: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/approval-symbol-in-badge@2x1.png`,//done
    checkBlue: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/approval-symbol-in-badge11@2x.png`,//done
    crossRed: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/noun_cancel_1888477red@2x.png`,//done
    crossBlue: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/noun_cancel_1888477@2x.png`,//done
    image1: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/image1.png`,//done
    image2: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/image2.png`,//done
    image3: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/image3.png`,//done
    image4: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/image4.png`,//done
    image5: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/image5.png`,//done
    image6: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/image6.png`,//done
    bgPattern: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/light-bg.svg`,//done
    userIcon: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/userIcon.svg`,//done
  },
  backgroundGo: {
    go: `${CLOUDINARY_BASE_URL}/drivelah/background-auth@1440.webp`,//done
  },
  landingPage: {
    sectionHeroInstant: `${CLOUDINARY_BASE_URL}/drivelah/landingpage/sectionHeroInstant.webp`,//done
  },
  antiCompetition: {
    arrowRight: `${CLOUDINARY_BASE_URL}/drivelah/antiCompetition/arrow-down-yellow.svg`,//done
    carsAvailable: `${CLOUDINARY_BASE_URL}/drivelah/antiCompetition/cars-available.svg`,//done
    bluesg: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/bluesg.webp`,//done
    drivelahSVG: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/drivelah.png`,//done
    smallCategories: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/category-small.webp`,//done
    compactPremiumCategories: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/compact-premium.webp`,//done
    compactSUVCategories: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/compact-suv.webp`,//done
    deliveryBanner: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/delivery-banner-image.webp`,//done
    instagramImage: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/drivelah-anti-competition-meta-image.webp`,//done
  },
  antiCompetition1: {
    midLargeCategories: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/mid-large.png`,//done
    fullSUVCategories: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/full-suv.png`,//done
    mpvCategories: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/mpv.png`,//done
    wagonCategories: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/wagon.png`,//done
    getGo: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/getgo.png`,//done
    shariot: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/shariot.png`,//done
    tribecar: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/tribecar.png`,//done
    hotSaleIcon: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/hot-sale.png`,//done
    happyUsers: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/happy-users.svg`,//done
    successfulTrips: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/successful-trips.svg`,//done
    slide1Thumbnail: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/slide-1-thumb.png`,//done
    slide2Thumbnail: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/slide-2-thumb.png`,//done
    slide3Thumbnail: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/slide-3-thumb.png`,//done
  },
  aboutUs: {
    driveMateLogo: `${CLOUDINARY_BASE_URL}/drivelah/aboutus/driveMateLogo.png`,//done
    heroImage: `${CLOUDINARY_BASE_URL}/drivelah/heroImage.webp`,//done
  },
  bLanding: {
    appStoreBadge: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/appstore-badge.svg`,//done
    flexColor: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/flex-color.svg`,//done
    googlePlayBadge: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/google-play-badge.png`,//done
    lightBg: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/light-bg.svg`,//done
    point1: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/point-1.svg`,//done
    point2: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/point-2.svg`,//done
    point3: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/point-3.svg`,//done
  },
  grabBanner1: {
    arrowBlue: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/arrow-right-blue.svg`,//done
    arrowYellow: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/arrow-right-yellow.svg`,//done
    grabIcon: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/grab-icon.svg`,//done
    donationBG: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/Logo-back-svg.png`,//done
    driveToMalaysia: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/malaysia.svg`,//done
  },
  landingPageNew :{
    GTInsurenceIcon : `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/gtInsurence.svg`,
    GTInsurenceIcon1x : `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/gt-logo-insurance2x.png`,
    AllianzInsurenceIcon : `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/allianceInsurence.svg`,
    dc : `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/daily-chores/two-women-shopping-cart.jpg`,
    car2 : `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/weekend-outing/family-road-trip.jpg`,
    car3 : `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/visit-friends/company-young-guys-welcoming-each-other-car.jpg`,
    outing : `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/move-houses/young-family-moving-into-their-new-house.jpg`,
    loveDrivemateSec1 : `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/testimonials/medium-shot-happy-couple-having-fun.jpg`,
    loveDrivemateSec2 : `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/testimonials/cute-family-taking-hot-drink-winter-field.jpg`,
    loveDrivemateSec3 : `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/testimonials/close-up-side-portrait-happy-man-driving-car.jpg`,
    loveDrivemateSec4 : `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/testimonials/smiling-women-near-man-car.jpg`,
    bannerImage : `${CLOUDINARY_BASE_URL}/drivemate/assets/landingPageNew/banner-bg.jpg`,
  },
  seoLandingPage: {
    cars: {
      audi: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/audi.jpg`,//done
      bmw: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/bmw.jpg`,//done
      honda: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/honda.jpg`,//done
      hyundai: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/hyundai.jpg`,//done
      mazda: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/mazda.jpg`,//done
      mercedez: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/mercedez.jpg`,//done
      mini: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/mini.jpg`,//done
      mitsubishi: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/mitsubishi.jpg`,//done
      porsche: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/porsche.jpg`,//done
      toyota: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/toyota.jpg`,//done
      volvo: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/volvo.jpg`,//done
    },
    location: {//this whole done
      angMoKoi: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/ang-mo-koi.jpg`,
      bukitBatok: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/bukit-batok.jpg`,
      bukitPanjang: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/bukit-panjang.jpg`,
      choaChuKang: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/choa-chu-kang.jpg`,
      hougang: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/hougang.jpg`,
      jurong: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/jurong.jpg`,
      punggol: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/punggol.jpg`,
      sembawang: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/sembawang.jpg`,
      sengkang: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/sengkang.jpg`,
      serangoon: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/serangoon.jpg`,
      tampines: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/tampines.jpg`,
      woodlands: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/woodlands.jpg`,
      yishun: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/yishun.jpg`,
    },
    segment: {//this whole done
      compact: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/segment/compact.jpg`,
      luxurySedan: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/segment/luxury-sedan.jpg`,
      luxurySuv: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/segment/luxury-suv.jpg`,
      standardSedan: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/segment/standard-sedan.jpg`,
      standardSuv: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/segment/standard-suv.jpg`,
    },
    cna: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cna.png`,//done
    vulcanPost: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/vulcan.png`,//done
    theStraitsTime: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/straits.png`,//done
    zaobao: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/zao.png`,//done
    landingBannerImage: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/LandingBanner.png`,//done
    seoLandingVideoBanner: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/videoLogo.jpg`,//done
  },
  testimonialImages : {
    noahHaris : `${CLOUDINARY_BASE_URL}/drivemate/assets/testimonialImages/Noah+Harris.jpg`,
    oliviaJohnson : `${CLOUDINARY_BASE_URL}/drivemate/assets/testimonialImages/Olivia+Johnson.jpg`,
    jamesA : `${CLOUDINARY_BASE_URL}/drivemate/assets/testimonialImages/James+A.jpg`,
    miaSmith : `${CLOUDINARY_BASE_URL}/drivemate/assets/testimonialImages/Mia Smith+-+1.jpg`,
    charlotteWalker : `${CLOUDINARY_BASE_URL}/drivemate/assets/testimonialImages/Charlotte+Walker.jpg`,
    michaelTan : `${CLOUDINARY_BASE_URL}/drivemate/assets/testimonialImages/Michael+Tan.jpg`,
    emilyTaylor : `${CLOUDINARY_BASE_URL}/drivemate/assets/testimonialImages/Emily++Taylor.jpg`,
    natashaLim : `${CLOUDINARY_BASE_URL}/drivemate/assets/testimonialImages/Natasha+Lim.jpg`,
  },
  becomeAHost: {
    insuranceLogo: `${CLOUDINARY_BASE_URL}/drivelah/becomeAHost/insuranceLogo-min.png`,
  },
  contact: {//done
    heroImage: `${CLOUDINARY_BASE_URL}/drivelah/contact/contact_background-min.jpg`,
    heroImageMobile1x: `${CLOUDINARY_BASE_URL}/drivelah/contact/contact-background-mobile@1x.jpg`,
    heroImageMobile2x: `${CLOUDINARY_BASE_URL}/drivelah/contact/contact-background-mobile@2x.jpg`,
  },
  newMarketingLandingPage: {
    support: `${CLOUDINARY_BASE_URL}/drivemate/assets/newMarketingLandingPage/24-support.svg`,
    benCar: `${CLOUDINARY_BASE_URL}/drivemate/assets/newMarketingLandingPage/ben-car.svg`,
    car: `${CLOUDINARY_BASE_URL}/drivemate/assets/newMarketingLandingPage/car.svg`,
    chevronRight: `${CLOUDINARY_BASE_URL}/drivemate/assets/newMarketingLandingPage/chevron-right 3.svg`,
    giftWhite: `${CLOUDINARY_BASE_URL}/drivemate/assets/newMarketingLandingPage/gift-white.svg`,
    gift: `${CLOUDINARY_BASE_URL}/drivemate/assets/newMarketingLandingPage/gift.svg`,
    hiwImage: `${CLOUDINARY_BASE_URL}/drivemate/assets/newMarketingLandingPage/hiw-image.svg`,
    instantCar: `${CLOUDINARY_BASE_URL}/drivemate/assets/newMarketingLandingPage/Instant-car.svg`,
    jonathanCar: `${CLOUDINARY_BASE_URL}/drivemate/assets/newMarketingLandingPage/Jonathan-car.svg`,
    likeDislike: `${CLOUDINARY_BASE_URL}/drivemate/assets/newMarketingLandingPage/like-dislike.svg`,
    likeTag: `${CLOUDINARY_BASE_URL}/drivemate/assets/newMarketingLandingPage/like-tag.svg`,
    mitchellCar: `${CLOUDINARY_BASE_URL}/drivemate/assets/newMarketingLandingPage/Mitchell-car.svg`,
    people: `${CLOUDINARY_BASE_URL}/drivemate/assets/newMarketingLandingPage/people.svg`,
    play: `${CLOUDINARY_BASE_URL}/drivemate/assets/newMarketingLandingPage/play.svg`,
    securitySafe: `${CLOUDINARY_BASE_URL}/drivemate/assets/newMarketingLandingPage/security-safe.svg`,
    setting2: `${CLOUDINARY_BASE_URL}/drivemate/assets/newMarketingLandingPage/setting-2.svg`,
    shareenCar: `${CLOUDINARY_BASE_URL}/drivemate/assets/newMarketingLandingPage/Shareen-car.svg`,
    star: `${CLOUDINARY_BASE_URL}/drivemate/assets/newMarketingLandingPage/star.svg`,
    yellowStar: `${CLOUDINARY_BASE_URL}/drivemate/assets/newMarketingLandingPage/yellow-star.svg`,
  },
  TopbarDesktop:{
    hamburger : `${CLOUDINARY_BASE_URL}/drivemate/assets/hamburger.svg`
  },
  updatelisting : {
    infoCircle :  `${CLOUDINARY_BASE_URL}/drivemate/assets/updatelisting/info-circle-solid.svg`
  }
};

export default imageSource;
