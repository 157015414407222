import React from 'react';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_INITIAL_PAYMENT } from '../../util/types';

import css from './ModificationCharges.css';
import LineItemUpdateBookingPeriod from '../BookingBreakdown/LineItemUpdateBookingPeriod';
import LineItemForDistance from '../BookingBreakdown/LineItemForDistance';
import LineItemCustomerCommissionMaybe from '../BookingBreakdown/LineItemCustomerCommissionMaybe';
import LineItemNewExcessReduction from '../BookingBreakdown/LineItemNewExcessReduction';
import LineItemNewExcessReduction2 from '../BookingBreakdown/LineItemNewExcessReduction2';
import LineItemYoungCustomerCommissionMaybe
  from '../BookingBreakdown/LineItemYoungCustomerCommissionMaybe';
import LineItemProcessingFee from '../BookingBreakdown/LineItemProcessingFee';
import LineItemModifyTotal from '../BookingBreakdown/LineItemInitialTotal';
import LineItemOriginalTotal from '../BookingBreakdown/LineItemOriginalTotal';
import ExtraDurationCharges from '../ExtraDurationCharges/ExtraDurationCharges';
import { get } from 'lodash';
import LineItemProtectionFee from '../BookingBreakdown/LineItemProtectionFee';
import { FormattedMessage } from '../../util/reactIntl';
import { IconInfo } from '../../forms/EditTripForm/BookingTimeForm';

const { Money } = sdkTypes;

const ModificationAndExtensionCharges = ({ transaction, isTripExtension, intl, isCustomer, unitType, isProvider, isListingPriceIncreased }) => {
  if (!transaction) return null;

  const compareTripDurations = (
    newStart,
    newEnd,
    oldStart,
    oldEnd,
  ) => {
    const MS_TO_HOURS = 1000 * 60 * 60; // Convert ms to hours
    const newDuration = (newEnd - newStart) / MS_TO_HOURS;
    const oldDuration = (oldEnd - oldStart) / MS_TO_HOURS;
    const differenceInHours = newDuration - oldDuration;
    const absDifference = Math.abs(differenceInHours);

    // Round to nearest whole number
    const roundedDifference = Math.round(absDifference);

    let differenceFormatted = '';
    if (roundedDifference >= 24) {
      const days = Math.round(roundedDifference / 24);
      differenceFormatted = `${differenceInHours > 0 ? 'For' : 'less'
      } ${days} day${days > 1 ? 's' : ''}`;
    } else if (roundedDifference >= 1) {
      differenceFormatted = `${differenceInHours > 0 ? 'For' : 'less'
      } ${roundedDifference} hour${roundedDifference > 1 ? 's' : ''}`;
    } else {
      const minutes = Math.round(absDifference * 60);
      differenceFormatted = `${differenceInHours > 0 ? 'For' : 'less'
      } ${minutes} min${minutes > 1 ? 's' : ''}`;
    }

    return {
      isSameOrShorter: newDuration < oldDuration,
      differenceInHours,
      differenceFormatted,
    };
  };

  const { updateBookingStartTimestamp, updateBookingEndTimestamp } = get(
    transaction,
    'attributes.protectedData',
    {}
  );

  const { bookingDisplayStart, bookingDisplayEnd } = get(
    transaction,
    'attributes.protectedData.initialTx.attributes.protectedData',
    {}
  );


  const initialPaymentLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_INITIAL_PAYMENT
  );

  const payinTotal = transaction.attributes.payinTotal;

  const totalPrice = isTripExtension ? payinTotal.amount : payinTotal.amount - Math.abs(initialPaymentLineItem.lineTotal.amount);

  const formattedTotalPrice = formatMoney(intl, new Money(totalPrice, payinTotal.currency), 2);

  const extraDuration = compareTripDurations(
    updateBookingStartTimestamp, updateBookingEndTimestamp, bookingDisplayStart, bookingDisplayEnd
  );

  return (
    <div className={css.modificationChargesRoot}>
      <div className={css.headTitle}>
        <span className={css.headText}>
          {isTripExtension ? 'Extension charges' : 'Modified trip price'}
        </span>
        {isTripExtension && <span className={css.head}>{extraDuration.differenceFormatted}</span>}
      </div>
      {isListingPriceIncreased && (
        <div className={css.listingPriceChange}>
          <IconInfo fill="#000000"/>
          <p className={css.updateBookingSubTitle}>
            <FormattedMessage id="BookingTimeForm.priceChangedMessage" />
          </p>
        </div>
        )}
      <div className={isTripExtension ? css.checkoutBreakdownContainer : css.explanationBreakdownContainer }>
        <LineItemUpdateBookingPeriod
          transaction={transaction}
          isCustomer={isCustomer}
          intl={intl}
          isProvider={isProvider}
          isExplanationBreakdown={true}
          isEditTripPage={true}
        />
        <LineItemForDistance
          transaction={transaction}
          isCustomer={isCustomer}
          intl={intl}
          isProvider={isProvider}
          isEditTripPage={true}
        />
        <LineItemCustomerCommissionMaybe
          transaction={transaction}
          isCustomer={isCustomer}
          intl={intl}
          isExplanationBreakdown={true}
        />
        <LineItemNewExcessReduction
          transaction={transaction}
          isCustomer={isCustomer}
          intl={intl}
          unitType={unitType}
          isExplanationBreakdown={true}
          isEditTripPage={true}
        />
        <LineItemNewExcessReduction2
          transaction={transaction}
          isCustomer={isCustomer}
          intl={intl}
          unitType={unitType}
          isExplanationBreakdown={true}
          isEditTripPage={true}
        />
        <LineItemYoungCustomerCommissionMaybe
          transaction={transaction}
          isCustomer={isCustomer}
          intl={intl}
          isExplanationBreakdown={true}
        />
        <LineItemProtectionFee
          transaction={transaction}
          isCustomer={isCustomer}
          isProvider={isProvider}
          intl={intl}
          isExplanationBreakdown={true}
        />
        <LineItemProcessingFee
          transaction={transaction}
          isCustomer={isCustomer}
          isProvider={isProvider}
          intl={intl}
          isExplanationBreakdown={true}
        />
        <LineItemModifyTotal transaction={transaction} intl={intl} />
      </div>

      {!isTripExtension && (
        <div className={css.explanationBreakdownContainer}>
          <div className={css.explanationContainerTitle}>
            Original trip price
            <span className={css.paidBanner}>Paid</span>
          </div>
          <LineItemOriginalTotal transaction={transaction} intl={intl} />
        </div>
      )}

      {!isTripExtension && <ExtraDurationCharges transaction={transaction} intl={intl} />}
    </div>
  );
};

export default ModificationAndExtensionCharges;
