import { array, func, object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { injectIntl } from '../../util/reactIntl';
import styles from './UnpaidInvoices.css';
import IconSpinner from '../IconSpinner/IconSpinner';
import helpImg from '../../assets/newPickupAndDropoff/FAQ-BLUE.svg';
import { createUnpaidDuesTransaction } from '../../containers/TransactionPage/TransactionPage.duck';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton } from '../Button/Button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import AlertBox from '../AlertBox/AlertBox';
import Modal from '../Modal/Modal';

export const UnpaidInvoicesComponent = ({
  openInvoices,
  onRefreshInvoices,
  showPayButton = false ,
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [invoices, setInvoices] = useState(openInvoices || []);
  const [showHelpModaL, setShowHelpModaL] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('success'); // 'success' or 'error'
  const [alertMessage, setAlertMessage] = useState('');

  const dispatch = useDispatch();

  // Getting current user from state
  const currentUser = useSelector(state => state.user.currentUser);
  
  useEffect(() => {
    if (openInvoices) {
      setIsLoading(false);
      setInvoices(openInvoices);
    }
  }, openInvoices);

  const hanleRefeshInvoices = () => {
    setIsLoading(true);
    onRefreshInvoices();
  }


  const onPayUnpaidInvoicesClick = async () => {
    try {
      setIsPaymentLoading(true);
      setShowAlert(false); // Reset alert state before starting new payment
      const totalAmount = invoices.reduce((acc, invoice) => acc + invoice.total, 0);
      const unpaidDues = {
        unpaidInvoices: invoices.map(invoice => ({
          id: invoice.invoiceId,  
          amount: +((invoice.total * 100).toFixed(0)),   
        })) || [],
        totalUnpaidAmount: +((totalAmount*100).toFixed(0)),
      };
      console.log('unpaidDues', unpaidDues);
      
      // Properly dispatch the thunk action
      await dispatch(createUnpaidDuesTransaction({ unpaidDues, currentUser }));
      console.log('Payment successful');
      
      setAlertType('success');
      setAlertMessage('Unpaid dues have been paid successfully.');
      setShowAlert(true);
      
      // Wait for alert to be visible before redirecting
      await new Promise(resolve => setTimeout(resolve, 2000));
      history.push('/'); 
    } catch (error) {
      console.error('Payment failed:', error);
      setAlertType('error');
      
      // Handle specific Stripe error messages
      let errorMessage = 'Failed to process payment. Please try again or contact support if the issue persists.';
      
      if (error?.error?.message) {
        // If it's a Stripe error with a specific message
        errorMessage = error.error.message;
      } else if (error?.message) {
        // If it's a general error with a message
        errorMessage = error.message;
      }
      
      setAlertMessage(errorMessage);
      setShowAlert(true);
    } finally {
      setIsPaymentLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <IconSpinner />
        <div>Please wait...</div>
      </div>
    );
  }

  return (
    <>
      <div className={`${showPayButton ? styles.defaultContainer : styles.simpleContainer}`}>

        <div className={styles.header}>
          <p> {showPayButton 
            ?  "Clear unpaid dues" 
            : "Unpaid dues"}</p>
          {/* <div className={styles.helpBtn}>
            <button className={styles.needHelpBtn} onClick={() => setShowHelpModaL(true)}> <img src={helpImg} alt='#' />Need help?</button>
          </div> */}
        </div>

        <p className={`${styles.subHeader} ${showPayButton ? styles.subHeaderWithBorder : ''}`}>
        {showPayButton 
          ? "You have unpaid dues with Drive mate. Please review your unpaid invoices below and click 'Pay' to settle them."
          : "You have the following unpaid dues with Drive mate. The details below are same as invoices shared with you earlier as well."}
        </p>

        <div className={styles.unpaidInvoicesContainer}>

          {invoices?.length && invoices.map((invoice, index) => (
            <div key={invoice.invoiceId} className={styles.invoiceListContainer}>
              <div className={styles.invoiceContent}>
                <div className={styles.invoiceDetails}>
                  
                  <div className={styles.invoiceNumber}>
                    Invoice #{invoice.invoiceNumber} for Trip #{invoice?.metadata?.tripReferenceCode || 'N/A'}
                  </div>
                  
                  <div className={styles.invoiceAmount}>
                   Due amount: ${invoice.total.toFixed(2)}
                  </div>
                  
                </div>

                {/* <a href={invoice.invoiceLink} target="_blank" rel="noopener noreferrer" className={styles.invoiceLink}>
                  <button className={styles.payButton}>Pay now</button>
                </a> */}
              
              </div>

              {invoice.description && invoice.description.split('\n').map((item, index) => (
                <div key={index} className={styles.invoiceDescription}>{item}</div>
              ))}

            </div>
          )
          )}
        </div>
        </div>

        {showPayButton && invoices.length > 0 && (
          <div className={styles.bottomBar}>
            <div className={styles.bottomBarContent}>
              <span className={styles.totalAmount}>
                ${invoices.reduce((acc, invoice) => acc + invoice.total, 0).toFixed(2)}
              </span>
              <span>
              <PrimaryButton 
                onClick={onPayUnpaidInvoicesClick}
                className={styles.payButton}
                inProgress={isPaymentLoading}
              >
                Pay
              </PrimaryButton>
              </span>
            </div>
          </div>
        )}

      {showAlert && (
        <div className={styles.alertContainer}>
          <AlertBox 
            type={alertType}
            title={alertType === 'success' ? "Payment Successful" : "Payment Failed"}
            message={alertMessage}
            autoCloseTime = {alertType === 'success' ? 4000 : 6000}
          />
        </div>
      )}

      {/* <div className={styles.bottomBar}>
        <p>Made the payment? Refresh page to update status</p>
        <button type="button" onClick={hanleRefeshInvoices} className={styles.reutrnToHomeBtn}>Refresh invoices</button>
      </div> */}

      <Modal  
        onClose={() => setShowHelpModaL(false)}
        isOpen={showHelpModaL}
        containerClassName={styles.modalCustomContainer}
        scrollLayerClassName={styles.modalScrollLayer}
        contentClassName={styles.modalContent}
      />
    </>
  );
};

UnpaidInvoicesComponent.defaultProps = {
  openInvoices: null,
  showPayButton: false,
};

UnpaidInvoicesComponent.propTypes = {
  openInvoices: object.isRequired,
  onRefreshInvoices: func.isRequired,
};

const UnpaidInvoices = injectIntl(UnpaidInvoicesComponent);

UnpaidInvoices.displayName = 'UnpaidInvoices';

export default UnpaidInvoices;
